import { EntityStatus } from '../../../../Global/Interfaces/EntityInterface';
import {
    CustomFieldType,
    ICustomSection,
} from '../../../../Global/Interfaces/TemplateInterface';
import { VerificationStatus } from '../../../../Redux/Slices/VerificationSlice';
import { IAdditionalCost } from '../../../Admin/Services/VendorsService';

export enum BuyerSellerStatus {
    // SETUP_PENDING = 'SETUP_PENDING',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    INVITED = 'INVITED',
}

export interface IBuyerContact {
    buyer_contact_id: string;
    seller_entity: string | null;
    buyer_entity: string | null;
    full_name: string;
    primary_email: string;
    phone_numbers: string[];
    tags: string[];
    is_primary: boolean;
    user: string | null;
    status: BuyerSellerStatus;
    emails: {
        type: 'SECONDARY' | 'CC';
        email: string;
    }[];
    designation: string;
    department: string;
}

export interface IBuyerMaster {
    enterprise_buyer_master_id: string;
    seller_enterprise: string;
    buyer_enterprise: string;
    buyer_entity: {
        entity_id: string;
        entity_name: string;
        entity_description: string | null;
        buyer_status: BuyerSellerStatus;
        seller_status: BuyerSellerStatus;
        contacts: {
            emails: string[];
            websites: string[];
            phone_numbers: number[];
        };
        verification_status: VerificationStatus;
        is_virtual: boolean;
    };
    buyer_code: string;
    buyer_name: string;
    status: BuyerSellerStatus; // vendor master status
    notes: string | null;
    assigned_seller_entity_count: number;
    primary_buyer_contact: IBuyerContact;
    created_datetime: string;
    modified_datetime: string;
    seller_entities: {
        seller_entity_id: string;
        seller_entity_name: string;
    }[];
    tags: string[];
    hasNext?: boolean;
}

export interface IBuyerContactDetails {
    full_name: string;
    primary_email: string;
    emails: {
        email: string;
        type: {
            isSecondary: boolean;
            isCC: boolean;
        };
    }[];
    phone_numbers: string[];
    tags: [];
    notes: string | null;
    isPrimary: boolean;
    designation: string;
    department: string;
}
export interface IBuyerAddDetails {
    buyer_enterprise_id?: string;
    buyer_address_information: string[];
    buyer_identifications: {
        identification_name: string;
        identification_value: string;
    }[];
    buyer_entity_id?: string;
    buyer_code: string;
    buyer_name: string;
    notes: string | null;
    // primary_contact: IBuyerContactDetails;
    contacts: IBuyerContactDetails[];
    entity_ids: string[];
    tags: string[];
    custom_sections: ICustomSection[];
}

export interface IBuyerSearchedData {
    entity_id: string;
    enterprise_id: string;
    enterprise_name?: string;
    entity_name: string;
    entity_description: null;
    buyer_status: BuyerSellerStatus;
    seller_status: BuyerSellerStatus;
    contacts: {
        emails: string[];
        websites: string[];
        phone_numbers: string[];
    };
    verification_status: VerificationStatus;
    is_virtual: boolean;
}

export interface IBuyerCustomField {
    type: CustomFieldType;
    is_required: boolean;
    is_locked: boolean;
    description: string;
    name: string;
    value: string | boolean | null | string[] | number;
}

export interface IBuyerDetails {
    enterprise_buyer_master_id: string;
    primary_buyer_contact: IBuyerContact;
    secondary_buyer_contacts: IBuyerContact[];
    additional_costs: IAdditionalCost[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    custom_sections: ICustomSection[];
    buyer_identifications: {
        identification_name: string;
        identification_value: string;
    }[];
    buyer_address_information: string[];
    buyer_code: string;
    buyer_name: string;
    status: BuyerSellerStatus;
    notes: string | null;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    seller_enterprise: string;
    buyer_enterprise: string;
    buyer_entity: string;
    tags: string[];
}

export interface IEntityViewBuyerMaster {
    entity_buyer_master_id: string;
    buyer_entity: {
        entity_id: string;
        entity_name: string;
        entity_logo: [];
        entity_description: string | null;
        buyer_status: EntityStatus;
        seller_status: EntityStatus;
        contacts: {
            emails: string[];
            websites: string[];
            phone_numbers: string[];
        };
        verification_status: VerificationStatus;
        is_virtual: boolean;
    };
    enterprise_buyer_master: {
        enterprise_buyer_master_id: string;
        buyer_code: string;
        buyer_name: string;
        status: BuyerSellerStatus;
        tags: string[];
    };
    primary_buyer_contact: IBuyerContact;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    created_by_user: string;
    modified_by_user: string | null;
    deleted_by_user: string | null;
    seller_enterprise: string;
    seller_entity: string;
    buyer_enterprise: string;
}
