import { templateType } from '../Interfaces/TemplatePageInterfaces';

export const getTemplateTypeFixedProperties = (templateType: templateType) => {
    // Hi :)

    // U could add either ur fied in the commonIsVisibleFields or do how its done for quote calc and visits
    let commonisRequiredFields = [
        'Quantity',
        'Target rate',
        'Shipping rate',
        'Tax',
    ];

    let commonIsVisibleFields = [
        'Event name',
        'Event type',
        'Buyer Entity',
        'Event deadline',
        'Currency',
        'Delivery date',
        'Quantity',
        'Quote name',
        'Entity',
        'Template',
        'Quote currency',
        'Customer name',
        'Quote deadline',
        'Quote validity',
        'Target rate',
        'Taxes',
        'Additional costs',
        'Discount',
    ];

    let commonIsNegotiableFields = [
        'Quantity',
        'Target rate',
        'Taxes',
        'Additional costs',
        'Discount',
        'Delivery date',
    ];

    let commonIsMandatoryFields = ['Quantity', 'Target rate'];

    if (templateType === 'QUOTE CALCULATOR') {
        commonIsMandatoryFields = [...commonIsMandatoryFields, 'Customer name'];
        commonIsVisibleFields = [
            ...commonIsVisibleFields,
            'Overall additional costs',
            'BOM additional costs',
            'Lead time',
            'Project',
            'GL',
            'Cost center',
            'BOM',
            'Customer',
        ];
    } else if (templateType === 'RFQ') {
        commonIsVisibleFields = commonIsVisibleFields.filter(
            (field) => !['Event deadline'].includes(field)
        );
    } else if (templateType === 'VISIT') {
        commonIsVisibleFields = [...commonIsVisibleFields, 'Attachments'];
    }

    return {
        is_required: commonisRequiredFields,
        is_visible: commonIsVisibleFields,
        is_negotiable: commonIsNegotiableFields,
        is_mandatory: commonIsMandatoryFields,
    };
};

export const fixedProperties = {
    is_required: ['Quantity', 'Target rate', 'Shipping rate', 'Tax'],
    is_visible: [
        'Event name',
        'Event type',
        'Buyer Entity',
        'Event deadline',
        'Currency',
        'Delivery date',
        'Quantity',
        'Quote name',
        'Entity',
        'Template',
        'Quote currency',
        'Customer name',
        'Quote deadline',
        'Quote validity',
        'Target rate',
        'Taxes',
        'Additional costs',
        'Discount',
    ],
    is_negotiable: [
        'Quantity',
        'Target rate',
        'Taxes',
        'Additional costs',
        'Discount',
        'Delivery date',
    ],
    is_mandatory: ['Quantity', 'Target rate'],
};
export const isOnlybuyerField = (name: string) =>
    ['GR tolerance', 'Requisition', 'GL', 'Cost center', 'Project'].includes(
        name.trim()
    );

export const showOption = (name: string, templateType: string) => ({
    vendorCanView:
        ['RFQ', 'PO GROUP', 'ITEM CART'].includes(templateType) &&
        !isOnlybuyerField(name),
    vendorCanNegotiate:
        ['RFQ', 'ITEM CART', 'QUOTE CALCULATOR'].includes(templateType) &&
        !isOnlybuyerField(name),
    vendorMandatory:
        ['RFQ', 'ITEM CART'].includes(templateType) && !isOnlybuyerField(name),
});

export const getAdditionalCostType = (name: string) => {
    if (
        [
            'Additional costs',
            'BOM additional costs',
            'Overall additional costs',
        ].includes(name)
    ) {
        return 'ADDITIONAL_COST';
    } else if (['Taxes', 'Overall taxes'].includes(name)) {
        return 'TAX';
    } else return '';
};

export const getFieldLevel = (
    templateType: templateType,
    sectionName?: string
) => {
    switch (templateType) {
        case 'QUOTE CALCULATOR':
            if (
                sectionName &&
                ['Quote Details', 'Overall Additional costs'].includes(
                    sectionName
                )
            ) {
                return 'OTHER';
            } else if (sectionName === 'BOM') {
                return 'BOM';
            }
            return 'ITEM';
        case 'ITEM':
        case 'ITEM CART':
            return 'ITEM';
        case 'PROJECT':
        case 'VENDOR':
        case 'BUYER':
            return 'OTHER';
        case 'PO GROUP': {
            if (
                sectionName &&
                ['PO Group Details', 'Group Details'].includes(sectionName)
            ) {
                return 'OTHER';
            }
            return 'ITEM';
        }
        case 'RFQ': {
            if (sectionName === 'Event Details') {
                return 'OTHER';
            } else {
                return 'ITEM';
            }
        }
        case 'CLM': {
            if (sectionName === 'Contract Details') {
                return 'OTHER';
            }
            return 'ITEM';
        }
        case 'BOM': {
            if (sectionName === 'Item Terms') {
                return 'ITEM';
            }
            return 'BOM';
        }

        case 'REQUISITION': {
            if (sectionName === 'Requisition Details') {
                return 'OTHER';
            }
            return 'ITEM';
        }

        default: {
            return 'OTHER';
        }
    }
};

const CommonFieldsTohide = [
    'Target rate value',
    'Target rate visibility',
    'Tax name',
    'Tax value',
    'Payment terms (days)',
    'Payment terms applied from',
    'Allocation percentage',
    'Payment term',
    'Payment term period',
    'Payment from date',
    'Requisition number',
    'Requisition approval',
    'Payment type',
    'Payment from options',
    'Prepayment percentage',
    // 'Procurement item',
    'Purchasing currency code',
    'Purchasing price',
    // 'Sales item',
    'Selling currency code',
    'Selling price',
];

const hideFieldsRFQ = [
    ...CommonFieldsTohide,
    'Item',
    'Specification',
    'Specification name',
    'Specification value',
    'Measurement unit',
    'Lead time value',
    'Lead time period',
    'Quantity Information',
    'Discount information',
    'Overall discount information',
];

const hideFieldsBuyer = [
    ...CommonFieldsTohide,
    'Customer identification value',
    'Customer identification name',
    'Primary contact notes',
    'Primary contact phone number',
    'Primary contact email',
    'Primary contact full name',
    'Secondary contact notes',
    'Secondary contact phone number',
    'Secondary contact email',
    'Secondary contact full name',
    'Primary contact secondary email',
    'Primary contact cc email',
    'Secondary contact secondary email',
    'Secondary contact cc email',
];

const hideFieldsItemCart = [
    ...CommonFieldsTohide,
    'Item',
    'Specification',
    'Specification name',
    'Specification value',
    'Measurement unit',
    'Lead time value',
    'Lead time period',
    'Quantity Information',
    'Discount information',
];

const hideFieldsRequistion = [
    ...CommonFieldsTohide,
    'Lead time value',
    'Item',
    'Quantity Information',
    'Discount information',
    'Specification',
    'Specification name',
    'Specification value',
    'Measurement unit',
    'Lead time period',
];

const hideFieldsCLM = [
    ...CommonFieldsTohide,
    'Lead time value',
    'Specification',
    'Specification name',
    'Specification value',
    'Lead time period',
    'Measurement unit',
    'Currency Code',
    'Currency code',
    'Discount information',
];

const hideFieldsItem = [
    ...CommonFieldsTohide,
    'Lead time',
    'Specification name',
    'Specification value',
    'Lead time period',
    'Identifier value',
    'Identifier name',
];

const hideFieldsQuoteCalc = [
    ...CommonFieldsTohide,
    'Lead time value',
    'Specification name',
    'Specification value',
    'Lead time period',
    'Quantity Information',
    'Identifier value',
    'Identifier name',
];

const hideFieldsBoms = [...CommonFieldsTohide, 'Discount information'];

const hideFieldsVendor = [
    ...CommonFieldsTohide,
    'Primary contact notes',
    'Primary contact phone number',
    'Primary contact email',
    'Primary contact full name',
    'Secondary contact notes',
    'Secondary contact phone number',
    'Secondary contact email',
    'Secondary contact full name',
    'Vendor identification value',
    'Primary contact secondary email',
    'Primary contact cc email',
    'Secondary contact secondary email',
    'Secondary contact cc email',
    'Vendor identification name',
];

export const getHiddenFieldsForTemplateType = (templateModule: string) => {
    switch (templateModule) {
        case 'RFQ':
        case 'PO GROUP':
            return hideFieldsRFQ;
        case 'CLM':
            return hideFieldsCLM;
        case 'ITEM':
            return hideFieldsItem;
        case 'VENDOR':
            return hideFieldsVendor;
        case 'ITEM CART':
            return hideFieldsItemCart;
        case 'QUOTE CALCULATOR':
            return hideFieldsQuoteCalc;
        case 'BUYER':
            return hideFieldsBuyer;
        case 'BOM':
            return hideFieldsBoms;
        case 'REQUISITION':
            return hideFieldsRequistion;
        default:
            return hideFieldsRequistion;
    }
};
export const fixedChoiceDropdowns = [
    'Assigned to',
    'BOM',
    'Buyer address',
    'Buyer contact',
    'Buyer entity',
    'Buyer Entity',
    'Buyer identification',
    'Buyer project',
    'Cost center',
    'Creator',
    'Currency',
    'Currency code',
    'Customer',
    'Customer name',
    'Default item terms',
    'Entity',
    'Event type',
    'GL',
    'Incoterms',
    'Item',
    'Item currency',
    'Measurement unit',
    'Overall currency',
    'Project',
    'Project managers',
    'Quote currency',
    'Requisition',
    'Requisition status',
    'Raw material code',
    'Shipping Address',
    'Sub BOM ID',
    'Template',
    'Vendor',
    'Vendor address',
    'Vendor contact',
    'Vendor entity',
    'Vendor identification',
    'Finished good code',
    'BOM currency',
    'BOM measurement unit',
    'Customer address',
];
