import { styled } from '@mui/material';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
    calculateAddtionalCost,
    calculateAddtionalCostEventLevel,
    convertAdditionalCostBackendToFrontend,
    convertAdditionalCostFrontendToBackend,
} from '../../../../AdditionalCost/helpers';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { IEventItemDetails } from '../../../../Events/Interfaces/Buyer/RFQ.model';
import { useGetRFQItemDetailsQuery } from '../../../../Events/Services/Buyer/RFQ.service';
import { isValueValidForStringArrNull } from '../../../../Global/Helpers/helpers';
import {
    transformPaymentTermsFromBackendToFrontend,
    transformPaymentTermsFromFrontendToBackend,
} from '../../../../Global/Helpers/paymentTermsHelpers';
import { IRfqTemplate } from '../../../../Global/Interfaces/TemplateInterface';
import {
    ICustomSellerBid,
    ICustomSellerItemBid,
    IGetSellerRFQBid,
    ISellerRFQDetails,
} from '../../../../Models/SellerRFQ.model';
import { updateRfqOpeningBid } from '../../../../Redux/Slices/RfqOpeningBidSlice';
import {
    postSellerRFQBid,
    putSellerRFQBid,
    putSellerRFQBidDetails,
} from '../../../../Services/seller/RfqOperations';
import { post } from '../../../../Utils/api';
// import { calculateNewItemTotalWithDecimalPlaces } from '../../../Shared/totalCalculator';
import { roundToNDecimalPlace } from '../../../../Common/CurrencyUtilsComponent';
import { calculateNewItemTotalWithDecimalPlaces } from '../../../Shared/totalCalculator';
import {
    modifyCustomSectionForUpdate,
    removeNullFromCustomFields,
} from '../../../../Global/Helpers/templateHelpers';

export const initializeNewBidItem = (
    rfqDetails: ISellerRFQDetails,
    seller_entity_id: string
): ICustomSellerBid => {
    return {
        rfq_bid_id: '',
        seller_entity_id: seller_entity_id,
        bulk_discount_percentage: '0',
        attachments: [],
        currency_code_symbol:
            rfqDetails.rfq_default_information.default_currency_symbol,
        bulk_discount_minimum_order_value: '0',
        currency_code_abbreviation:
            rfqDetails.rfq_default_information.default_currency_abbreviation,
        currency_code_id:
            rfqDetails.rfq_default_information.default_currency_id,
        internal_vendor_quote_id: `Q-${moment().format('YYYYMMDDHHmmss')}`,
        rfq_id: rfqDetails.rfq_entry_id,
        action: 'SAVE_DRAFT_OPENING_QUOTE',
        parent_bid: null,
        validity_datetime: null,
        removed_item_list: {},
        bid_items: {},
        custom_sections: [],
        bid_total: 0,
        vendor_bid_total: 0,
        items_additional_cost_total: 0,
        items_discount_total: 0,
        items_subtotal: 0,
        items_taxes_total: 0,
        items_total: 0,
        vendor_items_additional_cost_total: 0,
        vendor_items_discount_total: 0,
        vendor_items_taxes_total: 0,
        vendor_items_subtotal: 0,
        vendor_items_total: 0,
        additional_costs: [],
        discounts: [],
        taxes: [],
    };
};

export const initializeExsistingBidItem = (
    bidDetails: IGetSellerRFQBid,
    rfq_id: string
): ICustomSellerBid => {
    let removed_items: { [key: string]: any } = {};
    bidDetails.rfq_bid_items.forEach((item) => {
        if (item.bid_item_response_type === 'REMOVED') {
            removed_items[item.rfq_item] = {
                rfq_item_id: item.rfq_item,
                rfq_item_entry_id: item.rfq_item_entry_id,
                notes: item.notes,
            };
            if ('rfq_bid_item_id' in item)
                removed_items[item.rfq_item].rfq_bid_item_id =
                    item.rfq_bid_item_id;
        }
    });
    let negotiated_items: { [key: string]: any } = {};
    bidDetails.rfq_bid_items.forEach((item) => {
        if (item.bid_item_response_type === 'RENEGOTIATING') {
            // const {
            //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
            //     deliverables_payment_terms,
            //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
            //     payment_terms,
            //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
            //     payment_type,
            //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
            //     prepayment_percentage,
            // } = transformPaymentTermsFromFrontendToBackend({
            //     paymentTerms: item.paymentTerms,
            // });
            negotiated_items[item.rfq_item] = {
                rfq_bid_item_id: item.rfq_bid_item_id,
                rfq_item_id: item.rfq_item,
                quantity_information: item.quantity_information,
                pricing_information: item.pricing_information,
                delivery_information: item.delivery_information,
                property_information: [...item.property_information],
                procurement_information: item.procurement_information,
                incoterm: item.incoterm,
                notes: item.notes,
                attachments: item.attachments,
                custom_sections: item.custom_sections,
                paymentTerms: item.paymentTerms,
                // deliverables_payment_terms,
                // payment_terms,
                // payment_type,
                // prepayment_percentage,
            };
        }
    });

    return {
        rfq_bid_id: bidDetails.rfq_bid_id,
        parent_bid: bidDetails.parent_bid,
        seller_entity_id: bidDetails.seller_entity,
        attachments: bidDetails.attachments,
        bulk_discount_percentage:
            bidDetails.pricing_information.bulk_discount_percentage,
        currency_code_symbol: bidDetails.pricing_information.currency_symbol,
        bulk_discount_minimum_order_value:
            bidDetails.pricing_information.bulk_discount_minimum_order_value,
        currency_code_abbreviation:
            bidDetails.pricing_information.currency_code_abbreviation,
        currency_code_id: bidDetails.pricing_information.currency_code_id,
        internal_vendor_quote_id: bidDetails.internal_vendor_quote_id,
        rfq_id: rfq_id,
        action: '',
        validity_datetime: bidDetails.validity_datetime!,
        removed_item_list: removed_items,
        bid_items: negotiated_items,
        custom_sections: bidDetails.custom_sections,
        // custom_fields_negotiate: bidDetails.custom_fields_negotiate,
        bid_total: bidDetails.pricing_information.bid_total,
        vendor_bid_total: bidDetails.pricing_information.vendor_bid_total,
        items_additional_cost_total:
            bidDetails.pricing_information.items_additional_cost_total,
        items_discount_total:
            bidDetails.pricing_information.items_discount_total,
        items_subtotal: bidDetails.pricing_information.items_subtotal,
        items_taxes_total: bidDetails.pricing_information.items_taxes_total,
        items_total: bidDetails.pricing_information.items_total,
        vendor_items_additional_cost_total:
            bidDetails.pricing_information.vendor_items_additional_cost_total,
        vendor_items_discount_total:
            bidDetails.pricing_information.vendor_items_discount_total,
        vendor_items_taxes_total:
            bidDetails.pricing_information.vendor_items_taxes_total,
        vendor_items_subtotal:
            bidDetails.pricing_information.vendor_items_subtotal,
        vendor_items_total: bidDetails.pricing_information.vendor_items_total,
        additional_costs: convertAdditionalCostBackendToFrontend(
            bidDetails.additional_costs
        ),
        discounts: convertAdditionalCostBackendToFrontend(bidDetails.discounts),
        taxes: convertAdditionalCostBackendToFrontend(bidDetails.taxes),
    };
};

export const customObject: ICustomSellerBid = {
    seller_entity_id: '',
    bulk_discount_percentage: '',
    bulk_discount_minimum_order_value: '',
    attachments: [],
    currency_code_symbol: '',
    currency_code_abbreviation: '',
    currency_code_id: '',
    rfq_id: '',
    validity_datetime: null,
    rfq_bid_id: '',
    internal_vendor_quote_id: '',
    action: '',
    parent_bid: null,
    removed_item_list: {},
    bid_items: {},
    custom_sections: [],
    bid_total: 0,
    vendor_bid_total: 0,
    items_additional_cost_total: 0,
    items_discount_total: 0,
    items_subtotal: 0,
    items_taxes_total: 0,
    items_total: 0,
    vendor_items_additional_cost_total: 0,
    vendor_items_discount_total: 0,
    vendor_items_subtotal: 0,
    vendor_items_taxes_total: 0,
    vendor_items_total: 0,
    additional_costs: [],
    discounts: [],
    taxes: [],
};

export const initNewBidItem = (
    rfq_item_details: IEventItemDetails,
    rfqTemplate: IRfqTemplate
): ICustomSellerItemBid => {
    return {
        rfq_item_id: rfq_item_details.rfq_item_entry_id,
        quantity_information: {
            quantity: (+rfq_item_details.quantity_information
                .quantity).toString(),
            measurement_unit_id:
                rfq_item_details.quantity_information.measurement_unit_id,
            measurement_unit_name:
                rfq_item_details.quantity_information.measurement_unit_name,
        },
        pricing_information: {
            price:
                rfq_item_details.pricing_information.desired_price === null
                    ? '0'
                    : (+rfq_item_details.pricing_information
                          .desired_price).toString(),
            currency_code_id:
                rfq_item_details.pricing_information.currency_code_id,
            currency_symbol:
                rfq_item_details.pricing_information.currency_symbol,
            shipping_per_unit: rfqTemplate.fieldSettings.standardFields
                .SHIPPING_RATE.sellerSettings.negotiable
                ? rfq_item_details.pricing_information.desired_price === null
                    ? '0'
                    : (+rfq_item_details.pricing_information
                          .shipping_per_unit).toFixed(2)
                : (+rfq_item_details.pricing_information
                      .shipping_per_unit).toFixed(2),
            additional_charges:
                rfq_item_details.pricing_information.additional_charges === null
                    ? []
                    : rfq_item_details.pricing_information.additional_charges.map(
                          (charge) => {
                              return {
                                  charge_name: charge.charge_name,
                                  charge_value: charge.charge_value.toString(),
                              };
                          }
                      ),
            currency_code_abbreviation:
                rfq_item_details.pricing_information.currency_code_abbreviation,
            // taxes: convertAdditionalCostBackendToFrontend(
            //     rfq_item_details.pricing_information.taxes ?? []
            // ),
            // additional_costs: convertAdditionalCostBackendToFrontend(
            //     rfq_item_details.pricing_information.additional_costs ?? []
            // ),
            // discounts: convertAdditionalCostBackendToFrontend(
            //     rfq_item_details.pricing_information.discounts ?? []
            // ),
            // TODO: fix the things over here
            taxes: convertAdditionalCostBackendToFrontend(
                rfq_item_details.pricing_information.taxes
            ) as any,
            additional_costs: convertAdditionalCostBackendToFrontend(
                rfq_item_details.pricing_information.additional_costs
            ) as any,
            discounts: convertAdditionalCostBackendToFrontend(
                rfq_item_details.pricing_information.discounts
            ) as any,
        },
        delivery_information: rfq_item_details.delivery_information,
        property_information: [...rfq_item_details.property_information],
        procurement_information: {
            lead_time:
                rfq_item_details.procurement_information.lead_time ?? '0',
            lead_time_period:
                rfq_item_details.procurement_information.lead_time_period &&
                rfq_item_details.procurement_information.lead_time_period
                    .length > 0
                    ? rfq_item_details.procurement_information.lead_time_period
                    : 'DAYS',
        },
        paymentTerms: rfq_item_details.paymentTerms,
        incoterm: rfq_item_details.incoterm,
        notes: '',
        attachments: [],
        custom_sections: rfq_item_details.custom_sections,
    };
};

export const initExistingBidItem = (
    bid_item_details: ICustomSellerItemBid
): ICustomSellerItemBid => {
    return {
        rfq_bid_item_id: bid_item_details.rfq_bid_item_id,
        rfq_item_id: bid_item_details.rfq_item_id,
        quantity_information: {
            quantity: bid_item_details.quantity_information.quantity,
            measurement_unit_id:
                bid_item_details.quantity_information.measurement_unit_id,
            measurement_unit_name:
                bid_item_details.quantity_information.measurement_unit_name,
        },
        pricing_information: {
            price: bid_item_details.pricing_information.price,
            currency_code_id:
                bid_item_details.pricing_information.currency_code_id,
            currency_symbol:
                bid_item_details.pricing_information.currency_symbol,
            shipping_per_unit:
                bid_item_details.pricing_information.shipping_per_unit,
            additional_charges:
                bid_item_details.pricing_information.additional_charges,
            additional_costs: bid_item_details.pricing_information
                .additional_costs as any,
            discounts: bid_item_details.pricing_information.discounts as any,
            currency_code_abbreviation:
                bid_item_details.pricing_information.currency_code_abbreviation,
            taxes: bid_item_details.pricing_information.taxes as any,
        },
        delivery_information: bid_item_details.delivery_information,
        property_information: [...bid_item_details.property_information],
        paymentTerms: bid_item_details.paymentTerms,

        procurement_information: {
            lead_time: bid_item_details.procurement_information.lead_time,
            lead_time_period:
                bid_item_details.procurement_information.lead_time_period,
        },
        incoterm: bid_item_details.incoterm,
        notes: bid_item_details.notes,
        attachments: bid_item_details.attachments,
        custom_sections: removeNullFromCustomFields(
            bid_item_details.custom_sections
        ),
    };
};

export const updatefooterLevelsDetailsForICustomBuyerBidObject = (
    customBuyerBIdObject: ICustomSellerBid
): ICustomSellerBid => {
    let totalAmount = Object.keys(customBuyerBIdObject.bid_items).reduce(
        (acc, itemId) => {
            // if (
            //     customBuyerBIdObject.bid_items[itemId]
            //         .bid_item_response_type === 'REMOVED' ||
            //     customBuyerBIdObject.bid_items[itemId]
            //         .bid_item_response_type === 'EXCLUDED'
            // )
            //     return acc;
            return (
                acc +
                +(
                    customBuyerBIdObject?.bid_items[itemId].quantity_information
                        ?.quantity ?? 0
                ) *
                    +(
                        customBuyerBIdObject?.bid_items[itemId]
                            .pricing_information?.price ?? 0
                    )
            );
        },
        0
    );

    let totalItemLevelDiscount = Object.keys(
        customBuyerBIdObject?.bid_items ?? {}
    ).reduce((acc, itemId) => {
        // if (
        //     customBuyerBIdObject?.bid_items[itemId].bid_item_response_type ===
        //         'REMOVED' ||
        //     customBuyerBIdObject?.bid_items[itemId].bid_item_response_type ===
        //         'EXCLUDED'
        // )
        //     return acc;

        return (
            acc +
            +(
                calculateAddtionalCost({
                    type: 'DISCOUNT',
                    additionalCost: convertAdditionalCostBackendToFrontend(
                        customBuyerBIdObject?.bid_items[itemId]
                            .pricing_information?.discounts as any
                    ),
                    baseQuantity:
                        customBuyerBIdObject?.bid_items[itemId]
                            .quantity_information?.quantity ?? 0,
                    baseRate:
                        customBuyerBIdObject?.bid_items[itemId]
                            .pricing_information?.price ?? 0,
                })?.additionalCostValue ?? 0
            )
        );
    }, 0);

    let totalAmountwithTaxesAndAdditionalCosts = Object.keys(
        customBuyerBIdObject?.bid_items ?? {}
    ).reduce((acc, itemId) => {
        // if (
        //     customBuyerBIdObject?.bid_items[itemId].bid_item_response_type ===
        //         'REMOVED' ||
        //     customBuyerBIdObject?.bid_items[itemId].bid_item_response_type ===
        //         'EXCLUDED'
        // )
        //     return acc;

        return (
            acc +
            calculateNewItemTotalWithDecimalPlaces({
                additional_cost: convertAdditionalCostBackendToFrontend(
                    customBuyerBIdObject?.bid_items[itemId].pricing_information
                        ?.additional_costs as any
                ),
                decimalPlaces: 2,
                discount: convertAdditionalCostBackendToFrontend(
                    customBuyerBIdObject?.bid_items[itemId].pricing_information
                        ?.discounts as any
                ),
                item_price:
                    customBuyerBIdObject?.bid_items[itemId].pricing_information
                        ?.price ?? 0,
                itemDS:
                    customBuyerBIdObject?.bid_items[itemId]
                        .delivery_information === null
                        ? []
                        : customBuyerBIdObject?.bid_items[itemId]
                              .delivery_information?.length === 0
                        ? [
                              {
                                  quantity:
                                      customBuyerBIdObject?.bid_items[itemId]
                                          .quantity_information?.quantity,
                              },
                          ]
                        : customBuyerBIdObject?.bid_items[itemId]
                              .delivery_information ?? [],
                tax: convertAdditionalCostBackendToFrontend(
                    customBuyerBIdObject?.bid_items[itemId].pricing_information
                        ?.taxes as any
                ),
            })
        );
    }, 0);

    let overallTaxesTotal = calculateAddtionalCostEventLevel({
        additionalCost: cloneDeep(customBuyerBIdObject?.taxes ?? []),
        eventSubTotal: cloneDeep(totalAmount ?? 0),
        eventTotal: cloneDeep(totalAmountwithTaxesAndAdditionalCosts ?? 0),
        type: 'TAX',
    }).additionalCostValue;

    let overallDiscountsTotal = calculateAddtionalCostEventLevel({
        additionalCost: cloneDeep(customBuyerBIdObject?.discounts ?? []),
        eventSubTotal: cloneDeep(totalAmount ?? 0),
        eventTotal: cloneDeep(totalAmountwithTaxesAndAdditionalCosts ?? 0),
        type: 'DISCOUNT',
    }).additionalCostValue;

    let overallAdditionalCostsTotal = calculateAddtionalCostEventLevel({
        additionalCost: cloneDeep(customBuyerBIdObject?.additional_costs ?? []),
        eventSubTotal: cloneDeep(totalAmount ?? 0),
        eventTotal: cloneDeep(totalAmountwithTaxesAndAdditionalCosts ?? 0),
        type: 'ADDITIONAL_COST',
    }).additionalCostValue;

    customBuyerBIdObject.items_additional_cost_total = roundToNDecimalPlace(
        0.0,
        10
    );
    customBuyerBIdObject.items_taxes_total = roundToNDecimalPlace(0.0, 10);
    customBuyerBIdObject.items_discount_total = roundToNDecimalPlace(
        totalItemLevelDiscount,
        10
    );
    customBuyerBIdObject.items_subtotal = roundToNDecimalPlace(
        totalAmount - totalItemLevelDiscount,
        10
    );
    customBuyerBIdObject.items_total = roundToNDecimalPlace(
        totalAmountwithTaxesAndAdditionalCosts,
        10
    );
    customBuyerBIdObject.bid_total = roundToNDecimalPlace(
        totalAmountwithTaxesAndAdditionalCosts +
            overallTaxesTotal -
            overallDiscountsTotal +
            overallAdditionalCostsTotal,
        10
    );
    return customBuyerBIdObject;
};

export const updateSellerOpeningBidData = (
    customObject: ICustomSellerBid,
    updatedRfqItemId?: string,
    isBidLevelUpdate?: boolean
): Promise<{ error: boolean; msg: string; newQuoteId: string | null }> => {
    return new Promise((resolve, reject) => {
        customObject = cloneDeep(customObject);
        customObject =
            updatefooterLevelsDetailsForICustomBuyerBidObject(customObject);
        console.log('customObject', customObject);

        if (updatedRfqItemId) {
            if (
                customObject.removed_item_list &&
                customObject.removed_item_list[updatedRfqItemId]
            ) {
                customObject = {
                    ...customObject,
                    removed_item_list: {
                        [updatedRfqItemId]:
                            customObject.removed_item_list[updatedRfqItemId],
                    },
                    bid_items: {},
                };
            } else if (
                customObject.bid_items &&
                customObject.bid_items[updatedRfqItemId]
            ) {
                customObject = {
                    ...customObject,
                    bid_items: {
                        [updatedRfqItemId]:
                            customObject.bid_items[updatedRfqItemId],
                    },
                };
            }
        }
        for (let item of Object.keys(customObject.bid_items)) {
            //Update DS of all items
            let newDeliverySchedule: any[] = [];
            for (let delivery of customObject.bid_items[item]
                .delivery_information) {
                // is it a valid number AND number is greater than 0
                if (
                    !isNaN(parseFloat(delivery.quantity)) &&
                    parseFloat(delivery.quantity) > 0
                ) {
                    if (delivery.delivery_date !== null) {
                        newDeliverySchedule.push({
                            delivery_date: delivery.delivery_date,
                            quantity: parseFloat(delivery.quantity),
                        });
                    } else if (
                        delivery.delivery_date === null &&
                        delivery.quantity !== '' &&
                        delivery.quantity !== null
                    ) {
                        newDeliverySchedule.push({
                            delivery_date: null,
                            quantity: parseFloat(delivery.quantity),
                        });
                    }
                }
            }
            customObject.bid_items[item].delivery_information =
                newDeliverySchedule;
            //Update attachment list of all items
            customObject.bid_items[item].attachments.forEach((attachment) => {
                attachment.id = attachment.attachment_id;
                attachment.type = 'NEW';
            });

            if (
                customObject.bid_items[item].procurement_information
                    .lead_time_period === 'NA'
            ) {
                customObject.bid_items[
                    item
                ].procurement_information.lead_time_period = null;
                customObject.bid_items[item].procurement_information.lead_time =
                    null;
            }
        }

        let newObj = {
            custom_sections: customObject.custom_sections,
            items_additional_cost_total: Number(
                customObject.items_additional_cost_total
            ).toFixed(9),
            items_taxes_total: Number(customObject.items_taxes_total).toFixed(
                9
            ),
            items_discount_total: Number(
                customObject.items_discount_total
            ).toFixed(9),
            items_subtotal: Number(customObject.items_subtotal).toFixed(9),
            items_total: Number(customObject.items_total).toFixed(9),
            vendor_items_additional_cost_total: Number(
                customObject.vendor_items_additional_cost_total
            ).toFixed(9),
            vendor_items_taxes_total: Number(
                customObject.vendor_items_taxes_total
            ).toFixed(9),
            vendor_items_discount_total: Number(
                customObject.vendor_items_discount_total
            ).toFixed(9),
            vendor_items_subtotal: Number(
                customObject.vendor_items_subtotal
            ).toFixed(9),
            vendor_items_total: Number(customObject.vendor_items_total).toFixed(
                9
            ),
            additional_costs: convertAdditionalCostFrontendToBackend(
                customObject.additional_costs ?? []
            ),
            taxes: convertAdditionalCostFrontendToBackend(
                customObject.taxes ?? []
            ),
            discounts: convertAdditionalCostFrontendToBackend(
                customObject.discounts ?? []
            ),
            bid_total: Number(customObject.bid_total).toFixed(9),
            vendor_bid_total: Number(customObject.vendor_bid_total).toFixed(9),

            seller_entity_id: customObject.seller_entity_id,
            bulk_discount_percentage: customObject.bulk_discount_percentage,
            bulk_discount_minimum_order_value:
                customObject.bulk_discount_minimum_order_value,
            currency_code_id: customObject.currency_code_id,
            internal_vendor_quote_id: customObject.internal_vendor_quote_id,
            action:
                customObject.rfq_bid_id === ''
                    ? 'SAVE_DRAFT_OPENING_QUOTE'
                    : 'UPDATE_DRAFT_OPENING_QUOTE',
            validity_datetime:
                customObject.validity_datetime === '' ||
                customObject.validity_datetime === null
                    ? null
                    : moment(customObject.validity_datetime).toISOString(),
            removed_item_list: Object.keys(customObject.removed_item_list).map(
                (item_id) => customObject.removed_item_list[item_id]
            ),
            attachment_ids: customObject.attachments?.map(
                (attachment) => attachment.attachment_id
            ),
            bid_items: Object.keys(customObject.bid_items).map((item_id) => {
                const {
                    payment_type,
                    prepayment_percentage,
                    payment_terms,
                    deliverables_payment_terms,
                } = transformPaymentTermsFromFrontendToBackend({
                    paymentTerms: customObject.bid_items[item_id].paymentTerms,
                });

                // const procurement_information = {
                //     ...customObject.bid_items[item_id].procurement_information,
                //     lead_time_period:
                //         customObject.bid_items[item_id].procurement_information
                //             .lead_time_period === 'NA'
                //             ? null
                //             : customObject.bid_items[item_id]
                //                   .procurement_information.lead_time_period,

                // };

                return {
                    ...customObject.bid_items[item_id],
                    custom_sections: modifyCustomSectionForUpdate(
                        customObject.bid_items[item_id].custom_sections
                    ),
                    pricing_information: {
                        ...customObject.bid_items[item_id].pricing_information,
                        additional_costs:
                            convertAdditionalCostFrontendToBackend(
                                customObject.bid_items[item_id]
                                    .pricing_information.additional_costs as any
                            ),
                        taxes: convertAdditionalCostFrontendToBackend(
                            customObject.bid_items[item_id].pricing_information
                                .taxes as any
                        ),
                        discounts: convertAdditionalCostFrontendToBackend(
                            customObject.bid_items[item_id].pricing_information
                                .discounts as any
                        ),
                        total_price: calculateNewItemTotalWithDecimalPlaces({
                            additional_cost:
                                customObject.bid_items[item_id]
                                    .pricing_information.additional_costs,
                            decimalPlaces: 2,
                            discount:
                                customObject.bid_items[item_id]
                                    .pricing_information.discounts,
                            item_price:
                                customObject.bid_items[item_id]
                                    .pricing_information.price,
                            itemDS:
                                customObject.bid_items[item_id]
                                    .delivery_information.length === 0
                                    ? [
                                          {
                                              quantity:
                                                  customObject.bid_items[
                                                      item_id
                                                  ].quantity_information
                                                      .quantity,
                                          },
                                      ]
                                    : customObject.bid_items[item_id]
                                          .delivery_information,
                            tax: customObject.bid_items[item_id]
                                .pricing_information.taxes,
                        }).toFixed(10),
                    },
                    // procurement_information,
                    payment_type,
                    prepayment_percentage,
                    payment_terms,
                    deliverables_payment_terms,
                };
            }),
        };
        console.log('newObj', newObj);
        if (customObject.rfq_bid_id === '')
            postSellerRFQBid(customObject.rfq_id, newObj)
                .then((res) => {
                    resolve({ error: false, msg: '', newQuoteId: res });
                })
                .catch((err) => {
                    reject();
                });
        else {
            if (isBidLevelUpdate) {
                newObj.bid_items = [];
                putSellerRFQBidDetails(
                    customObject.rfq_id,
                    customObject.rfq_bid_id,
                    newObj
                )
                    .then((res) => {
                        resolve({ error: false, msg: '', newQuoteId: null });
                    })
                    .catch((err) => {
                        reject();
                    });
            } else {
                putSellerRFQBid(
                    customObject.rfq_id,
                    customObject.rfq_bid_id,
                    newObj
                )
                    .then((res) => {
                        resolve({ error: false, msg: '', newQuoteId: null });
                    })
                    .catch((err) => {
                        reject();
                    });
            }
        }
    });
};

export const validateItemDetails = (
    item: ICustomSellerItemBid,
    idx: string,
    rfq_total_quantity: string,
    rfqTemplate: IRfqTemplate
) => {
    if (
        rfqTemplate.fieldSettings.standardFields.QUANTITY.sellerSettings
            .showField &&
        (isNaN(parseFloat(item.quantity_information.quantity)) ||
            parseFloat(item.quantity_information.quantity) <= 0 ||
            parseFloat(item.quantity_information.quantity) >
                parseFloat(rfq_total_quantity))
    ) {
        return { error: true, msg: 'quantity_information.quantity' };
    }

    if (
        rfqTemplate.fieldSettings.standardFields.BASE_RATE.sellerSettings
            .showField &&
        (isNaN(parseFloat(item.pricing_information.price)) ||
            parseFloat(item.pricing_information.price) <= 0)
    )
        return {
            error: true,
            msg: `Please enter a valid rate for item ${idx}`,
        };
    if (
        rfqTemplate.fieldSettings.standardFields.LEAD_TIME.sellerSettings
            .showField &&
        rfqTemplate.fieldSettings.standardFields.LEAD_TIME?.sellerSettings
            ?.isMandatory &&
        (isNaN(parseInt(item?.procurement_information?.lead_time ?? '')) ||
            parseInt(item?.procurement_information?.lead_time ?? '') < 0 ||
            !Number.isInteger(
                parseFloat(item?.procurement_information?.lead_time ?? '')
            ))
    )
        return {
            error: true,
            msg: `Please enter a valid lead time for item ${idx}`,
        };
    if (
        rfqTemplate.fieldSettings.standardFields.LEAD_TIME.sellerSettings
            .showField &&
        rfqTemplate.fieldSettings.standardFields.LEAD_TIME?.sellerSettings
            ?.isMandatory &&
        item?.procurement_information?.lead_time_period?.length === 0
    )
        return {
            error: true,
            msg: `Please enter a valid lead time period for item ${idx}`,
        };
    // if (
    //     isNaN(parseFloat(item.procurement_information.prepayment_percentage)) ||
    //     parseFloat(item.procurement_information.prepayment_percentage) < 0 ||
    //     parseFloat(item.procurement_information.prepayment_percentage) > 100
    // )
    //     return {
    //         error: true,
    //         msg: `Please enter a valid pre payment % for item ${idx}`,
    //     };
    // else {
    //     // let prepayment_perc = parseFloat(
    //     //     item.procurement_information.prepayment_percentage
    //     // );
    //     // if (prepayment_perc < 100) {
    //     //     if (
    //     //         item.procurement_information.payment_terms === null ||
    //     //         item.procurement_information.payment_terms.length === 0
    //     //     )
    //     //         return {
    //     //             error: true,
    //     //             msg: `Please enter a valid payment term for item ${idx}`,
    //     //         };
    //     //     if (
    //     //         item.procurement_information.payment_terms_period === null ||
    //     //         item.procurement_information.payment_terms_period.length === 0
    //     //     )
    //     //         return {
    //     //             error: true,
    //     //             msg: `Please enter a valid payment term period for item ${idx}`,
    //     //         };
    //     //     if (
    //     //         item.procurement_information.payment_terms_applied_from ===
    //     //             null ||
    //     //         item.procurement_information.payment_terms_applied_from
    //     //             .length === 0
    //     //     )
    //     //         return {
    //     //             error: true,
    //     //             msg: `Please enter a valid payment term period applied from for item ${idx}`,
    //     //         };
    //     // }
    // }

    if (item.incoterm.length === 0)
        return {
            error: true,
            msg: `Please enter incoterms for item ${idx}`,
        };

    if (
        rfqTemplate.fieldSettings.standardFields.DELIVERY_DATE.sellerSettings
            .showField
    ) {
        let total = 0;
        for (let delivery of item.delivery_information)
            total += parseFloat(delivery.quantity);
        if (total !== parseFloat(item.quantity_information.quantity))
            return {
                error: true,
                msg: `Total delivery quantity and total quantity do not match for item ${idx}`,
            };
    }

    if (
        rfqTemplate.fieldSettings.standardFields.DELIVERY_DATE.sellerSettings
            .showField
    ) {
        for (let delivery of item.delivery_information) {
            if (
                delivery.delivery_date !== null &&
                new Date(delivery.delivery_date) < new Date()
            ) {
                return {
                    error: true,
                    msg: `Please enter a valid delivery date for item ${idx}`,
                };
            }
        }
    }

    if (
        item.pricing_information.discounts[0] &&
        calculateAddtionalCost({
            additionalCost: item.pricing_information.discounts,
            baseQuantity: item.quantity_information.quantity,
            baseRate: item.pricing_information.price,
            type: 'DISCOUNT',
        }).effectiveRateDeviation >= +item.pricing_information.price &&
        rfqTemplate.fieldSettings.standardFields.DELIVERY_DATE?.sellerSettings
            ?.isMandatory
    ) {
        return {
            error: true,
            msg: `Discount cannot exceed target rate`,
        };
    }

    if (item.custom_sections) {
        // validate custom fields
        for (const section of item.custom_sections) {
            for (const field of section.custom_fields) {
                if (
                    !isValueValidForStringArrNull(field.value) &&
                    rfqTemplate.fieldSettings.customFields[field.name]
                        ?.sellerSettings?.isMandatory
                ) {
                    return {
                        error: true,
                        msg: `Please enter a value for ${field.name} for item ${idx}`,
                    };
                }
            }
        }
    }

    return { error: false, msg: `` };
};

export const ColoredTypography = styled(FWTypography)(() => ({
    color: '#752fb5',
}));

export const downloadOpeningbiddetailsInCSV = async (
    rfq_event_id: string,
    seller_entity_id: string
) => {
    try {
        const response = await post(`/export/rfq/bid/items/seller/download/`, {
            rfq_event_id,
            seller_entity_id,
        });

        const data: any = response.data;

        const getUrl = await post<string, any>(
            `/export/url/generate/download/`,
            {
                export_id: data,
                resource_type: 'RFQ_ITEM_SELLER',
            }
        );

        return getUrl.data;
    } catch (err) {
        throw err;
    }
};

export const useDownloadBuyerOpeningBid = () => {
    const [isloading, setLoading] = useState(false);

    const downloadOpeningBidDetails = async (
        rfq_event_id: string,
        seller_entity_id: string
    ) => {
        setLoading(true);

        downloadOpeningbiddetailsInCSV(rfq_event_id, seller_entity_id)
            .then((response) => {
                setLoading(false);
                if (response) {
                    window.location.href = response;
                } else {
                    toast.error('Error downloading CSV');
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error downloading CSV', error);
                toast.error('Error downloading CSV');
            });
    };

    return { downloadOpeningBidDetails, isloading };
};

export const DummyComponentToCacheTheDataOfOpeningBids = ({
    rfq_id,
    entity_id,
}: {
    rfq_id: string;
    entity_id: string;
}) => {
    const [currentPage, setCurrentPage] = useState(1);

    const { data } = useGetRFQItemDetailsQuery({
        dashboard_view: 'rfq_event_item_seller',
        items_per_page: 10,
        page_number: currentPage,
        query_data: {
            rfq_event_id: rfq_id,
            seller_entity_id: entity_id,
        },
        search_text: '',
        sort_fields: [],
        tab: 'all',
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (data) {
            setCurrentPage((prev) => {
                const obj: {
                    [rfq_item_entry_id: string]: {
                        rfqData: IEventItemDetails;
                        itemNo: number;
                    };
                } = {};

                data.data.forEach((item, idx) => {
                    const clonedItem: IEventItemDetails = {
                        ...cloneDeep(item),
                        tags: [],
                        delivery_schedule: [],
                        deliverables_payment_terms: (item as any)
                            .deliverables_payment_terms,
                        payment_terms: (item as any).payment_terms,
                        prepayment_percentage: (item as any)
                            .prepayment_percentage,
                        paymentTerms:
                            transformPaymentTermsFromBackendToFrontend({
                                prepayment_percentage: (item as any)
                                    .prepayment_percentage,
                                payment_terms: (item as any).payment_terms,
                                deliverables_payment_terms: (item as any)
                                    .deliverables_payment_terms,
                            }),
                        quantity_information: {
                            ...item.quantity_information,
                            quantity_tolerance_percentage:
                                item.quantity_information
                                    .quantity_tolerance_percentage ?? '',
                        },
                    };

                    obj[item.rfq_item_entry_id] = {
                        rfqData: clonedItem,
                        itemNo: (prev - 1) * 10 + idx + 1,
                    };
                });

                dispatch(
                    updateRfqOpeningBid({
                        type: 'UPDATE_MULTIPLE_BID_OBJECT_ERR_RFQ_DATA',
                        value: obj,
                    })
                );

                if (data.metadata.total_pages <= prev) return prev;

                return prev + 1;
            });
        }
    }, [data, dispatch]);

    return null;
};
