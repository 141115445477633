import {
    Box,
    CircularProgress,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TemplateData } from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import FWAutocomplete from '../../Common/FWAutocomplete';
import { FWButton } from '../../Common/FWButton';
import { FWInput } from '../../Common/FWInput';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { FWTypography } from '../../Common/FWTypograhy';
import { useGetListOfTemplatesForEntityQuery } from '../../Global/Helpers/templateApiSlice';
import { IEntity } from '../../Organizations/Admin/Interfaces/EnterpriseInterface';
import { useGetEntityListQuery } from '../../Organizations/Admin/Services/EnterpriseService';
import { useCostingTemplate } from '../../Costing/Hooks/useCostingTemplate';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import { useDispatch } from 'react-redux';
import { updateQuoteDataStore } from '../../Costing/Store/Quote.store';
import { AuthContext } from '../../Contexts/AuthContext';
import useVisitTemplate from '../../CRM/Hooks/useVisitTemplate';
import { updateVisitDataStore } from '../../CRM/Store/Visit.store';

export interface IEntityAndTemplateSelection {
    open: boolean;
    handleClose: () => void;
    url: string;
    templateType: string;
    showCancel: boolean;
    title: string;
    customHandlerForSave?: () => void;
    callDefaultSaveHandler?: boolean;
    customUpdateHandler?: any;
    entityIdUpdateKey?: string;
    templateIdUpdateKey?: string;
    customTemplateList?: TemplateData[];
    accessDefaultTemplateList?: boolean;
}

export default function EntityAndTemplateSelectionPopup({
    open,
    handleClose,
    showCancel,
    templateType,
    title,
    url,
    customHandlerForSave,
    callDefaultSaveHandler = true,
    customUpdateHandler,
    entityIdUpdateKey = 'entity_id',
    templateIdUpdateKey = 'template_id',
    customTemplateList,
    accessDefaultTemplateList = true,
}: IEntityAndTemplateSelection) {
    const { checkPermission } = useContext(AuthContext);

    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

    const [essentials, setEssentials] = useState<{
        entity_id: string;
        template_id: string;
    }>({
        entity_id: '',
        template_id: '',
    });

    const [entityList, setEntityList] = useState<IEntity[]>([]);

    const { data: listOfEntities, refetch } = useGetEntityListQuery({});
    const { data: listOfTemplates, refetch: refetchTemplateDetails } =
        useGetListOfTemplatesForEntityQuery(
            {
                entityId: essentials?.entity_id,
                templateType: templateType as any,
            },
            {
                skip:
                    essentials?.entity_id === '' ||
                    accessDefaultTemplateList === false,
            }
        );

    const {
        costingTemplateDetails,
        hookState: templateHookState,
        isFetching,
    } = useCostingTemplate(
        templateType === 'QUOTE_CALCULATOR' ? essentials.template_id ?? '' : '',
        // costingSheetData?.seller_entity ?? '413fc653-908b-4dc4-869e-dd0c073b82f4'
        essentials.entity_id ?? ''
    );

    const {
        visitTemplateDetails,
        hookState: visitTemplateHookState,
        isFetching: fetchingVisit,
    } = useVisitTemplate(
        templateType === 'VISIT' ? essentials.template_id ?? '' : '',
        essentials.entity_id ?? ''
    );

    console.log('visitTemplateDetails', visitTemplateDetails);

    useEffect(() => {
        if (listOfEntities !== undefined) {
            if (templateType === 'QUOTE_CALCULATOR')
                setEntityList(
                    listOfEntities.filter((entity) =>
                        checkPermission(
                            'SELLER',
                            'QUOTE_CALCULATOR',
                            'CREATE',
                            entity.entityId
                        )
                    )
                );
            else setEntityList(listOfEntities);
        }
    }, [checkPermission, listOfEntities, templateType]);

    useEffect(() => {
        try {
            refetch();
        } catch (err) {}
    }, [refetch, essentials]);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        try {
            refetchTemplateDetails();
        } catch (err) {
            console.error(err);
        }
    }, [refetchTemplateDetails]);

    const handlePopupClose = () => {
        setEssentials({
            entity_id: '',
            template_id: '',
        });
        if (customUpdateHandler) {
            customUpdateHandler(entityIdUpdateKey, '');
            customUpdateHandler(templateIdUpdateKey, '');
        }

        setSubmitButtonClicked(false);
        handleClose();
    };

    const handleConfirmClick = () => {
        setSubmitButtonClicked(true);
        if (customHandlerForSave) {
            customHandlerForSave();
        }

        if (callDefaultSaveHandler) {
            history.push({
                pathname: url,
                state: {
                    templateId: essentials.template_id,
                    entityId: essentials.entity_id,
                },
            });
        }

        handlePopupClose();
    };

    useEffect(() => {}, [essentials]);

    useEffect(() => {
        if (
            templateType === 'QUOTE_CALCULATOR' &&
            templateHookState.state === HookStateValue.READY
        ) {
            dispatch(
                updateQuoteDataStore({
                    type: 'UPDATE_QUOTE_TEMPLATE',
                    value: costingTemplateDetails,
                })
            );
        } else if (
            templateType === 'VISIT' &&
            visitTemplateHookState.state === HookStateValue.READY
        ) {
            dispatch(
                updateVisitDataStore({
                    type: 'UPDATE_QUOTE_TEMPLATE',
                    value: visitTemplateDetails,
                })
            );
        }
    }, [
        costingTemplateDetails,
        dispatch,
        templateHookState.state,
        templateType,
        visitTemplateDetails,
        visitTemplateHookState.state,
    ]);

    const handleEntityChange = useCallback(
        (newEntity: IEntity | null) => {
            if (newEntity === null) {
                setEssentials(() => ({
                    entity_id: '',
                    template_id: '',
                }));
                customUpdateHandler(entityIdUpdateKey, '');
            } else {
                setEssentials((prev) => ({
                    entity_id: newEntity.entityId,
                    template_id: '',
                }));
                if (customUpdateHandler) {
                    customUpdateHandler(entityIdUpdateKey, newEntity?.entityId);
                }
            }
        },
        [customUpdateHandler, entityIdUpdateKey]
    );

    const handleTemplateChange = useCallback(
        (newTemplate: TemplateData | null) => {
            if (newTemplate === null) {
                setEssentials((prev) => ({
                    ...prev,
                    template_id: '',
                }));
                customUpdateHandler(templateIdUpdateKey, '');
            } else {
                setEssentials((prev) => ({
                    ...prev,
                    template_id: newTemplate.template_id,
                }));
                if (customUpdateHandler) {
                    customUpdateHandler(
                        templateIdUpdateKey,
                        newTemplate?.template_id
                    );
                }
            }
        },
        [customUpdateHandler, templateIdUpdateKey]
    );

    useEffect(() => {
        if (
            essentials.entity_id === '' &&
            listOfEntities !== undefined &&
            entityList.length > 0
        ) {
            handleEntityChange(entityList[0]);
        }
    }, [essentials.entity_id, handleEntityChange, listOfEntities, entityList]);

    useEffect(() => {
        if (accessDefaultTemplateList) {
            if (listOfTemplates !== undefined) {
                if (listOfTemplates?.length > 0) {
                    if (
                        essentials.template_id === '' ||
                        listOfTemplates.find(
                            (temp) =>
                                temp.template_id === essentials.template_id
                        ) === undefined
                    )
                        handleTemplateChange(listOfTemplates[0]);
                }
            }
        } else {
            if (customTemplateList) {
                if (customTemplateList?.length > 0) {
                    if (
                        essentials.template_id === '' ||
                        customTemplateList.find(
                            (temp) =>
                                temp.template_id === essentials.template_id
                        ) === undefined
                    )
                        handleTemplateChange(customTemplateList[0]);
                }
            }
        }
    }, [
        accessDefaultTemplateList,
        customTemplateList,
        essentials.template_id,
        handleTemplateChange,
        listOfTemplates,
    ]);

    return (
        <FWPopupWithChildren
            open={open}
            handleClose={handlePopupClose}
            showClose={showCancel}
            applyTitleStyling={false}
            title={title}
        >
            <DialogContent>
                <Grid
                    container
                    gap={2}
                    display={'flex'}
                    flexDirection={'column'}
                >
                    <FormControl fullWidth required>
                        <FWTypography color={'text.secondary'}>
                            Entity
                        </FWTypography>
                        <FWAutocomplete
                            options={entityList ?? []}
                            popupIcon={
                                <Box fontSize={'16px'}>
                                    <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                                </Box>
                            }
                            disableClearable
                            fullWidth
                            value={
                                entityList?.find(
                                    (entity) =>
                                        entity.entityId ===
                                        essentials?.entity_id
                                ) ?? null
                            }
                            onChange={(e, newValue: IEntity) => {
                                handleEntityChange(newValue);
                            }}
                            getOptionLabel={(option: IEntity) =>
                                `${option.entityName}`
                            }
                            renderInput={(params) => {
                                return (
                                    <FWInput
                                        {...params}
                                        required
                                        error={false}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth required>
                        <FWTypography color={'text.secondary'}>
                            Template
                        </FWTypography>
                        <FWAutocomplete
                            // disabled={
                            //     listOfTemplatesOfSelectedEntity?.length === 1
                            // }
                            options={
                                accessDefaultTemplateList
                                    ? listOfTemplates ?? []
                                    : customTemplateList
                                    ? customTemplateList.filter(
                                          (temp) =>
                                              temp.template_entity_id ===
                                              essentials.entity_id
                                      )
                                    : []
                            }
                            popupIcon={
                                <Box fontSize={'16px'}>
                                    <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                                </Box>
                            }
                            fullWidth
                            disableClearable
                            value={
                                accessDefaultTemplateList
                                    ? listOfTemplates?.find(
                                          (temp) =>
                                              temp.template_id ===
                                              essentials?.template_id
                                      ) ?? null
                                    : customTemplateList?.find(
                                          (temp) =>
                                              temp.template_id ===
                                              essentials?.template_id
                                      ) ?? null
                            }
                            onChange={(e, newValue: TemplateData) => {
                                handleTemplateChange(newValue);
                            }}
                            getOptionLabel={(option: TemplateData) =>
                                `${option.template_name}`
                            }
                            renderInput={(params) => {
                                return (
                                    <FWInput
                                        {...params}
                                        required
                                        error={false}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{ paddingRight: '1.5rem', marginBottom: '1.5rem' }}
            >
                {showCancel && (
                    <FWButton
                        variant="outlined"
                        onClick={handlePopupClose}
                        color="primary"
                    >
                        Cancel
                    </FWButton>
                )}
                <FWButton
                    variant="contained"
                    color="primary"
                    // onClick={completeDeleteProcess}
                    disabled={
                        !Boolean(essentials.entity_id) ||
                        !Boolean(essentials.template_id) ||
                        isFetching ||
                        (templateType === 'VISIT' && fetchingVisit) ||
                        (accessDefaultTemplateList
                            ? !Boolean(
                                  listOfTemplates?.find(
                                      (temp) =>
                                          temp.template_id ===
                                          essentials?.template_id
                                  )
                              )
                            : !Boolean(
                                  customTemplateList?.find(
                                      (temp) =>
                                          temp.template_id ===
                                          essentials?.template_id
                                  )
                              )) ||
                        submitButtonClicked
                    }
                    onClick={handleConfirmClick}
                    id={'buyerEventsDashboard_confirmDeleteEvent'}
                >
                    {isFetching ||
                    (templateType === 'VISIT' && fetchingVisit) ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        'Confirm'
                    )}
                </FWButton>
            </DialogActions>
        </FWPopupWithChildren>
    );
}
