import { Box, Grid, Typography } from '@mui/material';
import { cloneDeep, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FWTypography } from '../../Common/FWTypograhy';
import { getFWDate, getFWDateTime } from '../../Common/Utils/DateUtils';
import { URL_IDENTIFICATION_REGEX } from '../../FieldTypes/components/SingleFieldTypeRenderer';
import TagsDisplay from '../../Global/Components/TagsDisplay';
import { IAttachment } from '../../Models/RFQ.model';
import {
    IDocumentCustomField,
    IDocumentDetails,
    IDocumentDetailsErrors,
    INewDocument,
} from '../Constants/Document.model';
import { IDocumentTemplate } from '../Constants/Interfaces/documentTemplate';
import { handleDownloadFile, returnFileId } from '../Constants/helperfunctions';
import {
    AttachmentRender,
    ViewTemplateList,
} from './SellerDocumentCustomFieldViewMode';
import {
    ICustomField,
    ICustomSection,
} from '../../Global/Interfaces/TemplateInterface';

export interface ISellerDraftCustomFieldsProps {
    buyerDocumentDetails: IDocumentDetails;
    templateDetails: IDocumentTemplate;
    sectionName: string;
    updateDocumentDetails: (
        field: keyof INewDocument,
        value: any
    ) => Promise<void>;
    errors: IDocumentDetailsErrors;
    gridSize: number;
    sellerDocument: INewDocument;
    sellerDocumentDetails: IDocumentDetails;
    allowTemplateUpload?: boolean;
    customGridSizeForTemp?: number;
    allowFileUpload?: boolean;
    showRemoveButton?: boolean;
}

export const FieldSeperationComponent = () => {
    return <Typography>&nbsp;|&nbsp;</Typography>;
};

export default function SellerDraftCustomFields({
    buyerDocumentDetails,
    errors,
    gridSize,
    sectionName,
    sellerDocument,
    sellerDocumentDetails,
    templateDetails,
    allowTemplateUpload = true,
    customGridSizeForTemp,
    allowFileUpload = true,
    showRemoveButton = true,
    updateDocumentDetails,
}: ISellerDraftCustomFieldsProps) {
    const sectionNameInPayload =
        sectionName === 'DOCUMENT_DETAIL'
            ? templateDetails.sections.DOCUMENT_DETAIL.label
            : sectionName;

    const templateCF = templateDetails.sections[sectionName].fieldList?.filter(
        (field) => field.fieldType === 'CUSTOM'
    );

    // const lastCustomFieldName = templateCF[templateCF.length - 1]?.field;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sellerCustomFieldsNegotiable, setSellerCustomFieldsNegotiable] =
        useState<{
            section_list: {
                name: string;
                fields: IDocumentCustomField[];
            }[];
        }>({
            section_list: [
                {
                    name: sectionNameInPayload,
                    fields: [],
                },
            ],
        });

    const [
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sellerCustomFieldsNonNegotiable,
        setSellerCustomFieldsNonNegotiable,
    ] = useState<{
        section_list: {
            name: string;
            fields: IDocumentCustomField[];
        }[];
    }>({
        section_list: [
            {
                name: sectionNameInPayload,
                fields: [],
            },
        ],
    });

    const [buyerDocAttachments, setBuyerDocAttachments] = useState<{
        [customFieldName: string]: IAttachment[];
    }>({});

    console.log('buyerDocAttachments', buyerDocAttachments);

    useEffect(() => {
        setSellerCustomFieldsNegotiable({
            section_list:
                sellerDocument.custom_fields_negotiate.section_list.filter(
                    (section) => section.name === sectionNameInPayload
                ) ?? [
                    {
                        name: sectionNameInPayload,
                        fields: [],
                    },
                ],
        });
        setSellerCustomFieldsNonNegotiable({
            section_list: sellerDocument.custom_fields.section_list.filter(
                (section) => section.name === sectionNameInPayload
            ) ?? [
                {
                    name: sectionNameInPayload,
                    fields: [],
                },
            ],
        });
    }, [
        sectionNameInPayload,
        sellerDocument.custom_fields.section_list,
        sellerDocument.custom_fields_negotiate.section_list,
    ]);

    const updateCustomField = useCallback(
        async (
            newFieldDetails: IDocumentCustomField,
            isFieldNegotiable: boolean
        ) => {
            const setFieldList = isFieldNegotiable
                ? setSellerCustomFieldsNegotiable
                : setSellerCustomFieldsNonNegotiable;

            setFieldList((prev) => {
                let newPrev = cloneDeep(prev);

                const sectionIndex = newPrev.section_list.findIndex(
                    (section) => section.name === sectionNameInPayload
                );

                const fieldIndex = newPrev.section_list[
                    sectionIndex
                ]?.fields?.findIndex(
                    (field) => field.name === newFieldDetails.name
                );

                if (fieldIndex !== -1 && sectionIndex !== -1) {
                    newPrev.section_list[sectionIndex].fields[
                        fieldIndex
                    ].value = newFieldDetails.value;

                    if (isFieldNegotiable) {
                        updateDocumentDetails(
                            'custom_fields_negotiate',
                            newPrev
                        );
                    } else {
                        updateDocumentDetails('custom_fields', newPrev);
                    }
                }

                return newPrev;
            });
        },
        [sectionNameInPayload, updateDocumentDetails]
    );

    const buyerCustomFieldsNeg = useMemo(() => {
        return buyerDocumentDetails.custom_sections.find(
            (section) => section.name === sectionNameInPayload
        );
    }, [buyerDocumentDetails.custom_sections, sectionNameInPayload]);

    console.log('buyerCustomFieldsNeg', buyerCustomFieldsNeg);

    const updateDocAttachment = useCallback(() => {
        setBuyerDocAttachments((prev) => {
            let newPrev = cloneDeep(prev);

            const updateFieldAttachments = (field: ICustomField) => {
                if (['ATTACHMENT', 'TEMPLATE'].includes(field.type)) {
                    const documentsFromValue = field.attachments ?? [];
                    // buyerDocumentDetails.attachments.filter((docAttachment) =>
                    //     ((field?.value ?? []) as string[]).includes(
                    //         docAttachment.attachment_id
                    //     )
                    // );

                    const existingAttachments = newPrev[field.name] || [];
                    const uniqueSet = existingAttachments.filter(
                        (att) =>
                            !documentsFromValue.some(
                                (docAtt) =>
                                    docAtt.attachment_id === att.attachment_id
                            )
                    );

                    newPrev[field.name] = [...uniqueSet, ...documentsFromValue];
                }
            };

            const updateSectionFields = (sectionList: ICustomSection[]) => {
                const section = sectionList.find(
                    (section) => section.name === sectionNameInPayload
                );

                section?.custom_fields?.forEach(updateFieldAttachments);
            };

            // updateSectionFields(
            //     buyerDocumentDetails.custom_fields.section_list
            // );
            // updateSectionFields(
            //     buyerDocumentDetails.custom_fields_negotiate.section_list
            // );
            updateSectionFields(buyerDocumentDetails.custom_sections);

            return newPrev;
        });
    }, [buyerDocumentDetails.custom_sections, sectionNameInPayload]);

    useEffect(() => {
        updateDocAttachment();
    }, [updateDocAttachment]);

    const buyerCustomFieldsNonNeg = useMemo(() => {
        return buyerDocumentDetails.custom_sections.find(
            (section) => section.name === sectionNameInPayload
        );
    }, [buyerDocumentDetails.custom_sections, sectionNameInPayload]);

    console.log('dawwadawd', cloneDeep(buyerCustomFieldsNeg));

    const checkIsValueIfUrl = (value: string) => {
        const regex = new RegExp(URL_IDENTIFICATION_REGEX);

        if (!(!isEmpty(value?.trim()) && regex.test(value?.trim() ?? ''))) {
            return false;
        }

        return true;
    };

    return (
        <>
            <Grid
                container
                rowGap={2}
                columnGap={4}
                marginBottom={4}
                sx={{
                    borderRadius: '8px',
                    borderTop: '8px solid',
                    borderColor: 'primary.main',
                    background: 'white',
                    boxShadow:
                        '0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)',
                }}
                padding={4}
            >
                {templateCF?.map((field, index) => {
                    const fieldInfoFromTemplate =
                        templateDetails.fieldSettings.customFields[field.field];

                    const isFieldNegotiable =
                        fieldInfoFromTemplate.sellerSettings.negotiable;

                    const fieldList = isFieldNegotiable
                        ? buyerCustomFieldsNeg
                        : buyerCustomFieldsNonNeg;

                    const fieldInfoInDocumentDetails =
                        fieldList?.custom_fields?.find(
                            (payloadField) =>
                                payloadField.name ===
                                fieldInfoFromTemplate.label
                        );

                    if (!Boolean(fieldInfoInDocumentDetails)) {
                        return <></>;
                    }
                    switch (fieldInfoFromTemplate.constraints.fieldType) {
                        case 'SHORTTEXT':
                        case 'LONGTEXT':
                            return fieldInfoInDocumentDetails?.value ? (
                                <Grid
                                    item
                                    xs={12}
                                    // sx={{
                                    //     display: 'flex',
                                    //     gap: '4px',
                                    //     alignItems: 'center',
                                    // }}
                                >
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography color={'text.secondary'}>
                                            {fieldInfoFromTemplate?.label}:
                                        </FWTypography>
                                        {/* {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )} */}
                                    </Grid>
                                    <FWTypography>
                                        {fieldInfoInDocumentDetails?.value
                                            ? fieldInfoInDocumentDetails?.value
                                            : '-'}
                                    </FWTypography>
                                </Grid>
                            ) : (
                                <></>
                            );

                        case 'DATE':
                            return fieldInfoInDocumentDetails?.value ? (
                                <FWTypography
                                    // item
                                    // xs={'auto'}
                                    sx={{
                                        display: 'flex',
                                        gap: '4px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography color={'text.secondary'}>
                                            {fieldInfoFromTemplate?.label}:{' '}
                                        </FWTypography>
                                        {/* {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )} */}
                                    </Grid>
                                    <FWTypography>
                                        {fieldInfoInDocumentDetails?.value
                                            ? getFWDate(
                                                  (fieldInfoInDocumentDetails?.value ??
                                                      '') as string
                                              )
                                            : '-'}
                                    </FWTypography>
                                </FWTypography>
                            ) : (
                                <></>
                            );
                        case 'DATETIME':
                            return fieldInfoInDocumentDetails?.value ? (
                                <FWTypography
                                    // item
                                    // xs={'auto'}
                                    sx={{
                                        display: 'flex',
                                        gap: '4px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography color={'text.secondary'}>
                                            {fieldInfoFromTemplate?.label}:{' '}
                                        </FWTypography>
                                        {/* {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )} */}
                                    </Grid>
                                    <FWTypography>
                                        {fieldInfoInDocumentDetails?.value
                                            ? getFWDateTime(
                                                  (fieldInfoInDocumentDetails?.value ??
                                                      '') as string
                                              )
                                            : '-'}
                                    </FWTypography>
                                </FWTypography>
                            ) : (
                                <></>
                            );
                        case 'FLOAT':
                            return fieldInfoInDocumentDetails?.value ? (
                                <Grid
                                    item
                                    xs={'auto'}
                                    sx={{
                                        display: 'flex',
                                        gap: '4px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography color={'text.secondary'}>
                                            {fieldInfoFromTemplate?.label}:{' '}
                                        </FWTypography>
                                        {/* {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )} */}
                                    </Grid>
                                    <FWTypography>
                                        {fieldInfoInDocumentDetails?.value
                                            ? fieldInfoInDocumentDetails?.value
                                            : '-'}
                                    </FWTypography>
                                </Grid>
                            ) : (
                                <></>
                            );
                        case 'INTEGER':
                            return fieldInfoInDocumentDetails?.value ? (
                                <Grid
                                    item
                                    xs={'auto'}
                                    sx={{
                                        display: 'flex',
                                        gap: '4px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography color={'text.secondary'}>
                                            {fieldInfoFromTemplate?.label}:{' '}
                                        </FWTypography>
                                        {/* {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )} */}
                                    </Grid>
                                    <FWTypography>
                                        {fieldInfoInDocumentDetails?.value
                                            ? fieldInfoInDocumentDetails?.value
                                            : '-'}
                                    </FWTypography>
                                </Grid>
                            ) : (
                                <></>
                            );
                        case 'BOOLEAN':
                            return fieldInfoInDocumentDetails?.value?.toString() ? (
                                <Grid
                                    item
                                    xs={'auto'}
                                    sx={{
                                        display: 'flex',
                                        gap: '4px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography color={'text.secondary'}>
                                            {fieldInfoFromTemplate?.label}:{' '}
                                        </FWTypography>
                                        {/* {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )} */}
                                    </Grid>
                                    <FWTypography>
                                        {fieldInfoInDocumentDetails?.value?.toString()
                                            ? fieldInfoInDocumentDetails?.value
                                                ? 'Yes'
                                                : 'No'
                                            : '-'}
                                    </FWTypography>
                                </Grid>
                            ) : (
                                <></>
                            );
                        case 'CHOICE':
                            if (
                                fieldInfoFromTemplate?.constraints
                                    .choiceType === 'DROPDOWN'
                            ) {
                                return Boolean(
                                    fieldInfoInDocumentDetails?.value &&
                                        fieldInfoInDocumentDetails.value !==
                                            'NA(None)'
                                ) ? (
                                    <Grid
                                        item
                                        xs={'auto'}
                                        sx={{
                                            display: 'flex',
                                            gap: '4px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <FWTypography
                                                color={'text.secondary'}
                                            >
                                                {fieldInfoFromTemplate?.label}:{' '}
                                            </FWTypography>
                                            {/* {fieldInfoFromTemplate?.description && (
                                            <FWTooltip
                                                title={
                                                    fieldInfoFromTemplate?.description
                                                }
                                                placement="right"
                                            >
                                                <Box color="primary.main">
                                                    <i className="bi bi-question-circle" />
                                                </Box>
                                            </FWTooltip>
                                        )} */}
                                        </Grid>
                                        <FWTypography>
                                            {Boolean(
                                                fieldInfoInDocumentDetails?.value
                                            )
                                                ? fieldInfoInDocumentDetails?.value
                                                : '-'}
                                        </FWTypography>
                                    </Grid>
                                ) : (
                                    <></>
                                );
                            } else {
                                return (
                                    fieldInfoInDocumentDetails?.value as string[]
                                )?.length > 0 ? (
                                    <div
                                        // item
                                        // xs={'auto'}
                                        style={{
                                            display: 'flex',
                                            gap: '4px',
                                            overflow: '',

                                            alignItems: 'center',
                                        }}
                                    >
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <FWTypography
                                                color={'text.secondary'}
                                            >
                                                {fieldInfoFromTemplate?.label}:{' '}
                                            </FWTypography>
                                            {/* {fieldInfoFromTemplate?.description && (
                                            <FWTooltip
                                                title={
                                                    fieldInfoFromTemplate?.description
                                                }
                                                placement="right"
                                            >
                                                <Box color="primary.main">
                                                    <i className="bi bi-question-circle" />
                                                </Box>
                                            </FWTooltip>
                                        )} */}
                                        </Grid>

                                        <Box>
                                            {(
                                                fieldInfoInDocumentDetails?.value as string[]
                                            )?.length > 0 ? (
                                                <TagsDisplay
                                                    maxTags={2}
                                                    strictMode
                                                    tags={(
                                                        (fieldInfoInDocumentDetails?.value as string[]) ??
                                                        []
                                                    )?.sort(
                                                        (
                                                            a: string,
                                                            b: string
                                                        ) => a.length - b.length
                                                    )}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </Box>
                                    </div>
                                ) : (
                                    <></>
                                );
                            }
                        default:
                            return <></>;
                    }
                })}

                {templateCF?.map((field) => {
                    const fieldInfoFromTemplate =
                        templateDetails.fieldSettings.customFields[field.field];

                    const isFieldNegotiable =
                        fieldInfoFromTemplate.sellerSettings.negotiable;

                    const fieldList = isFieldNegotiable
                        ? buyerDocumentDetails.custom_fields_negotiate.section_list.find(
                              (section) => section.name === sectionNameInPayload
                          )
                        : buyerDocumentDetails.custom_fields.section_list.find(
                              (section) => section.name === sectionNameInPayload
                          );

                    const fieldInfoInDocumentDetails = fieldList?.fields.find(
                        (payloadField) =>
                            payloadField.name === fieldInfoFromTemplate.label
                    );

                    if (!Boolean(fieldInfoInDocumentDetails)) {
                        return <></>;
                    }

                    switch (fieldInfoFromTemplate.constraints.fieldType) {
                        case 'LONGTEXT':
                            return fieldInfoInDocumentDetails?.value ? (
                                <Grid item xs={12} marginY={1.5}>
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        {/* color={'text.secondary'} */}
                                        <FWTypography
                                            fontSize={28}
                                            // fontFamily={'sans-serif'}
                                        >
                                            {fieldInfoFromTemplate?.label}{' '}
                                        </FWTypography>
                                        {/* {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )} */}
                                    </Grid>
                                    {Boolean(
                                        fieldInfoInDocumentDetails?.value
                                    ) &&
                                    checkIsValueIfUrl(
                                        fieldInfoInDocumentDetails?.value as string
                                    ) ? (
                                        <a
                                            href={
                                                (fieldInfoInDocumentDetails?.value as string) ??
                                                ''
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <FWTypography
                                                color={'primary'}
                                                sx={{
                                                    textDecoration: 'none',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'normal',
                                                }}
                                            >
                                                {
                                                    fieldInfoInDocumentDetails?.value as string
                                                }
                                            </FWTypography>
                                        </a>
                                    ) : (
                                        <FWTypography
                                            sx={{
                                                whiteSpace: 'normal',
                                            }}
                                        >
                                            {fieldInfoInDocumentDetails?.value}
                                        </FWTypography>
                                        // <TextField
                                        //     multiline
                                        //     fullWidth
                                        //     maxRows={15}
                                        //     value={
                                        //         fieldInfoInDocumentDetails?.value
                                        //     }
                                        //     InputProps={{
                                        //         readOnly: true,
                                        //     }}
                                        //     size="small"
                                        // >
                                        //     {fieldInfoInDocumentDetails?.value
                                        //         ? fieldInfoInDocumentDetails?.value
                                        //         : '-'}
                                        // </TextField>
                                    )}
                                </Grid>
                            ) : (
                                <></>
                            );

                        default:
                            return <></>;
                    }
                })}
                {templateCF?.map((field) => {
                    const fieldInfoFromTemplate =
                        templateDetails.fieldSettings.customFields[field.field];

                    const isFieldNegotiable =
                        fieldInfoFromTemplate.sellerSettings.negotiable;

                    const fieldList = isFieldNegotiable
                        ? buyerCustomFieldsNeg
                        : buyerCustomFieldsNonNeg;
                    const fieldInfoInDocumentDetails =
                        fieldList?.custom_fields?.find(
                            (payloadField) =>
                                payloadField.name ===
                                fieldInfoFromTemplate.label
                        );

                    if (!Boolean(fieldInfoInDocumentDetails)) {
                        return <></>;
                    }

                    console.log(
                        'fieldInfoInDocumentDetails',
                        fieldInfoInDocumentDetails
                    );

                    switch (fieldInfoFromTemplate.constraints.fieldType) {
                        case 'ATTACHMENT':
                            console.log('meow meow', buyerDocAttachments);
                            return buyerDocAttachments[
                                fieldInfoFromTemplate?.label
                            ]?.length > 0 ? (
                                <Grid item xs={12} marginY={1}>
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography
                                            fontSize={28}
                                            // fontFamily={'sans-serif'}
                                        >
                                            {fieldInfoFromTemplate?.label}{' '}
                                        </FWTypography>
                                        {/* {buyerDocAttachments[
                                        fieldInfoFromTemplate?.label
                                    ]?.length > 1 && (
                                        <FWTooltip
                                            title={'Download all attachments'}
                                        >
                                            <Box color="primary.main">
                                                <IconButton
                                                    sx={{ fontSize: '16px' }}
                                                    onClick={() => {
                                                        bulkDownloadFiles(
                                                            buyerDocAttachments[
                                                                fieldInfoFromTemplate
                                                                    ?.label
                                                            ]?.map(
                                                                (attachment) =>
                                                                    attachment.attachment_id
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <Box color="primary.main">
                                                        <i className="bi bi-download" />
                                                    </Box>
                                                </IconButton>
                                            </Box>
                                        </FWTooltip>
                                    )} */}
                                        {/* {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )} */}
                                    </Grid>

                                    <Grid container rowGap={1} gap={2}>
                                        <AttachmentRender
                                            attachments={
                                                buyerDocAttachments[
                                                    fieldInfoFromTemplate?.label
                                                ] ?? []
                                            }
                                            handleFileDownload={(
                                                attachment: IAttachment
                                            ) => {
                                                handleDownloadFile(
                                                    returnFileId(attachment)
                                                );
                                            }}
                                            gridSize={gridSize}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <></>
                            );

                        default:
                            return <></>;
                    }
                })}
            </Grid>
            <Grid
                container
                rowGap={2}
                columnGap={4}
                marginBottom={4}
                sx={{
                    borderRadius: '8px',
                    borderTop: '8px solid',
                    borderColor: 'primary.main',
                    background: 'white',
                    boxShadow:
                        '0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)',
                }}
                padding={4}
            >
                {templateCF?.map((field) => {
                    const fieldInfoFromTemplate =
                        templateDetails.fieldSettings.customFields[field.field];

                    const isFieldNegotiable =
                        fieldInfoFromTemplate.sellerSettings.negotiable;

                    const fieldList = isFieldNegotiable
                        ? buyerDocumentDetails.custom_fields_negotiate.section_list.find(
                              (section) => section.name === sectionNameInPayload
                          )
                        : buyerDocumentDetails.custom_fields.section_list.find(
                              (section) => section.name === sectionNameInPayload
                          );

                    const fieldInfoInDocumentDetails = fieldList?.fields.find(
                        (payloadField) =>
                            payloadField.name === fieldInfoFromTemplate.label
                    );

                    if (!Boolean(fieldInfoInDocumentDetails)) {
                        return <></>;
                    }

                    const fieldError = isFieldNegotiable
                        ? errors.custom_fields_negotiate &&
                          errors.custom_fields_negotiate[
                              fieldInfoFromTemplate.label
                          ]
                        : errors.custom_fields &&
                          errors.custom_fields[fieldInfoFromTemplate.label];

                    switch (fieldInfoFromTemplate.constraints.fieldType) {
                        case 'TEMPLATE':
                            return buyerDocAttachments[
                                fieldInfoFromTemplate?.label
                            ]?.length > 0 ? (
                                <Grid item xs={12} marginY={1}>
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography
                                            // color={'text.secondary'}
                                            fontSize={28}
                                            // fontFamily={'sans-serif'}
                                        >
                                            {fieldInfoFromTemplate?.label}{' '}
                                        </FWTypography>
                                        {/* {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )} */}
                                    </Grid>
                                    <ViewTemplateList
                                        attachments={
                                            buyerDocAttachments[
                                                fieldInfoFromTemplate?.label
                                            ] ?? []
                                        }
                                        gridSize={2.75}
                                        allowTemplateUpload={
                                            allowTemplateUpload
                                        }
                                        customGridSizeForTemp={
                                            customGridSizeForTemp
                                        }
                                        fieldError={fieldError}
                                        updateCustomField={updateCustomField}
                                        fieldInfoFromTemplate={
                                            fieldInfoFromTemplate
                                        }
                                        allowFileUpload={allowFileUpload}
                                        showRemoveButton={showRemoveButton}
                                        sellerDocument={sellerDocument}
                                        sellerDocumentDetails={
                                            sellerDocumentDetails
                                        }
                                        isFieldNegotiable={isFieldNegotiable}
                                        fieldInfoInDocumentDetails={
                                            fieldInfoInDocumentDetails
                                        }
                                    />
                                </Grid>
                            ) : (
                                <></>
                            );

                        default:
                            return <></>;
                    }
                })}
            </Grid>
        </>
    );
}
