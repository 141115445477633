import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
} from '@mui/material';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { roundToNDecimalPlace } from '../../../Common/CurrencyUtilsComponent';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import FWIcon from '../../../Common/FWIcon';
import { FWInput } from '../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../Common/FWPopupWithChildren';
import { FWTypography } from '../../../Common/FWTypograhy';
import {
    ICostCenteDashboard,
    useGetListOfAllCostCenterQuery,
} from '../../../ProjectGlCostCenter/services/costCenter.services';
import {
    GeneralLedger,
    useListGeneralLedgerQuery,
} from '../../../ProjectGlCostCenter/services/generalLedger.services';
import { useListProjectsQuery } from '../../../ProjectGlCostCenter/services/project.services';
import { ItemCartTemplateStandardFieldsEnum } from '../../Interfaces/ItemCartTemplateInterfaces';
import { ItemCartAccessContext } from '../../Pages/ItemCartAccess';
import { CartItemAccordionContext } from './CartItemAccordion';
import { ccNone, glNone, projectNone } from '../../../Events/Components/Utils';
import FWAutocomplete from '../../../Common/FWAutocomplete';
import IProject from '../../../ProjectGlCostCenter/interface/project.model';

export interface IDeliveryScheduleForCartItemProps {
    cartItem: {
        delivery_information: {
            delivery_date: string | null;
            quantity: string;
            project_id: string | null;
            cost_centre_id: string | null;
            general_ledger_id: string | null;
        }[];
        quantity: string;
        measurement_unit_name: string;
    };
    open: boolean;
    handleClose: () => void;
}

export default function DeliveryScheduleForCartItem({
    cartItem,
    handleClose,
    open,
}: IDeliveryScheduleForCartItemProps) {
    const { templateDetails, buyerEntityId } = useContext(
        ItemCartAccessContext
    );

    const { updateItemData, errors } = useContext(CartItemAccordionContext);

    const quantityLabel = useMemo(() => {
        return templateDetails.fieldSettings.standardFields[
            ItemCartTemplateStandardFieldsEnum.QUANTITY
        ].label;
    }, [templateDetails.fieldSettings.standardFields]);

    const [showProject, projectFieldDescription, projectLabel] = useMemo(() => {
        return [
            templateDetails.fieldSettings.standardFields[
                ItemCartTemplateStandardFieldsEnum.PROJECT
            ].buyerSettings.showField,
            templateDetails.fieldSettings.standardFields[
                ItemCartTemplateStandardFieldsEnum.PROJECT
            ].description,
            templateDetails.fieldSettings.standardFields[
                ItemCartTemplateStandardFieldsEnum.PROJECT
            ].label,
        ];
    }, [templateDetails.fieldSettings.standardFields]);

    const [showCostCenter, costCenterFieldDescription, costCenterLabel] =
        useMemo(() => {
            return [
                templateDetails.fieldSettings.standardFields[
                    ItemCartTemplateStandardFieldsEnum.COST_CENTER
                ].buyerSettings.showField,
                templateDetails.fieldSettings.standardFields[
                    ItemCartTemplateStandardFieldsEnum.COST_CENTER
                ].description,
                templateDetails.fieldSettings.standardFields[
                    ItemCartTemplateStandardFieldsEnum.COST_CENTER
                ].label,
            ];
        }, [templateDetails.fieldSettings.standardFields]);

    const [showGL, glFieldDescription, glLabel] = useMemo(() => {
        return [
            templateDetails.fieldSettings.standardFields[
                ItemCartTemplateStandardFieldsEnum.GL
            ].buyerSettings.showField,
            templateDetails.fieldSettings.standardFields[
                ItemCartTemplateStandardFieldsEnum.GL
            ].description,
            templateDetails.fieldSettings.standardFields[
                ItemCartTemplateStandardFieldsEnum.GL
            ].label,
        ];
    }, [templateDetails.fieldSettings.standardFields]);

    const [deliveryDateDescription, deliveryLabel] = useMemo(() => {
        return [
            templateDetails.fieldSettings.standardFields[
                ItemCartTemplateStandardFieldsEnum.DELIVERY_DATE
            ].description,
            templateDetails.fieldSettings.standardFields[
                ItemCartTemplateStandardFieldsEnum.DELIVERY_DATE
            ].label,
        ];
    }, [templateDetails.fieldSettings.standardFields]);

    const [originalCartItem, setOriginalCartItem] = useState<{
        delivery_information: {
            delivery_date: string | null;
            quantity: string;
            project_id: string | null;
            cost_centre_id: string | null;
            general_ledger_id: string | null;
        }[];
        quantity: string;
        measurement_unit_name: string;
    } | null>(null);

    const rearrangeDeliverySchedule = (
        deliverySchedule: { delivery_date: string | null; quantity: string }[]
    ) => {
        deliverySchedule = deliverySchedule.sort((a, b) => {
            if (a.delivery_date === null) return 1;
            if (b.delivery_date === null) return -1;
            if (a.delivery_date === '') return 1;
            if (b.delivery_date === '') return -1;
            return moment(a.delivery_date).diff(moment(b.delivery_date));
        });
        return deliverySchedule;
    };

    const discardChangesAndClose = () => {
        if (originalCartItem) {
            updateItemData([
                {
                    field: 'delivery_information',
                    value: originalCartItem.delivery_information,
                },
                {
                    field: 'quantity',
                    value: originalCartItem.quantity,
                },
            ]);
        }
        handleClose();
    };

    const isProjectFieldMandatory =
        templateDetails.fieldSettings.standardFields[
            ItemCartTemplateStandardFieldsEnum.PROJECT
        ].buyerSettings.required;

    const isCostCenterMandatory =
        templateDetails.fieldSettings.standardFields[
            ItemCartTemplateStandardFieldsEnum.COST_CENTER
        ].buyerSettings.required;

    const isGLMandatory =
        templateDetails.fieldSettings.standardFields[
            ItemCartTemplateStandardFieldsEnum.GL
        ].buyerSettings.required;
    useEffect(() => {
        if (open) {
            setOriginalCartItem(cloneDeep(cartItem));
        }
        //Dont want to listen on changes in master rfqItem data as that defeats the purpose.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const [totalQuantity, setTotalQuantity] = useState(0);

    useEffect(() => {
        // let tbd_exists = false;
        let total = 0;
        for (let delivery of cartItem.delivery_information) {
            if (
                typeof delivery.quantity === 'number' ||
                (typeof delivery.quantity === 'string' &&
                    !isEmpty(delivery.quantity))
            ) {
                total += +delivery.quantity;
            }
        }

        total = roundToNDecimalPlace(total, 10);

        setTotalQuantity(total);
    }, [cartItem.delivery_information]);

    const onClickAddrow = () => {
        let newObj = cloneDeep(cartItem);
        newObj.delivery_information.push({
            delivery_date: null,
            quantity: '',
            cost_centre_id: null,
            general_ledger_id: null,
            project_id: null,
        });
        updateItemData([
            {
                field: 'delivery_information',
                value: newObj.delivery_information,
            },
        ]);
    };

    const onClickRemoveRow = (idx: number) => {
        let newObj = cloneDeep(cartItem);
        newObj.delivery_information.splice(idx, 1);
        updateItemData([
            {
                field: 'delivery_information',
                value: newObj.delivery_information,
            },
        ]);
    };

    const onEditDate = (idx: number, value: string | null) => {
        let newObj = cloneDeep(cartItem);
        if (value === null)
            newObj.delivery_information[idx].delivery_date = null;
        else {
            newObj.delivery_information[idx].delivery_date = value;
        }
        updateItemData([
            {
                field: 'delivery_information',
                value: rearrangeDeliverySchedule(newObj.delivery_information),
            },
        ]);
    };

    const onEditGlCostProject = (
        idx: number,
        updatingField: 'project_id' | 'cost_centre_id' | 'general_ledger_id',
        value: string | null
    ) => {
        let newObj = cloneDeep(cartItem);
        newObj.delivery_information[idx][updatingField] = value;

        updateItemData([
            {
                field: 'delivery_information',
                value: newObj.delivery_information,
            },
        ]);
    };

    const onEditQuantity = (idx: number, value: string) => {
        let newObj = cloneDeep(cartItem);
        newObj.delivery_information[idx].quantity = value;

        updateItemData([
            {
                field: 'delivery_information',
                value: newObj.delivery_information,
            },
        ]);
    };

    const dsErrors = errors.delivery_information;

    const disableSaveButton = useMemo(() => {
        let qty_error = false,
            date_error = false,
            project_error = false,
            cost_center_error = false,
            gl_error = false;

        for (let idx = 0; idx < cartItem.delivery_information.length; idx++) {
            const rowErrors = dsErrors[idx];
            if (rowErrors && rowErrors.quantity && !rowErrors.quantity.suppress)
                qty_error = true;
            if (rowErrors && rowErrors.date && !rowErrors.date.suppress)
                date_error = true;

            if (
                rowErrors &&
                rowErrors.project_id &&
                !rowErrors.project_id.suppress
            )
                project_error = true;

            if (
                rowErrors &&
                rowErrors.cost_centre_id &&
                !rowErrors.cost_centre_id.suppress
            )
                cost_center_error = true;

            if (
                rowErrors &&
                rowErrors.general_ledger_id &&
                !rowErrors.general_ledger_id.suppress
            )
                gl_error = true;
        }
        return (
            qty_error ||
            date_error ||
            project_error ||
            cost_center_error ||
            gl_error ||
            ('delivery_information' in errors &&
                errors.delivery_information?.filter(
                    (val: any) => !isEqual(val, {})
                )?.length > 0) ||
            roundToNDecimalPlace(+cartItem.quantity, 10) !== totalQuantity
        );
    }, [
        cartItem.delivery_information.length,
        cartItem.quantity,
        dsErrors,
        errors,
        totalQuantity,
    ]);

    const { data: listOfProjects } = useListProjectsQuery({});
    const { data: listOfCostCenter } = useGetListOfAllCostCenterQuery({});
    const { data: listOfGeneralLedger } = useListGeneralLedgerQuery({});

    const listOfActiveProjects = useMemo(
        () =>
            listOfProjects?.filter(
                (project) =>
                    project.status === 'ACTIVE' &&
                    buyerEntityId === project.entityUid
            ) ?? [],
        [listOfProjects, buyerEntityId]
    );

    const myOptionsForProject = useMemo(() => {
        if (isProjectFieldMandatory) {
            return listOfActiveProjects;
        }

        let projectOptions = cloneDeep(listOfActiveProjects);
        let naIndex = projectOptions.findIndex(
            (project) => project.projectUUid === 'NA'
        );
        if (naIndex === -1) {
            projectOptions.push(projectNone);
        }
        return projectOptions;
    }, [isProjectFieldMandatory, listOfActiveProjects]);

    const listOfActiveCostCenter = useMemo(
        () =>
            listOfCostCenter?.filter(
                (cc) =>
                    cc.status === 'ACTIVE' &&
                    cc.buyerEntites
                        .map((be) => be.buyer_entity)
                        .includes(buyerEntityId ?? '')
            ) ?? [],
        [listOfCostCenter, buyerEntityId]
    );
    const myOptionsForCostCentre = useMemo(() => {
        if (isCostCenterMandatory) {
            return listOfActiveCostCenter;
        }
        let costCentreOptions = cloneDeep(listOfActiveCostCenter);
        let naIndex = costCentreOptions.findIndex(
            (cc) => cc.costCenterUid === 'NA'
        );
        if (naIndex === -1) {
            costCentreOptions.push(ccNone);
        }
        return costCentreOptions;
    }, [isCostCenterMandatory, listOfActiveCostCenter]);

    const listOfActiveGeneralLedger = useMemo(
        () =>
            listOfGeneralLedger?.filter(
                (gl) =>
                    gl.status === 'ACTIVE' &&
                    gl.buyerEntites
                        .map((be) => be.buyer_entity)
                        .includes(buyerEntityId ?? '')
            ) ?? [],
        [listOfGeneralLedger, buyerEntityId]
    );

    const myOptionsForGL = useMemo(() => {
        if (isGLMandatory) {
            return listOfActiveGeneralLedger;
        }
        let glOptions = cloneDeep(listOfActiveGeneralLedger);
        let naIndex = glOptions.findIndex((gl) => gl.glUid === 'NA');
        if (naIndex === -1) {
            glOptions.push(glNone);
        }
        return glOptions;
    }, [isGLMandatory, listOfActiveGeneralLedger]);

    const noOfModulesHidden = useMemo(() => {
        let sum = 0;
        if (!showProject) sum++;
        if (!showCostCenter) sum++;
        if (!showGL) sum++;
        return sum;
    }, [showCostCenter, showGL, showProject]);

    const calculateAmountToAddToGrideSize = () => {
        // let noOfModulesHidden = 0;

        return (2.2 * noOfModulesHidden) / (5 - noOfModulesHidden);
    };

    return (
        <FWPopupWithChildren
            open={open}
            handleClose={discardChangesAndClose}
            title={'Delivery Schedule'}
            showClose={true}
            {...(noOfModulesHidden === 0
                ? {
                      size: 'custom',
                      popupWidth: '70%',
                  }
                : noOfModulesHidden === 1
                ? { size: 'custom', popupWidth: '50%' }
                : noOfModulesHidden === 2
                ? { size: 'custom', popupWidth: '40%' }
                : {
                      size: 'medium',
                  })}
        >
            <DialogContent onClick={(e) => e.stopPropagation()}>
                <Grid
                    container
                    rowSpacing={1}
                    // columnSpacing={1}
                    columnSpacing={2}
                    alignItems="flex-start"
                    paddingRight={2}
                >
                    {cartItem.delivery_information.length === 0 ? (
                        <Grid item xs={12}>
                            <FWTypography>No deliveries!</FWTypography>
                        </Grid>
                    ) : (
                        <div
                            className="flex flex--aic pt--10 pl--15 pr--15 pb--5"
                            style={{ width: '100%' }}
                        >
                            <Grid
                                item
                                xs={2.2 + calculateAmountToAddToGrideSize()}
                            >
                                <FWTypography color="text.secondary">
                                    Delivery {quantityLabel}
                                </FWTypography>
                            </Grid>
                            <Grid
                                item
                                xs={2.2 + calculateAmountToAddToGrideSize()}
                                className="pl--10"
                            >
                                <Grid
                                    container
                                    columnSpacing={1}
                                    alignItems={'center'}
                                >
                                    <Grid item>
                                        <FWTypography color={'secondary'}>
                                            {deliveryLabel}
                                        </FWTypography>
                                    </Grid>
                                    {deliveryDateDescription && (
                                        <Grid item>
                                            <FWTooltip
                                                title={deliveryDateDescription}
                                                placement="top"
                                            >
                                                <Box color={'primary.main'}>
                                                    <i className="bi bi-question-circle" />
                                                </Box>
                                            </FWTooltip>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            {/* {listOfProjects && listOfProjects.length > 0 && ( */}
                            {showProject && (
                                <Grid
                                    item
                                    xs={2.2 + calculateAmountToAddToGrideSize()}
                                    className="pl--10"
                                >
                                    <Grid
                                        container
                                        columnSpacing={1}
                                        alignItems={'center'}
                                    >
                                        <Grid item xs={10}>
                                            <FWTypography color={'secondary'}>
                                                {projectLabel}
                                            </FWTypography>
                                        </Grid>
                                        {projectFieldDescription && (
                                            <Grid item>
                                                <FWTooltip
                                                    title={
                                                        projectFieldDescription
                                                    }
                                                    placement="top"
                                                >
                                                    <Box color={'primary.main'}>
                                                        <i className="bi bi-question-circle" />
                                                    </Box>
                                                </FWTooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {/* )} */}
                            {/* {listOfCostCenter && listOfCostCenter.length > 0 && ( */}
                            {showCostCenter && (
                                <Grid
                                    item
                                    xs={2.2 + calculateAmountToAddToGrideSize()}
                                    className="pl--10"
                                >
                                    <Grid
                                        container
                                        columnSpacing={1}
                                        alignItems={'center'}
                                    >
                                        <Grid item xs={10}>
                                            <FWTypography
                                                color={'secondary'}
                                                style={{
                                                    overflow:
                                                        'scroll !important',
                                                }}
                                            >
                                                {costCenterLabel}
                                            </FWTypography>
                                        </Grid>
                                        {costCenterFieldDescription && (
                                            <Grid item>
                                                <FWTooltip
                                                    title={
                                                        costCenterFieldDescription
                                                    }
                                                    placement="top"
                                                >
                                                    <Box color={'primary.main'}>
                                                        <i className="bi bi-question-circle" />
                                                    </Box>
                                                </FWTooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {/* )} */}
                            {/* {listOfGeneralLedger &&
                                listOfGeneralLedger.length > 0 && ( */}
                            {showGL && (
                                <Grid
                                    item
                                    xs={2.2 + calculateAmountToAddToGrideSize()}
                                    className="pl--10"
                                >
                                    <Grid
                                        container
                                        columnSpacing={1}
                                        alignItems={'center'}
                                    >
                                        <Grid item xs={10}>
                                            <FWTypography color={'secondary'}>
                                                {glLabel}
                                            </FWTypography>
                                        </Grid>
                                        {glFieldDescription && (
                                            <Grid item>
                                                <FWTooltip
                                                    title={glFieldDescription}
                                                    placement="top"
                                                >
                                                    <Box color={'primary.main'}>
                                                        <i className="bi bi-question-circle" />
                                                    </Box>
                                                </FWTooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {/* )} */}
                        </div>
                    )}
                    {cartItem.delivery_information.map(
                        (delivery, idx: number) => {
                            const rowErrors = dsErrors[idx];

                            return (
                                <div
                                    className="flex flex--aifs pl--15 pr--15 pb--10 "
                                    key={idx}
                                    style={{ width: '100%' }}
                                >
                                    <Grid
                                        item
                                        xs={
                                            2.2 +
                                            calculateAmountToAddToGrideSize()
                                        }
                                    >
                                        <FWInput
                                            allowOnly="DECIMAL_NUMBER"
                                            error={
                                                rowErrors?.quantity
                                                    ?.fieldMessageText ===
                                                    'Input required' &&
                                                delivery.quantity !== ''
                                                    ? false
                                                    : rowErrors &&
                                                      rowErrors.quantity &&
                                                      !rowErrors.quantity
                                                          .suppress
                                            }
                                            helper={{
                                                text:
                                                    rowErrors?.quantity
                                                        ?.fieldMessageText ===
                                                        'Input required' &&
                                                    delivery.quantity !== ''
                                                        ? ''
                                                        : rowErrors &&
                                                          rowErrors.quantity &&
                                                          !rowErrors.quantity
                                                              .suppress
                                                        ? rowErrors.quantity
                                                              .fieldMessageText
                                                        : '',
                                            }}
                                            placeholder="0"
                                            value={delivery.quantity}
                                            onChange={(e: {
                                                target: { value: string };
                                            }) => {
                                                onEditQuantity(
                                                    idx,
                                                    e.target.value
                                                );
                                            }}
                                            iconEnd={
                                                cartItem.measurement_unit_name +
                                                '(s)'
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={
                                            2.2 +
                                            calculateAmountToAddToGrideSize()
                                        }
                                        className="pl--10"
                                    >
                                        <div>
                                            {delivery.delivery_date !== null ? (
                                                <Box
                                                    display={'flex'}
                                                    justifyContent={'center'}
                                                    alignItems={'normal'}
                                                >
                                                    <FWInput
                                                        id="EventsAddItemStage_TBDOnTbdFieldSave"
                                                        type="date"
                                                        error={
                                                            rowErrors &&
                                                            rowErrors.date &&
                                                            !rowErrors.date
                                                                ?.suppress
                                                        }
                                                        helper={{
                                                            text:
                                                                rowErrors &&
                                                                rowErrors.date &&
                                                                !rowErrors.date
                                                                    .suppress
                                                                    ? rowErrors
                                                                          .date
                                                                          ?.fieldMessageText
                                                                    : '',
                                                            textColor:
                                                                rowErrors &&
                                                                rowErrors.date &&
                                                                rowErrors.date
                                                                    .type ===
                                                                    'WARNING'
                                                                    ? 'warning'
                                                                    : 'error',
                                                        }}
                                                        value={
                                                            delivery.delivery_date
                                                        }
                                                        InputProps={{
                                                            inputProps: {
                                                                min: moment().format(
                                                                    'YYYY-MM-DD'
                                                                ),
                                                            },
                                                        }}
                                                        onChange={(e) => {
                                                            onEditDate(
                                                                idx,
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <FWTooltip
                                                        title="Decide date later"
                                                        placement="top"
                                                    >
                                                        <div
                                                            style={{
                                                                height: 'max-content',
                                                                width: 'max-content',
                                                            }}
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                color="warning"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    onEditDate(
                                                                        idx,
                                                                        null
                                                                    );
                                                                }}
                                                            >
                                                                <FWIcon
                                                                    name="fw--decide-later"
                                                                    size={20.57}
                                                                />
                                                            </IconButton>
                                                        </div>
                                                    </FWTooltip>
                                                </Box>
                                            ) : (
                                                <Box
                                                    display={'flex'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                >
                                                    <FWInput
                                                        value="To be decided"
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        disabled
                                                    />
                                                    <FWTooltip
                                                        title="Set date"
                                                        placement="top"
                                                    >
                                                        <div>
                                                            <IconButton
                                                                size="small"
                                                                color="success"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    onEditDate(
                                                                        idx,
                                                                        ''
                                                                    );
                                                                }}
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                                className="EventsAddItemStage_TBDOnSelectingTheDate"
                                                            >
                                                                <i className="bi bi-calendar-plus"></i>
                                                            </IconButton>
                                                        </div>
                                                    </FWTooltip>
                                                </Box>
                                            )}
                                        </div>
                                    </Grid>
                                    {/* {listOfProjects && listOfProjects.length > 0 && ( */}
                                    {showProject && (
                                        <Grid
                                            item
                                            xs={
                                                2.2 +
                                                calculateAmountToAddToGrideSize()
                                            }
                                            className="pl--10"
                                        >
                                            <FormControl
                                                // sx={{ m: 1, minWidth: 120 }}
                                                fullWidth
                                                error={
                                                    rowErrors &&
                                                    rowErrors.project_id &&
                                                    !rowErrors.project_id
                                                        ?.suppress
                                                }
                                            >
                                                <FWAutocomplete
                                                    disableClearable
                                                    popupIcon={
                                                        <Box fontSize={'16px'}>
                                                            <i className="bi bi-chevron-down"></i>
                                                        </Box>
                                                    }
                                                    renderInput={(params) => (
                                                        <FWInput
                                                            {...params}
                                                            // error={error}
                                                            // helper={{
                                                            //     text: helperText ?? '',
                                                            // }}
                                                        />
                                                    )}
                                                    options={
                                                        myOptionsForProject
                                                    }
                                                    getOptionLabel={(
                                                        option: IProject
                                                    ) =>
                                                        option.projectUUid ===
                                                        'NA'
                                                            ? 'NA(None)'
                                                            : `${option.projectCode} (${option.projectName})`
                                                    }
                                                    value={
                                                        isProjectFieldMandatory
                                                            ? myOptionsForProject.find(
                                                                  (entity) =>
                                                                      entity.projectUUid ===
                                                                      delivery.project_id
                                                              ) ?? null
                                                            : myOptionsForProject.find(
                                                                  (entity) =>
                                                                      entity.projectUUid ===
                                                                      delivery.project_id
                                                              ) ?? projectNone
                                                    }
                                                    onChange={(
                                                        e,
                                                        newVal: IProject
                                                    ) => {
                                                        onEditGlCostProject(
                                                            idx,
                                                            'project_id',
                                                            newVal.projectUUid
                                                        );
                                                    }}
                                                    componentsProps={{
                                                        paper: {
                                                            style: {
                                                                minWidth:
                                                                    '285px',
                                                                position:
                                                                    'absolute',
                                                                width: 'fit-content',
                                                                left: '50%',
                                                                transform:
                                                                    'translateX(-50%)',
                                                            },
                                                        },
                                                    }}
                                                ></FWAutocomplete>
                                                {/* <FWSelect
                                                    value={
                                                        delivery.project_id ??
                                                        ''
                                                    }
                                                    fullWidth
                                                    onChange={(e: any) => {
                                                        onEditGlCostProject(
                                                            idx,
                                                            'project_id',
                                                            e.target.value ===
                                                                ''
                                                                ? null
                                                                : e.target.value
                                                        );
                                                    }}
                                                >
                                                    {listOfActiveProjects?.map(
                                                        (project) => (
                                                            <FWMenuItem
                                                                value={
                                                                    project.projectUUid
                                                                }
                                                                key={
                                                                    project.projectUUid
                                                                }
                                                            >
                                                                {
                                                                    project.projectCode
                                                                }
                                                            </FWMenuItem>
                                                        )
                                                    )}
                                                </FWSelect> */}
                                                <FormHelperText
                                                    sx={{
                                                        fontSize: '0.75rem',
                                                        marginTop: '0',
                                                    }}
                                                >
                                                    {rowErrors &&
                                                        rowErrors.project_id &&
                                                        !rowErrors.project_id
                                                            ?.suppress &&
                                                        rowErrors.project_id
                                                            .fieldMessageText}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    {/* )} */}
                                    {/* {listOfCostCenter &&
                                    listOfCostCenter.length > 0 && ( */}
                                    {showCostCenter && (
                                        <Grid
                                            item
                                            xs={
                                                2.2 +
                                                calculateAmountToAddToGrideSize()
                                            }
                                            className="pl--10"
                                        >
                                            <FormControl
                                                // sx={{ m: 1, minWidth: 120 }}
                                                fullWidth
                                                error={
                                                    rowErrors &&
                                                    rowErrors.cost_centre_id &&
                                                    !rowErrors.cost_centre_id
                                                        ?.suppress
                                                }
                                            >
                                                <FWAutocomplete
                                                    disableClearable
                                                    popupIcon={
                                                        <Box fontSize={'16px'}>
                                                            <i className="bi bi-chevron-down"></i>
                                                        </Box>
                                                    }
                                                    renderInput={(params) => (
                                                        <FWInput
                                                            {...params}
                                                            // error={error}
                                                            // helper={{
                                                            //     text: helperText ?? '',
                                                            // }}
                                                        />
                                                    )}
                                                    options={
                                                        myOptionsForCostCentre
                                                    }
                                                    getOptionLabel={(
                                                        option: ICostCenteDashboard
                                                    ) =>
                                                        option.costCenterUid ===
                                                        'NA'
                                                            ? 'NA(None)'
                                                            : `${option.costCenterId} (${option.costCenterName})`
                                                    }
                                                    value={
                                                        isCostCenterMandatory
                                                            ? myOptionsForCostCentre.find(
                                                                  (entity) =>
                                                                      entity.costCenterUid ===
                                                                      delivery.cost_centre_id
                                                              ) ?? null
                                                            : myOptionsForCostCentre.find(
                                                                  (entity) =>
                                                                      entity.costCenterUid ===
                                                                      delivery.cost_centre_id
                                                              ) ?? ccNone
                                                    }
                                                    onChange={(
                                                        e,
                                                        newVal: ICostCenteDashboard
                                                    ) => {
                                                        onEditGlCostProject(
                                                            idx,
                                                            'cost_centre_id',
                                                            newVal.costCenterUid
                                                        );
                                                    }}
                                                    componentsProps={{
                                                        paper: {
                                                            style: {
                                                                minWidth:
                                                                    '285px',
                                                                position:
                                                                    'absolute',
                                                                width: 'fit-content',
                                                                left: '50%',
                                                                transform:
                                                                    'translateX(-50%)',
                                                            },
                                                        },
                                                    }}
                                                ></FWAutocomplete>
                                                {/* <FWSelect
                                                    value={
                                                        delivery.cost_centre_id ??
                                                        ''
                                                    }
                                                    onChange={(e: any) => {
                                                        onEditGlCostProject(
                                                            idx,
                                                            'cost_centre_id',
                                                            e.target.value ===
                                                                ''
                                                                ? null
                                                                : e.target.value
                                                        );
                                                    }}
                                                    fullWidth
                                                >
                                                    {listOfActiveCostCenter?.map(
                                                        (costCenter) => (
                                                            <FWMenuItem
                                                                value={
                                                                    costCenter.costCenterUid
                                                                }
                                                                key={
                                                                    costCenter.costCenterUid
                                                                }
                                                            >
                                                                {
                                                                    costCenter.costCenterId
                                                                }
                                                            </FWMenuItem>
                                                        )
                                                    )}
                                                </FWSelect> */}
                                                <FormHelperText
                                                    sx={{
                                                        fontSize: '0.75rem',
                                                        marginTop: '0',
                                                    }}
                                                >
                                                    {rowErrors &&
                                                        rowErrors.cost_centre_id &&
                                                        !rowErrors
                                                            .cost_centre_id
                                                            ?.suppress &&
                                                        rowErrors.cost_centre_id
                                                            .fieldMessageText}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    {/* )} */}
                                    {/* {listOfGeneralLedger &&
                                    listOfGeneralLedger.length > 0 && ( */}
                                    {showGL && (
                                        <Grid
                                            item
                                            xs={
                                                2.2 +
                                                calculateAmountToAddToGrideSize()
                                            }
                                            className="pl--10"
                                        >
                                            <FormControl
                                                // sx={{ m: 1, minWidth: 120 }}
                                                fullWidth
                                                error={
                                                    rowErrors &&
                                                    rowErrors.general_ledger_id &&
                                                    !rowErrors.general_ledger_id
                                                        ?.suppress
                                                }
                                            >
                                                <FWAutocomplete
                                                    disableClearable
                                                    popupIcon={
                                                        <Box fontSize={'16px'}>
                                                            <i className="bi bi-chevron-down"></i>
                                                        </Box>
                                                    }
                                                    renderInput={(params) => (
                                                        <FWInput
                                                            {...params}
                                                            // error={error}
                                                            // helper={{
                                                            //     text: helperText ?? '',
                                                            // }}
                                                        />
                                                    )}
                                                    options={myOptionsForGL}
                                                    getOptionLabel={(
                                                        option: GeneralLedger
                                                    ) =>
                                                        option.glUid === 'NA'
                                                            ? 'NA(None)'
                                                            : `${option.glCode} (${option.glAccountName})`
                                                    }
                                                    value={
                                                        isGLMandatory
                                                            ? myOptionsForGL.find(
                                                                  (entity) =>
                                                                      entity.glUid ===
                                                                      delivery.general_ledger_id
                                                              ) ?? null
                                                            : myOptionsForGL.find(
                                                                  (entity) =>
                                                                      entity.glUid ===
                                                                      delivery.general_ledger_id
                                                              ) ?? glNone
                                                    }
                                                    onChange={(
                                                        e,
                                                        newVal: GeneralLedger
                                                    ) => {
                                                        onEditGlCostProject(
                                                            idx,
                                                            'general_ledger_id',
                                                            newVal.glUid
                                                        );
                                                    }}
                                                    componentsProps={{
                                                        paper: {
                                                            style: {
                                                                minWidth:
                                                                    '285px',
                                                                position:
                                                                    'absolute',
                                                                width: 'fit-content',
                                                                left: '50%',
                                                                transform:
                                                                    'translateX(-50%)',
                                                            },
                                                        },
                                                    }}
                                                ></FWAutocomplete>
                                                {/* <FWSelect
                                                    value={
                                                        delivery.general_ledger_id ??
                                                        ''
                                                    }
                                                    fullWidth
                                                    onChange={(e: any) => {
                                                        onEditGlCostProject(
                                                            idx,
                                                            'general_ledger_id',
                                                            e.target.value ===
                                                                ''
                                                                ? null
                                                                : e.target.value
                                                        );
                                                    }}
                                                >
                                                    {listOfActiveGeneralLedger?.map(
                                                        (generalLedger) => (
                                                            <FWMenuItem
                                                                value={
                                                                    generalLedger.glUid
                                                                }
                                                                key={
                                                                    generalLedger.glUid
                                                                }
                                                            >
                                                                {
                                                                    generalLedger.glCode
                                                                }
                                                            </FWMenuItem>
                                                        )
                                                    )}
                                                </FWSelect> */}
                                                <FormHelperText
                                                    sx={{
                                                        fontSize: '0.75rem',
                                                        marginTop: '0',
                                                    }}
                                                >
                                                    {rowErrors &&
                                                        rowErrors.general_ledger_id &&
                                                        !rowErrors
                                                            .general_ledger_id
                                                            ?.suppress &&
                                                        rowErrors
                                                            .general_ledger_id
                                                            .fieldMessageText}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    {/* )} */}
                                    <Grid
                                        item
                                        xs={1}
                                        className="flex flex--aic pl--10"
                                        sx={{
                                            pt: '0 !important',
                                            height: '40px',
                                        }}
                                    >
                                        <FWTooltip
                                            title="Remove row"
                                            placement="top"
                                        >
                                            <div>
                                                <IconButton
                                                    size="small"
                                                    color="error"
                                                    onClick={(e) => {
                                                        onClickRemoveRow(idx);
                                                    }}
                                                    disabled={
                                                        cartItem
                                                            .delivery_information
                                                            .length === 1
                                                    }
                                                    sx={{
                                                        fontSize: '20px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        ml: '10px',
                                                    }}
                                                >
                                                    <i className="bi bi-dash-circle"></i>
                                                </IconButton>
                                            </div>
                                        </FWTooltip>
                                        {cartItem.delivery_information.length -
                                            1 ===
                                            idx && (
                                            <div>
                                                <IconButton
                                                    color="primary"
                                                    onClick={onClickAddrow}
                                                    sx={{
                                                        fontSize: '20px',
                                                        padding: '5px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <i className="bi bi-plus-circle"></i>
                                                </IconButton>
                                            </div>
                                        )}
                                    </Grid>
                                </div>
                            );
                        }
                    )}
                    <Grid item md={12}>
                        <FWTypography sx={{ color: 'text.secondary' }}>
                            Total delivery {quantityLabel}
                        </FWTypography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FWTypography>
                                {`${totalQuantity} ${cartItem.measurement_unit_name}(s)`}
                            </FWTypography>
                            &nbsp;&nbsp;
                            {roundToNDecimalPlace(+cartItem.quantity, 10) !==
                                totalQuantity && (
                                <FWTooltip
                                    title={'Mismatch with total item quantity'}
                                >
                                    <i
                                        className="bi bi-exclamation-circle"
                                        style={{ color: 'red' }}
                                    ></i>
                                </FWTooltip>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{ m: '0.5rem 1rem 1rem 1rem' }}
                onClick={(e) => e.stopPropagation()}
            >
                <Button
                    variant="outlined"
                    color="error"
                    onClick={discardChangesAndClose}
                >
                    Cancel
                </Button>
                <Button
                    disabled={disableSaveButton}
                    onClick={handleClose}
                    variant="contained"
                    id="EventsAddItemStage_TbdFieldSaveButton"
                >
                    Save
                </Button>
            </DialogActions>
        </FWPopupWithChildren>
    );
}
