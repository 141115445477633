import { Box, Grid } from '@mui/material';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWTypography } from '../../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../../Common/Utils/CurrencyUtils';
import IncotermsGraph from '../../../Components/Shared/IncotermsGraph';
import { IPaymentTerms } from '../../../Global/Interfaces/PaymentTermsInterface';
import {
    IRfqTemplate,
    TemplateStandardFieldsEnum,
} from '../../../Global/Interfaces/TemplateInterface';
import { paymentTermsFormatter } from '../../RFQAnalytics/Helpers/HelperFunction';
import { useGetAllIncotermsQuery } from '../../Services/Buyer/shared.service';
import PaymentTermsPopover from './PaymentTermsPopover';

interface IPaymentTermsIncotermGRTolerenceForEventProps {
    templateDetails: IRfqTemplate;
    gridSize: number;
    accessedBy: 'BUYER' | 'VENDOR';
    incoterm_id: string;
    paymentTerms: IPaymentTerms;
    lead_time: string;
    lead_time_period: string | null;
    default_event_quantity_tolerance_percentage: string | undefined;
}

const PaymentTermsIncotermGRTolerenceForEventViewMode = ({
    paymentTerms,
    incoterm_id,
    lead_time,
    lead_time_period,
    default_event_quantity_tolerance_percentage,
    gridSize,
    templateDetails,
    accessedBy,
}: IPaymentTermsIncotermGRTolerenceForEventProps) => {
    const { data: incotermsFromQuery } = useGetAllIncotermsQuery({});

    return (
        <>
            {templateDetails?.sections.EVENT_DETAILS.fieldList.map(
                (fieldListItem, idx) => {
                    if (fieldListItem.fieldType === 'STANDARD')
                        if (
                            templateDetails?.fieldSettings.standardFields[
                                fieldListItem.field
                            ].buyerSettings.showField
                        )
                            switch (fieldListItem.field) {
                                case TemplateStandardFieldsEnum.PAYMENT_TERMS:
                                    const templatePt =
                                        templateDetails?.fieldSettings
                                            .standardFields.PAYMENT_TERMS;

                                    let standardReferenceDateOptions = {
                                        DISPATCH_DATE: false,
                                        RECEIPT_DATE: false,
                                        INVOICE_DATE: false,
                                    };
                                    let allowManualDeliverable = false;

                                    let customReferenceDateOptions: string[] =
                                        [];

                                    if (
                                        paymentTerms?.paymentType ===
                                            'invoiceItemValues' &&
                                        templatePt.paymentTermOptions
                                            ?.paymentType ===
                                            'invoiceItemValues'
                                    ) {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        standardReferenceDateOptions = {
                                            DISPATCH_DATE:
                                                templatePt.paymentTermOptions
                                                    .standardReferenceDateOptions
                                                    .DISPATCH_DATE.enabled,
                                            INVOICE_DATE:
                                                templatePt.paymentTermOptions
                                                    .standardReferenceDateOptions
                                                    .INVOICE_DATE.enabled,
                                            RECEIPT_DATE:
                                                templatePt.paymentTermOptions
                                                    .standardReferenceDateOptions
                                                    .RECEIPT_DATE.enabled,
                                        };
                                        customReferenceDateOptions =
                                            templatePt.paymentTermOptions
                                                .customReferenceDateOptions;
                                        allowManualDeliverable =
                                            templatePt.paymentTermOptions
                                                .allowOtherReferenceDate;
                                    } else if (
                                        paymentTerms?.paymentType ===
                                            'poItemPercentages' &&
                                        templatePt.paymentTermOptions
                                            ?.paymentType ===
                                            'poItemPercentages'
                                    ) {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        customReferenceDateOptions =
                                            templatePt.paymentTermOptions
                                                .deliverableNameOptions;
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        allowManualDeliverable =
                                            templatePt.paymentTermOptions
                                                .allowOtherDeliverableNames;
                                    }

                                    if (!templatePt.buyerSettings.showField) {
                                        return undefined;
                                    }
                                    return (
                                        (accessedBy === 'BUYER' ||
                                            (accessedBy === 'VENDOR' &&
                                                templateDetails.fieldSettings
                                                    .standardFields[
                                                    TemplateStandardFieldsEnum
                                                        .PAYMENT_TERMS
                                                ].sellerSettings
                                                    .showField)) && (
                                            <Grid item xs={gridSize} key={idx}>
                                                <Box
                                                    gridColumn={'span 1'}
                                                    component={'span'}
                                                >
                                                    <div className="flex flex--aic">
                                                        <FWTypography
                                                            color={
                                                                'text.secondary'
                                                            }
                                                        >
                                                            {
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields
                                                                    .PAYMENT_TERMS
                                                                    .label
                                                            }
                                                        </FWTypography>
                                                        &nbsp;
                                                        {templateDetails
                                                            ?.fieldSettings
                                                            .standardFields[
                                                            fieldListItem.field
                                                        ]?.description && (
                                                            <FWTooltip
                                                                title={
                                                                    templateDetails
                                                                        ?.fieldSettings
                                                                        .standardFields[
                                                                        fieldListItem
                                                                            .field
                                                                    ]
                                                                        ?.description
                                                                }
                                                                placement="right"
                                                            >
                                                                <Box color="primary.main">
                                                                    <i className="bi bi-info-circle" />
                                                                </Box>
                                                            </FWTooltip>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {paymentTerms?.paymentType ===
                                                            'invoiceItemValues' ||
                                                        (paymentTerms?.paymentType ===
                                                            'poItemPercentages' &&
                                                            paymentTerms?.list
                                                                .length ===
                                                                1) ? (
                                                            <>
                                                                {paymentTermsFormatter(
                                                                    {
                                                                        paymentTerms:
                                                                            paymentTerms,
                                                                        includePrepaymentString:
                                                                            templatePt
                                                                                .paymentTermOptions
                                                                                .prepaymentAllowed,
                                                                        delimiter:
                                                                            ' | ',
                                                                    }
                                                                )}
                                                            </>
                                                        ) : (
                                                            <PaymentTermsPopover
                                                                label={
                                                                    templateDetails
                                                                        .fieldSettings
                                                                        .standardFields
                                                                        .PAYMENT_TERMS
                                                                        .label
                                                                }
                                                                paymentTerms={
                                                                    paymentTerms
                                                                }
                                                                showPrepayment={
                                                                    templatePt
                                                                        .paymentTermOptions
                                                                        .prepaymentAllowed
                                                                }
                                                                hideLabel={true}
                                                                darkLabel
                                                            />
                                                        )}
                                                    </div>
                                                </Box>
                                            </Grid>
                                        )
                                    );
                                case TemplateStandardFieldsEnum.INCOTERMS:
                                    return (
                                        (accessedBy === 'BUYER' ||
                                            (accessedBy === 'VENDOR' &&
                                                templateDetails.fieldSettings
                                                    .standardFields[
                                                    TemplateStandardFieldsEnum
                                                        .INCOTERMS
                                                ].sellerSettings
                                                    .showField)) && (
                                            <Grid item xs={gridSize} key={idx}>
                                                <Box>
                                                    <div className="flex flex--aic">
                                                        <FWTypography color="text.secondary">
                                                            {
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    fieldListItem
                                                                        .field
                                                                ].label
                                                            }
                                                        </FWTypography>
                                                        &nbsp;
                                                        {templateDetails
                                                            ?.fieldSettings
                                                            .standardFields[
                                                            fieldListItem.field
                                                        ]?.description && (
                                                            <FWTooltip
                                                                title={
                                                                    templateDetails
                                                                        ?.fieldSettings
                                                                        .standardFields[
                                                                        fieldListItem
                                                                            .field
                                                                    ]
                                                                        ?.description
                                                                }
                                                                placement="right"
                                                            >
                                                                <Box color="primary.main">
                                                                    <i className="bi bi-info-circle" />
                                                                </Box>
                                                            </FWTooltip>
                                                        )}
                                                        &nbsp;
                                                        <IncotermsGraph />
                                                    </div>

                                                    <FWTypography>
                                                        {incotermsFromQuery?.find(
                                                            (inc) =>
                                                                inc.entry_id ===
                                                                incoterm_id
                                                        )
                                                            ?.incoterm_abbreviation ??
                                                            ''}
                                                    </FWTypography>
                                                </Box>
                                            </Grid>
                                        )
                                    );
                                case TemplateStandardFieldsEnum.LEAD_TIME:
                                    return (
                                        (accessedBy === 'BUYER' ||
                                            (accessedBy === 'VENDOR' &&
                                                templateDetails.fieldSettings
                                                    .standardFields[
                                                    TemplateStandardFieldsEnum
                                                        .LEAD_TIME
                                                ].sellerSettings
                                                    .showField)) && (
                                            <Grid item xs={gridSize} key={idx}>
                                                <Box>
                                                    <div className="flex flex--aic">
                                                        <FWTypography
                                                            color={
                                                                'text.secondary'
                                                            }
                                                        >
                                                            {
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    fieldListItem
                                                                        .field
                                                                ].label
                                                            }
                                                        </FWTypography>
                                                        &nbsp;
                                                        {templateDetails
                                                            ?.fieldSettings
                                                            .standardFields[
                                                            fieldListItem.field
                                                        ]?.description && (
                                                            <FWTooltip
                                                                title={
                                                                    templateDetails
                                                                        ?.fieldSettings
                                                                        .standardFields[
                                                                        fieldListItem
                                                                            .field
                                                                    ]
                                                                        ?.description
                                                                }
                                                                placement="right"
                                                            >
                                                                <Box color="primary.main">
                                                                    <i className="bi bi-info-circle" />
                                                                </Box>
                                                            </FWTooltip>
                                                        )}
                                                    </div>
                                                    <FWTypography
                                                        color={'text.primary'}
                                                    >
                                                        {lead_time_period ===
                                                            null ||
                                                        lead_time_period ===
                                                            'NA'
                                                            ? 'NA(None)'
                                                            : +lead_time > 0
                                                            ? `${lead_time} ${lead_time_period?.toLowerCase()}`
                                                            : 'No lead time'}
                                                    </FWTypography>
                                                </Box>
                                            </Grid>
                                        )
                                    );
                                case TemplateStandardFieldsEnum.GR_TOLERANCE:
                                    return (
                                        (accessedBy === 'BUYER' ||
                                            (accessedBy === 'VENDOR' &&
                                                templateDetails.fieldSettings
                                                    .standardFields[
                                                    TemplateStandardFieldsEnum
                                                        .GR_TOLERANCE
                                                ].sellerSettings
                                                    .showField)) && (
                                            <Grid item xs={gridSize} key={idx}>
                                                <Box>
                                                    <div className="flex flex--aic">
                                                        <FWTypography color="text.secondary">
                                                            {
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    fieldListItem
                                                                        .field
                                                                ].label
                                                            }
                                                        </FWTypography>
                                                        &nbsp;
                                                        {templateDetails
                                                            ?.fieldSettings
                                                            .standardFields[
                                                            fieldListItem.field
                                                        ]?.description && (
                                                            <FWTooltip
                                                                title={
                                                                    templateDetails
                                                                        ?.fieldSettings
                                                                        .standardFields[
                                                                        fieldListItem
                                                                            .field
                                                                    ]
                                                                        ?.description
                                                                }
                                                                placement="right"
                                                            >
                                                                <Box color="primary.main">
                                                                    <i className="bi bi-info-circle" />
                                                                </Box>
                                                            </FWTooltip>
                                                        )}
                                                        {/* &nbsp;
                                                        <IncotermsGraph /> */}
                                                    </div>
                                                    <FWTypography
                                                        style={
                                                            {
                                                                // padding: '7px 0',
                                                            }
                                                        }
                                                    >
                                                        {`${FWNumberFormatter(
                                                            default_event_quantity_tolerance_percentage
                                                                ? +default_event_quantity_tolerance_percentage
                                                                : 0
                                                        )}%`}
                                                    </FWTypography>
                                                </Box>
                                            </Grid>
                                        )
                                    );
                                default:
                                    return <></>;
                            }
                        else return null;
                    else {
                        return <></>;
                    }
                }
            )}
        </>
    );
};

export default PaymentTermsIncotermGRTolerenceForEventViewMode;
