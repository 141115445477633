import { PeriodEnum } from './PeriodInterface';

export type IPaymentTerms =
    | IPaymentTermsPoItemPercentages
    | IPaymentTermsInvoiceItemValues;

export interface IPaymentTermsInvoiceItemValues {
    paymentType: 'invoiceItemValues';
    prepaymentPercentage: string; //must be >=0, <=100. If blank, send null to BE.
    terms: string; // Integer
    period: PeriodEnum | ''; // Days/weeks/months
    appliedFrom: PaymentTermsInvoiceItemAppliedFromType | '';
}

export type PaymentTermsInvoiceItemAppliedFromType =
    | {
          referenceDateCategory: 'STANDARD';
          referenceDateLabel: StandardPaymentReferenceDateEnum | '';
      }
    | {
          referenceDateCategory: 'CUSTOM';
          referenceDateLabel: string;
      };

export enum StandardPaymentReferenceDateEnum {
    INVOICE_DATE = 'INVOICE_DATE',
    DISPATCH_DATE = 'DISPATCH_DATE',
    RECEIPT_DATE = 'RECEIPT_DATE',
    // NA="NA"
}

export interface IPaymentTermsPoItemPercentages {
    paymentType: 'poItemPercentages';
    prepaymentPercentage: string; //must be >=0, <=100. If blank, send null to BE.
    appliedFrom?: PaymentTermsInvoiceItemAppliedFromType | '';
    list: IPaymentTermsAllocation[];
    terms?: string;
}

export type IPaymentTermsAllocation =
    | PaymentTermsAllocationFixedDate
    | PaymentTermsAllocationDeliverable;

export type IPaymentTermsAllocationBase = {
    poItemPercentage: string; //must be >=0, <=100.
};

export type PaymentTermsAllocationFixedDate = IPaymentTermsAllocationBase & {
    milestoneType: 'FIXED_DATE';
    paymentDate: string; // Date
};

export type PaymentTermsAllocationDeliverable = IPaymentTermsAllocationBase & {
    milestoneType: 'DELIVERABLE';
    terms: string; // Integer
    period: PeriodEnum | ''; // Days/weeks/months
    deliverableName: string;
};

export type BackendPaymentType = 'PER_INVOICE_ITEM' | 'PER_DELIVERABLE';

export type BackendInvoiceItemPaymentTerms = {
    term: number;
    period: PeriodEnum;
    applied_from: string;
};
export type BackendPoItemsPaymentTerms = {
    allocation_percentage: number;
    term: number;
    period: PeriodEnum;
    applied_from_milestone: string | null;
    applied_from_fixed_date: string | null;
};

export type PaymentTermsValidation = {
    overallErrors: {
        prepaymentBlank: boolean;
        prepaymentAbove100: boolean;
        allocationNot100: boolean;
        termsBlank: boolean;
        periodBlank: boolean;
        appliedFromBlank: boolean;
        appliedFromInvalid: boolean;
        appliedFromTooLong: boolean;
    };
    rowErrors: {
        allocationBlank: boolean;
        allocationOver100: boolean;
        termsBlank: boolean;
        periodBlank: boolean;
        deliverableBlank: boolean;
        deliverableTooLong: boolean;
        deliverableInvalid: boolean;
        dateBlank: boolean;
        datePast: boolean;
    }[];
    errorExists: boolean;
};
