import { isEmpty } from 'lodash';
import moment from 'moment';

export const monthMap: {
    [monthIndex: number]: { name: string; abbreviation: string };
} = {
    1: { name: 'January', abbreviation: 'Jan' },
    2: { name: 'February', abbreviation: 'Feb' },
    3: { name: 'March', abbreviation: 'Mar' },
    4: { name: 'April', abbreviation: 'Apr' },
    5: { name: 'May', abbreviation: 'May' },
    6: { name: 'June', abbreviation: 'Jun' },
    7: { name: 'July', abbreviation: 'Jul' },
    8: { name: 'August', abbreviation: 'Aug' },
    9: { name: 'September', abbreviation: 'Sep' },
    10: { name: 'October', abbreviation: 'Oct' },
    11: { name: 'November', abbreviation: 'Nov' },
    12: { name: 'December', abbreviation: 'Dec' },
};

export const getFWDate = (date: string | null, format = "DD MMM 'YY") => {
    if (date && !isEmpty(date)) {
        if (date === 'TODAY') {
            return moment().format(format);
        } else {
            const formattedDate = new Date(date);
            if (isNaN(formattedDate.getTime())) {
                return '';
            }
            return moment(formattedDate).startOf('day').format(format);
        }
    } else {
        return 'TBD';
    }
};

export const getFWDateTimeForProject = (
    dateTime: string | null,
    format: string = "DD MMM 'YY hh:mm a",
    requiredFor?: string,
    dontConvertToLocal?: boolean
) => {
    if (dateTime) {
        const formattedDate = new Date(dateTime);

        if (isNaN(formattedDate.getTime())) {
            return ''; // Handle invalid dates
        }

        if (dontConvertToLocal) {
            // Don't convert to local timezone; keep the original ISO time
            return moment(formattedDate).format(format);
        }

        // Convert to local timezone
        const userTimeZoneDate = moment.utc(dateTime).local();
        if (requiredFor === 'shareEventHistoryPermission') {
            return userTimeZoneDate.format(format);
        }

        return userTimeZoneDate.format(format);
    } else {
        return '-';
    }
};

export const getFWDateTime = (
    dateTime: string | null,
    format: string = "DD MMM 'YY hh:mm a",
    requiredFor?: string,
    dontConvertToLocal?: boolean
) => {
    if (dateTime) {
        const formattedDate = new Date(dateTime);

        if (dontConvertToLocal) {
            // formattedDate.setMilliseconds(
            //     formattedDate.getMilliseconds() + getLocalTimeSone()
            // );
            return moment(formattedDate).utc().format(format);
        }

        const userTimeZone = moment.utc(dateTime).local();

        if (isNaN(formattedDate.getTime())) {
            return '';
        }
        if (requiredFor === 'shareEventHistoryPermission') {
            return userTimeZone.format(format);
        }
        return moment(formattedDate).format(format);
    } else {
        return '-';
    }
};

export const convertUTCtoLocal = (
    dateTime: string,
    stringMethodToCall: 'string' | 'ISO'
) => {
    if (dateTime) {
        const d = new Date(dateTime);
        d.setTime(d.getTime() + -1 * new Date().getTimezoneOffset() * 60000);
        if (stringMethodToCall === 'ISO') {
            return d.toISOString();
        } else {
            return d.toString();
        }
    } else {
        return '';
    }
};

export const convertLocalToUTC = (
    dateTime: string,
    stringMethodToCall: 'string' | 'ISO'
) => {
    if (dateTime) {
        const d = new Date(dateTime);

        d.setTime(d.getTime() + new Date().getTimezoneOffset() * 60000);
        if (stringMethodToCall === 'ISO') {
            return d.toISOString();
        } else {
            return d.toString();
        }
    } else {
        return '';
    }
};

export const getFWDateDifference = (date: string) => {
    var date1 = new Date(date);
    var date2 = new Date(Date.now());

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Math.round(Difference_In_Days);
};

export const getFWTime = (dateTime: string | null) => {
    if (dateTime) {
        const formattedDate = new Date(dateTime);
        if (isNaN(formattedDate.getTime())) {
            return '';
        }
        return moment(formattedDate).format('hh:mm a');
    } else {
        return '-';
    }
};

export const todaysDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // add leading zero if necessary
    const day = String(today.getDate()).padStart(2, '0'); // add leading zero if necessary
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate; // prints the date in 'YYYY-MM-DD' format
};

export const convertDateTimeFormatForPayload = (dateTime: string) => {
    const parsedDateTime = moment(dateTime);

    const outputDate = parsedDateTime.format('YYYY-MM-DD');

    return outputDate;
};
