import { IForumlaWithIfElseAndOperators } from '../../AdditionalCost/Interfaces/AdditionalCostInterfaces';
import { IAdditionalCost } from '../../AdditionalCost/models/AdditionalCost.model';
import { templateType } from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import { IFieldTypeConstraints } from '../../FieldTypes/FieldTypes.model';
import { CustomFieldType } from '../../Global/Interfaces/TemplateInterface';

export enum TemplateStandardFieldsEnum {
    QUANTITY = 'QUANTITY',
    BASE_RATE = 'BASE_RATE',
    ENTITY = 'ENTITY',
    QUOTE_NAME = 'QUOTE_NAME',
    TEMPLATE = 'TEMPLATE',
    QUOTE_VALIDITY = 'QUOTE_VALIDITY',
    QUOTE_DEADLINE = 'QUOTE_DEADLINE',
    QUOTE_CURRENCY = 'QUOTE_CURRENCY',
    CUSTOMER_NAME = 'CUSTOMER_NAME',
    VENDOR_RATE = 'VENDOR_RATE',
    ADDITIONAL_COSTS = 'ADDITIONAL_COSTS',
    OVERALL_ADDITIONAL_COSTS = 'OVERALL_ADDITIONAL_COSTS',
    BOM_ADDITIONAL_COSTS = 'BOM_ADDITIONAL_COSTS',
    DELIVERY_DATE = 'DELIVERY_DATE',
    PROJECT = 'PROJECT',
    COST_CENTER = 'COST_CENTER',
    GL = 'GL',
    BOM = 'BOM',
    TAX = 'TAX',
    NOTES = 'NOTES',
    LEAD_TIME = 'LEAD_TIME',
    VENDOR_NAME = 'VENDOR_NAME',
}

export enum CostingStandardSections {
    QUOTE_DETAILS = 'QUOTE_DETAILS',
    ESSENTIAL_TERMS = 'ESSENTIAL_TERMS',
    OVERALL_ADDITIONAL_COSTS = 'OVERALL_ADDITIONAL_COSTS',
    BOM = 'BOM',
}

export const CostingTemplateStandardFieldBackendNames: {
    [BackendTemplateItemName: string]: TemplateStandardFieldsEnum;
} = {
    Quantity: TemplateStandardFieldsEnum.QUANTITY,
    Rate: TemplateStandardFieldsEnum.BASE_RATE,
    Taxes: TemplateStandardFieldsEnum.TAX,
    'Additional costs': TemplateStandardFieldsEnum.ADDITIONAL_COSTS,
    'Overall additional costs':
        TemplateStandardFieldsEnum.OVERALL_ADDITIONAL_COSTS,
    'BOM additional costs': TemplateStandardFieldsEnum.BOM_ADDITIONAL_COSTS,
    Notes: TemplateStandardFieldsEnum.NOTES,
    Entity: TemplateStandardFieldsEnum.ENTITY,
    'Quote name': TemplateStandardFieldsEnum.QUOTE_NAME,
    Template: TemplateStandardFieldsEnum.TEMPLATE,
    'Quote validity': TemplateStandardFieldsEnum.QUOTE_VALIDITY,
    'Quote deadline': TemplateStandardFieldsEnum.QUOTE_DEADLINE,
    'Quote currency': TemplateStandardFieldsEnum.QUOTE_CURRENCY,
    Customer: TemplateStandardFieldsEnum.CUSTOMER_NAME,
    'Vendor rate': TemplateStandardFieldsEnum.VENDOR_RATE,
    'Vendor name': TemplateStandardFieldsEnum.VENDOR_NAME,
    'Lead time': TemplateStandardFieldsEnum.LEAD_TIME,
    'Delivery date': TemplateStandardFieldsEnum.DELIVERY_DATE,
    Project: TemplateStandardFieldsEnum.PROJECT,
    GL: TemplateStandardFieldsEnum.GL,
    'Cost center': TemplateStandardFieldsEnum.COST_CENTER,
    BOM: TemplateStandardFieldsEnum.BOM,
};
// export enum CustomFieldType {
//     SHORT_TEXT = 'SHORT_TEXT',
//     LONG_TEXT = 'LONG_TEXT',
//     SHORTTEXT = 'SHORTTEXT',
//     LONGTEXT = 'LONGTEXT',
//     DECIMAL = 'DECIMAL',
//     LOCATION = 'LOCATION',
//     INTEGER = 'INTEGER',
//     CHOICE = 'CHOICE',
//     BOOLEAN = 'BOOLEAN',
//     DATE = 'DATE',
//     DATETIME = 'DATETIME',
//     ATTACHMENT = 'ATTACHMENT',
//     TEMPLATE = 'TEMPLATE',
//     FLOAT = 'FLOAT',
//     MULTI_CHOICE = 'MULTI_CHOICE',
//     // MULTI_SELECT = 'MULTI_SELECT',
// }

export interface IMultipleQuoteTemplate {
    template_id: string;
    type: string;
    name: string;
    tags: string[];
    status: string;
    published_on: any;
    description: string;
    is_global: boolean;
    is_default: boolean;
    created_on: string;
    creator_name: string;
    last_edited_on: string;
    entity_id: string;
    entity_name: string;
    auto_reminders: any;
    last_edited_by_name: string;
    deleted_datetime: string | null;
    assigned_users: { user_id: string; permission: string }[];
    section_list: {
        section_id: string;
        name: string;
        alternate_name: string;
        assigned_users: { user_id: string; permission: string }[];
        section_type: 'ITEM' | 'BOM' | 'OTHER';
        section_items: {
            name: string;
            alternate_name: string;
            is_builtin_field: boolean;
            type: CustomFieldType;
            constraints: {
                max_limit?: number;
                min_limit?: number;
                field_type: string;
                decimal_places?: number;
                choices?: string[];
                choice_type?: 'DROPDOWN' | 'MULTI_SELECT' | undefined;
            };
        }[];
    }[];
}

export interface IMultipleQuoteTemplateApiResponse {
    type: templateType;
    count: number;
    templates: IMultipleQuoteTemplate[];
}

export interface ICostingTemplate {
    templateId: string;
    templateName: string;
    assigned_users: {
        permission: 'CREATE' | 'ASSIGN';
        user: { user_id: string; name: string };
    }[];
    fieldSettings: ICostingTemplateFieldSetting;
    sections: ICostingTemplateSections;
}

export type ICostingTemplateSections = {
    [section in CostingStandardSections]: ICostingTemplateSectionDetails;
};

export interface ICostingTemplateSectionDetails {
    label: string;
    section_type: 'OTHER' | 'BOM' | 'ITEM';
    assigned_users: {
        permission: 'VIEW' | 'EDIT' | 'ASSIGN';
        user: { user_id: string; name: string };
    }[];
    fieldList: ISectionField[];
}

export type ISectionField =
    | {
          fieldType: 'STANDARD';
          field: TemplateStandardFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export interface ICostingTemplateFieldSetting {
    standardFields: CostingTemplateStandardFieldSettingsObj;
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export interface ITemplateCustomFieldOptions
    extends ITemplateStandardFieldOptionsBase {
    type: CustomFieldType;
    constraints: IFieldTypeConstraints;
}

export type CostingTemplateStandardFieldSettingsObj = {
    [TemplateStandardFieldsEnum.ENTITY]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.QUOTE_NAME]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.TEMPLATE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.QUOTE_VALIDITY]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.QUOTE_DEADLINE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.QUOTE_CURRENCY]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.CUSTOMER_NAME]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.VENDOR_RATE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.VENDOR_NAME]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.BASE_RATE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.QUANTITY]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.LEAD_TIME]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.ADDITIONAL_COSTS]: ITemplateAdditionalCostOptions;
    [TemplateStandardFieldsEnum.OVERALL_ADDITIONAL_COSTS]: ITemplateAdditionalCostOptions;
    [TemplateStandardFieldsEnum.BOM_ADDITIONAL_COSTS]: ITemplateAdditionalCostOptions;
    [TemplateStandardFieldsEnum.TAX]: ITemplateTaxesOptions;
    [TemplateStandardFieldsEnum.DELIVERY_DATE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.PROJECT]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.COST_CENTER]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.GL]: ITemplateStandardFieldOptions;

    [TemplateStandardFieldsEnum.NOTES]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.VENDOR_NAME]: ITemplateStandardFieldOptions;
};

export type ITemplateStandardFieldOptionsBase = {
    label: string;
    description: string;
    buyerSettings: {
        showField: boolean;
        required: boolean;
    };
    formula?: IForumlaWithIfElseAndOperators;
    sellerSettings: {
        showField: boolean;
        required: boolean;
        negotiable: boolean;
    };
};

export type ITemplateStandardFieldOptions =
    ITemplateStandardFieldOptionsBase & {
        standardFieldCategory: 'STANDARD';
    };

export type ITemplateAdditionalCostOptions =
    ITemplateStandardFieldOptionsBase & {
        standardFieldCategory: 'ADDITIONAL_COSTS';
        additionalCostOptions: ({
            sequence: number;
            is_required: boolean;
        } & IAdditionalCost)[];
    };

export type ITemplateTaxesOptions = ITemplateStandardFieldOptionsBase & {
    standardFieldCategory: 'TAX';
    taxesOptions: ({
        is_required: boolean;
    } & IAdditionalCost)[];
};

export const blankCostingTemplate: ICostingTemplate = {
    fieldSettings: {
        customFields: {},
        standardFields: {
            VENDOR_RATE: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Vendor rate',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            VENDOR_NAME: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Vendor name',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            CUSTOMER_NAME: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Customer name',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            PROJECT: {
                standardFieldCategory: 'STANDARD',
                label: 'Project',
                description: '',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },

                buyerSettings: {
                    required: false,
                    showField: false,
                },
            },
            GL: {
                standardFieldCategory: 'STANDARD',
                label: 'GL',
                description: '',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },

                buyerSettings: {
                    required: false,
                    showField: false,
                },
            },
            COST_CENTER: {
                standardFieldCategory: 'STANDARD',
                label: 'Cost center',
                description: '',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },

                buyerSettings: {
                    required: false,
                    showField: false,
                },
            },
            DELIVERY_DATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Delivery date',
                description: '',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },

                buyerSettings: {
                    required: false,
                    showField: false,
                },
            },
            ENTITY: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Entity',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            TEMPLATE: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Template',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            QUOTE_NAME: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Quote name',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            QUOTE_VALIDITY: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Quote validity',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            QUOTE_DEADLINE: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Quote deadline',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            QUOTE_CURRENCY: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Quote currency',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            BASE_RATE: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            LEAD_TIME: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    required: false,
                },
                description: '',
                label: 'Lead time',
                standardFieldCategory: 'STANDARD',
            },
            NOTES: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            QUANTITY: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            ADDITIONAL_COSTS: {
                additionalCostOptions: [],
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: '',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'ADDITIONAL_COSTS',
            },

            // VENDOR_NAME: {
            //     buyerSettings: {
            //         required: false,
            //         showField: true,
            //     },
            //     description: '',
            //     label: 'Vendor name',
            //     sellerSettings: {
            //         negotiable: false,
            //         required: false,
            //         showField:true,
            //     },
            //     standardFieldCategory: 'STANDARD',
            // },

            TAX: {
                taxesOptions: [],
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: '',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'TAX',
            },
            OVERALL_ADDITIONAL_COSTS: {
                additionalCostOptions: [],
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: '',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'ADDITIONAL_COSTS',
            },
            BOM_ADDITIONAL_COSTS: {
                additionalCostOptions: [],
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: '',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'ADDITIONAL_COSTS',
            },
        },
    },
    sections: {
        QUOTE_DETAILS: {
            fieldList: [],
            assigned_users: [],
            section_type: 'OTHER',
            label: 'Quote Details',
        },
        ESSENTIAL_TERMS: {
            fieldList: [],
            assigned_users: [],
            section_type: 'ITEM',
            label: 'Item Terms',
        },
        BOM: {
            fieldList: [],
            assigned_users: [],
            section_type: 'BOM',
            label: 'BOM',
        },
        OVERALL_ADDITIONAL_COSTS: {
            fieldList: [],
            assigned_users: [],
            section_type: 'OTHER',
            label: 'Overall Additional costs',
        },
    },
    assigned_users: [],
    templateId: '',
    templateName: '',
};
