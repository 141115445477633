import { cloneDeep } from 'lodash';
import { transformPaymentTermsFromFrontendToBackend } from '../../Global/Helpers/paymentTermsHelpers';
import { modifyCustomSectionForUpdate } from '../../Global/Helpers/templateHelpers';
import { ICustomSection } from '../../Global/Interfaces/TemplateInterface';
import { RequisitionItemBackend } from '../../ItemAnalytics/Interfaces/OrderInterfaces';
import { RequisitionItem } from '../Slices/RequisitionSlice';
// import { NewRequisitionItemForPayload } from './RequisitionEditPageHelpers';

export const convertBackendRequisitionDetailsToFrontend = () => {
    // let response: IRequisition = {
    //     requisitionId: req.requisition_id,
    //     requisitionItems: req.requisition_items.map((item) =>
    //         convertBackendRequisitionItemToFrontEnd(item)
    //     ),
    // };
};

export const convertBackendRequisitionItemToFrontEnd = (
    item: RequisitionItemBackend
) => {
    return {
        additionalCosts: item.additional_costs.map((ac) => ac),
        //NEW WAY
        attributes: item.attributes,
        createdByUser: item.created_by_user,
        createdDatetime: item.created_datetime,
        customSections: replaceNullValuesWithEmptyString(item.custom_sections),
        customFields: {
            sectionList: item.custom_fields.section_list
                ? item.custom_fields.section_list.map((data) => data)
                : item.custom_fields.section_items?.map((data) => data),
        },
        deletedByUser: item.deleted_by_user,
        deletedDatetime: item.deleted_datetime,
        deliverables_payment_terms: item.deliverables_payment_terms.map(
            (data: any) => data
        ),
        deliveryInformation: item.delivery_information
            ? item.delivery_information.map((ds) => ds)
            : null,
        deliverySchedule: item.delivery_schedule?.map((ds) => ds),
        discounts: item.discounts.map((discount) => discount),
        enterpriseItem: item.enterprise_item,
        incoterm: item.incoterm,
        isLinkedToEntity: item.is_linked_to_entity,
        itemInformation: {
            itemAdditionalDetails:
                item.item_information.item_additional_details,
            itemCode: item.item_information.item_code,
            itemDescription: item.item_information.item_description,
            itemId: item.item_information.item_id,
            itemName: item.item_information.item_name,
            customItemName: item.item_information.custom_item_name,
            customIds: item.item_information.custom_ids,
        },
        measurementUnit: item.measurement_unit,
        measurementUnitDetails: {
            measurementUnitAbbreviation:
                item.measurement_unit_details.measurement_unit_abbreviation,
            measurementUnitName:
                item.measurement_unit_details.measurement_unit_name,
        },
        modifiedByUser: item.modified_by_user,
        modifiedDatetime: item.modified_datetime,
        payment_terms: {
            applied_from: item.payment_terms.applied_from,
            period: item.payment_terms.period,
            term: item.payment_terms.terms,
        },
        sellerEntities: item.seller_entities
            ? item.seller_entities.map((e) => e)
            : null,
        sellerContactsInformation: item.seller_contacts_information
            ? item.seller_contacts_information.map((ci: any) => {
                  return {
                      buyer_entity: ci.buyer_entity,
                      emails: ci.emails,
                      enterprise_vendor_master: {
                          vendor_code: ci.enterprise_vendor_master.vendor_code,
                      },
                      full_name: ci.full_name,
                      is_primary: ci.is_primary,
                      phone_numbers: ci.phone_numbers,
                      primary_email: ci.primary_email,
                      seller_entity: ci.seller_entity,
                      status: ci.status,
                      tags: ci.tags,
                      user: ci.user,
                      vendor_contact_id: ci.vendor_contact_id,
                  };
              })
            : null,
        sellerEntitiesInformation: item.seller_entities_information
            ? item.seller_entities_information.map((ei: any) => {
                  return {
                      enterprise_name: ei.enterprise_name,
                      entity_id: ei.entity_id,
                      entity_name: ei.entity_name,
                      is_virtual: ei.is_virtual,
                      verification_status: ei.verification_status,
                      entity_logo: ei.entity_logo
                          ? ei.entity_logo.map((logo: any) => {
                                return {
                                    attachment_id: logo.attachment_id,
                                    attachment_status: logo.attachment_status,
                                    attachment_type: logo.attachment_type,
                                    file_name: logo.file_name,
                                    resource_id: logo.resource_id,
                                    storage_id: logo.storage_id,
                                    tags: logo.tags
                                        ? logo.tags.map((t: any) => t)
                                        : null,
                                    type: logo.type,
                                };
                            })
                          : null,
                      contacts: {
                          emails:
                              ei.contacts && ei.contacts.emails
                                  ? ei.contacts.emails.map((data: any) => data)
                                  : null,
                          phone_numbers:
                              ei.contacts && ei.contacts.phone_numbers
                                  ? ei.contacts.phone_numbers.map(
                                        (data: any) => data
                                    )
                                  : null,
                          websites:
                              ei.contacts && ei.contacts.websites
                                  ? ei.contacts.websites.map(
                                        (data: any) => data
                                    )
                                  : null,
                      },
                  };
              })
            : null,
        sellerContacts: item.seller_contacts.map((e) => e),
        shippingAddress: {
            shippingAddressId: item.shipping_address.shipping_address_id,
            shippingFullAddress: item.shipping_address.shipping_full_address,
        },
        paymentType: item.payment_type,
        prepaymentPercentage: item.prepayment_percentage,
        pricingInformation: {
            additionalCharges: item.pricing_information.additional_charges,
            currencyCodeAbbreviation:
                item.pricing_information.currency_code_abbreviation,
            currencyCodeId: item.pricing_information.currency_code_id,
            currencyName: item.pricing_information.currency_name,
            currencySymbol: item.pricing_information.currency_symbol,
            desiredPrice: item.pricing_information.desired_price,
            shippingPerUnit: item.pricing_information.shipping_per_unit,
        },
        procurementInformation: {
            leadTime: item.procurement_information.lead_time,
            leadTimePeriod: item.procurement_information.lead_time_period,
        },
        quantity: item.quantity,
        quantityTolerancePercentage: item.quantity_tolerance_percentage,
        questions: {
            sectionItems: item.questions.section_list
                ? item.questions.section_list.map((data) => data)
                : item.questions.section_items?.map((data) => data),
        },
        tags: item.tags ?? [],
        requisition: item.requisition,
        requisitionItemId: item.requisition_item_id,
        taxes: item.taxes.map((tax) => tax),
        errors: {
            customFields: {},
            deliveryScheduleRows: [],
            paymentTerms_poItem_allocationRows: [],
        },
    } as any;
};

export const convertFrontendRequisitionItemToBackedn = (
    item: RequisitionItem
) => {
    return {
        total: (+item.total).toFixed(10) === 'NaN' ? 0 : +item.total,
        enterprise_item_id: item.enterpriseItem,
        custom_item_name: item.itemInformation.itemName,
        measurement_unit_id: item.measurementUnit,
        quantity: String(item.quantity),
        incoterm_id: item.incoterm
            ? item.incoterm
            : 'bbb27fda-712f-4aa2-b42b-386fde9c2b50',

        desired_price: +item.pricingInformation.desiredPrice,
        lead_time:
            item.procurementInformation.leadTimePeriod === 'NA' ||
            item.procurementInformation.leadTimePeriod === null
                ? null
                : item.procurementInformation.leadTime
                ? +item.procurementInformation.leadTime
                : 0,
        lead_time_period:
            item.procurementInformation.leadTimePeriod === 'NA' ||
            item.procurementInformation.leadTimePeriod === null
                ? null
                : item.procurementInformation.leadTimePeriod,

        item_additional_details: item.itemInformation.itemAdditionalDetails
            ? item.itemInformation.itemAdditionalDetails
            : '',
        quantity_tolerance_percentage: +item.quantityTolerancePercentage,
        delivery_schedule_items: item.deliverySchedule.map((delivery) => ({
            cost_centre_id: Boolean(delivery.cost_centre_id)
                ? delivery.cost_centre_id === 'NA'
                    ? null
                    : delivery.cost_centre_id
                : null,
            delivery_date: Boolean(delivery.delivery_date)
                ? delivery.delivery_date
                : null,
            delivery_schedule_item_id: Boolean(
                delivery.delivery_schedule_item_id
            )
                ? delivery.delivery_schedule_item_id
                : null,
            general_ledger_id: Boolean(delivery.general_ledger_id)
                ? delivery.general_ledger_id === 'NA'
                    ? null
                    : delivery.general_ledger_id
                : null,
            parent_delivery_schedule_item_id: Boolean(
                delivery.parent_delivery_schedule_item_id
            )
                ? delivery.parent_delivery_schedule_item_id
                : null,
            project_id: Boolean(delivery.project_id)
                ? delivery.project_id
                : null,
            quantity:
                delivery.quantity !== null && delivery.quantity !== undefined
                    ? String(delivery.quantity)
                    : null,
            requisition_item_id: Boolean(delivery.requisition_item_id)
                ? delivery.requisition_item_id
                : null,
        })),
        deliverables_payment_terms:
            transformPaymentTermsFromFrontendToBackend({
                paymentTerms: item.paymentTerms,
            }).deliverables_payment_terms ?? [],
        shipping_address_id: item.shippingAddress.shippingAddressId,
        payment_terms: transformPaymentTermsFromFrontendToBackend({
            paymentTerms: item.paymentTerms,
        })?.payment_terms,
        prepayment_percentage: transformPaymentTermsFromFrontendToBackend({
            paymentTerms: item.paymentTerms,
        }).prepayment_percentage,
        payment_type: transformPaymentTermsFromFrontendToBackend({
            paymentTerms: item.paymentTerms,
        }).payment_type,

        seller_contact_ids: item.sellerContacts,
        seller_entity_ids: item.sellerEntities,
        currency_code_id: item.pricingInformation?.currencyCodeId,
        additional_costs: item.additionalCosts,
        discounts: item.discounts,
        taxes: item.taxes,
        questions: {
            section_items: item.questions.sectionItems,
        },
        custom_sections: modifyCustomSectionForUpdate(
            item.customSections
        ).filter((section) => section.section_type === 'ITEM'),
        attributes: item.attributes
            .filter((attr) => attr.attribute_id === null)
            .map((attr) => {
                return {
                    attribute_linkage_id: attr.attribute_linkage_id,
                    attribute_id: attr.attribute_id,
                    attribute_name: attr.attribute_name,
                    attribute_type: attr.attribute_type,
                    attribute_values: attr.attribute_values,
                };
            }),
    };
};

export const replaceNullValuesWithEmptyString = (
    customSections: ICustomSection[]
): ICustomSection[] => {
    const newCustomSections = cloneDeep(customSections);

    for (let section of newCustomSections) {
        section.custom_fields = section.custom_fields.map((field) => {
            return {
                ...field,
                value: field.value === null ? '' : field.value,
            };
        });
    }
    return newCustomSections;
};
