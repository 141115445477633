import { Box, IconButton } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWInput } from '../../../Common/FWInput';
import { FWTypography } from '../../../Common/FWTypograhy';
import SingleFieldTypeRenderer from '../../../FieldTypes/components/SingleFieldTypeRenderer';
// import { CustomTemplateItemTypes } from '../../../Global/Interfaces/CustomTemplate.interface';
import { IGlobalAppStore } from '../../../Redux/Store';
import { IItemCartTemplate } from '../../Interfaces/ItemCartTemplateInterfaces';
import { updateCartItemDetailsActionType } from '../../Interfaces/itemCartItemInterfaces';
import { CartItemAccordionContext } from './CartItemAccordion';
import { CustomFieldType } from '../../../Global/Interfaces/TemplateInterface';

export interface ICartItemCustomFieldsProps {
    updateItemData: (
        params: {
            field: updateCartItemDetailsActionType;
            value: any;
        }[]
    ) => void;
    openEditCustomFieldPopup: (idx: number) => void;
    fieldName: string;
    sectionName: string;
    isCustomFieldFromTemplate: boolean;
    templateDetails: IItemCartTemplate;
}

export default function CartItemCustomFields({
    updateItemData,
    fieldName,
    isCustomFieldFromTemplate,
    openEditCustomFieldPopup,
    sectionName,
    templateDetails,
}: ICartItemCustomFieldsProps) {
    const { cartItemId, itemInCart, errors } = useContext(
        CartItemAccordionContext
    );

    const itemInformation = useSelector((store: IGlobalAppStore) =>
        itemInCart
            ? store.ItemCartStore.cartItems[cartItemId]
            : store.ItemCartStore.itemsSavedForLater[cartItemId]
    );

    // const storeDetailsField = useSelector((store: IGlobalAppStore) =>
    //     itemInCart
    //         ? store.ItemCartStore.cartItems[
    //               cartItemId
    //           ].custom_fields.section_list
    //               .find((section) => section.name === sectionName)
    //               ?.fields?.find((field) => field.name === fieldName)
    //         : store.ItemCartStore.itemsSavedForLater[
    //               cartItemId
    //           ].custom_fields.section_list
    //               .find((section) => section.name === sectionName)
    //               ?.fields?.find((field) => field.name === fieldName)
    // );

    const storeDetailsField = useMemo(() => {
        return (
            itemInformation.custom_sections
                .find((section) => section.name === sectionName)
                ?.custom_fields?.find((field) => field.name === fieldName) ??
            null
        );
    }, [fieldName, itemInformation.custom_sections, sectionName]);

    const updateCustomFields = useCallback(
        (updatedValue: any) => {
            try {
                const customFieldSectionIndex =
                    itemInformation.custom_sections.findIndex(
                        (section) => section.name === sectionName
                    );

                if (customFieldSectionIndex !== -1) {
                    const customFieldIndex = itemInformation.custom_sections[
                        customFieldSectionIndex
                    ].custom_fields.findIndex(
                        (field) => field.name === fieldName
                    );

                    if (customFieldIndex !== -1) {
                        let updatedItemInfo = cloneDeep(
                            itemInformation.custom_sections
                        );
                        updatedItemInfo[customFieldSectionIndex].custom_fields[
                            customFieldIndex
                        ].value = updatedValue;

                        updateItemData([
                            {
                                field: 'custom_sections',
                                value: updatedItemInfo,
                            },
                        ]);
                    }
                }
            } catch (err) {}
        },
        [
            fieldName,
            itemInformation.custom_sections,
            sectionName,
            updateItemData,
        ]
    );

    const idx = useSelector((store: IGlobalAppStore) =>
        itemInCart
            ? store.ItemCartStore.cartItems[cartItemId].custom_sections
                  .find((section) => section.name === sectionName)
                  ?.custom_fields?.findIndex(
                      (field) => field.name === fieldName
                  ) ?? -1
            : store.ItemCartStore.itemsSavedForLater[cartItemId].custom_sections
                  .find((section) => section.name === sectionName)
                  ?.custom_fields?.findIndex(
                      (field) => field.name === fieldName
                  ) ?? -1
    );

    const getSingleFieldTypeRendererComponent = () => {
        if (
            isCustomFieldFromTemplate &&
            storeDetailsField &&
            templateDetails.fieldSettings.customFields[storeDetailsField.name]
        ) {
            let currrentFieldTypeErrors =
                errors.custom_sections &&
                errors.custom_sections[sectionName] &&
                errors.custom_sections[sectionName][storeDetailsField.name];

            let currentFieldType =
                templateDetails.fieldSettings.customFields[
                    storeDetailsField.name
                ].constraints.fieldType;
            switch (currentFieldType) {
                case 'LONGTEXT':
                case 'SHORTTEXT':
                    let constrainFromTemplateOfText =
                        templateDetails.fieldSettings.customFields[
                            storeDetailsField.name
                        ].constraints;

                    if (
                        constrainFromTemplateOfText.fieldType === 'SHORTTEXT' ||
                        constrainFromTemplateOfText.fieldType === 'LONGTEXT'
                    )
                        return (
                            <SingleFieldTypeRenderer
                                fieldType={currentFieldType}
                                constraints={{
                                    fieldType:
                                        constrainFromTemplateOfText.fieldType,
                                    maxLimit:
                                        constrainFromTemplateOfText.maxLimit,
                                    minLimit:
                                        constrainFromTemplateOfText.minLimit,
                                }}
                                value={storeDetailsField.value as string}
                                updateHandler={(newValue: string) => {
                                    updateCustomFields(newValue);
                                }}
                                errors={
                                    currrentFieldTypeErrors &&
                                    currrentFieldTypeErrors.type === 'ERROR'
                                        ? true
                                        : false
                                }
                                helper={{
                                    text:
                                        currrentFieldTypeErrors?.fieldMessageText ??
                                        '',
                                }}
                            />
                        );
                    else return null;

                case 'FLOAT':
                    let constrainFromTemplateOfFloat =
                        templateDetails.fieldSettings.customFields[
                            storeDetailsField.name
                        ].constraints;

                    if (constrainFromTemplateOfFloat.fieldType === 'FLOAT')
                        return (
                            <SingleFieldTypeRenderer
                                fieldType={currentFieldType}
                                constraints={{
                                    fieldType:
                                        constrainFromTemplateOfFloat.fieldType,
                                    maxLimit:
                                        constrainFromTemplateOfFloat.maxLimit,
                                    minLimit:
                                        constrainFromTemplateOfFloat.minLimit,
                                }}
                                value={storeDetailsField.value as string}
                                updateHandler={(newValue: string) => {
                                    updateCustomFields(newValue);
                                }}
                                errors={
                                    currrentFieldTypeErrors &&
                                    currrentFieldTypeErrors.type === 'ERROR'
                                        ? true
                                        : false
                                }
                                helper={{
                                    text:
                                        currrentFieldTypeErrors?.fieldMessageText ??
                                        '',
                                }}
                            />
                        );
                    else return null;

                case 'INTEGER':
                    let constrainFromTemplateOfInteger =
                        templateDetails.fieldSettings.customFields[
                            storeDetailsField.name
                        ].constraints;
                    if (constrainFromTemplateOfInteger.fieldType === 'INTEGER')
                        return (
                            <SingleFieldTypeRenderer
                                fieldType={currentFieldType}
                                constraints={{
                                    fieldType:
                                        constrainFromTemplateOfInteger.fieldType,
                                    maxLimit:
                                        constrainFromTemplateOfInteger.maxLimit,
                                    minLimit:
                                        constrainFromTemplateOfInteger.minLimit,
                                }}
                                value={storeDetailsField.value as string}
                                updateHandler={(newValue: string) => {
                                    updateCustomFields(newValue);
                                }}
                                errors={
                                    currrentFieldTypeErrors &&
                                    currrentFieldTypeErrors.type === 'ERROR'
                                        ? true
                                        : false
                                }
                                helper={{
                                    text:
                                        currrentFieldTypeErrors?.fieldMessageText ??
                                        '',
                                }}
                            />
                        );
                    else return null;

                case 'BOOLEAN':
                    let contrainFromTemplateOfBoolean =
                        templateDetails.fieldSettings.customFields[
                            storeDetailsField.name
                        ].constraints;
                    if (contrainFromTemplateOfBoolean.fieldType === 'BOOLEAN')
                        return (
                            <Box className="flex flex--aic">
                                <SingleFieldTypeRenderer
                                    fieldType={currentFieldType}
                                    constraints={{
                                        fieldType:
                                            contrainFromTemplateOfBoolean.fieldType,
                                    }}
                                    errors={
                                        currrentFieldTypeErrors &&
                                        currrentFieldTypeErrors.type === 'ERROR'
                                            ? true
                                            : false
                                    }
                                    helper={{
                                        text:
                                            currrentFieldTypeErrors?.fieldMessageText ??
                                            '',
                                    }}
                                    value={storeDetailsField.value as boolean}
                                    updateHandler={(
                                        newValue: boolean | null
                                    ) => {
                                        updateCustomFields(newValue);
                                    }}
                                />
                            </Box>
                        );
                    return null;
                case 'DATE':
                    let constrainFromTemplateOfDate =
                        templateDetails.fieldSettings.customFields[
                            storeDetailsField.name
                        ].constraints;
                    if (constrainFromTemplateOfDate.fieldType === 'DATE')
                        return (
                            <SingleFieldTypeRenderer
                                constraints={{
                                    fieldType:
                                        constrainFromTemplateOfDate.fieldType,
                                }}
                                fieldType={currentFieldType}
                                value={storeDetailsField.value as string | null}
                                updateHandler={(newValue: string | null) => {
                                    updateCustomFields(newValue);
                                }}
                                errors={
                                    currrentFieldTypeErrors &&
                                    currrentFieldTypeErrors.type === 'ERROR'
                                        ? true
                                        : false
                                }
                                helper={{
                                    text:
                                        currrentFieldTypeErrors?.fieldMessageText ??
                                        '',
                                }}
                            />
                        );
                    else return null;
                case 'DATETIME':
                    let constrainFromTemplateOfDateTime =
                        templateDetails.fieldSettings.customFields[
                            storeDetailsField.name
                        ].constraints;
                    if (
                        constrainFromTemplateOfDateTime.fieldType === 'DATETIME'
                    )
                        return (
                            <SingleFieldTypeRenderer
                                constraints={{
                                    fieldType:
                                        constrainFromTemplateOfDateTime.fieldType,
                                }}
                                fieldType={currentFieldType}
                                value={storeDetailsField.value as string | null}
                                updateHandler={(newValue: string | null) => {
                                    updateCustomFields(newValue);
                                }}
                                errors={
                                    currrentFieldTypeErrors &&
                                    currrentFieldTypeErrors.type === 'ERROR'
                                        ? true
                                        : false
                                }
                                helper={{
                                    text:
                                        currrentFieldTypeErrors?.fieldMessageText ??
                                        '',
                                }}
                            />
                        );
                    else return null;

                case 'CHOICE':
                    let constrainFromTemplate =
                        templateDetails.fieldSettings.customFields[
                            storeDetailsField.name
                        ].constraints;

                    let isFieldRequired =
                        templateDetails.fieldSettings.customFields[
                            storeDetailsField.name
                        ].buyerSettings.required;

                    if (
                        constrainFromTemplate.fieldType === 'CHOICE' &&
                        constrainFromTemplate.choiceType === 'DROPDOWN'
                    )
                        return (
                            <SingleFieldTypeRenderer
                                isFieldRequired={isFieldRequired}
                                fieldType={currentFieldType}
                                constraints={{
                                    choices: isFieldRequired
                                        ? constrainFromTemplate.choices
                                        : [
                                              ...(constrainFromTemplate.choices ??
                                                  []),
                                              'NA(None)',
                                          ],
                                    choiceType:
                                        constrainFromTemplate.choiceType,
                                    fieldType: constrainFromTemplate.fieldType,
                                }}
                                value={storeDetailsField.value as string | null}
                                updateHandler={(newValue: string | null) => {
                                    updateCustomFields(newValue);
                                }}
                                errors={
                                    currrentFieldTypeErrors &&
                                    currrentFieldTypeErrors.type === 'ERROR'
                                        ? true
                                        : false
                                }
                                helper={{
                                    text:
                                        currrentFieldTypeErrors?.fieldMessageText ??
                                        '',
                                }}
                            />
                        );
                    else if (
                        constrainFromTemplate.fieldType === 'CHOICE' &&
                        constrainFromTemplate.choiceType === 'MULTI_SELECT'
                    )
                        return (
                            <SingleFieldTypeRenderer
                                fieldType={currentFieldType}
                                constraints={{
                                    choices: constrainFromTemplate.choices,
                                    choiceType:
                                        constrainFromTemplate.choiceType,
                                    fieldType: constrainFromTemplate.fieldType,
                                }}
                                value={
                                    (storeDetailsField.value as string[]) ?? []
                                }
                                updateHandler={(newValue: string[]) => {
                                    updateCustomFields(newValue);
                                }}
                                errors={
                                    currrentFieldTypeErrors &&
                                    currrentFieldTypeErrors.type === 'ERROR'
                                        ? true
                                        : false
                                }
                                helper={{
                                    text:
                                        currrentFieldTypeErrors?.fieldMessageText ??
                                        '',
                                }}
                            />
                        );
                    else return null;

                default:
                    return null;
            }
        }
        return null;
    };

    if (storeDetailsField)
        return (
            <>
                {/* {customFieldsForSection.map((field, idx) => {
                const errorKey = `${errorSectionName}_custom_field_${idx}`;
                return ;
            })} */}

                <Box>
                    <Box className="flex flex--aic">
                        <FWTypography color="text.secondary">
                            {`${storeDetailsField.name}${
                                storeDetailsField.is_required
                                    ? ''
                                    : ' (optional)'
                            }`}
                        </FWTypography>
                        &nbsp;
                        {/* Names of admin fields cannot be edited */}
                        {!storeDetailsField.is_locked && (
                            <>
                                &nbsp;
                                <FWTooltip title={'Edit field'} placement="top">
                                    <Box>
                                        <IconButton
                                            color="primary"
                                            size={'small'}
                                            sx={{
                                                fontSize: '15px',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openEditCustomFieldPopup(idx);
                                            }}
                                        >
                                            <i className="bi bi-pencil" />
                                        </IconButton>
                                    </Box>
                                </FWTooltip>
                            </>
                        )}
                        {storeDetailsField.description?.length > 0 && (
                            <>
                                &nbsp;
                                <FWTooltip
                                    title={storeDetailsField.description}
                                    placement="top"
                                >
                                    <Box color="primary.main">
                                        <i className="bi bi-question-circle" />
                                    </Box>
                                </FWTooltip>
                            </>
                        )}
                    </Box>
                    <Box
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {!isCustomFieldFromTemplate ? (
                            <FWInput
                                value={storeDetailsField.value as string}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    updateCustomFields(e.target.value);
                                }}
                                allowOnly={
                                    storeDetailsField.type ===
                                    CustomFieldType.INTEGER
                                        ? 'NUMBER'
                                        : storeDetailsField.type ===
                                          CustomFieldType.FLOAT
                                        ? 'DECIMAL_NUMBER'
                                        : undefined // allows everything. type "TEXT" would not allow numbers.
                                }
                                // Placeholder causes error box not to show up, so removing.
                                // placeholder={
                                //     field.type === CustomTemplateItemTypes.INTEGER ||
                                //     field.type === CustomTemplateItemTypes.FLOAT
                                //         ? 'Enter a number'
                                //         : ''
                                // }
                                iconEnd={
                                    storeDetailsField.type ===
                                        CustomFieldType.INTEGER ||
                                    storeDetailsField.type ===
                                        CustomFieldType.FLOAT ? (
                                        <>
                                            <FWTooltip
                                                title={'Numeric input'}
                                                placement="top"
                                            >
                                                <Box>
                                                    <i className="bi bi-123" />
                                                </Box>
                                            </FWTooltip>
                                            &nbsp;
                                        </>
                                    ) : undefined
                                }
                            />
                        ) : (
                            getSingleFieldTypeRendererComponent()
                        )}
                    </Box>
                </Box>
            </>
        );
    return null;
}
