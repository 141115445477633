import { isEqual } from 'lodash';
import moment from 'moment';
// import { IEventItemCustomFieldsBackend } from '../../../../../Events/Interfaces/Buyer/RFQ.model';
import { stringValueForStringArrNull } from '../../../../../Global/Helpers/helpers';
import {
    IAttachment,
    ICustomBuyerBidObject,
    IGetBuyerRFQBid,
    IGetBuyerRFQBidItemDetails,
} from '../../../../../Models/RFQ.model';
import { ICustomSection } from '../../../../../Global/Interfaces/TemplateInterface';
export const bidsAreIdentical = (
    draftBid: ICustomBuyerBidObject,
    prevBid: IGetBuyerRFQBid
): boolean => {
    if (
        !isNaN(parseFloat(draftBid.bulk_discount_minimum_order_value || '')) &&
        !isNaN(
            parseFloat(
                prevBid.pricing_information.bulk_discount_minimum_order_value
            )
        ) &&
        parseFloat(draftBid.bulk_discount_minimum_order_value || '') !==
            parseFloat(
                prevBid.pricing_information.bulk_discount_minimum_order_value
            )
    ) {
        return false;
    }
    if (
        !isNaN(parseFloat(draftBid.bulk_discount_percentage || '')) &&
        !isNaN(
            parseFloat(prevBid.pricing_information.bulk_discount_percentage)
        ) &&
        draftBid.bulk_discount_percentage !==
            prevBid.pricing_information.bulk_discount_percentage
    ) {
        return false;
    }
    if (
        (draftBid.validity_datetime === null &&
            prevBid.validity_datetime !== null) ||
        (draftBid.validity_datetime !== null &&
            prevBid.validity_datetime === null) ||
        (draftBid.validity_datetime !== null &&
            prevBid.validity_datetime !== null &&
            !moment(draftBid.validity_datetime).isSame(
                moment(prevBid.validity_datetime)
            ))
    ) {
        return false;
    }
    if (
        draftBid.currency_code_abbreviation !==
        prevBid.pricing_information.currency_code_abbreviation
    ) {
        return false;
    }
    if (
        !bidItemDictIsIdentical(
            draftBid.items,
            makeItemDict(prevBid.rfq_bid_items)
        )
    ) {
        return false;
    }
    if (!isEqual(draftBid, prevBid)) {
        return false;
    }
    return true;
};

const bidItemDictIsIdentical = (
    dict1: { [key: string]: IGetBuyerRFQBidItemDetails },
    dict2: { [key: string]: IGetBuyerRFQBidItemDetails }
): boolean => {
    if (Object.keys(dict1).length !== Object.keys(dict2).length) {
        return false;
    }
    for (let rfqItemId in dict1) {
        if (!(rfqItemId in dict2)) {
            return false;
        }
        if (!bidItemsAreIdentical(dict1[rfqItemId], dict2[rfqItemId])) {
            return false;
        }
    }
    return true;
};

const makeItemDict = (itemList: IGetBuyerRFQBidItemDetails[]) => {
    let newDict: { [key: string]: IGetBuyerRFQBidItemDetails } = {};
    for (let item of itemList) {
        newDict[item.rfq_item] = item;
    }
    return newDict;
};

const bidItemsAreIdentical = (
    item1: IGetBuyerRFQBidItemDetails,
    item2: IGetBuyerRFQBidItemDetails
): boolean => {
    if (item1.rfq_item !== item2.rfq_item) {
        return false;
    }
    if (item1.bid_item_response_type !== item2.bid_item_response_type) {
        return false;
    }
    if (
        !pricingInfoIsSame(item1.pricing_information, item2.pricing_information)
    ) {
        return false;
    }
    if (
        !quantityInfoIsSame(
            item1.quantity_information,
            item2.quantity_information
        )
    ) {
        return false;
    }
    if (
        !deliverySchedulesItemsAreSame(
            item1.delivery_information,
            item2.delivery_information
        )
    ) {
        return false;
    }
    if (
        !procurementInfoIsSame(
            item1.procurement_information ?? '',
            item2.procurement_information
        )
    ) {
        return false;
    }

    if (
        !negotiationCustomFieldsAreSame(
            item1.custom_sections,
            item2.custom_sections
        )
    ) {
        return false;
    }

    if (!attachmentsAreSame(item1.attachments, item2.attachments)) {
        return false;
    }

    return true;
};

interface pricingInfo {
    price: string;
    currency_name: string;
    currency_symbol: string;
    currency_code_id: string;
    shipping_per_unit: string;
    additional_charges: {
        charge_name: string;
        charge_value: string;
    }[];
    currency_code_abbreviation: string;
}

const pricingInfoIsSame = (
    pricing1: pricingInfo,
    pricing2: pricingInfo
): boolean => {
    if (pricing1 === null && pricing2 === null) {
        return true;
    }
    if (pricing1 === null || pricing2 === null) {
        return false;
    }
    if (
        pricing1.currency_code_abbreviation !==
        pricing2.currency_code_abbreviation
    ) {
        return false;
    }
    if (
        !isNaN(parseFloat(pricing1.price)) &&
        !isNaN(parseFloat(pricing2.price)) &&
        parseFloat(pricing1.price) !== parseFloat(pricing2.price)
    ) {
        return false;
    }
    if (
        !isNaN(parseFloat(pricing1.shipping_per_unit)) &&
        !isNaN(parseFloat(pricing2.shipping_per_unit)) &&
        parseFloat(pricing1.shipping_per_unit) !==
            parseFloat(pricing2.shipping_per_unit)
    ) {
        return false;
    }
    if (
        !taxesAreSame(
            pricing1?.additional_charges ?? [],
            pricing2?.additional_charges ?? []
        )
    ) {
        return false;
    }
    return true;
};

const taxesAreSame = (
    taxes1: {
        charge_name: string;
        charge_value: string;
    }[],
    taxes2: {
        charge_name: string;
        charge_value: string;
    }[]
) => {
    let taxes1Sorted = taxes1.sort(
        (x, y) =>
            x.charge_name.trim().localeCompare(y.charge_name.trim()) ||
            parseFloat(x.charge_value) - parseFloat(y.charge_value)
    );
    let taxes2Sorted = taxes1.sort(
        (x, y) =>
            x.charge_name.trim().localeCompare(y.charge_name.trim()) ||
            parseFloat(x.charge_value) - parseFloat(y.charge_value)
    );
    return JSON.stringify(taxes1Sorted) === JSON.stringify(taxes2Sorted);
};

interface quantityInfo {
    quantity: string;
    measurement_unit_id: string;
    measurement_unit_name: string;
}

const quantityInfoIsSame = (
    quantityInfo1: quantityInfo,
    quantityInfo2: quantityInfo
): boolean => {
    if (quantityInfo1 === null && quantityInfo2 === null) {
        return true;
    }
    if (quantityInfo1 === null || quantityInfo2 === null) {
        return false;
    }

    if (
        !isNaN(parseFloat(quantityInfo1.quantity)) &&
        !isNaN(parseFloat(quantityInfo2.quantity)) &&
        parseFloat(quantityInfo1.quantity) !==
            parseFloat(quantityInfo2.quantity)
    ) {
        return false;
    }
    if (
        quantityInfo1.measurement_unit_name.trim() !==
        quantityInfo2.measurement_unit_name.trim()
    ) {
        return false;
    }
    return true;
};

interface procurementInfo {
    lead_time: string | null;
    lead_time_period: string | null;
}

interface basicDS {
    quantity: string;
    delivery_date: string | null;
}

const sortedDeliveryScheduleItems = (dsItems: basicDS[]) => {
    let nonTbdItemsSorted: basicDS[] = dsItems
        .filter((row) => row.delivery_date !== null && row.delivery_date !== '')
        .sort((x, y) => x.delivery_date!.localeCompare(y.delivery_date!));
    let tbdItems: basicDS[] = dsItems
        .filter((row) => row.delivery_date === null || row.delivery_date === '')
        .sort((x, y) => parseFloat(x.quantity) - parseFloat(y.quantity));
    return nonTbdItemsSorted.concat(tbdItems);
};

export const deliverySchedulesItemsAreSame = (
    dsItems1: basicDS[],
    dsItems2: basicDS[]
) => {
    if (dsItems1 === null && dsItems2 === null) {
        return true;
    }
    if (dsItems1 === null || dsItems2 === null) {
        return false;
    }
    let sortedDs1 = sortedDeliveryScheduleItems(dsItems1);
    let sortedDs2 = sortedDeliveryScheduleItems(dsItems2);
    if (sortedDs1.length !== sortedDs2.length) {
        return false;
    }
    for (let i = 0; i < sortedDs1.length; i++) {
        if (
            (sortedDs1[i].delivery_date === null &&
                sortedDs2[i].delivery_date !== null) ||
            (sortedDs1[i].delivery_date !== null &&
                sortedDs2[i].delivery_date === null) ||
            (sortedDs1[i].delivery_date !== null &&
                sortedDs2[i].delivery_date !== null &&
                !moment(sortedDs1[i].delivery_date).isSame(
                    sortedDs2[i].delivery_date
                ))
        ) {
            return false;
        }
        if (
            isNaN(parseFloat(sortedDs1[i].quantity)) ||
            isNaN(parseFloat(sortedDs2[i].quantity))
        ) {
            return false;
        }
        if (
            parseFloat(sortedDs1[i].quantity) !==
            parseFloat(sortedDs2[i].quantity)
        ) {
            return false;
        }
    }
    return true;
};

const procurementInfoIsSame = (
    procurementInfo1: procurementInfo,
    procurementInfo2: procurementInfo
) => {
    if (procurementInfo1 === null && procurementInfo2 === null) {
        return true;
    }
    if (procurementInfo1 === null || procurementInfo2 === null) {
        return false;
    }
    if (
        !isNaN(parseFloat(procurementInfo1.lead_time ?? '')) &&
        !isNaN(parseFloat(procurementInfo2.lead_time ?? '')) &&
        parseFloat(procurementInfo1.lead_time ?? '') !==
            parseFloat(procurementInfo2.lead_time ?? '')
    ) {
        return false;
    }
    return true;
};

const negotiationCustomFieldsAreSame = (
    negotiationCustomFields1: ICustomSection[],
    negotiationCustomFields2: ICustomSection[]
) => {
    for (let i = 0; i < negotiationCustomFields1.length; i++) {
        const section = negotiationCustomFields1[i];

        const section2 = negotiationCustomFields2.find(
            (sectionFind) => sectionFind.name === section.name
        );

        if (section2) {
            for (let j = 0; j < section.custom_fields.length; j++) {
                const field = section.custom_fields[j];
                const field2 = section2.custom_fields.find(
                    (fieldFind) => fieldFind.name === field.name
                );
                if (field2) {
                    if (
                        stringValueForStringArrNull(field.value)
                            .trim()
                            .toUpperCase() !==
                        stringValueForStringArrNull(field2.value)
                            .trim()
                            .toUpperCase()
                    ) {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    }
    return true;
};

const attachmentsAreSame = (
    attachments1: IAttachment[],
    attachments2: IAttachment[]
) => {
    let storage_id_array_1 = attachments1.map(
        (attachment) => attachment.storage_id ?? 'not_found'
    );
    let storage_id_array_2 = attachments2.map(
        (attachment) => attachment.storage_id ?? 'not_found'
    );
    if (
        storage_id_array_1.includes('not_found') ||
        storage_id_array_2.includes('not_found')
    ) {
        return false;
    }
    let storage_id_set_1 = new Set(storage_id_array_1);
    let storage_id_set_2 = new Set(storage_id_array_2);
    if (!isEqual(storage_id_set_1, storage_id_set_2)) {
        return false;
    }
    return true;
};
