import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    FormControl,
    IconButton,
    Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FWCurrencyTypoGraphyWithCurrencyUid } from '../../../Common/CurrencyUtilsComponent';
import FWAutocomplete from '../../../Common/FWAutocomplete';
import { FWCombinedInput } from '../../../Common/FWCombinedInput';
import { FWMenuItem } from '../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWInput } from '../../../Common/FWInput';
import { FWTypography } from '../../../Common/FWTypograhy';
import IncotermsGraph from '../../../Components/Shared/IncotermsGraph';
import PaymentTermsPopover, {
    PaymentTermsCount,
} from '../../../Events/Components/Buyer/PaymentTermsPopover';
import {
    PaymentTermsUi,
    PaymentTermsUiErrorMessages,
    PaymentTermsUiErrorMessagesAllocationRow,
    PaymentTermsUpdateFunctions,
} from '../../../Events/Components/PaymentTermsUi';
import {
    paymentTermsFormatter,
    paymentTermsFormatterWithDefaultText,
} from '../../../Events/RFQAnalytics/Helpers/HelperFunction';
import { useGetAllIncotermsQuery } from '../../../Events/Services/Buyer/shared.service';
import {
    IPaymentTermsAllocation,
    PaymentTermsInvoiceItemAppliedFromType,
} from '../../../Global/Interfaces/PaymentTermsInterface';
import { PeriodEnum } from '../../../Global/Interfaces/PeriodInterface';
import { IAddresses } from '../../../Models/Address.model';
import { IIncotermDetails } from '../../../Models/Incoterms.model';
import { IGlobalAppStore } from '../../../Redux/Store';
import { ItemCartTemplateStandardFieldsEnum } from '../../Interfaces/ItemCartTemplateInterfaces';
import { ItemCartAccessContext } from '../../Pages/ItemCartAccess';
import { CartItemAccordionContext } from './CartItemAccordion';
import CartItemCustomFields from './CartItemCustomFields';

export default function CartItemPaymentAndDeliveryTerms() {
    const {
        updateItemData,
        errors,
        shippingAddressOptions,
        cartItemId,

        itemInCart,
    } = useContext(CartItemAccordionContext);

    const [open, setOpen] = useState(false);

    const { data: incoterms } = useGetAllIncotermsQuery({});

    const { templateDetails } = useContext(ItemCartAccessContext);

    const paymentTerms = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].paymentTerms
            : state.ItemCartStore.itemsSavedForLater[cartItemId].paymentTerms
    );

    const shippingAddress = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].shipping_address
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .shipping_address
    );

    const lead_time = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].procurement_information
                  .lead_time
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .procurement_information.lead_time
    );

    const lead_time_period = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].procurement_information
                  .lead_time_period
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .procurement_information.lead_time_period
    );

    const incoterm_id = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].incoterm
            : state.ItemCartStore.itemsSavedForLater[cartItemId].incoterm
    );

    // const price = useSelector((state: IGlobalAppStore) =>
    //     itemInCart
    //         ? state.ItemCartStore.cartItems[cartItemId].pricing_information
    //               .desired_price
    //         : state.ItemCartStore.itemsSavedForLater[cartItemId]
    //               .pricing_information.desired_price
    // );

    const quantity_tolerance_percentage = useSelector(
        (state: IGlobalAppStore) =>
            itemInCart
                ? state.ItemCartStore.cartItems[cartItemId]
                      .quantity_tolerance_percentage
                : state.ItemCartStore.itemsSavedForLater[cartItemId]
                      .quantity_tolerance_percentage
    );

    const shipping_address = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].shipping_address
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .shipping_address
    );

    const currency_code_abbreviation = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].pricing_information
                  .currency_code_abbreviation
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .pricing_information.currency_code_abbreviation
    );

    const isLeadTimeMandatory =
        templateDetails.fieldSettings.standardFields[
            ItemCartTemplateStandardFieldsEnum.LEAD_TIME
        ].buyerSettings.required;

    const paymentTermsUpdateFunctions =
        useMemo((): PaymentTermsUpdateFunctions => {
            const updateTerms = (newTerms: string) => {
                updateItemData([
                    {
                        field: 'paymentTerms_invoiceItem_terms',
                        value: newTerms,
                    },
                ]);
            };
            const updatePeriod = (newPeriod: PeriodEnum) => {
                updateItemData([
                    {
                        field: 'paymentTerms_invoiceItem_period',
                        value: newPeriod,
                    },
                ]);
            };
            const updateAppliedFrom = (
                newAppliedFrom: PaymentTermsInvoiceItemAppliedFromType
            ) => {
                updateItemData([
                    {
                        field: 'paymentTerms_invoiceItem_appliedFrom',
                        value: newAppliedFrom,
                    },
                ]);
            };
            const updateAllocationList = (
                newList: IPaymentTermsAllocation[]
            ) => {
                updateItemData([
                    {
                        field: 'paymentTerms_poItem_allocationRows',
                        value: newList,
                    },
                ]);
            };
            return {
                invoiceItemValues: {
                    updateTerms,
                    updatePeriod,
                    updateAppliedFrom,
                },
                poItemPercentages: {
                    updateAllocationList,
                },
            };
        }, [updateItemData]);

    const prepaymentSummary = () => {
        if (
            !templateDetails.fieldSettings.standardFields.PAYMENT_TERMS
                .buyerSettings.showField
        ) {
            return undefined;
        }
        if (
            !templateDetails.fieldSettings.standardFields.PAYMENT_TERMS
                .paymentTermOptions.prepaymentAllowed
        ) {
            return undefined;
        }

        return (
            <>
                <FWTypography
                    color={
                        errors.prepayment_percentage &&
                        !errors.prepayment_percentage.suppress
                            ? errors.prepayment_percentage.type === 'ERROR'
                                ? 'error.main'
                                : 'warning.main'
                            : 'text.secondary'
                    }
                    className="flex flex--aic"
                >
                    {!paymentTerms?.prepaymentPercentage ||
                    +(paymentTerms?.prepaymentPercentage ?? '0') === 0
                        ? `No Prepayment`
                        : `Prepayment: ${(+paymentTerms?.prepaymentPercentage).toFixed(
                              2
                          )}% `}
                </FWTypography>
                &nbsp;
                <FWTypography color="text.secondary" className="flex flex--aic">
                    |
                </FWTypography>
                &nbsp;
            </>
        );
    };

    const paymentTermsSummary = () => {
        const templatePt =
            templateDetails.fieldSettings.standardFields.PAYMENT_TERMS;
        if (!templatePt.buyerSettings.showField) {
            return undefined;
        }

        const pt = paymentTerms;

        if (!pt) return undefined;
        if (+pt.prepaymentPercentage >= 100) {
            return undefined;
        }

        if (pt.paymentType === 'invoiceItemValues') {
            const isError =
                (errors.paymentTerms_invoiceItem_terms &&
                    errors.paymentTerms_invoiceItem_terms.type === 'ERROR') ||
                (errors.paymentTerms_invoiceItem_period &&
                    errors.paymentTerms_invoiceItem_period.type === 'ERROR') ||
                (errors.paymentTerms_invoiceItem_appliedFrom &&
                    errors.paymentTerms_invoiceItem_appliedFrom.type ===
                        'ERROR');

            // const isWarning =
            //     (errors.paymentTerms_invoiceItem_terms &&
            //         errors.paymentTerms_invoiceItem_terms.type === 'WARNING') ||
            //     (errors.paymentTerms_invoiceItem_period &&
            //         errors.paymentTerms_invoiceItem_period.type ===
            //             'WARNING') ||
            //     (errors.paymentTerms_invoiceItem_appliedFrom &&
            //         errors.paymentTerms_invoiceItem_appliedFrom.type ===
            //             'WARNING');

            if (isError) {
                return (
                    <FWTypography
                        color={'text.secondary'}
                        className="flex flex--aic"
                    >{`Invalid ${templatePt.label}`}</FWTypography>
                );
            }

            const termsStr = paymentTermsFormatter({
                paymentTerms: pt,
                includePrepaymentString: false,
            });
            return (
                <FWTypography
                    color={'text.secondary'}
                    className="flex flex--aic"
                >{`${templatePt.label}: ${termsStr}`}</FWTypography>
            );
        } else {
            let isError =
                errors.paymentTerms_poItem_overallPercentage &&
                errors.paymentTerms_poItem_overallPercentage.type === 'ERROR';
            let isWarning =
                errors.paymentTerms_poItem_overallPercentage &&
                errors.paymentTerms_poItem_overallPercentage.type === 'WARNING';

            const rowValidations = errors.paymentTerms_poItem_allocationRows;

            for (let rowValidation of rowValidations) {
                if (isError) {
                    // No need to check more because it is already an error.
                    break;
                }
                if (rowValidation.rowAllocationPercentage) {
                    if (
                        rowValidation.rowAllocationPercentage.type === 'ERROR'
                    ) {
                        isError = true;
                    } else {
                        isWarning = true;
                    }
                } else if (rowValidation.rowTerms) {
                    if (rowValidation.rowTerms.type === 'ERROR') {
                        isError = true;
                    } else {
                        isWarning = true;
                    }
                } else if (rowValidation.rowPeriod) {
                    if (rowValidation.rowPeriod.type === 'ERROR') {
                        isError = true;
                    } else {
                        isWarning = true;
                    }
                } else if (rowValidation.rowDeliverableName) {
                    if (rowValidation.rowDeliverableName.type === 'ERROR') {
                        isError = true;
                    } else {
                        isWarning = true;
                    }
                } else if (rowValidation.rowDate) {
                    if (rowValidation.rowDate.type === 'ERROR') {
                        isError = true;
                    } else {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        isWarning = true;
                    }
                }
            }
            if (isError) {
                return (
                    <FWTypography
                        color={'text.secondary'}
                        className="flex flex--aic"
                    >{`Invalid ${templatePt.label}`}</FWTypography>
                );
            }

            const termsStr = paymentTermsFormatter({
                paymentTerms: pt,
                includePrepaymentString: false,
            });

            return pt.list.length === 1 ? (
                <FWTypography
                    color={'text.secondary'}
                    className="flex flex--aic"
                >{`${templatePt.label}: ${termsStr}`}</FWTypography>
            ) : (
                <PaymentTermsPopover
                    label={templatePt.label}
                    paymentTerms={pt}
                    showPrepayment={false}
                    // darkLabel
                />
            );
        }
    };

    const [openPaymentTerms, setOpenPaymentTerms] = useState(false);

    const paymentTermsUiErrors =
        useCallback((): PaymentTermsUiErrorMessages => {
            const templatePt =
                templateDetails.fieldSettings.standardFields.PAYMENT_TERMS;
            if (
                paymentTerms?.paymentType === 'invoiceItemValues' &&
                templatePt.paymentTermOptions.paymentType ===
                    'invoiceItemValues'
            ) {
                const termsErr =
                    errors.paymentTerms_invoiceItem_terms &&
                    !errors.paymentTerms_invoiceItem_terms.suppress
                        ? errors.paymentTerms_invoiceItem_terms.fieldMessageText
                        : null;
                const periodErr =
                    errors.paymentTerms_invoiceItem_period &&
                    !errors.paymentTerms_invoiceItem_period.suppress
                        ? errors.paymentTerms_invoiceItem_period
                              .fieldMessageText
                        : null;
                const appliedFromErr =
                    errors.paymentTerms_invoiceItem_appliedFrom &&
                    !errors.paymentTerms_invoiceItem_appliedFrom.suppress
                        ? errors.paymentTerms_invoiceItem_appliedFrom
                              .fieldMessageText
                        : null;
                return {
                    invoiceItemValues: {
                        terms: termsErr,
                        period: periodErr,
                        appliedFrom: appliedFromErr,
                    },
                };
            } else if (
                paymentTerms?.paymentType === 'poItemPercentages' &&
                templatePt.paymentTermOptions.paymentType ===
                    'poItemPercentages'
            ) {
                const overallPercentageErr =
                    errors.paymentTerms_poItem_overallPercentage &&
                    !errors.paymentTerms_poItem_overallPercentage.suppress
                        ? errors.paymentTerms_poItem_overallPercentage
                              .fieldMessageText
                        : null;
                const rowErrors: PaymentTermsUiErrorMessagesAllocationRow[] =
                    [];
                for (let curRowStateErrors of errors.paymentTerms_poItem_allocationRows) {
                    const curRowUiErrors: PaymentTermsUiErrorMessagesAllocationRow =
                        {
                            allocationPercentage:
                                curRowStateErrors.rowAllocationPercentage &&
                                !curRowStateErrors.rowAllocationPercentage
                                    .suppress
                                    ? curRowStateErrors.rowAllocationPercentage
                                          .fieldMessageText
                                    : null,
                            date:
                                curRowStateErrors.rowDate &&
                                !curRowStateErrors.rowDate.suppress
                                    ? curRowStateErrors.rowDate.fieldMessageText
                                    : null,
                            deliverableName:
                                curRowStateErrors.rowDeliverableName &&
                                !curRowStateErrors.rowDeliverableName.suppress
                                    ? curRowStateErrors.rowDeliverableName
                                          .fieldMessageText
                                    : null,
                            period:
                                curRowStateErrors.rowPeriod &&
                                !curRowStateErrors.rowPeriod.suppress
                                    ? curRowStateErrors.rowPeriod
                                          .fieldMessageText
                                    : null,
                            terms:
                                curRowStateErrors.rowTerms &&
                                !curRowStateErrors.rowTerms.suppress
                                    ? curRowStateErrors.rowTerms
                                          .fieldMessageText
                                    : null,
                        };
                    rowErrors.push(curRowUiErrors);
                }
                return {
                    poItemPercentages: {
                        overallPercentage: overallPercentageErr,
                        rowErrors: rowErrors,
                    },
                };
            } else {
                return {};
            }
        }, [
            errors.paymentTerms_invoiceItem_appliedFrom,
            errors.paymentTerms_invoiceItem_period,
            errors.paymentTerms_invoiceItem_terms,
            errors.paymentTerms_poItem_allocationRows,
            errors.paymentTerms_poItem_overallPercentage,
            paymentTerms?.paymentType,
            templateDetails.fieldSettings.standardFields.PAYMENT_TERMS,
        ]);

    const paymentTermsHasError = () => {
        const paymentMethodError = paymentTermsUiErrors();
        if (
            paymentMethodError.invoiceItemValues &&
            (paymentMethodError.invoiceItemValues.terms ||
                paymentMethodError.invoiceItemValues.period ||
                paymentMethodError.invoiceItemValues.appliedFrom)
        )
            return true;
        else if (
            paymentMethodError.poItemPercentages &&
            (paymentMethodError.poItemPercentages.overallPercentage ||
                paymentMethodError.poItemPercentages.rowErrors.some(
                    (row) =>
                        row.allocationPercentage ||
                        row.terms ||
                        row.period ||
                        row.deliverableName ||
                        row.date
                ))
        )
            return true;
        else return +paymentTerms?.prepaymentPercentage > 100;
    };

    const paymentTermsFieldHeaderRightElements = useCallback(() => {
        const templatePt =
            templateDetails.fieldSettings.standardFields.PAYMENT_TERMS;
        const lastPoPaymentTermsStr = null;
        return (
            <>
                &nbsp;
                <FWTooltip
                    title={
                        lastPoPaymentTermsStr
                            ? lastPoPaymentTermsStr
                            : 'Previous PO data not available'
                    }
                    placement="right"
                >
                    <Box
                        color={'text.secondary'}
                        sx={{
                            fontSize: '15px',
                        }}
                    >
                        <i className="bi bi-clipboard-data" />
                    </Box>
                </FWTooltip>
                {templatePt.description && (
                    <>
                        &nbsp;
                        <FWTooltip
                            title={templatePt.description}
                            placement="top"
                        >
                            <Box color={'primary.main'}>
                                <i className="bi bi-question-circle" />
                            </Box>
                        </FWTooltip>
                    </>
                )}
            </>
        );
    }, [templateDetails.fieldSettings.standardFields.PAYMENT_TERMS]);

    return (
        <Accordion
            expanded={open}
            onChange={() => setOpen((prev) => !prev)}
            sx={{
                backgroundColor: 'transparent',
                '&:before': {
                    display: 'none',
                },
            }}
            disableGutters
        >
            <AccordionSummary
                sx={{
                    padding: '0px',
                    margin: '0 0.5rem 0 0.5rem',
                }}
            >
                <IconButton size="small" color={'primary'}>
                    {open ? (
                        <i className="bi bi-chevron-down"></i>
                    ) : (
                        <i className="bi bi-chevron-right"></i>
                    )}
                </IconButton>
                &nbsp;
                <Box className="flex flex--aic">
                    <FWTypography
                        variant="h4"
                        fontWeight={500}
                        color={'text.primary'}
                    >
                        {/* Payment and delivery terms */}
                        {
                            templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS
                                .label
                        }
                    </FWTypography>
                </Box>
                &nbsp; &nbsp;
                {!open && (
                    <Box className="flex flex--aic" flexWrap={'wrap'}>
                        {templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.map(
                            (field) => {
                                if (field.fieldType === 'STANDARD') {
                                    switch (field.field) {
                                        case ItemCartTemplateStandardFieldsEnum.PAYMENT_TERMS:
                                            return (
                                                <>
                                                    <FWTypography
                                                        color="text.secondary"
                                                        className="flex flex--aic"
                                                    >
                                                        |
                                                    </FWTypography>
                                                    &nbsp;
                                                    {prepaymentSummary()}
                                                    {paymentTermsSummary()}
                                                </>
                                            );
                                        case ItemCartTemplateStandardFieldsEnum.LEAD_TIME:
                                            return (
                                                templateDetails.fieldSettings
                                                    .standardFields[field.field]
                                                    .buyerSettings.showField &&
                                                lead_time !== null &&
                                                !isEmpty(lead_time_period) && (
                                                    <>
                                                        &nbsp;
                                                        <FWTypography
                                                            color="text.secondary"
                                                            className="flex flex--aic"
                                                        >
                                                            |
                                                        </FWTypography>
                                                        &nbsp;
                                                        <FWTypography
                                                            color={
                                                                'lead_time' in
                                                                    errors &&
                                                                !errors
                                                                    .lead_time
                                                                    ?.suppress
                                                                    ? 'warning.main'
                                                                    : 'text.secondary'
                                                            }
                                                            className="flex flex--aic"
                                                        >
                                                            {+lead_time === 0
                                                                ? `No ${
                                                                      templateDetails
                                                                          .fieldSettings
                                                                          .standardFields[
                                                                          field
                                                                              .field
                                                                      ].label
                                                                  }`
                                                                : `${
                                                                      templateDetails
                                                                          .fieldSettings
                                                                          .standardFields[
                                                                          field
                                                                              .field
                                                                      ].label
                                                                  }: ${lead_time} ${lead_time_period!
                                                                      .toLowerCase()
                                                                      .substring(
                                                                          0,
                                                                          lead_time_period!
                                                                              .length -
                                                                              1
                                                                      )}(s) `}
                                                        </FWTypography>
                                                    </>
                                                )
                                            );
                                        case ItemCartTemplateStandardFieldsEnum.INCOTERMS:
                                            return (
                                                templateDetails.fieldSettings
                                                    .standardFields[field.field]
                                                    .buyerSettings.showField &&
                                                !isEmpty(incoterm_id) &&
                                                incoterms?.find(
                                                    (x) =>
                                                        x.entry_id ===
                                                        incoterm_id
                                                ) && (
                                                    <>
                                                        &nbsp;
                                                        <FWTypography
                                                            color="text.secondary"
                                                            className="flex flex--aic"
                                                        >
                                                            |
                                                        </FWTypography>
                                                        &nbsp;
                                                        <FWTypography
                                                            color="text.secondary"
                                                            className="flex flex--aic"
                                                        >
                                                            {incoterms?.find(
                                                                (x) =>
                                                                    x.entry_id ===
                                                                    incoterm_id
                                                            )!
                                                                .incoterm_abbreviation ===
                                                            'NA'
                                                                ? `No ${
                                                                      templateDetails
                                                                          .fieldSettings
                                                                          .standardFields[
                                                                          field
                                                                              .field
                                                                      ].label
                                                                  }`
                                                                : `${
                                                                      templateDetails
                                                                          .fieldSettings
                                                                          .standardFields[
                                                                          field
                                                                              .field
                                                                      ].label
                                                                  }: ${
                                                                      incoterms?.find(
                                                                          (x) =>
                                                                              x.entry_id ===
                                                                              incoterm_id
                                                                      )!
                                                                          .incoterm_abbreviation
                                                                  }`}
                                                        </FWTypography>
                                                    </>
                                                )
                                            );
                                        case ItemCartTemplateStandardFieldsEnum.GR_TOLERANCE:
                                            return (
                                                templateDetails.fieldSettings
                                                    .standardFields[field.field]
                                                    .buyerSettings.showField &&
                                                +quantity_tolerance_percentage >=
                                                    0 && (
                                                    <div className="flex flex--aic">
                                                        <FWTypography color="text.secondary">
                                                            &nbsp; | &nbsp;
                                                            {
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    field.field
                                                                ].label
                                                            }
                                                            :{' '}
                                                        </FWTypography>
                                                        &nbsp;
                                                        <FWTypography color="text.secondary">
                                                            {quantity_tolerance_percentage ? (
                                                                <div className="flex flex--aic">
                                                                    <FWCurrencyTypoGraphyWithCurrencyUid
                                                                        currencyUidOrAbbrevaition={
                                                                            currency_code_abbreviation
                                                                        }
                                                                        currency_code_abbreviation={
                                                                            currency_code_abbreviation
                                                                        }
                                                                        value={
                                                                            quantity_tolerance_percentage
                                                                        }
                                                                        hideCurrency={
                                                                            true
                                                                        }
                                                                    />
                                                                    %
                                                                </div>
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </FWTypography>
                                                    </div>
                                                )
                                            );
                                        case ItemCartTemplateStandardFieldsEnum.SHIPPING_ADDRESS:
                                            return (
                                                templateDetails.fieldSettings
                                                    .standardFields[
                                                    'SHIPPING_ADDRESS'
                                                ].buyerSettings.showField && (
                                                    <div className="flex flex--aic">
                                                        <FWTypography color="text.secondary">
                                                            &nbsp; | &nbsp;
                                                            {
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    'SHIPPING_ADDRESS'
                                                                ].label
                                                            }
                                                            :{' '}
                                                        </FWTypography>
                                                        &nbsp;
                                                        <FWTypography color="text.secondary">
                                                            {shipping_address !==
                                                            null ? (
                                                                <div className="flex flex--aic">
                                                                    {
                                                                        shipping_address.shipping_full_address
                                                                    }
                                                                </div>
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </FWTypography>
                                                    </div>
                                                )
                                            );

                                        default:
                                            return null;
                                    }
                                } else return null;
                            }
                        )}
                    </Box>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box
                    display="grid"
                    gridTemplateColumns={{
                        lg: 'repeat(4, 1fr)',
                        sm: 'repeat(2, 1fr)',
                        xs: 'repeat(1, 1fr)',
                    }}
                    columnGap="1.5rem"
                    rowGap={'1rem'}
                >
                    {templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.map(
                        (fieldListItem, idx) => {
                            if (fieldListItem.fieldType === 'STANDARD') {
                                if (
                                    templateDetails.fieldSettings
                                        .standardFields[fieldListItem.field]
                                        .buyerSettings.showField
                                )
                                    switch (fieldListItem.field) {
                                        case ItemCartTemplateStandardFieldsEnum.PAYMENT_TERMS:
                                            const templatePt =
                                                templateDetails.fieldSettings
                                                    .standardFields
                                                    .PAYMENT_TERMS;

                                            // const paymentTerms = paymentTerms;

                                            let allowManualDeliverable = false;

                                            let customReferenceDateOptions: string[] =
                                                [];

                                            if (
                                                paymentTerms?.paymentType ===
                                                    'invoiceItemValues' &&
                                                templatePt.paymentTermOptions
                                                    .paymentType ===
                                                    'invoiceItemValues'
                                            ) {
                                                customReferenceDateOptions =
                                                    templatePt
                                                        .paymentTermOptions
                                                        .customReferenceDateOptions;
                                                allowManualDeliverable =
                                                    templatePt
                                                        .paymentTermOptions
                                                        .allowOtherReferenceDate;
                                            } else if (
                                                paymentTerms?.paymentType ===
                                                    'poItemPercentages' &&
                                                templatePt.paymentTermOptions
                                                    .paymentType ===
                                                    'poItemPercentages'
                                            ) {
                                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                                customReferenceDateOptions =
                                                    templatePt
                                                        .paymentTermOptions
                                                        .deliverableNameOptions;
                                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                                allowManualDeliverable =
                                                    templatePt
                                                        .paymentTermOptions
                                                        .allowOtherDeliverableNames;
                                            }

                                            if (
                                                !templatePt.buyerSettings
                                                    .showField
                                            ) {
                                                return undefined;
                                            }
                                            return (
                                                <div>
                                                    <PaymentTermsUi
                                                        fieldError={
                                                            errors
                                                                ?.paymentTerms_prepayment
                                                                ?.fieldMessageText ??
                                                            ''
                                                        }
                                                        prepaymentPercentage={
                                                            paymentTerms?.prepaymentPercentage
                                                        }
                                                        showPrepaymentField={
                                                            templateDetails
                                                                .fieldSettings
                                                                .standardFields
                                                                .PAYMENT_TERMS
                                                                .paymentTermOptions
                                                                .prepaymentAllowed
                                                        }
                                                        updatePrepaymentPercentage={(
                                                            prepaymentVal: string
                                                        ) => {
                                                            updateItemData([
                                                                {
                                                                    field: 'paymentTerms_prepaymentPercentage',
                                                                    value: prepaymentVal,
                                                                },
                                                            ]);
                                                        }}
                                                        popupOpen={
                                                            openPaymentTerms
                                                        }
                                                        onClose={() =>
                                                            setOpenPaymentTerms(
                                                                false
                                                            )
                                                        }
                                                        label={templatePt.label}
                                                        paymentTerms={
                                                            paymentTerms
                                                        }
                                                        standardReferenceDateOptions={
                                                            templateDetails
                                                                .fieldSettings
                                                                .standardFields
                                                                .PAYMENT_TERMS
                                                                .paymentTermOptions
                                                                .paymentType ===
                                                            'invoiceItemValues'
                                                                ? templateDetails
                                                                      .fieldSettings
                                                                      .standardFields
                                                                      .PAYMENT_TERMS
                                                                      .paymentTermOptions
                                                                      .standardReferenceDateOptions
                                                                : {
                                                                      DISPATCH_DATE:
                                                                          {
                                                                              label: 'DISPATCH_DATE',

                                                                              enabled:
                                                                                  false,
                                                                          },
                                                                      INVOICE_DATE:
                                                                          {
                                                                              label: 'INVOICE_DATE',
                                                                              enabled:
                                                                                  false,
                                                                          },
                                                                      RECEIPT_DATE:
                                                                          {
                                                                              label: 'RECEIPT_DATE',
                                                                              enabled:
                                                                                  false,
                                                                          },
                                                                  }
                                                        }
                                                        customReferenceDateOptions={
                                                            templateDetails
                                                                .fieldSettings
                                                                .standardFields
                                                                .PAYMENT_TERMS
                                                                .paymentTermOptions
                                                                .paymentType ===
                                                            'invoiceItemValues'
                                                                ? templateDetails
                                                                      .fieldSettings
                                                                      .standardFields
                                                                      .PAYMENT_TERMS
                                                                      .paymentTermOptions
                                                                      .customReferenceDateOptions
                                                                : templateDetails
                                                                      .fieldSettings
                                                                      .standardFields
                                                                      .PAYMENT_TERMS
                                                                      .paymentTermOptions
                                                                      .deliverableNameOptions
                                                        }
                                                        allowManualDeliverable={
                                                            false
                                                        }
                                                        updateFunctions={
                                                            paymentTermsUpdateFunctions
                                                        }
                                                        errorMessages={paymentTermsUiErrors()}
                                                        type="BUYER"
                                                    />
                                                    <Box>
                                                        <div className="flex flex--aic">
                                                            <FWTypography
                                                                color={
                                                                    'text.secondary'
                                                                }
                                                            >
                                                                {
                                                                    templateDetails
                                                                        .fieldSettings
                                                                        .standardFields
                                                                        .PAYMENT_TERMS
                                                                        .label
                                                                }
                                                            </FWTypography>
                                                            {paymentTermsFieldHeaderRightElements()}
                                                        </div>
                                                        <Box
                                                            className="flex flex--aic"
                                                            height="2.25rem"
                                                        >
                                                            <FWTypography
                                                                color={
                                                                    paymentTermsHasError()
                                                                        ? 'error.main'
                                                                        : 'primary.main'
                                                                }
                                                                sx={{
                                                                    ':hover': {
                                                                        cursor: 'pointer',
                                                                        textDecoration:
                                                                            'underline',
                                                                    },
                                                                }}
                                                                onClick={() =>
                                                                    setOpenPaymentTerms(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                {paymentTerms?.paymentType ===
                                                                    'invoiceItemValues' ||
                                                                (paymentTerms?.paymentType ===
                                                                    'poItemPercentages' &&
                                                                    paymentTerms
                                                                        .list
                                                                        .length ===
                                                                        1) ? (
                                                                    <>
                                                                        {paymentTermsFormatterWithDefaultText(
                                                                            {
                                                                                paymentTerms:
                                                                                    paymentTerms,
                                                                                includePrepaymentString:
                                                                                    templateDetails
                                                                                        .fieldSettings
                                                                                        .standardFields
                                                                                        .PAYMENT_TERMS
                                                                                        .paymentTermOptions
                                                                                        .prepaymentAllowed,
                                                                                delimiter:
                                                                                    ' | ',
                                                                            }
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <PaymentTermsCount
                                                                            paymentTerms={
                                                                                paymentTerms
                                                                            }
                                                                            showPrepayment={
                                                                                templateDetails
                                                                                    .fieldSettings
                                                                                    .standardFields
                                                                                    .PAYMENT_TERMS
                                                                                    .paymentTermOptions
                                                                                    .prepaymentAllowed
                                                                            }
                                                                        />
                                                                    </>
                                                                )}
                                                            </FWTypography>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            );
                                        case ItemCartTemplateStandardFieldsEnum.INCOTERMS:
                                            return (
                                                <Box key={idx}>
                                                    <Box className="flex flex--aic">
                                                        <FWTypography color="text.secondary">
                                                            {
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    fieldListItem
                                                                        .field
                                                                ].label
                                                            }
                                                        </FWTypography>
                                                        &nbsp;
                                                        <IncotermsGraph />
                                                        &nbsp;
                                                        {templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            fieldListItem.field
                                                        ].description.length >
                                                            0 && (
                                                            <>
                                                                &nbsp;
                                                                <FWTooltip
                                                                    title={
                                                                        templateDetails
                                                                            .fieldSettings
                                                                            .standardFields[
                                                                            fieldListItem
                                                                                .field
                                                                        ]
                                                                            .description
                                                                    }
                                                                    placement="top"
                                                                >
                                                                    <Box
                                                                        color={
                                                                            'primary.main'
                                                                        }
                                                                    >
                                                                        <i className="bi bi-question-circle" />
                                                                    </Box>
                                                                </FWTooltip>
                                                            </>
                                                        )}
                                                    </Box>
                                                    <FormControl
                                                        fullWidth
                                                        required
                                                    >
                                                        <FWAutocomplete
                                                            popupIcon={
                                                                <Box
                                                                    fontSize={
                                                                        '16px'
                                                                    }
                                                                >
                                                                    <i className="bi bi-chevron-down"></i>
                                                                </Box>
                                                            }
                                                            disableClearable
                                                            disablePortal
                                                            getOptionLabel={(
                                                                option: IIncotermDetails
                                                            ) =>
                                                                `${option.incoterm_abbreviation} (${option.incoterm_full_name})`
                                                            }
                                                            options={
                                                                incoterms ?? []
                                                            }
                                                            value={
                                                                incoterms?.find(
                                                                    (x) =>
                                                                        x.entry_id ===
                                                                        incoterm_id
                                                                ) ?? null
                                                            }
                                                            isOptionEqualToValue={(
                                                                option: IIncotermDetails,
                                                                value: string
                                                            ) => {
                                                                return (
                                                                    option.entry_id ===
                                                                    value
                                                                );
                                                            }}
                                                            onChange={(
                                                                e,
                                                                newValue: IIncotermDetails
                                                            ) =>
                                                                updateItemData([
                                                                    {
                                                                        field: 'incoterm',
                                                                        value: newValue
                                                                            ? newValue.entry_id
                                                                            : null,
                                                                    },
                                                                ])
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <FWInput
                                                                    {...params}
                                                                    required
                                                                    error={
                                                                        'incoterm' in
                                                                            errors &&
                                                                        !errors
                                                                            .incoterm
                                                                            ?.suppress
                                                                    }
                                                                    helper={{
                                                                        text:
                                                                            'incoterm' in
                                                                                errors &&
                                                                            !errors
                                                                                .incoterm
                                                                                ?.suppress
                                                                                ? errors[
                                                                                      'incoterm'
                                                                                  ]!
                                                                                      .fieldMessageText
                                                                                : '',
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Box>
                                            );
                                        case ItemCartTemplateStandardFieldsEnum.LEAD_TIME:
                                            return (
                                                <Box key={idx}>
                                                    <Box className="flex flex--aic">
                                                        <Typography color="text.secondary">
                                                            {
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    fieldListItem
                                                                        .field
                                                                ].label
                                                            }
                                                        </Typography>
                                                        {false && (
                                                            <div className="flex">
                                                                &nbsp;
                                                                <FWTooltip
                                                                    title={`Lead time exceeds one year`}
                                                                    placement="right"
                                                                >
                                                                    <IconButton
                                                                        color={
                                                                            'warning'
                                                                        }
                                                                        size={
                                                                            'small'
                                                                        }
                                                                        sx={{
                                                                            fontSize:
                                                                                '15px',
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-exclamation-circle"></i>
                                                                    </IconButton>
                                                                </FWTooltip>
                                                            </div>
                                                        )}
                                                        &nbsp;
                                                        {templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            fieldListItem.field
                                                        ].description.length >
                                                            0 && (
                                                            <>
                                                                &nbsp;
                                                                <FWTooltip
                                                                    title={
                                                                        templateDetails
                                                                            .fieldSettings
                                                                            .standardFields[
                                                                            fieldListItem
                                                                                .field
                                                                        ]
                                                                            .description
                                                                    }
                                                                    placement="top"
                                                                >
                                                                    <Box
                                                                        color={
                                                                            'primary.main'
                                                                        }
                                                                    >
                                                                        <i className="bi bi-question-circle" />
                                                                    </Box>
                                                                </FWTooltip>
                                                            </>
                                                        )}
                                                    </Box>
                                                    <FWTooltip
                                                        title={
                                                            lead_time_period ===
                                                                'NA' ||
                                                            lead_time_period ===
                                                                null
                                                                ? 'Not required as NA is selected'
                                                                : ''
                                                        }
                                                    >
                                                        <Box>
                                                            <FWCombinedInput
                                                                inputDisabled={
                                                                    lead_time_period ===
                                                                        'NA' ||
                                                                    lead_time_period ===
                                                                        null
                                                                }
                                                                required={true}
                                                                isDropLeft={
                                                                    false
                                                                }
                                                                allowOnly="NUMBER"
                                                                textFieldValue={
                                                                    lead_time_period ===
                                                                        'NA' ||
                                                                    lead_time_period ===
                                                                        null
                                                                        ? ''
                                                                        : lead_time
                                                                }
                                                                textFieldOnChange={(
                                                                    e: any
                                                                ) =>
                                                                    updateItemData(
                                                                        [
                                                                            {
                                                                                field: 'lead_time',
                                                                                value: e
                                                                                    .target
                                                                                    .value,
                                                                            },
                                                                        ]
                                                                    )
                                                                }
                                                                error={
                                                                    ('lead_time' in
                                                                        errors &&
                                                                        !errors
                                                                            .lead_time
                                                                            ?.suppress) ||
                                                                    ('lead_time_period' in
                                                                        errors &&
                                                                        !errors
                                                                            .lead_time_period
                                                                            ?.suppress)
                                                                }
                                                                helperText={
                                                                    'lead_time' in
                                                                        errors &&
                                                                    !errors
                                                                        .lead_time
                                                                        ?.suppress
                                                                        ? errors[
                                                                              'lead_time'
                                                                          ]!
                                                                              .fieldMessageText
                                                                        : 'lead_time_period' in
                                                                              errors &&
                                                                          !errors
                                                                              .lead_time_period
                                                                              ?.suppress
                                                                        ? errors[
                                                                              'lead_time_period'
                                                                          ]!
                                                                              .fieldMessageText
                                                                        : ''
                                                                }
                                                                selectFieldValue={
                                                                    isLeadTimeMandatory
                                                                        ? lead_time_period ??
                                                                          ''
                                                                        : lead_time_period ??
                                                                          'NA'
                                                                }
                                                                selectFieldOnChange={(
                                                                    e
                                                                ) =>
                                                                    updateItemData(
                                                                        [
                                                                            {
                                                                                field: 'lead_time_period',
                                                                                value: e
                                                                                    .target
                                                                                    .value,
                                                                            },
                                                                        ]
                                                                    )
                                                                }
                                                                selectFieldMenuItems={[
                                                                    <FWMenuItem
                                                                        value={
                                                                            'DAYS'
                                                                        }
                                                                        key={0}
                                                                    >
                                                                        Day(s)
                                                                    </FWMenuItem>,
                                                                    <FWMenuItem
                                                                        value={
                                                                            'WEEKS'
                                                                        }
                                                                        key={1}
                                                                    >
                                                                        {' '}
                                                                        Week(s)
                                                                    </FWMenuItem>,
                                                                    <FWMenuItem
                                                                        value={
                                                                            'MONTHS'
                                                                        }
                                                                        key={2}
                                                                    >
                                                                        {' '}
                                                                        Month(s)
                                                                    </FWMenuItem>,

                                                                    ...(!isLeadTimeMandatory
                                                                        ? [
                                                                              <FWMenuItem
                                                                                  value={
                                                                                      'NA'
                                                                                  }
                                                                                  key={
                                                                                      3
                                                                                  }
                                                                              >
                                                                                  <FWTypography>
                                                                                      NA
                                                                                      (None)
                                                                                  </FWTypography>
                                                                              </FWMenuItem>,
                                                                          ]
                                                                        : []),
                                                                ]}
                                                            />
                                                        </Box>
                                                    </FWTooltip>
                                                </Box>
                                            );
                                        case ItemCartTemplateStandardFieldsEnum.GR_TOLERANCE:
                                            return (
                                                <Box key={idx}>
                                                    <Box className=" flex flex-aic">
                                                        <FWTypography color="text.secondary">
                                                            {
                                                                templateDetails
                                                                    .fieldSettings
                                                                    .standardFields[
                                                                    fieldListItem
                                                                        .field
                                                                ].label
                                                            }
                                                        </FWTypography>
                                                        {templateDetails
                                                            .fieldSettings
                                                            .standardFields[
                                                            fieldListItem.field
                                                        ].description.length >
                                                            0 && (
                                                            <>
                                                                &nbsp;
                                                                <FWTooltip
                                                                    title={
                                                                        templateDetails
                                                                            .fieldSettings
                                                                            .standardFields[
                                                                            fieldListItem
                                                                                .field
                                                                        ]
                                                                            .description
                                                                    }
                                                                    placement="top"
                                                                >
                                                                    <Box
                                                                        color={
                                                                            'primary.main'
                                                                        }
                                                                    >
                                                                        <i className="bi bi-question-circle" />
                                                                    </Box>
                                                                </FWTooltip>
                                                            </>
                                                        )}
                                                    </Box>

                                                    <FWInput
                                                        allowOnly="DECIMAL_NUMBER"
                                                        value={
                                                            quantity_tolerance_percentage
                                                        }
                                                        onChange={(e) =>
                                                            updateItemData([
                                                                {
                                                                    field: 'quantity_tolerance_percentage',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            ])
                                                        }
                                                        maxNumberOfDecimal={4}
                                                        error={
                                                            'quantity_tolerance_percentage' in
                                                                errors &&
                                                            !errors
                                                                .quantity_tolerance_percentage
                                                                ?.suppress
                                                        }
                                                        helper={{
                                                            text:
                                                                'quantity_tolerance_percentage' in
                                                                    errors &&
                                                                !errors
                                                                    .quantity_tolerance_percentage
                                                                    ?.suppress
                                                                    ? errors.quantity_tolerance_percentage!
                                                                          .fieldMessageText
                                                                    : '',
                                                        }}
                                                        iconEnd={'%'}
                                                    />
                                                </Box>
                                            );

                                        default:
                                            return <></>;
                                    }
                                else return null;
                            } else {
                                return (
                                    <React.Fragment key={idx}>
                                        <CartItemCustomFields
                                            fieldName={fieldListItem.field}
                                            templateDetails={templateDetails}
                                            sectionName={
                                                templateDetails.sections
                                                    .PAYMENT_AND_DELIVERY_TERMS
                                                    .label
                                            }
                                            updateItemData={updateItemData}
                                            openEditCustomFieldPopup={() => {}}
                                            isCustomFieldFromTemplate={true}
                                        />
                                    </React.Fragment>
                                );
                            }
                        }
                    )}
                </Box>
                {templateDetails.fieldSettings.standardFields[
                    ItemCartTemplateStandardFieldsEnum.SHIPPING_ADDRESS
                ].buyerSettings.showField && (
                    <Box sx={{ width: '100%' }} marginTop={2}>
                        <Box className=" flex flex-aic">
                            <FWTypography color="text.secondary">
                                {
                                    templateDetails.fieldSettings
                                        .standardFields['SHIPPING_ADDRESS']
                                        .label
                                }
                            </FWTypography>
                            {templateDetails.fieldSettings.standardFields[
                                'SHIPPING_ADDRESS'
                            ].description.length > 0 && (
                                <>
                                    &nbsp;
                                    <FWTooltip
                                        title={
                                            templateDetails.fieldSettings
                                                .standardFields[
                                                'SHIPPING_ADDRESS'
                                            ].description
                                        }
                                        placement="top"
                                    >
                                        <Box color={'primary.main'}>
                                            <i className="bi bi-question-circle" />
                                        </Box>
                                    </FWTooltip>
                                </>
                            )}
                        </Box>

                        <FWAutocomplete
                            popupIcon={
                                <Box fontSize={'16px'}>
                                    <i className="bi bi-chevron-down"></i>
                                </Box>
                            }
                            noOptionsText={
                                <FWTypography>No addresses found</FWTypography>
                            }
                            fullWidth
                            disableClearable
                            disablePortal
                            getOptionLabel={(address: IAddresses) =>
                                `[${address.address.address_nickname}]:  ${address.address.full_address}`
                            }
                            options={shippingAddressOptions}
                            value={
                                shippingAddressOptions.find(
                                    (address) =>
                                        address.address.address_id ===
                                        shippingAddress?.shipping_address_id
                                ) ?? null
                            }
                            isOptionEqualToValue={(option, value) =>
                                option.entry_id === value.entry_id
                            }
                            onChange={(e, newValue: IAddresses) => {
                                if (newValue !== null) {
                                    updateItemData([
                                        {
                                            field: 'shipping_address',
                                            value: {
                                                shipping_address_id:
                                                    newValue.address.address_id,
                                                shipping_full_address:
                                                    newValue.address
                                                        .full_address,
                                            },
                                        },
                                    ]);
                                }
                            }}
                            renderInput={(params) => (
                                <FWInput
                                    {...params}
                                    required
                                    error={'shipping_address' in errors}
                                    helper={{
                                        text:
                                            'shipping_address' in errors
                                                ? errors.shipping_address!
                                                      .fieldMessageText
                                                : '',
                                    }}
                                />
                            )}
                            renderOption={(props: any, option: IAddresses) => {
                                const isDisabled =
                                    option.status === 'INACTIVE' ||
                                    option.address.status === 'INACTIVE';
                                return (
                                    <FWMenuItem
                                        {...props}
                                        disabled={isDisabled}
                                        sx={{
                                            fontStyle: isDisabled
                                                ? 'italic'
                                                : undefined,
                                            color: isDisabled
                                                ? 'text.secondary'
                                                : undefined,
                                        }}
                                    >
                                        <FWTooltip
                                            title={
                                                isDisabled
                                                    ? 'Disabled from admin settings'
                                                    : ''
                                            }
                                        >
                                            <div>
                                                <FWTypography
                                                    sx={{
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'pre-wrap',
                                                    }}
                                                >
                                                    {`${
                                                        isDisabled
                                                            ? '(Disabled) '
                                                            : ''
                                                    }[${
                                                        option.address
                                                            .address_nickname
                                                    }]:  ${
                                                        option.address
                                                            .full_address
                                                    }`}
                                                </FWTypography>
                                            </div>
                                        </FWTooltip>
                                    </FWMenuItem>
                                );
                            }}
                        />
                    </Box>
                )}
            </AccordionDetails>
        </Accordion>
    );
}
