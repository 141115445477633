import { Badge, Box, Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { FWLink } from '../../Common/FWLink';
import FWPopover from '../../Common/FWPopover';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import { getFWDate } from '../../Common/Utils/DateUtils';
import { useGetListOfAllCostCenterQuery } from '../../ProjectGlCostCenter/services/costCenter.services';
import { useListGeneralLedgerQuery } from '../../ProjectGlCostCenter/services/generalLedger.services';
import { useListProjectsQuery } from '../../ProjectGlCostCenter/services/project.services';
import {
    getBomHierarachyLabelFromBoms,
    getMostDeepestSubBomCode,
    // getMostDeepestSubBomCode,
} from '../../Events/RFQAnalytics/Helpers/BomDsHelper';
import { IBomItemDeliverScheduleStructure } from '../../Events/Interfaces/Buyer/RFQ.model';
import { useLocation } from 'react-router-dom';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import {
    IRfqTemplate,
    TemplateStandardFieldsEnum,
} from '../../Global/Interfaces/TemplateInterface';

interface IDeliveryPopup {
    deliverySchedule: {
        bom_item?: IBomItemDeliverScheduleStructure | null;
        delivery_date: string | null;
        quantity: string | number;
        project_id?: string | null;
        cost_centre_id?: string | null;
        general_ledger_id?: string | null;
        parent_delivery_schedule_item?: any;
    }[];
    measurement_unit?: string;
    button_variant?: 'contained' | 'text' | 'outlined';
    button_color?: 'primary' | 'secondary' | 'success' | 'error' | 'cancelled';
    template_deliveryLabel?: {
        quantityLabel: string;
        dateLabel: string;
        projectLabel: string;
        costCenterLabel: string;
        glLabel: string;
    };
    showProject: boolean;
    showCostCenter: boolean;
    showGL: boolean;
    showBom?: boolean;
    rfqTemplate?: IRfqTemplate | null;
}

const DeliveryPopUp = ({
    deliverySchedule,
    measurement_unit,
    button_variant = 'contained',
    button_color = 'primary',
    template_deliveryLabel = {
        quantityLabel: 'Quantity',
        dateLabel: 'Date',
        projectLabel: 'Project',
        costCenterLabel: 'Cost Center',
        glLabel: 'General Ledger',
    },
    showProject = true,
    showCostCenter = true,
    showGL = true,
    showBom = true,
    rfqTemplate = null,
}: IDeliveryPopup) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'delivery-schedule-popover' : undefined;
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };
    const { pathname } = useLocation();
    const handleClose = () => setAnchorEl(null);

    const { data: listOfProjects } = useListProjectsQuery(
        {},
        { skip: showProject === false }
    );
    const { data: listOfCostCenter } = useGetListOfAllCostCenterQuery(
        {},
        { skip: showCostCenter === false }
    );
    const { data: listOfGeneralLedger } = useListGeneralLedgerQuery(
        {},
        { skip: showGL === false }
    );
    const sourceType = useMemo(() => {
        let d = deliverySchedule.find((d) => d.parent_delivery_schedule_item);
        let type = '';
        if (d) {
            type =
                d.parent_delivery_schedule_item?.requisition_item
                    .requisition_source_type === 'DEFAULT'
                    ? 'Requisition ID'
                    : d.parent_delivery_schedule_item?.requisition_item
                          .requisition_source_type === 'ORDER_REQUEST'
                    ? 'Order ID'
                    : d.parent_delivery_schedule_item?.requisition_item
                          .requisition_source_type === 'CART'
                    ? 'Cart ID'
                    : '';
        }
        return type;
    }, [deliverySchedule]);
    const showCartOrder = deliverySchedule.some(
        (data) =>
            data.parent_delivery_schedule_item?.delivery_schedule_item_id !==
            undefined
    );
    const showDeliveryDate = useMemo(() => {
        if (rfqTemplate) {
            return pathname.includes('seller')
                ? rfqTemplate.fieldSettings.standardFields[
                      TemplateStandardFieldsEnum.DELIVERY_DATE
                  ].sellerSettings.showField
                : rfqTemplate.fieldSettings.standardFields[
                      TemplateStandardFieldsEnum.DELIVERY_DATE
                  ].buyerSettings.showField;
        }
        return true;
    }, [pathname, rfqTemplate]);

    const caculateAmountOfGridSize = () => {
        let noOfhidden = 0;
        if (!showCostCenter) {
            noOfhidden += 1;
        }
        if (!showProject) {
            noOfhidden += 1;
        }
        if (!showGL) {
            noOfhidden += 1;
        }
        if (!showCartOrder) {
            noOfhidden += 1;
        }
        if (
            !(
                showBom &&
                rfqTemplate?.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.BOM
                ].buyerSettings.showField
            ) ||
            pathname.includes('seller')
        ) {
            noOfhidden += 1;
        }
        if (!showDeliveryDate) {
            noOfhidden += 1;
        }
        return 12 / (7 - noOfhidden);
    };

    return (
        <>
            {/* <IconButton color="primary" onClick={(e: any) => handleClick(e)}>
       {showTruck? <i className="bi bi-truck"></i>:<FWTypography sx={{ color: "primary.main" }}>{title}</FWTypography>}
      </IconButton> */}
            {deliverySchedule.length === 1 ? (
                deliverySchedule[0].delivery_date ? (
                    <FWLink
                        onClick={(e: any) => handleClick(e)}
                        sx={
                            button_color === 'cancelled'
                                ? {
                                      textDecoration: 'line-through',
                                      color: 'text.secondary',
                                  }
                                : button_color === 'secondary'
                                ? {
                                      color: 'text.secondary',
                                  }
                                : button_color === 'error'
                                ? {
                                      color: 'error.man',
                                  }
                                : {}
                        }
                    >
                        {getFWDate(deliverySchedule[0].delivery_date)}
                    </FWLink>
                ) : (
                    <FWLink
                        onClick={(e: any) => {
                            handleClick(e);
                        }}
                        sx={
                            button_color === 'cancelled'
                                ? {
                                      textDecoration: 'line-through',
                                      color: 'text.secondary',
                                  }
                                : button_color === 'secondary'
                                ? {
                                      color: 'text.secondary',
                                  }
                                : button_color === 'error'
                                ? {
                                      color: 'error.man',
                                  }
                                : {}
                        }
                    >
                        {showDeliveryDate ? 'TBD' : '1 lot'}
                    </FWLink>
                )
            ) : (
                <FWLink
                    sx={
                        button_color === 'cancelled'
                            ? {
                                  textDecoration: 'line-through!important',
                                  color: 'text.secondary',
                              }
                            : button_color === 'secondary'
                            ? {
                                  color: 'text.secondary',
                              }
                            : button_color === 'error'
                            ? {
                                  color: 'error.main',
                              }
                            : {}
                    }
                    onClick={(e: any) => handleClick(e)}
                    id={'rfqInvitation_deliverySchedule'}
                >
                    {`${deliverySchedule.length} lots`}
                </FWLink>
            )}
            <FWPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid
                    container
                    columnSpacing={1}
                    rowSpacing={1}
                    style={{ width: '50vw' }}
                    alignItems={'center'}
                >
                    {/* <Grid item md={12}>
                        <FWTypography>Delivery Schedule</FWTypography>
                    </Grid> */}
                    <Grid
                        item
                        md={caculateAmountOfGridSize()}
                        style={{
                            width: 'fit-content',
                        }}
                    >
                        <FWTypography color="text.secondary">
                            {template_deliveryLabel.quantityLabel}
                        </FWTypography>
                    </Grid>
                    {showDeliveryDate && (
                        <Grid
                            item
                            md={caculateAmountOfGridSize()}
                            style={{
                                width: 'fit-content',
                            }}
                        >
                            <FWTypography color="text.secondary">
                                {template_deliveryLabel.dateLabel}
                            </FWTypography>
                        </Grid>
                    )}
                    {showBom &&
                        (rfqTemplate === null ||
                            rfqTemplate.fieldSettings.standardFields[
                                TemplateStandardFieldsEnum.BOM
                            ].buyerSettings.showField) &&
                        !pathname.includes('seller') && (
                            <Grid
                                item
                                md={caculateAmountOfGridSize()}
                                style={{
                                    width: 'fit-content',
                                }}
                            >
                                <FWTypography color="text.secondary">
                                    {/* use template details  */}
                                    BOM ID
                                </FWTypography>
                            </Grid>
                        )}
                    {showProject && (
                        <Grid
                            item
                            md={caculateAmountOfGridSize()}
                            style={{
                                width: 'fit-content',
                            }}
                        >
                            <FWTypography color="text.secondary">
                                {/* use template details  */}

                                {template_deliveryLabel.projectLabel}
                            </FWTypography>
                        </Grid>
                    )}
                    {showCostCenter && (
                        <Grid
                            item
                            md={caculateAmountOfGridSize()}
                            style={{
                                width: 'fit-content',
                            }}
                        >
                            <FWTypography color="text.secondary">
                                {/* use template details  */}
                                {template_deliveryLabel.costCenterLabel}
                            </FWTypography>
                        </Grid>
                    )}
                    {showGL && (
                        <Grid
                            item
                            md={caculateAmountOfGridSize()}
                            style={{
                                width: 'fit-content',
                            }}
                        >
                            <FWTypography color="text.secondary">
                                {/* use template details  */}
                                {template_deliveryLabel.glLabel}
                            </FWTypography>
                        </Grid>
                    )}
                    {showCartOrder && (
                        <Grid
                            item
                            md={caculateAmountOfGridSize()}
                            style={{
                                width: 'fit-content',
                            }}
                        >
                            <FWTypography color="text.secondary">
                                {/* use template details  */}
                                {sourceType}
                            </FWTypography>
                        </Grid>
                    )}
                    {deliverySchedule.map((delivery, idx) => (
                        <React.Fragment key={idx}>
                            <Grid
                                item
                                md={caculateAmountOfGridSize()}
                                style={{
                                    width: 'fit-content',
                                }}
                            >
                                <FWTypography textAlign="left">
                                    {FWNumberFormatter(
                                        Number(delivery.quantity)
                                    )}{' '}
                                    {measurement_unit}(s)
                                </FWTypography>
                            </Grid>
                            {showDeliveryDate && (
                                <>
                                    {delivery.delivery_date !== null ? (
                                        <Grid
                                            item
                                            md={caculateAmountOfGridSize()}
                                            style={{
                                                width: 'fit-content',
                                            }}
                                        >
                                            <FWTypography>
                                                {getFWDate(
                                                    delivery.delivery_date
                                                )}
                                            </FWTypography>
                                        </Grid>
                                    ) : (
                                        <Grid
                                            item
                                            md={caculateAmountOfGridSize()}
                                            style={{
                                                width: 'fit-content',
                                            }}
                                        >
                                            <FWTypography>TBD</FWTypography>
                                        </Grid>
                                    )}
                                </>
                            )}

                            {showBom &&
                                (rfqTemplate === null ||
                                    rfqTemplate.fieldSettings.standardFields[
                                        TemplateStandardFieldsEnum.BOM
                                    ].buyerSettings.showField) &&
                                !pathname.includes('seller') && (
                                    <Grid
                                        item
                                        xs={caculateAmountOfGridSize()}
                                        // className="pl--10 "
                                        // marginTop={'8px'}
                                    >
                                        <FWTypography>
                                            <div className="flex flex--row flex--aic">
                                                {/* <FWTypography> */}
                                                <FWTooltip
                                                    title={
                                                        delivery.bom_item
                                                            ? getBomHierarachyLabelFromBoms(
                                                                  delivery.bom_item
                                                              )
                                                            : ''
                                                    }
                                                    placement="top"
                                                >
                                                    <Box paddingTop={0.5}>
                                                        <Badge
                                                            badgeContent={
                                                                delivery.bom_item
                                                                    ? getMostDeepestSubBomCode(
                                                                          delivery
                                                                              .bom_item
                                                                              .parent_sub_bom_item
                                                                      )?.depth
                                                                    : undefined
                                                            }
                                                        >
                                                            {delivery.bom_item
                                                                ? delivery
                                                                      .bom_item
                                                                      .parent_sub_bom_item
                                                                    ? getMostDeepestSubBomCode(
                                                                          delivery
                                                                              .bom_item
                                                                              .parent_sub_bom_item
                                                                      )
                                                                        ? getMostDeepestSubBomCode(
                                                                              delivery
                                                                                  .bom_item
                                                                                  .parent_sub_bom_item
                                                                          )
                                                                              ?.bomCode
                                                                        : '-'
                                                                    : delivery
                                                                          .bom_item
                                                                          .enterprise_bom
                                                                          ?.bom_code ??
                                                                      '-'
                                                                : '-'}
                                                        </Badge>
                                                    </Box>
                                                </FWTooltip>
                                                {/* </FWTypography> */}

                                                {/* {delivery.bom_item &&
                                                    delivery.bom_item
                                                        .parent_sub_bom_item &&
                                                    getMostDeepestSubBomCode(
                                                        delivery.bom_item
                                                            .parent_sub_bom_item
                                                    ) && (
                                                        <>
                                                            ,&nbsp;
                                                            <FWTooltip
                                                                title={getBomHierarachyLabelFromBoms(
                                                                    delivery.bom_item
                                                                )}
                                                                placement="top"
                                                            >
                                                                <Box>
                                                                    <FWTypography>
                                                                        Depth:{' '}
                                                                        {getMostDeepestSubBomCode(
                                                                            delivery
                                                                                .bom_item
                                                                                .parent_sub_bom_item
                                                                        )
                                                                            ?.depth ??
                                                                            '-'}
                                                                    </FWTypography>
                                                                </Box>
                                                            </FWTooltip>
                                                        </>
                                                    )} */}
                                            </div>
                                        </FWTypography>
                                    </Grid>
                                )}
                            {showProject && (
                                <Grid
                                    item
                                    md={caculateAmountOfGridSize()}
                                    style={{
                                        width: 'fit-content',
                                    }}
                                >
                                    <FWTypography>
                                        {listOfProjects?.find(
                                            (project) =>
                                                project.projectUUid ===
                                                delivery.project_id
                                        )?.projectCode ?? 'NA(None)'}
                                    </FWTypography>
                                </Grid>
                            )}

                            {showCostCenter && (
                                <Grid
                                    item
                                    md={caculateAmountOfGridSize()}
                                    style={{
                                        width: 'fit-content',
                                    }}
                                >
                                    <FWTypography>
                                        {listOfCostCenter?.find(
                                            (cc) =>
                                                cc.costCenterUid ===
                                                delivery.cost_centre_id
                                        )?.costCenterId ?? 'NA(None)'}
                                    </FWTypography>
                                </Grid>
                            )}
                            {showGL && (
                                <Grid
                                    item
                                    md={caculateAmountOfGridSize()}
                                    style={{
                                        width: 'fit-content',
                                    }}
                                >
                                    <FWTypography>
                                        {listOfGeneralLedger?.find(
                                            (gl) =>
                                                gl.glUid ===
                                                delivery.general_ledger_id
                                        )?.glCode ?? 'NA(None)'}
                                    </FWTypography>
                                </Grid>
                            )}
                            {showCartOrder && (
                                <Grid
                                    item
                                    md={caculateAmountOfGridSize()}
                                    style={{
                                        width: 'fit-content',
                                    }}
                                >
                                    <FWTypography>
                                        {delivery.parent_delivery_schedule_item
                                            ?.requisition_item
                                            .custom_requisition_id ?? '-'}
                                    </FWTypography>
                                </Grid>
                            )}
                        </React.Fragment>
                    ))}
                </Grid>
            </FWPopover>
        </>
    );
};

export default DeliveryPopUp;
