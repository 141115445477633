import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import { capitaliseFirstLetter } from '../../BuyerTemplates/Component/TemplateSuggestedFieldsSection';
import { FWCurrencyTypoGraphyWithCurrencyUid } from '../../Common/CurrencyUtilsComponent';
import { FWTypography } from '../../Common/FWTypograhy';
import { getFWDate, getFWDateTime } from '../../Common/Utils/DateUtils';
import { IFileResourceType } from '../../Components/Shared/UploadFile';
import TagsDisplay from '../../Global/Components/TagsDisplay';
import EditAttachmentsButtonAndPopover from '../../Global/Containers/EditAttachmentsButtonAndPopover';
import { ICurrencyDetails } from '../../Models/Currency.model';
import { IAttachment } from '../../Models/RFQ.model';
import { CustomFieldType } from '../../SRM/Constants/Interfaces/documentTemplate';
import ProjectManagerCell from '../components/ProjectManagerPopover';
import IProject, {
    DASHBOARD_FIELD,
    DASHBOARD_FIELD_COLUMN,
    DASHBOARD_FIELD_NAME,
    DASHBOARD_FIELD_TYPE,
    DASHBOARD_SECTION_NAME,
    IMultipleProjectTemplateApiResponse,
    IProjectCustomField,
    IprojectDashboardContext,
    ProjectCustomFieldSectionFieldType,
    ProjectStatusEnum,
    TEMPLATE_FIELD_TYPE,
    TProjectUsersList,
} from '../interface/project.model';
import { MenuRender } from '../pages/ProjectDashBoard';
// import CustomTableFilter from '../../Events/RFQAnalytics/Containers/CustomTableFilter';

import { forwardRef, useImperativeHandle, useState } from 'react';
import { PROJECT_DASHBOARD_FIELDS_NOT_IN_TEMPLATE } from '../constants/projectDashboardConstant';
import { IUserPermissionFEStructure } from '../../BuyerTemplates/Component/ShareSectionPopup';
// import FWSelect from '../../Common/FWSelect';

export interface IProjectField {
    fieldNameInTemplate: string;
    fieldNameInPayload: string;
    is_built_in: boolean;
    sectionName: string;
    sectionAlternameName: string;
    type: ProjectCustomFieldSectionFieldType;
    choices: string[];
}

export interface IFieldTypeLabel {
    label: string;
    fieldLabelWithSectionName: string;
    column: string;
    fieldType: string;
    is_built_in: boolean | null;
    sectionName: string | null;
    sectionAlternameName: string | null;
    choices: string[];
    isFieldFWFieldInCustomSection: boolean;
}

export const COMMON_FIELDS_ACROSS_SECTIONS = [
    'Deadline date',
    'Attachments',
    'Status',
];

export const PROJECT_DASHBOARD_FIELD_DELIMITER = '<;|;>';
export const PROJECT_DASHBOARD_MAX_FIELD_WIDTH = 200;

export const getProjectDashboardFields = (
    projectTemplateList: IMultipleProjectTemplateApiResponse[],
    filterByTemplateAndSelection: string[],
    listOffAllUserList: TProjectUsersList,
    currentTab: string
) => {
    // const [filterByTemplate, filterBySection] =
    //     filterByTemplateAndSelection.split(PROJECT_DASHBOARD_FIELD_DELIMITER);

    const filterByTemplates = filterByTemplateAndSelection.map(
        (selection) => selection.split(PROJECT_DASHBOARD_FIELD_DELIMITER)[0]
    );

    const filterBySections = filterByTemplateAndSelection.map(
        (selection) => selection.split(PROJECT_DASHBOARD_FIELD_DELIMITER)[1]
    );

    let defaultTemplateDetails: {
        PROJECT_CODE: string;
        PROJECT_NAME: string;
    } = {
        PROJECT_CODE: 'Project Code',
        PROJECT_NAME: 'Project Name',
    };

    let listOfAllFieldsAcrossTemplate: string[] = [];

    let fieldsInAllTemplate: {
        [templateId: string]: string[];
    } = {};

    let fieldSettingsInAllTemplate: {
        [templateId: string]: {
            [fieldName: string]: IProjectField;
        };
    } = {};

    let templateAndSectionList: string[] = [];

    projectTemplateList[0]?.templates
        ?.filter((template) => template.status === 'ONGOING')
        ?.forEach((template) => {
            if (template.is_default) {
            }
            template.section_list.forEach((section) => {
                const sectionAlternameName = section.alternate_name;

                const templateSectionLabel = `${template.name}${PROJECT_DASHBOARD_FIELD_DELIMITER}${sectionAlternameName}`;

                templateAndSectionList = [
                    ...templateAndSectionList,
                    templateSectionLabel,
                ];
            });
        });

    projectTemplateList[0]?.templates
        ?.filter((template) => template.status === 'ONGOING')
        ?.filter((template) =>
            filterByTemplates?.length === 0
                ? true
                : filterByTemplates.includes(template.name)
        )
        ?.forEach((template) => {
            if (fieldsInAllTemplate[template.template_id] === undefined) {
                fieldsInAllTemplate[template.template_id] = [];
            }

            if (
                fieldSettingsInAllTemplate[template.template_id] === undefined
            ) {
                fieldSettingsInAllTemplate[template.template_id] = {};
            }

            template.section_list
                .filter((section) =>
                    filterBySections?.length === 0
                        ? true
                        : filterBySections.includes(section.alternate_name)
                )
                .forEach((section) => {
                    const sectionName = section.name;
                    const sectionAlternameName = section.alternate_name;

                    section.section_items.forEach((sectionItem) => {
                        const fieldAlternateName =
                            sectionItem.alternate_name[
                                sectionItem.alternate_name.length - 1
                            ] === '_'
                                ? sectionItem.alternate_name.slice(0, -1)
                                : sectionItem.alternate_name;

                        if (sectionItem.name === 'Project code') {
                            defaultTemplateDetails.PROJECT_CODE =
                                fieldAlternateName;
                        }

                        if (sectionItem.name === 'Project name') {
                            defaultTemplateDetails.PROJECT_NAME =
                                fieldAlternateName;
                        }

                        const fieldName = createFieldNameUsingDelimiter(
                            sectionAlternameName,
                            sectionName,
                            fieldAlternateName,
                            sectionItem.name,
                            sectionItem.is_builtin_field,
                            sectionItem.constraints.field_type ===
                                ProjectCustomFieldSectionFieldType.CHOICE
                                ? sectionItem.constraints.choice_type ===
                                  'DROPDOWN'
                                    ? ProjectCustomFieldSectionFieldType.CHOICE
                                    : ProjectCustomFieldSectionFieldType.MULTICHOICE
                                : sectionItem.constraints.field_type
                        );

                        fieldsInAllTemplate[template.template_id] = [
                            ...fieldsInAllTemplate[template.template_id],
                            fieldName,
                        ];

                        const fieldSetting: IProjectField = {
                            choices:
                                sectionItem.constraints.field_type ===
                                ProjectCustomFieldSectionFieldType.CHOICE
                                    ? sectionItem.constraints.choices
                                    : [],
                            fieldNameInPayload: sectionItem.alternate_name,
                            fieldNameInTemplate: sectionItem.name,
                            type:
                                sectionItem.constraints.field_type ===
                                ProjectCustomFieldSectionFieldType.CHOICE
                                    ? sectionItem.constraints.choice_type ===
                                      'DROPDOWN'
                                        ? ProjectCustomFieldSectionFieldType.CHOICE
                                        : ProjectCustomFieldSectionFieldType.MULTICHOICE
                                    : sectionItem.constraints.field_type,
                            is_built_in: sectionItem.is_builtin_field,
                            sectionAlternameName: sectionAlternameName,
                            sectionName: sectionName,
                        };
                        fieldSettingsInAllTemplate[template.template_id][
                            fieldName
                        ] = fieldSetting;

                        listOfAllFieldsAcrossTemplate = [
                            ...listOfAllFieldsAcrossTemplate,
                            fieldName,
                        ];
                    });

                    fieldsInAllTemplate[template.template_id] = Array.from(
                        new Set(fieldsInAllTemplate[template.template_id])
                    );
                });
        });

    listOfAllFieldsAcrossTemplate = Array.from(
        new Set(listOfAllFieldsAcrossTemplate)
    );

    const fieldTypeLabels = [
        ...extractFieldTypeLabels(fieldSettingsInAllTemplate),
        ...PROJECT_DASHBOARD_FIELDS_NOT_IN_TEMPLATE(currentTab),
    ];

    const fieldTypeLabelWithOption = getFieldTypeLabelsWithOptions(
        fieldTypeLabels,
        listOffAllUserList
    );

    return {
        fieldsInAllTemplate,
        fieldSettingsInAllTemplate,
        listOfAllFieldsAcrossTemplate,
        templateAndSectionList,
        defaultTemplateDetails,
        fieldTypeLabels: fieldTypeLabelWithOption,
    };
};

const getFieldTypeLabelsWithOptions = (
    fieldTypeLabels: IFieldTypeLabel[],
    listOffAllUserList: TProjectUsersList
) => {
    const fieldTypeLabelsWithOptions = fieldTypeLabels.map((option) => {
        if (option.column === 'Project Manager(s)') {
            let updatedOption = cloneDeep(option);
            updatedOption.choices = listOffAllUserList.project_managers
                .filter(
                    (creator) =>
                        Boolean(creator.name) && Boolean(creator.user_id)
                )
                .map(
                    (manager) =>
                        `${manager.name}${PROJECT_DASHBOARD_FIELD_DELIMITER}${manager.user_id}`
                );

            return updatedOption;
        } else if (option.column === 'Customer') {
            let updatedOption = cloneDeep(option);
            updatedOption.choices = listOffAllUserList.customers
                .filter(
                    (customer) =>
                        Boolean(customer.customer_entity_name) &&
                        Boolean(customer.customer_entity_id)
                )
                .map(
                    (customer) =>
                        `${customer.customer_entity_name}${PROJECT_DASHBOARD_FIELD_DELIMITER}${customer.customer_entity_id}`
                );
            return updatedOption;
        } else if (option.column === 'Creator') {
            let updatedOption = cloneDeep(option);
            updatedOption.choices = listOffAllUserList.created_by_users
                .filter(
                    (creator) =>
                        Boolean(creator.name) && Boolean(creator.user_id)
                )
                .map(
                    (creator) =>
                        `${creator.name}${PROJECT_DASHBOARD_FIELD_DELIMITER}${creator.user_id}`
                );
            return updatedOption;
        } else if (option.column === 'Last modified by') {
            let updatedOption = cloneDeep(option);
            updatedOption.choices = listOffAllUserList.modified_by_users
                .filter(
                    (modifiedBy) =>
                        Boolean(modifiedBy.name) && Boolean(modifiedBy.user_id)
                )
                .map(
                    (modifiedBy) =>
                        `${modifiedBy.name}${PROJECT_DASHBOARD_FIELD_DELIMITER}${modifiedBy.user_id}`
                );
            return updatedOption;
        } else if (option.column === 'Submitted by') {
            let updatedOption = cloneDeep(option);
            updatedOption.choices = listOffAllUserList.submitted_by_users
                .filter(
                    (submittedBy) =>
                        Boolean(submittedBy.name) &&
                        Boolean(submittedBy.user_id)
                )
                .map(
                    (submittedBy) =>
                        `${submittedBy.name}${PROJECT_DASHBOARD_FIELD_DELIMITER}${submittedBy.user_id}`
                );
            return updatedOption;
        }
        return option;
    });

    let projectSectionNameField: IFieldTypeLabel = {
        label: 'Project Section Name',
        fieldLabelWithSectionName: 'Project Section Name',
        column: 'section_name',
        fieldType: 'MULTI_CHOICE',
        is_built_in: null,
        sectionName: null,
        sectionAlternameName: null,
        choices: listOffAllUserList.section_names.map((sectionName) => {
            return Object.values(sectionName)[0];
        }),
        isFieldFWFieldInCustomSection: false,
    };

    let projectSectionSubmissionStatusField: IFieldTypeLabel = {
        label: 'Section Submission Status',
        fieldLabelWithSectionName: 'Section Submission Status',
        column: 'section_submission_status',
        fieldType: 'MULTI_CHOICE',
        is_built_in: null,
        sectionName: null,
        sectionAlternameName: null,
        choices: ['DRAFT', 'SUBMITTED', 'REVISED', 'PENDING'],
        isFieldFWFieldInCustomSection: false,
    };

    let fieldTypeLabelsWithOptionsCopy = cloneDeep(fieldTypeLabelsWithOptions);
    fieldTypeLabelsWithOptionsCopy.push(projectSectionNameField);
    fieldTypeLabelsWithOptionsCopy.push(projectSectionSubmissionStatusField);
    return fieldTypeLabelsWithOptionsCopy;
};

const extractFieldTypeLabels = (fieldSettingsInAllTemplate: {
    [templateId: string]: { [fieldName: string]: IProjectField };
}) => {
    let fieldTypeLabels: IFieldTypeLabel[] = [];

    Object.values(fieldSettingsInAllTemplate).forEach((templateFields) => {
        Object.values(templateFields).forEach((fieldSetting) => {
            const currentColumnIndex = fieldTypeLabels.findIndex(
                (col) =>
                    col.column ===
                        getFieldColumn(fieldSetting.fieldNameInTemplate) &&
                    col.sectionName === fieldSetting.sectionName
            );
            const isBuiltIn = getFieldBuiltInStatus(fieldSetting);
            if (currentColumnIndex === -1)
                fieldTypeLabels.push({
                    label: cleanUpFieldName(fieldSetting.fieldNameInPayload),
                    column: getFieldColumn(fieldSetting.fieldNameInTemplate),
                    fieldLabelWithSectionName:
                        getFieldLabelWithSectionName(fieldSetting),
                    fieldType:
                        fieldSetting.type === 'MULTICHOICE'
                            ? 'MULTI_CHOICE'
                            : fieldSetting.type,
                    is_built_in: isBuiltIn,
                    sectionName:
                        fieldSetting.is_built_in &&
                        fieldSetting?.sectionName === 'Project details'
                            ? null
                            : fieldSetting?.sectionName,
                    sectionAlternameName:
                        fieldSetting.is_built_in &&
                        fieldSetting?.sectionName === 'Project details'
                            ? null
                            : fieldSetting.sectionAlternameName,
                    choices: fieldSetting?.choices ?? [],
                    isFieldFWFieldInCustomSection:
                        fieldSetting.is_built_in &&
                        fieldSetting?.sectionName !== 'Project details',
                });
        });
    });

    return fieldTypeLabels;
};

const getFieldColumn = (fieldNameInTemplate: string) => {
    if (fieldNameInTemplate === 'Project managers') return 'Project Manager(s)';
    if (fieldNameInTemplate === 'Customer name') return 'Customer name';

    return fieldNameInTemplate;
};

export const cleanUpFieldName = (fieldName: string) => {
    return fieldName[fieldName.length - 1] === '_'
        ? fieldName.slice(0, -1)
        : fieldName;
};

const getFieldBuiltInStatus = (fieldSetting: IProjectField) => {
    return fieldSetting.is_built_in
        ? fieldSetting.sectionName === 'Project details'
        : fieldSetting.is_built_in;
};

const getFieldLabelWithSectionName = (fieldSetting: IProjectField) => {
    const isFieldFWFieldInCustomSection =
        fieldSetting.is_built_in &&
        fieldSetting?.sectionName !== 'Project details';

    return isFieldFWFieldInCustomSection
        ? `${fieldSetting.sectionAlternameName} ${cleanUpFieldName(
              fieldSetting.fieldNameInPayload
          )}`
        : `${cleanUpFieldName(fieldSetting.fieldNameInPayload)}`;
};

export const getFieldSettingFromTemplateById = (
    templateId: string,
    fieldName: string,
    fieldSettingsInAllTemplate: {
        [templateId: string]: {
            [fieldName: string]: IProjectField;
        };
    },
    fieldsInAllTemplate: {
        [templateId: string]: string[];
    }
) => {
    let fieldSettingInTemplate: IProjectField[] = [];

    const allTemplateFields = fieldsInAllTemplate[templateId];

    if (allTemplateFields !== undefined) {
        const allSectionsWithCurrentFieldName = allTemplateFields.filter(
            (fields) =>
                fields.split(PROJECT_DASHBOARD_FIELD_DELIMITER)[1] === fieldName
        );

        const allFieldsSettingsInCurrentTemplate =
            fieldSettingsInAllTemplate[templateId];

        if (allFieldsSettingsInCurrentTemplate !== undefined) {
            allSectionsWithCurrentFieldName.forEach((field) => {
                const fieldSetting = allFieldsSettingsInCurrentTemplate[field];
                fieldSettingInTemplate.push(fieldSetting);
            });
        }
    }

    return fieldSettingInTemplate;
};

interface CustomFilterProps {
    colDef: ColDef;
    filterChangedCallback: () => void;
    valueList: string[];
    context: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomFilter = forwardRef((props: CustomFilterProps, ref) => {
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    useImperativeHandle(ref, () => ({
        isFilterActive() {
            return selectedValues.length > 0;
        },
        doesFilterPass(params: any) {
            return true;
        },
        getModel() {
            return { values: selectedValues };
        },
        setModel(model: any) {
            setSelectedValues(model ? model.values : []);
        },
        onFloatingFilterChanged(type: any, value: any) {
            setSelectedValues(value);
        },
    }));

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSelectedValues((prevSelectedValues) => {
            if (prevSelectedValues.includes(value)) {
                return prevSelectedValues.filter((v) => v !== value);
            } else {
                return [...prevSelectedValues, value];
            }
        });
        props.filterChangedCallback();
    };

    return (
        <Grid
            container
            direction="column"
            style={{
                padding: '1em',
                width: 225,
                height: '250px',
                // overflow: 'hidden',
                flexWrap: 'nowrap',
            }}
        >
            <FWTypography>Select values:</FWTypography>
            {props.context.multiSelectDropDown[props.colDef.headerName!].map(
                (value: string) => (
                    <FormControlLabel
                        key={value}
                        style={{
                            fontSize: '12px',
                            padding: '0.1em',
                            width: '100%',
                        }}
                        control={
                            <Checkbox
                                checked={selectedValues.includes(value)}
                                onChange={onValueChange}
                                value={value}
                                color="primary"
                                style={{ fontSize: '12px' }}
                                size="small"
                            />
                        }
                        label={value}
                    />
                )
            )}
        </Grid>
    );
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomPmFilter = forwardRef((props: CustomFilterProps, ref) => {
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    useImperativeHandle(ref, () => ({
        isFilterActive() {
            return selectedValues.length > 0;
        },
        doesFilterPass(params: any) {
            return true;
        },
        getModel() {
            return { values: selectedValues };
        },
        setModel(model: any) {
            setSelectedValues(model ? model.values : []);
        },
        onFloatingFilterChanged(type: any, value: any) {
            setSelectedValues(value);
        },
    }));

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSelectedValues((prevSelectedValues) => {
            if (prevSelectedValues.includes(value)) {
                return prevSelectedValues.filter((v) => v !== value);
            } else {
                return [...prevSelectedValues, value];
            }
        });
        props.filterChangedCallback();
    };

    return (
        <Grid
            container
            direction="column"
            style={{
                padding: '1em',
                width: 225,
                height: '250px',
                // overflow: 'hidden',
                flexWrap: 'nowrap',
            }}
        >
            <FWTypography>Select values:</FWTypography>
            {props.context.projectManagerList.map(
                (projectManager: IUserPermissionFEStructure) => (
                    <FormControlLabel
                        key={projectManager.user_id}
                        style={{
                            fontSize: '12px',
                            padding: '0.1em',
                            width: '100%',
                        }}
                        control={
                            <Checkbox
                                checked={selectedValues.includes(
                                    projectManager.user_id
                                )}
                                onChange={onValueChange}
                                value={projectManager.user_id}
                                color="primary"
                                style={{ fontSize: '12px' }}
                                size="small"
                            />
                        }
                        label={projectManager.name}
                    />
                )
            )}
        </Grid>
    );
});

export interface IFieldInfo {
    headerName: string;
    field: string;
    sortable: boolean;
    filter?: any;
    cellRenderer: (data: any) => JSX.Element;
    flex?: number;
    hide: boolean;
    width?: number;
}

const getFieldRenderFromType = (
    isFieldPresentInPayload: IProjectCustomField,
    data: IProject,
    allCurrencies: ICurrencyDetails[]
) => {
    switch (isFieldPresentInPayload.type) {
        case CustomFieldType.SHORTTEXT:
        case CustomFieldType.LONGTEXT:
        case CustomFieldType.CHOICE:
            return (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {isFieldPresentInPayload.text_value ?? ''}
                </FWTypography>
            );
        case CustomFieldType.FLOAT:
            return (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {isFieldPresentInPayload.decimal_value ?? ''}
                </FWTypography>
            );
        case CustomFieldType.INTEGER:
            return (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {isFieldPresentInPayload.integer_value ?? ''}
                </FWTypography>
            );
        case CustomFieldType.DATE:
            return (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {isFieldPresentInPayload.date_value
                        ? getFWDate(isFieldPresentInPayload.date_value)
                        : ''}
                </FWTypography>
            );
        case CustomFieldType.DATETIME:
            return (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {isFieldPresentInPayload.datetime_value
                        ? getFWDate(isFieldPresentInPayload.datetime_value)
                        : ''}
                </FWTypography>
            );

        case CustomFieldType.MULTI_CHOICE:
            return (isFieldPresentInPayload.multi_choice_value ?? [])?.length >
                0 ? (
                <TagsDisplay
                    tags={
                        (isFieldPresentInPayload.multi_choice_value as string[]) ??
                        []
                    }
                />
            ) : (
                <FWTypography></FWTypography>
            );
        case CustomFieldType.BOOLEAN:
            return (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {isFieldPresentInPayload.boolean_value !== null
                        ? Boolean(isFieldPresentInPayload.boolean_value)
                            ? 'Yes'
                            : 'No'
                        : ''}
                </FWTypography>
            );
        case CustomFieldType.ATTACHMENT:
            const attachments =
                isFieldPresentInPayload.attachment_values !== null
                    ? data.attachments.filter((attachment) =>
                          isFieldPresentInPayload.attachment_values !== null
                              ? isFieldPresentInPayload.attachment_values.includes(
                                    attachment.attachment_id
                                )
                              : false
                      )
                    : [];

            return (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <EditAttachmentsButtonAndPopover
                        attachments={attachments}
                        resourceType={IFileResourceType.PROJECT}
                        onFileUpload={(files: IAttachment[]) => {}}
                        showRemoveButton={false}
                        customIconTooltip="View Attachments"
                        allowFileUpload={false}
                        removeAttachment={async (idx: number) => {}}
                        clipIconSize={20}
                    />
                </div>
            );
        case CustomFieldType.CURRENCY:
            const currencyDetails = allCurrencies.find(
                (curr) => curr.entry_id === isFieldPresentInPayload.currency
            );
            return currencyDetails === undefined ||
                !Boolean(isFieldPresentInPayload.decimal_value) ? (
                <FWTypography></FWTypography>
            ) : (
                <FWCurrencyTypoGraphyWithCurrencyUid
                    currencyUidOrAbbrevaition={
                        currencyDetails?.currency_code_abbreviation ?? ''
                    }
                    currency_code_abbreviation={
                        currencyDetails?.currency_code_abbreviation ?? ''
                    }
                    value={isFieldPresentInPayload.decimal_value ?? 0}
                />
            );
        default:
            return <></>;
    }
};

const getFieldRenderValueFromType = (
    isFieldPresentInPayload: IProjectCustomField,
    data: IProject,
    allCurrencies: ICurrencyDetails[]
) => {
    switch (isFieldPresentInPayload.type) {
        case CustomFieldType.SHORTTEXT:
        case CustomFieldType.LONGTEXT:
        case CustomFieldType.CHOICE:
            return isFieldPresentInPayload.text_value ?? '';
        case CustomFieldType.FLOAT:
            return Number(isFieldPresentInPayload.decimal_value) ?? '';
        case CustomFieldType.INTEGER:
            return Number(isFieldPresentInPayload.integer_value) ?? '';
        case CustomFieldType.DATE:
            return isFieldPresentInPayload.date_value
                ? moment(
                      getFWDate(isFieldPresentInPayload.date_value),
                      "DD MMM 'YY"
                  )
                      .startOf('day')
                      .toDate()
                : '';
        case CustomFieldType.DATETIME:
            return isFieldPresentInPayload.datetime_value
                ? moment(
                      getFWDate(isFieldPresentInPayload.datetime_value),
                      "DD MMM 'YY"
                  )
                      .startOf('day')
                      .toDate()
                : '';

        case CustomFieldType.MULTI_CHOICE:
            return (isFieldPresentInPayload.multi_choice_value ?? [])?.length >
                0 ? (
                <TagsDisplay
                    tags={
                        (isFieldPresentInPayload.multi_choice_value as string[]) ??
                        []
                    }
                />
            ) : (
                <FWTypography></FWTypography>
            );
        case CustomFieldType.BOOLEAN:
            return isFieldPresentInPayload.boolean_value !== null
                ? Boolean(isFieldPresentInPayload.boolean_value)
                    ? 'Yes'
                    : 'No'
                : '';
        case CustomFieldType.ATTACHMENT:
            const attachments =
                isFieldPresentInPayload.attachment_values !== null
                    ? data.attachments.filter((attachment) =>
                          isFieldPresentInPayload.attachment_values !== null
                              ? isFieldPresentInPayload.attachment_values.includes(
                                    attachment.attachment_id
                                )
                              : false
                      )
                    : [];
            return (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <EditAttachmentsButtonAndPopover
                        attachments={attachments}
                        resourceType={IFileResourceType.PROJECT}
                        onFileUpload={(files: IAttachment[]) => {}}
                        showRemoveButton={false}
                        customIconTooltip="View Attachments"
                        allowFileUpload={false}
                        removeAttachment={async (idx: number) => {}}
                        clipIconSize={20}
                    />
                </div>
            );
        case CustomFieldType.CURRENCY:
            const currencyDetails = allCurrencies.find(
                (curr) => curr.entry_id === isFieldPresentInPayload.currency
            );
            return currencyDetails === undefined &&
                Boolean(isFieldPresentInPayload.decimal_value)
                ? 0
                : Number(isFieldPresentInPayload.decimal_value) ?? 0;
        default:
            return <></>;
    }
};

// const multiSelectValues = (isFeildPresentInPayload: IProjectCustomField) => {
//     if (isFeildPresentInPayload.type === CustomFieldType.MULTI_CHOICE) {
//         return isFeildPresentInPayload.multi_choice_value
//             ? (isFeildPresentInPayload.multi_choice_value as string[])
//             : [];
//     }
//     return [];
// };

export const GetFieldTypeRendererForDashboard = ({
    data,
    sectionName,
    fieldName,
    fieldBy,
    allCurrencies,
}: {
    data: IProject;
    sectionName: string;
    fieldName: string;
    fieldBy: string;
    allCurrencies: ICurrencyDetails[];
}): JSX.Element => {
    if (fieldBy === 'FW-CF') {
        const SectionPresentInPayload = data.projectCustomSections.find(
            (section) => section.name === sectionName
        );

        if (SectionPresentInPayload === undefined) {
            return <FWTypography></FWTypography>;
        }

        const isFieldPresentInPayload =
            SectionPresentInPayload.custom_fields.find(
                (field) => field.name === fieldName
            );

        if (isFieldPresentInPayload === undefined) {
            return <FWTypography></FWTypography>;
        }

        return getFieldRenderFromType(
            isFieldPresentInPayload,
            data,
            allCurrencies
        );
    } else {
        let foundField: IProjectCustomField | undefined = undefined;

        data.projectCustomSections.some((section) => {
            foundField = section.custom_fields.find(
                (field) => field.name === fieldName
            );

            if (foundField !== undefined) {
                return true;
            }
            return false;
        });

        if (foundField === undefined) {
            return <FWTypography></FWTypography>;
        }

        return getFieldRenderFromType(foundField, data, allCurrencies);
    }
};

export const getAllDashboardColumns = (
    listOfAllFieldsAcrossTemplate: string[],
    tab: string,
    currentTab: string,
    hideEntity: boolean,
    showOptions: boolean,
    history: any,
    defaultTemplateDetails: {
        PROJECT_CODE: string;
        PROJECT_NAME: string;
    }
) => {
    const preDefinedDashboardColumns: ColDef[] = [
        {
            headerName: defaultTemplateDetails.PROJECT_CODE,
            field: 'projectCode',
            pinned: 'left',
            lockPinned: true,
            cellClass: 'lock-pinned',
            sortable: false,
            filter: false,
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            headerTooltip: defaultTemplateDetails.PROJECT_CODE,
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        {defaultTemplateDetails.PROJECT_CODE}
                    </FWTypography>
                );
            },
            // headerTooltip: 'projectCode',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'project_code',
                [DASHBOARD_FIELD_COLUMN]: 'Project code',
                [DASHBOARD_SECTION_NAME]: 'Project details',
                [DASHBOARD_FIELD_TYPE]: 'SHORTTEXT',
                [DASHBOARD_FIELD]: 'BUILTIN',
            },
            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {params.data.projectCode}
                </FWTypography>
            ),
            flex: 10,
        } as ColDef,

        {
            headerName: defaultTemplateDetails.PROJECT_NAME,
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            field: 'projectName',
            lockPinned: true,
            cellClass: 'lock-pinned',
            sortable: false,
            filter: false,
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        Project Name
                    </FWTypography>
                );
            },
            headerTooltip: 'Project Name',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'project_name',
                [DASHBOARD_FIELD_COLUMN]: 'Project Name',
                [DASHBOARD_SECTION_NAME]: 'Project details',
                [DASHBOARD_FIELD_TYPE]: 'SHORTTEXT',
                [DASHBOARD_FIELD]: 'BUILTIN',
            },
            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {params.data.projectName}
                </FWTypography>
            ),
            flex: 35,
        } as ColDef,

        {
            headerName: 'Submission Status',
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            field: 'status',
            lockPinned: true,
            cellClass: 'lock-pinned',
            sortable: false,
            filter: false,
            headerTooltip: 'Submission Status',
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        Submission Status
                    </FWTypography>
                );
            },
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            refData: {
                [DASHBOARD_FIELD_NAME]: 'project_status',
                [DASHBOARD_FIELD_COLUMN]: 'Submission status',
                [DASHBOARD_SECTION_NAME]: 'Project details',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'CHOICE',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                    color={
                        params.data.projectStatus === ProjectStatusEnum.DRAFT
                            ? ''
                            : params.data.projectStatus ===
                              ProjectStatusEnum.SUBMITTED
                            ? 'success.main'
                            : params.data.projectStatus ===
                              ProjectStatusEnum.EXPIRED
                            ? 'error.main'
                            : ''
                    }
                >
                    {capitaliseFirstLetter(
                        params.data.projectStatus?.toLowerCase() as string
                    )}
                </FWTypography>
            ),
            valueGetter: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => params.data.projectStatus,

            flex: 10,
            hide: tab !== 'all',
        } as ColDef,

        {
            headerName: 'Project Manager(s)',
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            field: 'project_manager',
            lockPinned: true,
            cellClass: 'lock-pinned',
            sortable: false,
            filter: false,
            filterParams: {
                valueList: ['red'],
            },
            headerTooltip: 'Project Manager(s)',
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        Project Manager(s)
                    </FWTypography>
                );
            },
            refData: {
                [DASHBOARD_FIELD_NAME]: 'project_managers',
                [DASHBOARD_FIELD_COLUMN]: 'Project Manager(s)',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'MULTI_CHOICE',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: ProjectManagerCell,
            flex: 10,
            hide: tab !== 'all' || ['finished'].includes(currentTab),
        } as ColDef,
        {
            headerName: 'Creator',
            lockPinned: true,
            cellClass: 'lock-pinned',
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            field: 'createdBy',
            sortable: false,
            filter: false,
            headerTooltip: 'Creator',
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        Creator
                    </FWTypography>
                );
            },
            refData: {
                [DASHBOARD_FIELD_NAME]: 'created_by_user_name',
                [DASHBOARD_FIELD_COLUMN]: 'Creator',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'TEXT',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {params.data.createdByUsername}
                </FWTypography>
            ),
            valueGetter: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => params.data.createdByUsername,
            flex: 10,
        } as ColDef,
        {
            headerName: 'Created on',
            lockPinned: true,
            cellClass: 'lock-pinned',
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        Created on
                    </FWTypography>
                );
            },
            field: 'createdOn',
            // filter: 'agDateColumnFilter',
            filterParams: {
                debounceMs: 800,
                maxNumConditions: 1,
            },
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            sortable: false,
            filter: false,
            headerTooltip: 'Created on',
            // filter: !['ongoing'].includes(currentTab),
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            refData: {
                [DASHBOARD_FIELD_NAME]: 'created_datetime',
                [DASHBOARD_FIELD_COLUMN]: 'Created on',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'DATETIME',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                    color={'secondary'}
                    textAlign={'center'}
                >
                    {params.data.createdOn}
                </FWTypography>
            ),
            valueGetter: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) =>
                moment(params.data.createdOn, "DD MMM 'YY hh:mm a")
                    .startOf('day')
                    .toDate(),
            flex: 10,
            hide: ['ongoing', 'finished'].includes(currentTab),
        } as ColDef,
        ...(currentTab !== 'in_progress'
            ? [
                  {
                      headerName: 'Submitted on',
                      field: 'submittedOn',
                      lockPinned: true,
                      cellClass: 'lock-pinned',
                      suppressMovable: true,
                      sortable: false,
                      filter: false,
                      filterParams: {
                          debounceMs: 800,
                          maxNumConditions: 1,
                          //   comparator: () => 1,
                      },
                      comparator: (
                          valueA: any,
                          valueB: any,
                          nodeA: any,
                          nodeB: any,
                          isInverted: any
                      ) => {
                          return 0;
                      },
                      headerTooltip: 'Submitted on',
                      onCellClicked: (event) => {
                          history.push(
                              `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                          );
                      },
                      headerComponent: () => {
                          return (
                              <FWTypography
                                  style={{ color: 'black', fontWeight: 300 }}
                              >
                                  Submitted on
                              </FWTypography>
                          );
                      },
                      refData: {
                          [DASHBOARD_FIELD_NAME]: 'modified_datetime',
                          [DASHBOARD_FIELD_COLUMN]: 'Submitted on',
                          [DASHBOARD_SECTION_NAME]: '',
                          [DASHBOARD_FIELD]: 'BUILTIN',
                          [DASHBOARD_FIELD_TYPE]: CustomFieldType.DATETIME,
                          [TEMPLATE_FIELD_TYPE]: 'FW',
                      },
                      cellRenderer: (params: {
                          data: IProject;
                          context: IprojectDashboardContext;
                      }) => (
                          <FWTypography
                              style={{
                                  fontFamily: 'Inter, sans-serif',
                                  fontStyle: 'normal',
                                  fontSize: '16px',
                              }}
                              color={'secondary'}
                          >
                              {getFWDateTime(params.data.submittedOn)}
                          </FWTypography>
                      ),
                      valueGetter: (params: {
                          data: IProject;
                          context: IprojectDashboardContext;
                      }) =>
                          moment(params.data.submittedOn, "DD MMM 'YY hh:mm a")
                              .startOf('day')
                              .toDate(),
                      flex: 10,
                      hide: ['finished', 'in_progress'].includes(currentTab),
                  } as ColDef,
                  {
                      headerName: 'Submitted by',
                      lockPinned: true,
                      cellClass: 'lock-pinned',
                      headerComponent: () => {
                          return (
                              <FWTypography
                                  style={{ color: 'black', fontWeight: 300 }}
                              >
                                  Submitted by
                              </FWTypography>
                          );
                      },
                      comparator: (
                          valueA: any,
                          valueB: any,
                          nodeA: any,
                          nodeB: any,
                          isInverted: any
                      ) => {
                          return 0;
                      },
                      field: 'submittedBy',
                      sortable: false,
                      filter: false,
                      suppressMovable: true,
                      headerTooltip: 'Submitted by',
                      onCellClicked: (event) => {
                          history.push(
                              `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                          );
                      },
                      refData: {
                          [DASHBOARD_FIELD_NAME]: 'modified_by_user_name',
                          [DASHBOARD_FIELD_COLUMN]: 'Submitted by',
                          [DASHBOARD_SECTION_NAME]: '',
                          [DASHBOARD_FIELD]: 'BUILTIN',
                          [DASHBOARD_FIELD_TYPE]: CustomFieldType.SHORTTEXT,
                          [TEMPLATE_FIELD_TYPE]: 'FW',
                      },
                      cellRenderer: (params: {
                          data: IProject;
                          context: IprojectDashboardContext;
                      }) => (
                          <FWTypography
                              style={{
                                  fontFamily: 'Inter, sans-serif',
                                  fontStyle: 'normal',
                                  fontSize: '16px',
                              }}
                              color={'secondary'}
                          >
                              {params.data.modifiedByUsername}
                          </FWTypography>
                      ),
                      flex: 10,
                      hide: ['finished', 'in_progress'].includes(currentTab),
                  } as ColDef,
              ]
            : []),
        {
            headerName: 'Last modified by',
            lockPinned: true,
            cellClass: 'lock-pinned',
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        Last modified by
                    </FWTypography>
                );
            },

            field: 'modified_by_user_name',
            sortable: false,
            filter: false,
            // filter: true,
            // headerTooltip: 'Last modified by',
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            refData: {
                [DASHBOARD_FIELD_NAME]: 'modified_by_user_name',
                [DASHBOARD_FIELD_COLUMN]: 'Last modified by',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.SHORTTEXT,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                    // marginLeft={'auto'}
                >
                    {params.data.modifiedByUsername}
                </FWTypography>
            ),
            valueGetter: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => params.data.modifiedByUsername,
            flex: 10,
            hide: ['ongoing', 'finished'].includes(currentTab),
        } as ColDef,

        {
            headerName: 'Modified on',
            field: 'modified_datetime',
            lockPinned: true,
            cellClass: 'lock-pinned',
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        Modified on
                    </FWTypography>
                );
            },

            sortable: false,
            filter: false,
            filterParams: {
                debounceMs: 800,
                maxNumConditions: 1,
                // comparator: () => 1,
            },
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            refData: {
                [DASHBOARD_FIELD_NAME]: 'modified_datetime',
                [DASHBOARD_FIELD_COLUMN]: 'Modified on',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.DATETIME,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                    color={'secondary'}
                >
                    {getFWDateTime(params.data.modifiedOn)}
                </FWTypography>
            ),
            valueGetter: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) =>
                moment(params.data.modifiedOn, "DD MMM 'YY hh:mm a")
                    .startOf('day')
                    .toDate(),
            flex: 10,
            hide: ['ongoing', 'finished', 'in_progress'].includes(currentTab),
        },
        {
            headerName: 'Start date',
            field: 'validityFrom',
            lockPinned: true,
            cellClass: 'lock-pinned',
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        Start date
                    </FWTypography>
                );
            },
            sortable: false,
            filter: false,
            filterParams: {
                debounceMs: 800,
                maxNumConditions: 1,
                // comparator: () => 1,
            },
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            refData: {
                [DASHBOARD_FIELD_NAME]: 'validity_from',
                [DASHBOARD_FIELD_COLUMN]: 'Start date',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.DATETIME,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                    color={'secondary'}
                >
                    {getFWDateTime(params.data.validityFrom)}
                </FWTypography>
            ),
            valueGetter: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) =>
                moment(params.data.validityFrom, "DD MMM 'YY hh:mm a")
                    .startOf('day')
                    .toDate(),
            flex: 10,
            hide: ['ongoing', 'finished', 'in_progress'].includes(currentTab),
        },
        {
            headerName: 'End date',
            field: 'validityTo',
            lockPinned: true,
            cellClass: 'lock-pinned',
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        End date
                    </FWTypography>
                );
            },
            sortable: false,
            filter: false,
            filterParams: {
                debounceMs: 800,
                maxNumConditions: 1,
                // comparator: () => 1,
            },
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            refData: {
                [DASHBOARD_FIELD_NAME]: 'validity_to',
                [DASHBOARD_FIELD_COLUMN]: 'End date',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.DATETIME,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                    color={'secondary'}
                >
                    {getFWDateTime(params.data.validityTo)}
                </FWTypography>
            ),
            valueGetter: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) =>
                moment(params.data.validityTo, "DD MMM 'YY hh:mm a")
                    .startOf('day')
                    .toDate(),
            flex: 10,
            hide: ['ongoing', 'finished', 'in_progress'].includes(currentTab),
        },

        {
            headerName: 'Entity name',
            lockPinned: true,
            cellClass: 'lock-pinned',
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        Entity name
                    </FWTypography>
                );
            },
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            field: 'entityName',
            // suppressMovable: false,
            headerTooltip: 'Entity name',
            sortable: false,
            filter: false,
            // headerTooltip: 'Entity name',
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            refData: {
                [DASHBOARD_FIELD_NAME]: 'buyer_entity',
                [DASHBOARD_FIELD_COLUMN]: 'Entity name',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.SHORTTEXT,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {params.data.entityName}
                </FWTypography>
            ),
            flex: 10,
            hide: hideEntity || ['ongoing'].includes(currentTab),
        },

        {
            headerName: 'Customer name',
            lockPinned: true,
            cellClass: 'lock-pinned',
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        Customer name
                    </FWTypography>
                );
            },
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            field: 'customer_entity',
            headerTooltip: 'Customer name',
            suppressMovable: false,
            sortable: false,
            filter: false,
            // sortable: true,
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            refData: {
                [DASHBOARD_FIELD_NAME]: 'customer_entity',
                [DASHBOARD_FIELD_COLUMN]: 'Customer name',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.SHORTTEXT,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {params.data.customerEntity?.entity_name ?? ''}
                </FWTypography>
            ),
            valueGetter: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => params.data.customerEntity?.entity_name ?? '',
            flex: 10,
        },
        {
            headerName: 'Description',
            lockPinned: true,
            cellClass: 'lock-pinned',
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        Description
                    </FWTypography>
                );
            },
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            field: 'projectName',
            sortable: false,
            filter: false,
            // do not remove the below ref data -> it is used in filter popup
            refData: {
                [DASHBOARD_FIELD_NAME]: 'description',
                [DASHBOARD_FIELD_COLUMN]: 'description',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.LONGTEXT,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {params.data.description ?? ''}
                </FWTypography>
            ),
            valueGetter: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => params.data.description ?? '',
            // maxWidth: PROJECT_DASHBOARD_MAX_FIELD_WIDTH,
        },
        {
            headerName: 'Internal Notes',
            lockPinned: true,
            cellClass: 'lock-pinned',
            headerComponent: () => {
                return (
                    <FWTypography style={{ color: 'black', fontWeight: 300 }}>
                        Internal Notes
                    </FWTypography>
                );
            },
            field: 'internal notes',
            sortable: false,
            filter: false,
            headerTooltip: 'Internal Notes',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'internal_notes',
                [DASHBOARD_FIELD_COLUMN]: 'Internal notes',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.LONGTEXT,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            onCellClicked: (event) => {
                history.push(
                    `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                );
            },
            comparator: (
                valueA: any,
                valueB: any,
                nodeA: any,
                nodeB: any,
                isInverted: any
            ) => {
                return 0;
            },
            valueGetter: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => params.data.internalNotes ?? '',

            cellRenderer: (params: {
                data: IProject;
                context: IprojectDashboardContext;
            }) => (
                <FWTypography
                    style={{
                        fontFamily: 'Inter, sans-serif',
                        fontStyle: 'normal',
                        fontSize: '16px',
                    }}
                >
                    {params.data.internalNotes ?? ''}
                </FWTypography>
            ),
            // maxWidth: PROJECT_DASHBOARD_MAX_FIELD_WIDTH,
        } as ColDef,
    ];

    const dashboardColumns = listOfAllFieldsAcrossTemplate
        .map((field) => {
            let {
                fieldAlternameName,
                fieldBy,
                fieldName,
                field_type,
                sectionAlternateName,
            } = getFieldInformation(field);
            if (fieldAlternameName[fieldAlternameName.length - 1] === '_') {
                fieldAlternameName = fieldAlternameName.slice(0, -1);
            }

            if (fieldBy === 'FW') {
                const fieldSettings = fieldSortAndFilterStatus[fieldName];
                switch (fieldSettings?.payloadKey) {
                    default:
                        return {} as ColDef;
                }
            } else {
                return {
                    headerName:
                        fieldBy === 'CF'
                            ? fieldAlternameName
                            : `${sectionAlternateName} ${fieldAlternameName}`,
                    headerComponent: () => {
                        return (
                            <FWTypography
                                style={{ color: 'black', fontWeight: 300 }}
                            >
                                {fieldBy === 'CF'
                                    ? fieldAlternameName
                                    : `${sectionAlternateName} ${fieldAlternameName}`}
                            </FWTypography>
                        );
                    },
                    headerTooltip:
                        fieldBy === 'CF'
                            ? fieldAlternameName
                            : `${sectionAlternateName} ${fieldAlternameName}`,
                    field: fieldAlternameName,
                    lockPinned: true,
                    cellClass: 'lock-pinned',
                    sortable: ['ATTACHMENT'].includes(field_type)
                        ? false
                        : true,
                    filter: ['MULTICHOICE', 'CHOICE'].includes(field_type)
                        ? false
                        : ['ATTACHMENT'].includes(field_type)
                        ? false
                        : ['DATE', 'DATETIME'].includes(field_type)
                        ? 'agDateColumnFilter'
                        : [
                              'DECIMAL',
                              'FLOAT',
                              'INTEGER',
                              'PERCENTAGE',
                              'CURRENCY',
                          ].includes(field_type)
                        ? 'agNumberColumnFilter'
                        : true,

                    filterParams: ['MULTICHOICE', 'CHOICE'].includes(fieldName)
                        ? {
                              //   valueList: multiSelectDropDown ?? [],
                              valueList: ['red'],
                          }
                        : ['BOOLEAN'].includes(field_type)
                        ? {
                              debounceMs: 800,
                              maxNumConditions: 1,
                              filterOptions: [
                                  'equals',
                                  'notEqual',
                                  'blank',
                                  'notBlank',
                              ],
                              //   comparator: () => 1,
                          }
                        : {
                              debounceMs: 800,
                              maxNumConditions: 1,
                          },

                    comparator: (
                        valueA: any,
                        valueB: any,
                        nodeA: any,
                        nodeB: any,
                        isInverted: any
                    ) => {
                        return 0;
                    },

                    refData: {
                        [DASHBOARD_FIELD_NAME]: fieldAlternameName,
                        [DASHBOARD_FIELD_COLUMN]: fieldAlternameName,
                        [DASHBOARD_SECTION_NAME]: sectionAlternateName,
                        [DASHBOARD_FIELD_TYPE]:
                            field_type === 'MULTICHOICE'
                                ? 'MULTI_CHOICE'
                                : field_type,
                        [DASHBOARD_FIELD]: 'CUSTOM_FIELD',
                        [TEMPLATE_FIELD_TYPE]: fieldBy,
                    },
                    onCellClicked: (event) => {
                        const data = event.data as IProject;

                        const SectionPresentInPayload =
                            data.projectCustomSections.find(
                                (section) =>
                                    section.name === sectionAlternateName
                            );

                        const isFieldPresentInPayload =
                            SectionPresentInPayload?.custom_fields.find(
                                (field) => field.name === fieldAlternameName
                            );

                        if (
                            isFieldPresentInPayload !== undefined &&
                            (isFieldPresentInPayload.type ===
                            CustomFieldType.ATTACHMENT
                                ? isFieldPresentInPayload.attachment_values ===
                                      null ||
                                  isFieldPresentInPayload.attachment_values
                                      ?.length === 0
                                : true)
                        ) {
                            history.push(
                                `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                            );
                        } else if (isFieldPresentInPayload === undefined) {
                            history.push(
                                `/custom/cost-tracking/projects/${event.data.projectUUid}/draft`
                            );
                        }
                    },
                    suppressMovable: false,
                    cellRenderer: (params: {
                        data: IProject;
                        context: IprojectDashboardContext;
                    }) => (
                        <Box>
                            <GetFieldTypeRendererForDashboard
                                data={params.data}
                                fieldName={fieldAlternameName}
                                sectionName={sectionAlternateName}
                                fieldBy={fieldBy}
                                allCurrencies={params.context.allCurrencies}
                            />
                        </Box>
                    ),
                    // maxWidth: PROJECT_DASHBOARD_MAX_FIELD_WIDTH,

                    hide: true,
                    valueGetter: (params: {
                        data: IProject;
                        context: IprojectDashboardContext;
                    }) => {
                        if (fieldBy === 'FW-CF') {
                            const SectionPresentInPayload =
                                params.data.projectCustomSections.find(
                                    (section) =>
                                        section.name === sectionAlternateName
                                );

                            if (SectionPresentInPayload === undefined) {
                                return '';
                            }

                            const isFieldPresentInPayload =
                                SectionPresentInPayload.custom_fields.find(
                                    (field) => field.name === fieldName
                                );

                            if (isFieldPresentInPayload === undefined) {
                                return '';
                            }

                            return getFieldRenderValueFromType(
                                isFieldPresentInPayload,
                                params.data,
                                params.context.allCurrencies
                            );
                        } else {
                            let foundField: IProjectCustomField | undefined =
                                undefined;

                            params.data.projectCustomSections.some(
                                (section) => {
                                    foundField = section.custom_fields.find(
                                        (field) => field.name === fieldName
                                    );

                                    if (foundField !== undefined) {
                                        return true;
                                    }
                                    return false;
                                }
                            );

                            if (foundField === undefined) {
                                return '';
                            }

                            return getFieldRenderValueFromType(
                                foundField,
                                params.data,
                                params.context.allCurrencies
                            );
                        }
                    },
                } as ColDef;
            }
        })
        ?.filter((column) => !isEqual(column, {}));

    const finalDashboardColumnList = [
        ...preDefinedDashboardColumns,
        ...dashboardColumns,
        ...(showOptions
            ? [
                  {
                      headerName: 'Options',
                      field: '',
                      sortable: false,
                      width: 100,
                      filter: false,
                      suppressMovable: true,
                      cellRenderer: (params: {
                          data: IProject;
                          context: IprojectDashboardContext;
                      }) => <MenuRender {...params} />,
                  },
              ]
            : []),
    ];

    return finalDashboardColumnList;
};

export const fieldSortAndFilterStatus: {
    [fieldName: string]: {
        sortable: boolean;
        filter: boolean;
        field: string;
        payloadKey: string;
    };
} = {
    'Project code': {
        sortable: true,
        filter: true,
        field: 'projectCode',
        payloadKey: 'projectCode',
    },
    'Project name': {
        sortable: true,
        filter: true,
        field: 'projectCode',
        payloadKey: 'projectName',
    },
    Status: {
        sortable: true,
        filter: true,
        field: 'status',
        payloadKey: 'projectStatus',
    },
    'Project manager': {
        sortable: true,
        filter: true,
        field: 'status',
        payloadKey: 'projectManagers',
    },
    Description: {
        sortable: false,
        filter: true,
        field: 'description',
        payloadKey: 'description',
    },
    'Internal notes': {
        sortable: false,
        filter: true,
        field: 'internalNotes',
        payloadKey: 'internalNotes',
    },
    'Deadline date': {
        sortable: false,
        filter: true,
        field: 'deadlineDate',
        payloadKey: 'deadlineDate',
    },
};

export const createFieldNameUsingDelimiter = (
    sectionAlternateName: string,
    sectionName: string,
    fieldAlternameName: string,
    fieldName: string,
    isBuiltIn: boolean,
    field_type: ProjectCustomFieldSectionFieldType
) => {
    return `${sectionAlternateName}${PROJECT_DASHBOARD_FIELD_DELIMITER}${sectionName}${PROJECT_DASHBOARD_FIELD_DELIMITER}${fieldAlternameName}${PROJECT_DASHBOARD_FIELD_DELIMITER}${fieldName}${PROJECT_DASHBOARD_FIELD_DELIMITER}${
        isBuiltIn ? (sectionName === 'Project details' ? 'FW' : 'FW-CF') : 'CF'
    }${PROJECT_DASHBOARD_FIELD_DELIMITER}${field_type}${PROJECT_DASHBOARD_FIELD_DELIMITER}`;
};

export const getFieldInformation = (encryptedKey: string) => {
    const [
        sectionAlternateName,
        sectionName,
        fieldAlternameName,
        fieldName,
        fieldBy,
        field_type,
    ] = encryptedKey.split(PROJECT_DASHBOARD_FIELD_DELIMITER);

    return {
        sectionAlternateName,
        sectionName,
        fieldAlternameName,
        fieldName,
        fieldBy,
        field_type,
    };
};
