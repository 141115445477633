export enum PeriodEnum {
    DAYS = 'DAYS',
    WEEKS = 'WEEKS',
    MONTHS = 'MONTHS',
}

export const convertPeriodToDays = ({
    value,
    period,
}: {
    value: number;
    period: PeriodEnum;
}): number => {
    switch (period) {
        case PeriodEnum.DAYS:
            return value;
        case PeriodEnum.WEEKS:
            return 7 * value;
        case PeriodEnum.MONTHS:
            return 30 * value;
    }
};

export enum PeriodEnumPaymentTerms{
    DAYS = 'DAYS',
    WEEKS = 'WEEKS',
    MONTHS = 'MONTHS',
    NA='NA'
}
