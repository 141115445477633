import { isEmpty, isNull, uniqueId } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
    AllocationTypeEnum,
    CostSourceEnum,
    CostTypeEnum,
    IAdditionalCostsBackend,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import { getFWDateTime } from '../../../Common/Utils/DateUtils';
import { IBackendVendorMasterMultipleItemResponse } from '../../../Events/Services/Buyer/RFQ.service';
import {
    CustomFieldType,
    ICustomSection,
} from '../../../Global/Interfaces/TemplateInterface';
import {
    DashBoardFilter,
    TVendorList,
} from '../../../ProjectGlCostCenter/interface/project.model';
import { baseApiSlice } from '../../../Redux/Slices/BaseApiSlice';
import { VerificationStatus } from '../../../Redux/Slices/VerificationSlice';
import { del, delWpl, get, patch, post, put } from '../../../Utils/api';
import {
    vendorStatusMapService,
    vendorVerificationStatusMapService,
} from '../Constants/VendorsConstants';
import { blankVendorTemplate } from '../Helpers/VendorHelpers';
import { ItemStatus } from '../Interfaces/ItemDirectoryInterface';
import {
    IEntityVendorListSummary,
    IPreferredItem,
    IVendorAddDetails,
    IVendorBuyerEntity,
    IVendorContact,
    IVendorContactAdd,
    IVendorDirDetails,
    IVendorLinkSecondaryContactResponse,
    IVendorListSummary,
    IVendorTemplateAdditionalCostOptions,
    IVendorTemplateBackend,
    IVendorTemplateCustomFieldOptions,
    IVendorTemplateFrontEnd,
    IVendorTemplateInfo,
    IVendorTemplateStandardFieldOptions,
    PreferredItemStatus,
    VendorEntityStatus,
    VendorStatus,
    VendorTemplateStandardFieldBackendNames,
    VendorVerificationStatus,
} from '../Interfaces/VendorsInterface';
import {
    IITemTemplateFieldResponse,
    ITemplateResponse,
} from './ItemDirectoryService';

// TODO:Need to find a replacement for this
export const fetchVendorsList = async (
    filter?: boolean
): Promise<IVendorListSummary[]> => {
    const response: any = await get<any>(`/organization/vendor_master/admin/`);
    const data: any[] = response.data;
    if (data) {
        try {
            const vendors: IVendorListSummary[] = data
                .filter((vendor) => {
                    if (filter) {
                        return (
                            vendor.status === VendorStatus.ACTIVE ||
                            vendor.status === VendorStatus.INVITED
                        );
                    }
                    return true;
                })
                .sort((a, b) => {
                    let vendor_1_modified_dateTime = a.modified_datetime;
                    let vendor_2_modified_dateTime = b.modified_datetime;
                    if (
                        moment(vendor_1_modified_dateTime).isBefore(
                            moment(vendor_2_modified_dateTime)
                        )
                    ) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                .map((vendor, idx) => {
                    const { primary_vendor_contact: pc } = vendor;
                    const newVendor: IVendorListSummary = {
                        index: idx,
                        vendorUid: vendor.seller_entity
                            ? vendor.seller_entity.entity_id
                            : '',
                        tags: vendor.tags,
                        vendorEnterpriseUid: vendor.seller_enterprise || '',
                        vendorDirUid: vendor.enterprise_vendor_master_id,
                        vendorName: vendor.vendor_name,
                        vendorCode: vendor.vendor_code,
                        secondaryContact: vendor.secondary_contacts?.map(
                            (
                                vendorSecContact: IVendorLinkSecondaryContactResponse
                            ) => ({
                                fullName: vendorSecContact?.full_name,
                                emails: vendorSecContact?.emails,
                                userUid: vendorSecContact?.user,
                                vendorContactUid:
                                    vendorSecContact?.vendor_contact_id,
                                invitationStatus: vendorSecContact?.status,
                            })
                        ),
                        primaryContact: {
                            fullName: pc ? pc.full_name : '',
                            email: pc ? pc.primary_email : '',
                            contacts: pc ? pc.phone_numbers : [],
                            userUid: pc ? pc.user : '',
                            vendorContactUid: pc ? pc.vendor_contact_id : '',
                            invitationStatus: pc ? pc.status : '',
                            notes: '',
                        },
                        vendorStatus: vendorStatusMapService[vendor.status],
                        buyerEntitiesCount: vendor.buyer_entity_count,
                        buyerEntities: null,
                        vendorVerificationStatus:
                            vendorVerificationStatusMapService[
                                vendor.seller_entity &&
                                !vendor.seller_entity.is_virtual
                                    ? vendor.seller_entity.verification_status
                                    : VendorVerificationStatus.INVITATION_PENDING
                            ],
                        description: vendor.notes,
                        modifiedDateTime: getFWDateTime(
                            vendor.modified_datetime
                        ),
                    };
                    return newVendor;
                });
            return vendors;
        } catch (error) {}
    }
    return response;
};

export const { useFetchVendorsListQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        fetchVendorsList: build.query<
            IVendorListSummary[],
            {
                filter?: boolean;
            }
        >({
            queryFn: async ({ filter }) => {
                try {
                    const response: any = await get<any>(
                        `/organization/vendor_master/admin/`
                    );
                    const data: any[] = response.data;
                    if (data) {
                        const vendors: IVendorListSummary[] = data
                            .filter((vendor) => {
                                if (filter) {
                                    return (
                                        vendor.status === VendorStatus.ACTIVE ||
                                        vendor.status === VendorStatus.INVITED
                                    );
                                }
                                return true;
                            })
                            .sort((a, b) => {
                                let vendor_1_modified_dateTime =
                                    a.modified_datetime;
                                let vendor_2_modified_dateTime =
                                    b.modified_datetime;
                                if (
                                    moment(vendor_1_modified_dateTime).isBefore(
                                        moment(vendor_2_modified_dateTime)
                                    )
                                ) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            })
                            .map((vendor, idx) => {
                                const { primary_vendor_contact: pc } = vendor;
                                const newVendor: IVendorListSummary = {
                                    index: idx,
                                    vendorUid: vendor.seller_entity
                                        ? vendor.seller_entity.entity_id
                                        : '',
                                    tags: vendor.tags,
                                    vendorEnterpriseUid:
                                        vendor.seller_enterprise || '',
                                    vendorDirUid:
                                        vendor.enterprise_vendor_master_id,
                                    vendorName: vendor.vendor_name,
                                    vendorCode: vendor.vendor_code,
                                    secondaryContact:
                                        vendor.secondary_contacts?.map(
                                            (
                                                vendorSecContact: IVendorLinkSecondaryContactResponse
                                            ) => ({
                                                fullName:
                                                    vendorSecContact?.full_name,
                                                emails: vendorSecContact?.emails,
                                                userUid: vendorSecContact?.user,
                                                vendorContactUid:
                                                    vendorSecContact?.vendor_contact_id,
                                                invitationStatus:
                                                    vendorSecContact?.status,
                                            })
                                        ),
                                    primaryContact: {
                                        fullName: pc ? pc.full_name : '',
                                        email: pc ? pc.primary_email : '',
                                        contacts: pc ? pc.phone_numbers : [],
                                        userUid: pc ? pc.user : '',
                                        vendorContactUid: pc
                                            ? pc.vendor_contact_id
                                            : '',
                                        invitationStatus: pc ? pc.status : '',
                                        notes: '',
                                    },
                                    vendorStatus:
                                        vendorStatusMapService[vendor.status],
                                    buyerEntitiesCount:
                                        vendor.buyer_entity_count,
                                    buyerEntities: null,
                                    vendorVerificationStatus:
                                        vendorVerificationStatusMapService[
                                            vendor.seller_entity &&
                                            !vendor.seller_entity.is_virtual
                                                ? vendor.seller_entity
                                                      .verification_status
                                                : VendorVerificationStatus.INVITATION_PENDING
                                        ],
                                    description: vendor.notes,
                                    modifiedDateTime: getFWDateTime(
                                        vendor.modified_datetime
                                    ),
                                };
                                return newVendor;
                            });
                        return { data: vendors };
                    }
                    return { data: response };
                } catch (err: any) {
                    return { error: err };
                }
            },
        }),
    }),
});

export const newfetchVendorsList = async ({
    tab,
    sortFields,
    searchText,
    pageNumber,
    itemsPerPage,
    query_data,
}: {
    tab: string;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
    query_data: {
        vendor_entity_status?: VendorEntityStatus;
    };
}): Promise<{
    vendors: IVendorListSummary[];
    pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
    };
}> => {
    const response: any = await post<any, any>(`/dashboard/`, {
        dashboard_view: 'enterprise_vendor',
        tab: tab,
        sort_fields: sortFields,
        search_text: searchText,
        items_per_page: itemsPerPage,
        page_number: pageNumber,
        query_data: query_data,
    });
    const data: any = response.data;
    let vendors: IVendorListSummary[] = [];
    let pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
    } = {
        currentPage: 0,
        totalPages: 0,
        count: 0,
    };
    if (data) {
        vendors = data.data.map((vendor: any, index: number) => {
            const { primary_vendor_contact: pc } = vendor;
            const newVendor: IVendorListSummary = {
                index: index,
                vendorUid: vendor.seller_entity
                    ? vendor.seller_entity.entity_id
                    : '',
                tags: vendor.tags,
                vendorEnterpriseUid: vendor.seller_enterprise || '',
                vendorDirUid: vendor.enterprise_vendor_master_id,
                vendorName: vendor.vendor_name,
                vendorCode: vendor.vendor_code,
                secondaryContact: vendor.secondary_contacts?.map(
                    (
                        vendorSecContact: IVendorLinkSecondaryContactResponse
                    ) => ({
                        fullName: vendorSecContact?.full_name,
                        emails: vendorSecContact?.emails,
                        userUid: vendorSecContact?.user,
                        vendorContactUid: vendorSecContact?.vendor_contact_id,
                        invitationStatus: vendorSecContact?.status,
                    })
                ),
                primaryContact: {
                    fullName: pc ? pc.full_name : '',
                    email: pc ? pc.primary_email : '',
                    contacts: pc ? pc.phone_numbers : [],
                    userUid: pc ? pc.user : '',
                    vendorContactUid: pc ? pc.vendor_contact_id : '',
                    invitationStatus: pc ? pc.status : '',
                    notes: '',
                },
                vendorStatus: vendorStatusMapService[vendor.status],
                buyerEntitiesCount: vendor.buyer_entity_count,
                buyerEntities: vendor.buyer_entities
                    ? vendor.buyer_entities.map((val: any) => ({
                          entityUid: val.buyer_entity_id,
                          entityName: val.buyer_entity_name,
                          preferredItemsCount: 0,
                          vendorEntitySelected: false,
                      }))
                    : [],
                vendorVerificationStatus:
                    vendorVerificationStatusMapService[
                        vendor.seller_entity && !vendor.seller_entity.is_virtual
                            ? vendor.seller_entity.verification_status
                            : VendorVerificationStatus.INVITATION_PENDING
                    ],
                description: vendor.notes,
                modifiedDateTime: getFWDateTime(vendor.modified_datetime),
            };
            return newVendor;
        });

        pageDetails = {
            currentPage: data.metadata.current_page,
            totalPages: data.metadata.total_pages,
            count: data.metadata.count,
        };
    }

    return {
        vendors: vendors,
        pageDetails: pageDetails,
    };
};

interface IVendorDashboardBackendResponse {
    data: any;
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
        active: number;
        disabled: number;
    };
}
interface IVendorDashboardFrontend {
    data: IVendorListSummary[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
        active: number;
        disabled: number;
    };
}

export const vendorDirectoryApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getVendorDirectoryTabCount: builder.mutation<
            {
                tab_counts: {
                    active: number;
                    disabled: number;
                    all: number;
                };
            },
            {}
        >({
            query: () => ({
                url: '/dashboard/tab_counts/',
                method: 'POST',
                body: {
                    dashboard_view: 'enterprise_vendor',
                },
            }),
        }),
        getVendorDashboardData: builder.mutation<
            IVendorDashboardFrontend,
            {
                tab: string;
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
                query_data: {
                    vendor_entity_status?: VendorEntityStatus | null;
                };
                filters: DashBoardFilter | null;
            }
        >({
            query: ({
                tab,
                search_text = '',
                items_per_page = '10',
                page_number = 1,
                sort_fields = [],
                query_data = {},
                filters,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'enterprise_vendor',
                    tab: tab,
                    page_number,
                    items_per_page,
                    sort_fields,
                    search_text,
                    query_data,
                    filters,
                },
            }),
            transformResponse: (
                baseQueryReturnValue: IVendorDashboardBackendResponse,
                meta,
                arg
            ) => {
                let response: IVendorListSummary[] =
                    baseQueryReturnValue.data.map(
                        (vendor: any, index: number) => {
                            const { primary_vendor_contact: pc } = vendor;
                            const newVendor: IVendorListSummary = {
                                index: index,
                                vendorUid: vendor.seller_entity
                                    ? vendor.seller_entity.entity_id
                                    : '',
                                tags: vendor.tags,
                                vendorEnterpriseUid:
                                    vendor.seller_enterprise || '',
                                vendorDirUid:
                                    vendor.enterprise_vendor_master_id,
                                vendorName: vendor.vendor_name,
                                vendorCode: vendor.vendor_code,
                                secondaryContact:
                                    vendor?.secondary_vendor_contacts
                                        ?.filter(
                                            (
                                                contact: IVendorLinkSecondaryContactResponse
                                            ) => !contact.is_primary
                                        )
                                        ?.map(
                                            (
                                                vendorSecContact: IVendorLinkSecondaryContactResponse
                                            ) => ({
                                                fullName:
                                                    vendorSecContact?.full_name,
                                                emails: vendorSecContact?.emails,
                                                userUid: vendorSecContact?.user,
                                                primary_email:
                                                    vendorSecContact?.primary_email,
                                                vendorContactUid:
                                                    vendorSecContact?.vendor_contact_id,
                                                invitationStatus:
                                                    vendorSecContact?.status,
                                            })
                                        ),
                                primaryContact: {
                                    fullName: pc ? pc.full_name : '',
                                    email: pc ? pc.primary_email : '',
                                    contacts: pc ? pc.phone_numbers : [],
                                    userUid: pc ? pc.user : '',
                                    vendorContactUid: pc
                                        ? pc.vendor_contact_id
                                        : '',
                                    invitationStatus: pc ? pc.status : '',
                                    notes: '',
                                },
                                vendorStatus:
                                    vendorStatusMapService[vendor.status],
                                buyerEntitiesCount: vendor.buyer_entity_count,
                                buyerEntities: vendor.buyer_entities
                                    ? vendor.buyer_entities.map((val: any) => ({
                                          entityUid: val.buyer_entity_id,
                                          entityName: val.buyer_entity_name,
                                          preferredItemsCount: 0,
                                          vendorEntitySelected: false,
                                      }))
                                    : [],
                                vendorVerificationStatus:
                                    vendorVerificationStatusMapService[
                                        vendor.seller_entity &&
                                        !vendor.seller_entity.is_virtual
                                            ? vendor.seller_entity
                                                  .verification_status
                                            : VendorVerificationStatus.INVITATION_PENDING
                                    ],
                                description: vendor.notes,
                                modifiedDateTime: getFWDateTime(
                                    vendor.modified_datetime
                                ),
                            };

                            return newVendor;
                        }
                    );

                return {
                    data: response,
                    metadata: baseQueryReturnValue.metadata,
                    counts: baseQueryReturnValue.counts,
                };
            },
        }),
        getVendorListOfAllUserList: builder.query<TVendorList, {}>({
            query: () => ({
                url: '/organization/vendor_master/admin/columns',
            }),
        }),
    }),
});

export const {
    useGetVendorDirectoryTabCountMutation,
    useGetVendorDashboardDataMutation,
    useGetVendorListOfAllUserListQuery,
} = vendorDirectoryApiSlice;

export const fetchEntityVendorsList = async (
    entityUid: string
): Promise<IEntityVendorListSummary[]> => {
    const response: any = await get<any>(
        `/organization/vendor_master/admin/entity/${entityUid}/vendors/`
    );
    const data: any[] = response.data;

    if (data) {
        try {
            const vendors: IEntityVendorListSummary[] = data
                // .filter(
                //     (vendor) =>
                //         vendor.enterprise_vendor_master.status ===
                //         VendorStatus.ACTIVE
                // )
                .map((vendor) => {
                    const { primary_vendor_contact: pc } = vendor;
                    const newVendor: IEntityVendorListSummary = {
                        vendorUid: vendor.seller_entity
                            ? vendor.seller_entity.entity_id
                            : '',
                        tags: vendor.tags,
                        vendorEnterpriseUid: vendor.seller_enterprise,
                        vendorDirUid:
                            vendor.enterprise_vendor_master
                                .enterprise_vendor_master_id,
                        vendorName: vendor.enterprise_vendor_master.vendor_name,
                        vendorCode: vendor.enterprise_vendor_master.vendor_code,
                        secondaryContact: vendor.secondary_vendor_contacts?.map(
                            (
                                vendorSecContact: IVendorLinkSecondaryContactResponse
                            ) => ({
                                fullName: vendorSecContact?.full_name,
                                emails: vendorSecContact?.emails,
                                primary_email: vendorSecContact.primary_email,
                                userUid: vendorSecContact?.user,
                                vendorContactUid:
                                    vendorSecContact?.vendor_contact_id,
                                invitationStatus: vendorSecContact?.status,
                            })
                        ),
                        primaryContact: {
                            fullName: pc ? pc.full_name : '',
                            email: pc ? pc.primary_email : '',
                            contacts: pc ? pc.phone_numbers : [],
                            userUid: pc ? pc.user : '',
                            vendorContactUid: pc ? pc.vendor_contact_id : '',
                            invitationStatus: pc
                                ? pc.user
                                    ? 'ACTIVE'
                                    : 'INVITATION_PENDING'
                                : '',
                            notes: pc ? pc.notes : '',
                        },
                        vendorStatus:
                            vendorStatusMapService[
                                vendor.enterprise_vendor_master.status
                            ],
                        vendorVerificationStatus:
                            vendorVerificationStatusMapService[
                                vendor.seller_entity &&
                                !vendor.seller_entity.is_virtual
                                    ? vendor.seller_entity.verification_status
                                    : VendorVerificationStatus.INVITATION_PENDING
                            ],
                        preferredItemsCount: vendor.preferred,
                        modifiedDateTime: getFWDateTime(
                            vendor.modified_datetime
                        ),
                    };
                    return newVendor;
                })
                .sort((a, b) => {
                    if (
                        a.vendorName.toLowerCase() < b.vendorName.toLowerCase()
                    ) {
                        return -1;
                    }
                    if (
                        a.vendorName.toLowerCase() > b.vendorName.toLowerCase()
                    ) {
                        return 1;
                    }
                    return 0;
                });
            return vendors;
        } catch (error) {}
    }
    return response;
};

// TODO: Add Response Interface
//This is the new Fetch Entity Vendors List
export const newFetchEntityVendorsList = async ({
    sortFields,
    searchText,
    pageNumber,
    itemsPerPage,
    entityUid,
}: {
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
    entityUid: string;
}): Promise<{
    vendors: IEntityVendorListSummary[];
    pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
    };
}> => {
    const response: any = await post<any, any>(`/dashboard/`, {
        dashboard_view: 'entity_vendor',
        tab: 'active',
        sort_fields: sortFields,
        search_text: searchText,
        items_per_page: itemsPerPage,
        page_number: pageNumber,
        query_data: {
            entity_id: entityUid,
        },
    });
    const data: any = response.data;
    let vendors: IEntityVendorListSummary[] = [];
    let pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
    } = {
        currentPage: 0,
        totalPages: 0,
        count: 0,
    };
    if (data) {
        vendors = data.data.map((vendor: any) => {
            const { primary_vendor_contact: pc } = vendor;
            const newVendor: IEntityVendorListSummary = {
                vendorUid: vendor.seller_entity
                    ? vendor.seller_entity.entity_id
                    : '',
                tags: vendor.tags,
                vendorEnterpriseUid: vendor.seller_enterprise,
                vendorDirUid:
                    vendor.enterprise_vendor_master.enterprise_vendor_master_id,
                vendorName: vendor.enterprise_vendor_master.vendor_name,
                vendorCode: vendor.enterprise_vendor_master.vendor_code,
                secondaryContact: vendor.secondary_contacts?.map(
                    (
                        vendorSecContact: IVendorLinkSecondaryContactResponse
                    ) => ({
                        fullName: vendorSecContact?.full_name,
                        emails: vendorSecContact?.emails,
                        userUid: vendorSecContact?.user,
                        vendorContactUid: vendorSecContact?.vendor_contact_id,
                        invitationStatus: vendorSecContact?.status,
                    })
                ),
                primaryContact: {
                    fullName: pc ? pc.full_name : '',
                    email: pc ? pc.primary_email : '',
                    contacts: pc ? pc.phone_numbers : [],
                    userUid: pc ? pc.user : '',
                    vendorContactUid: pc ? pc.vendor_contact_id : '',
                    invitationStatus: pc
                        ? pc.user
                            ? 'ACTIVE'
                            : 'INVITATION_PENDING'
                        : '',
                    notes: pc ? pc.notes : '',
                },
                vendorStatus:
                    vendorStatusMapService[
                        vendor.enterprise_vendor_master.status
                    ],
                vendorVerificationStatus:
                    vendorVerificationStatusMapService[
                        vendor.seller_entity && !vendor.seller_entity.is_virtual
                            ? vendor.seller_entity.verification_status
                            : VendorVerificationStatus.INVITATION_PENDING
                    ],
                preferredItemsCount: vendor.preferred,
                modifiedDateTime: getFWDateTime(vendor.modified_datetime),
            };
            return newVendor;
        });

        pageDetails = {
            currentPage: data.metadata.current_page,
            totalPages: data.metadata.total_pages,
            count: data.metadata.count,
        };
    }

    return {
        vendors: vendors,
        pageDetails: pageDetails,
    };
};

export const fetchVendorEntitiesList = async (
    vendorUid: string
): Promise<IVendorBuyerEntity[]> => {
    const response: any = await get<any>(
        `/organization/vendor_master/admin/sellers/${vendorUid}/buyers/`
    );
    const data: any[] = response.data;

    if (data) {
        const buyers: IVendorBuyerEntity[] = data.map((buyer, idx) => {
            const newBuyer: IVendorBuyerEntity = {
                entityName: buyer.buyer_entity_name,
                preferredItemsCount: buyer.preferred || 0,
                vendorEntitySelected: true,
                entityUid: buyer.buyer_entity,
            };
            return newBuyer;
        });

        return buyers;
    }
    return response;
};

export const fetchBuyerEntitiesList = async (
    vendorEntityId: string
): Promise<IVendorBuyerEntity[]> => {
    const response: any = await get<any>(
        `/organization/vendor_master/entity/${vendorEntityId}/buyers/`
    );
    const data: any[] = response.data;

    if (data) {
        const buyers: IVendorBuyerEntity[] = data.map((buyer, idx) => {
            const newBuyer: IVendorBuyerEntity = {
                entityName: buyer.buyer_entity_name,
                preferredItemsCount: buyer.preferred || 0,
                vendorEntitySelected: true,
                entityUid: buyer.buyer_entity,
            };
            return newBuyer;
        });

        return buyers;
    }
    return response;
};

export const fetchFilteredVendorEntitiesList = async ({
    sortFields,
    searchText,
    pageNumber,
    itemsPerPage,
    entityUid,
}: {
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
    entityUid: string;
}): Promise<{
    vendors: IVendorListSummary[];
    pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
    };
}> => {
    const response: any = await post<any, any>(`/dashboard/`, {
        dashboard_view: 'non_entity_enterprise_vendor',
        tab: 'active',
        sort_fields: sortFields,
        search_text: searchText,
        items_per_page: itemsPerPage,
        page_number: pageNumber,
        query_data: {
            entity_id: entityUid,
        },
    });
    const data: any = response.data;
    let vendors: IVendorListSummary[] = [];
    let pageDetails: {
        currentPage: number;
        totalPages: number;
        count: number;
    } = {
        currentPage: 0,
        totalPages: 0,
        count: 0,
    };
    if (data) {
        vendors = data.data.map((vendor: any, index: number) => {
            const { primary_vendor_contact: pc } = vendor;
            const newVendor: IVendorListSummary = {
                index: index,
                vendorUid: vendor.seller_entity
                    ? vendor.seller_entity.entity_id
                    : '',
                tags: vendor.tags,
                vendorEnterpriseUid: vendor.seller_enterprise || '',
                vendorDirUid: vendor.enterprise_vendor_master_id,
                vendorName: vendor.vendor_name,
                vendorCode: vendor.vendor_code,
                primaryContact: {
                    fullName: pc ? pc.full_name : '',
                    email: pc ? pc.primary_email : '',
                    contacts: pc ? pc.phone_numbers : [],
                    userUid: pc ? pc.user : '',
                    vendorContactUid: pc ? pc.vendor_contact_id : '',
                    invitationStatus: pc ? pc.status : '',
                    notes: '',
                },
                vendorStatus: vendorStatusMapService[vendor.status],
                secondaryContact: vendor.secondary_contacts?.map(
                    (
                        vendorSecContact: IVendorLinkSecondaryContactResponse
                    ) => ({
                        fullName: vendorSecContact?.full_name,
                        emails: vendorSecContact?.emails,
                        userUid: vendorSecContact?.user,
                        vendorContactUid: vendorSecContact?.vendor_contact_id,
                        invitationStatus: vendorSecContact?.status,
                    })
                ),
                buyerEntitiesCount: vendor.buyer_entity_count,
                buyerEntities: null,
                vendorVerificationStatus:
                    vendorVerificationStatusMapService[
                        vendor.seller_entity && !vendor.seller_entity.is_virtual
                            ? vendor.seller_entity.verification_status
                            : VendorVerificationStatus.INVITATION_PENDING
                    ],
                description: vendor.notes,
                modifiedDateTime: getFWDateTime(vendor.modified_datetime),
            };
            return newVendor;
        });

        pageDetails = {
            currentPage: data.metadata.current_page,
            totalPages: data.metadata.total_pages,
            count: data.metadata.count,
        };
    }

    return {
        vendors: vendors,
        pageDetails: pageDetails,
    };
};

export const deleteVendorFromEntity = async (
    entityUid: string,
    vendorUid: string
): Promise<boolean> => {
    try {
        await delWpl<any>(
            `/organization/vendor_master/admin/entity/${entityUid}/delete/`,
            {
                seller_entity_id: vendorUid,
            }
        );
        return true;
    } catch (error) {
        return false;
    }
};

export const {
    useGetVendorTemplateByIdQuery,
    useGetVendortemplateDetailsQuery,
} = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getVendortemplateDetails: builder.query<IVendorTemplateInfo[], {}>({
            query: () => '/module_templates/?template_type=VENDOR',
        }),
        getVendorTemplateById: builder.query<
            IVendorTemplateFrontEnd,
            { templateId: string; entityId: string }
        >({
            query: ({ entityId, templateId }) =>
                `/module_templates/${entityId}/${templateId}`,
            transformResponse: (
                baseQueryReturnValue: IVendorTemplateBackend,
                meta,
                arg
            ): IVendorTemplateFrontEnd => {
                const response: IVendorTemplateFrontEnd = JSON.parse(
                    JSON.stringify(blankVendorTemplate)
                );

                response.templateId = baseQueryReturnValue.template_id;
                response.templateName = baseQueryReturnValue.name;

                const templateSections = baseQueryReturnValue.section_list.map(
                    (section) => {
                        return {
                            ...section,
                            section_items: section.section_items.map(
                                (item: any) => {
                                    return {
                                        ...item,
                                        alternate_name:
                                            item.alternate_name.replace(
                                                '_',
                                                ''
                                            ),
                                        name: item.name.replace('_', ''),
                                    };
                                }
                            ),
                        };
                    }
                );
                baseQueryReturnValue = {
                    ...baseQueryReturnValue,
                    section_list: templateSections,
                };

                const vendorDetailsFromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) => section.name === 'Vendor Details'
                    );

                response.sections.VENDOR_DETAILS = {
                    fieldList: [],
                    label:
                        vendorDetailsFromBackend &&
                        vendorDetailsFromBackend?.alternate_name
                            ? vendorDetailsFromBackend.alternate_name
                            : 'Vendor Details',
                };

                const vendorAdditionalCostSectionFromBackend =
                    vendorDetailsFromBackend?.section_items.find(
                        (sectionItem) => sectionItem.name === 'Additional costs'
                    );

                vendorDetailsFromBackend?.section_items.forEach((item) => {
                    if (
                        item.parent_section_item ===
                        vendorAdditionalCostSectionFromBackend?.section_item_id
                    ) {
                        let formattedData: IVendorTemplateAdditionalCostOptions =
                            {
                                additional_information: {
                                    is_hidden:
                                        item.additional_information.is_hidden,
                                },
                                additional_cost_information:
                                    item.additional_information
                                        .additional_cost_information,

                                constraints: item.constraints,
                                description: item.description,
                                is_builtin_field: item.is_builtin_field,
                                is_mandatory: item.is_mandatory,
                                is_required: item.is_required,
                                label: item.alternate_name,
                            };

                        response.sections.VENDOR_DETAILS.fieldList.push({
                            fieldType: 'ADDITIONAL_COST',
                            field: item.alternate_name,
                        });

                        response.fieldSettings.additionalCosts[
                            item.alternate_name
                        ] = formattedData;
                    } else {
                        if (item.is_builtin_field) {
                            let formattedData: IVendorTemplateStandardFieldOptions =
                                {
                                    additional_information: {
                                        is_hidden:
                                            item.additional_information
                                                .is_hidden,
                                    },
                                    constraints: item.constraints,
                                    description: item.description,
                                    is_builtin_field: item.is_builtin_field,
                                    is_mandatory: item.is_mandatory,
                                    is_required: item.is_mandatory,
                                    label: item.alternate_name,
                                    standardFieldCategory: 'STANDARD',
                                };

                            response.sections.VENDOR_DETAILS.fieldList.push({
                                fieldType: 'STANDARD',
                                field: VendorTemplateStandardFieldBackendNames[
                                    item.name
                                ],
                            });
                            response.fieldSettings.standardFields[
                                VendorTemplateStandardFieldBackendNames[
                                    item.name
                                ]
                            ] = formattedData;
                        } else {
                            let formattedData: IVendorTemplateCustomFieldOptions =
                                {
                                    additional_information: {
                                        is_hidden:
                                            item.additional_information
                                                .is_hidden,
                                    },
                                    constraints: item.constraints,
                                    description: item.description,
                                    is_builtin_field: item.is_builtin_field,
                                    is_mandatory: item.is_mandatory,
                                    is_required: item.is_required,
                                    label: item.alternate_name,
                                    type:
                                        item.constraints.field_type ===
                                            'CHOICE' &&
                                        item.constraints.choice_type ===
                                            'MULTI_SELECT'
                                            ? CustomFieldType.MULTI_CHOICE
                                            : (item.constraints
                                                  .field_type as CustomFieldType),
                                };
                            // IVendorTemplateCustomFieldOptions;
                            response.sections.VENDOR_DETAILS.fieldList.push({
                                fieldType: 'CUSTOM',
                                field: item.name,
                            });
                            response.fieldSettings.customFields[
                                item.alternate_name
                            ] = formattedData;
                        }
                    }
                });
                return response;
            },
        }),
    }),
});

//This is for the vendor search which is searchable by
export interface IVendorFactwiseSearchResponse {
    entity_id: string;
    entity_name: string;
    enterprise_id: string;
    enterprise_name: string;
    enterprise_vendor_master_id: string;
    entity_doing_business_as: string;
    buyer_status: string;
    seller_status: string;
    verification_status: VerificationStatus;
    entity_description: string;
    contacts: {
        emails: any[];
        phone_numbers: any[];
        websites: any[];
    };
    name: string;
    is_virtual: boolean;
    email: string;
    address_info: {
        country: string;
        state: string;
        city: string;
        postal_code: string;
    };
    vendor_information: {
        primary_vendor_contact: {
            vendor_contact_id: string;
            full_name: string;
            primary_email: string;
            emails: {
                email: string;
                type: string;
            }[];
            phone_numbers: string[];
            tags: string[];
        };
    };
}

export const runSearchAllVendorsInFactwise = async (
    query: string
): Promise<IVendorSearchedData[]> => {
    const response: any = await get<{
        search_list: IVendorFactwiseSearchResponse[];
    }>(`/organization/entity/search/?search_term=${query}`);
    const data: IVendorFactwiseSearchResponse[] = response.data.search_list;
    if (data) {
        const vendors: IVendorSearchedData[] = data.map((vendor) => {
            let contacts: any = {};
            if (vendor.contacts) {
                contacts = vendor.contacts;
            } else {
                contacts = {
                    emails: [],
                    phone_numbers: [],
                    websites: [],
                };
            }
            const { emails, phone_numbers: tel, websites } = contacts;
            const webLinks = websites.map((web: string) => web.split('//')[1]);

            const newVendor: IVendorSearchedData = {
                vendorName: vendor.entity_name,
                vendorUid: vendor.entity_id,
                vendorEnterpriseUid: vendor.enterprise_id,
                vendorEnterpriseName: vendor.enterprise_name,
                enterpriseVendorMasterId: vendor.enterprise_vendor_master_id,
                vendorStatus: vendorStatusMapService[vendor.seller_status],
                vendorVerificationStatus:
                    VendorVerificationStatus.INVITATION_HOLD,
                description: vendor.entity_description || '',
                email: emails.length > 0 ? emails.join(', ') : '',
                telephone: tel.length > 0 ? tel.join(', ') : '',
                website: webLinks.length > 0 ? webLinks.join(', ') : '',
                vendorCode: '',
                notes: '',
                tags: [],
                seller_contacts: [],
                primaryContacts: [
                    // TODO: Enable when actual POC is integrated and add
                    {
                        parent: null,
                        uid: uniqueId(),
                        default: true,
                        email: '',
                        fullName: '',
                        contactNumber: '',
                        notes: '',
                        isPresentInFW: false, // TODO: when actual POC is integrated make this true
                        selected: false, // TODO: when actual POC is integrated make this true
                        internalName: '',
                        isCCEmail: false,
                        isSecondaryEmail: false,
                    },
                ],
                is_entity_linked: false,
                entities: [],
                is_virtual: vendor.is_virtual ?? false,
                verification_status: vendor.verification_status,
                is_new_invitation_row: false,
                vendor_internal_name: null,
                entity_doing_business_as: vendor.entity_doing_business_as,
                is_Dir_Vendor: false,
                address_info: vendor.address_info,
                globalName: vendor.name,
                globalEmail: vendor.email,
                vendor_contact_name: null,
                vendor_contact_email: null,
                seller_address_information: [],
                seller_identifications: [],
                custom_sections: [],
            };
            return newVendor;
        });

        return vendors;
    }
    return response;
};
interface IVendorSearchResponse {
    enterprise_id: string;
    enterprise_vendor_master_id: string;
    entity_id: string;
    entity_description: string;
    enterprise_name: string;
    entity_doing_business_as: string;
    entity_name: string;
    is_virtual: boolean;
    verification_status: VerificationStatus;
    name: string;
    email: string;
    contacts?: {
        emails?: string[];
        phone_numbers?: string[];
        websites?: string[];
    };
    vendor_information: vendorMasterInfoType | {};
    address_info: {
        country: string;
        state: string;
        city: string;
        postal_code: string;
    } | null;
    buyer_status?: string;
    seller_status?: string;
}

type vendorMasterInfoType = {
    is_entity_linked: boolean;
    enterprise_vm_status: 'ACTIVE' | 'INACTIVE' | 'INVITED';
    entity_vm_status: 'PREFERRED' | 'BLOCKED' | 'STANDARD';
    notes: string;
    vendor_code: string;
    vendor_name: string;
    enterprise_vendor_master_id: string;
    tags: string[];
    vendor_contact_name: string;
    vendor_contact_email: string;
    primary_vendor_contact: {
        vendor_contact_id: string;
        full_name: string;
        primary_email: string;
        emails: {
            email: string;
            type: string;
        }[];
        phone_numbers: string[];
        tags: string[];
    };
};

export interface IVendorSearchedData extends IVendorAddDetails {
    vendorEnterpriseName?: string;
    entity_doing_business_as: string;
    is_new_invitation_row: boolean;
    is_Dir_Vendor: boolean;
    vendor_internal_name: string | null;
    address_info: {
        country: string;
        state: string;
        city: string;
        postal_code: string;
    } | null;
    globalName: string;
    globalEmail: string;
    vendor_contact_name: string | null;
    vendor_contact_email: string | null;
    is_entity_linked: boolean;
    enterpriseVendorMasterId: string;
}

export const runVendorSearch = async (props: {
    searchByQuery?: {
        query: string;
        entityId: string;
    };
    searchByEnterpriseVmId?: {
        enterpriseVmId: string;
    };
}): Promise<IVendorSearchedData[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let url = '/organization/vendor_master/search/';
            if (props.searchByQuery) {
                url =
                    url +
                    `?search_term=${props.searchByQuery.query}&entity_id=${props.searchByQuery.entityId}`;
            }
            if (props.searchByEnterpriseVmId) {
                url =
                    url +
                    `?enterprise_vendor_master_id=${props.searchByEnterpriseVmId.enterpriseVmId}`;
            }

            const response = await get<{
                search_list: IVendorSearchResponse[];
            }>(url);
            const searchResults: IVendorSearchResponse[] =
                response.data.search_list;

            const vendors: IVendorSearchedData[] = searchResults.map(
                (vendor) => {
                    const contacts = {
                        emails: vendor.contacts
                            ? vendor.contacts.emails ?? []
                            : [],
                        phone_numbers: vendor.contacts
                            ? vendor.contacts.phone_numbers ?? []
                            : [],
                        websites: vendor.contacts
                            ? vendor.contacts.websites ?? []
                            : [],
                    };
                    const { emails, phone_numbers: tel, websites } = contacts;
                    const webLinks = websites.map(
                        (web: string) => web.split('//')[1]
                    );

                    const vendorMasterInfo: {
                        is_entity_linked: boolean;
                        enterprise_vm_status: 'ACTIVE' | 'INACTIVE' | 'INVITED';
                        entity_vm_status: 'PREFERRED' | 'BLOCKED' | 'STANDARD';
                        notes: string;
                        tags: string[];
                        vendor_code: string;
                        vendor_name: string;
                        enterprise_vendor_master_id: string;
                        vendor_contact_name: string | undefined;
                        vendor_contact_email: string | undefined;
                        primary_vendor_contact: {
                            vendor_contact_id: string;
                            full_name: string;
                            primary_email: string;
                            emails: {
                                email: string;
                                type: string;
                            }[];
                            phone_numbers: string[];
                            tags: string[];
                        };
                    } | null = isEmpty(vendor.vendor_information)
                        ? null
                        : (vendor.vendor_information as vendorMasterInfoType);

                    const vendorStatus =
                        vendorMasterInfo &&
                        (vendorMasterInfo.enterprise_vm_status === 'INACTIVE' ||
                            vendorMasterInfo.entity_vm_status === 'BLOCKED')
                            ? VendorStatus.INACTIVE
                            : VendorStatus.ACTIVE;

                    const newVendor: IVendorSearchedData = {
                        vendorName: vendor.entity_name,
                        vendorUid: vendor.entity_id,
                        vendorEnterpriseUid: vendor.enterprise_id,
                        vendorEnterpriseName: vendor.enterprise_name,
                        enterpriseVendorMasterId:
                            vendorMasterInfo?.enterprise_vendor_master_id ?? '',
                        vendorStatus: vendorStatus,
                        vendorVerificationStatus:
                            VendorVerificationStatus.INVITATION_HOLD,
                        description: vendor.entity_description,
                        globalName: vendor.name,
                        globalEmail: vendor.email,
                        email:
                            emails.length > 0
                                ? emails.join(', ')
                                : vendor.email,
                        telephone: tel.length > 0 ? tel.join(', ') : '',
                        website: webLinks.length > 0 ? webLinks.join(', ') : '',
                        vendorCode: vendorMasterInfo
                            ? vendorMasterInfo.vendor_code
                            : '',
                        notes: vendorMasterInfo ? vendorMasterInfo.notes : '',
                        tags: vendorMasterInfo ? vendorMasterInfo.tags : [],
                        is_entity_linked: vendorMasterInfo
                            ? vendorMasterInfo.is_entity_linked
                            : false,
                        primaryContacts: [
                            // TODO: Enable when actual POC is integrated and add
                            {
                                parent: null,
                                uid: uniqueId(),
                                default: true,
                                email: '',
                                fullName: '',
                                contactNumber: tel[0] ?? '',
                                notes: '',
                                isPresentInFW: false, // TODO: when actual POC is integrated make this true
                                selected: false, // TODO: when actual POC is integrated make this true
                                internalName: '',
                                isCCEmail: false,
                                isSecondaryEmail: false,
                            },
                        ],
                        seller_contacts:
                            vendorMasterInfo?.primary_vendor_contact
                                ? [vendorMasterInfo?.primary_vendor_contact]
                                : [],
                        entities: vendor.vendor_information
                            ? [props?.searchByQuery?.entityId!] //the function will not even work without entity id
                            : [],
                        is_virtual: vendor.is_virtual ?? false,
                        verification_status: vendor.verification_status,
                        is_new_invitation_row: false,
                        is_Dir_Vendor: vendor.vendor_information ? true : false,
                        vendor_internal_name: vendorMasterInfo
                            ? vendorMasterInfo.vendor_name
                            : null,
                        address_info: vendor.address_info,
                        entity_doing_business_as:
                            vendor.entity_doing_business_as,
                        vendor_contact_name:
                            vendorMasterInfo?.vendor_contact_name &&
                            vendorMasterInfo?.vendor_contact_name !== ''
                                ? vendorMasterInfo?.vendor_contact_name
                                : null,
                        vendor_contact_email:
                            vendorMasterInfo?.vendor_contact_email &&
                            vendorMasterInfo?.vendor_contact_email !== ''
                                ? vendorMasterInfo?.vendor_contact_email
                                : null,
                        seller_address_information: [],
                        seller_identifications: [],
                        custom_sections: [],
                    };
                    return newVendor;
                }
            );
            resolve(vendors);
        } catch (e) {
            reject(e);
        }
    });
};

export interface IVendorUpdatePayload {
    vendor_code: string;
    vendor_name: string;
    tags: string[];
    notes: string | null;
    primary_contact: {
        user_id: string;
        full_name: string;
        emails: string[];
        phone_numbers: string[];
    };
    seller_address_information: string[] | null;
    additional_costs: IAdditionalCostsBackend[];
    seller_identifications:
        | {
              identification_name: string;
              identification_value: string;
          }[]
        | null;
    custom_sections: ICustomSection[];
}

export const updateVendorDetails = async (
    vendorDirUid: string,
    payload: IVendorUpdatePayload
): Promise<boolean> => {
    try {
        await put(
            `/organization/vendor_master/${vendorDirUid}/admin/update/`,
            payload
        );
        return true;
    } catch (error) {
        toast.error('Error updating vendor information');
        return false;
    }
};

export const getGlobalVendorTags = async (): Promise<string[]> => {
    const response: any = await get<any>(`/organization/tags?tag_type=VENDOR`);
    const data: any[] = response.data;
    if (data) {
        return data.map((entity, index: number) => {
            return entity.name;
        });
    }
    return response;
};

export const updateVendorStatus = async (
    status: VendorStatus,
    vendorDirUid: string
): Promise<boolean> => {
    try {
        await put(
            `/organization/vendor_master/${vendorDirUid}/admin/update/state/`,
            {
                status,
            }
        );
        return true;
    } catch (error) {
        return false;
    }
};

export const deleteVendorInvitation = async (
    vendorDirUid: string
): Promise<boolean> => {
    try {
        await del(`/organization/vendor_master/${vendorDirUid}/admin/delete/`);
        return true;
    } catch (error) {
        return false;
    }
};

export const resendVendorEnterpriseInvitation = async (
    dirUid: string,
    email: string,
    portal: 'VENDOR_MASTER' | 'BUYER_MASTER'
): Promise<boolean> => {
    try {
        let payload = {};
        if (portal === 'VENDOR_MASTER') {
            payload = {
                enterprise_vendor_master_id: dirUid,
                email,
            };
        } else {
            payload = {
                enterprise_buyer_master_id: dirUid,
                email,
            };
        }
        await post(
            `/organization/${
                portal === 'VENDOR_MASTER' ? 'vendor' : 'buyer'
            }_master/admin/resend/invite/`,
            payload
        );
        return true;
    } catch (error) {
        return false;
    }
};

export const resendVendorEntitiesInvitation = async (
    dirUid: string,
    emails: string[],
    portal: 'VENDOR_MASTER' | 'BUYER_MASTER'
): Promise<boolean> => {
    try {
        if (portal === 'VENDOR_MASTER') {
            await post(
                `/organization/vendor_master/admin/resend/contact/invite/`,
                {
                    enterprise_vendor_master_id: dirUid,
                    emails: emails,
                }
            );
            return true;
        } else {
            await post(
                `/organization/buyer_master/admin/resend/contacts/invite/`,
                {
                    enterprise_buyer_master_id: dirUid,
                    emails: emails,
                }
            );
            return true;
        }
    } catch (error) {
        return false;
    }
};

interface IAddVendorPayload {
    vendor_code: string;
    vendor_name: string;
    notes: string;
    seller_enterprise_id: string;
    seller_entity_id: string;
    entity_ids: string[];
    tags: string[];
    primary_contact: {
        full_name: string;
        primary_email: string;
        phone_numbers: string[];
        emails: {
            email: string;
            type: 'SECONDARY' | 'CC';
        }[];
        notes: string | null;
        tags: string[];
    };
    // secondary_contacts: [];
    secondary_contacts: {
        full_name: string;
        primary_email: string;
        emails: {
            email: string;
            type: 'CC' | 'SECONDARY';
        }[];
        notes: string | null;
        phone_numbers: string[];
        tags: string[];
    }[];
    seller_address_information: string[] | null;
    seller_identifications:
        | {
              identification_name: string;
              identification_value: string;
          }[]
        | null;
    custom_sections: ICustomSection[];
}

export const addVendorToVendorDirectory = async (
    details: IVendorAddDetails
): Promise<{
    enterprise_vendor_master_id: string;
    vendor_code: string;
}> => {
    return new Promise(async (resolve, reject) => {
        const pc = details.primaryContacts.filter((c) => c.default)[0];
        const sc = details.primaryContacts
            .filter((c) => !c.default)
            .filter((c) => !c.parent);

        const primaryEmails: {
            email: string;
            type: 'CC' | 'SECONDARY';
        }[] = [];
        details.primaryContacts
            .filter((c) => c.parent === pc.uid)
            .forEach((c) => {
                if (c.isCCEmail)
                    primaryEmails.push({
                        email: c.email,
                        type: 'CC',
                    });

                if (c.isSecondaryEmail)
                    primaryEmails.push({
                        email: c.email,
                        type: 'SECONDARY',
                    });
            });

        const payload: IAddVendorPayload = {
            //API FOR PAYLOAD
            seller_enterprise_id: details.vendorEnterpriseUid,
            seller_entity_id: details.vendorUid,
            vendor_code: details.vendorCode,
            vendor_name: details.vendorName,
            notes: details.notes,
            tags: details.tags ?? [],
            custom_sections: details.custom_sections,
            primary_contact: {
                full_name: pc.fullName,
                primary_email: pc.email,
                phone_numbers: isEmpty(pc.contactNumber)
                    ? []
                    : [pc.contactNumber, ...sc.map((c) => c.contactNumber)],
                emails: primaryEmails,
                notes: pc.notes !== '' ? pc.notes : null,
                tags: [],
            },
            entity_ids: details.entities,
            secondary_contacts: sc.map((c) => {
                const emails: {
                    email: string;
                    type: 'CC' | 'SECONDARY';
                }[] = [];

                sc.filter((ssc) => ssc.parent === c.uid).forEach((ssc) => {
                    if (ssc.isCCEmail)
                        emails.push({
                            email: ssc.email,
                            type: 'CC',
                        });

                    if (ssc.isSecondaryEmail)
                        emails.push({
                            email: ssc.email,
                            type: 'SECONDARY',
                        });
                });

                return {
                    full_name: c.fullName,
                    phone_numbers: [c.contactNumber].filter(
                        (phone) => phone.trim().length > 0
                    ),
                    primary_email: c.email,
                    tags: [],
                    emails,
                    notes: c.notes !== '' ? c.notes : null,
                };
            }),
            // secondary_contacts: sc.map((c) => ({
            //     full_name: c.fullName,
            //     email: c.email,
            //     phone_numbers: isEmpty(c.contactNumber)
            //         ? []
            //         : [c.contactNumber],
            //     tags: [],
            // })),
            seller_address_information: details.seller_address_information?.[0]
                ? details.seller_address_information
                : ([] as any),
            seller_identifications:
                details.seller_identifications?.filter(
                    (val) => val.identification_name && val.identification_value
                ) || [],
        };
        try {
            let resp = await post<
                IAddVendorPayload,
                {
                    enterprise_vendor_master_id: string;
                    vendor_code: string;
                }
            >(`/organization/vendor_master/admin/create/`, payload);
            resolve(resp.data);
        } catch (error) {
            reject();
        }
    });
};

interface IAddCustomVendorPayload {
    vendor_code: string;
    vendor_name: string;
    notes: string;
    tags: string[];
    entity_ids: string[];
    primary_contact: {
        full_name: string;
        primary_email: string;
        emails: {
            email: string;
            type: 'SECONDARY' | 'CC';
        }[];
        notes: string | null;
        phone_numbers: string[];
        tags: string[];
    };
    secondary_contacts: {
        full_name: string;
        primary_email: string;
        emails: {
            email: string;
            type: 'SECONDARY' | 'CC';
        }[];
        notes: string | null;
        phone_numbers: string[];
        tags: string[];
    }[];
    seller_address_information: string[] | null;
    seller_identifications:
        | {
              identification_name: string;
              identification_value: string;
          }[]
        | null;
    custom_sections: ICustomSection[];
}

export const addCustomVendorToVendorDirectory = async (
    details: IVendorAddDetails
): Promise<{
    enterprise_vendor_master_id: string;
    vendor_code: string;
}> => {
    return new Promise(async (resolve, reject) => {
        const pc = details.primaryContacts.filter((c) => c.default)[0];
        const pc_phones = isEmpty(pc.contactNumber.trim())
            ? []
            : [pc.contactNumber];
        const sc = details.primaryContacts
            .filter((c) => !c.default)
            .filter((c) => !c.parent);

        const primaryEmails: {
            email: string;
            type: 'CC' | 'SECONDARY';
        }[] = [];
        details.primaryContacts
            .filter((c) => c.parent === pc.uid)
            .forEach((c) => {
                if (c.isCCEmail)
                    primaryEmails.push({
                        email: c.email,
                        type: 'CC',
                    });

                if (c.isSecondaryEmail)
                    primaryEmails.push({
                        email: c.email,
                        type: 'SECONDARY',
                    });
            });
        const payload: IAddCustomVendorPayload = {
            vendor_code: details.vendorCode,
            vendor_name: details.vendorName,
            notes: details.notes,
            tags: details.tags,
            entity_ids: details.entities,
            primary_contact: {
                full_name: pc.fullName,
                primary_email: pc.email,
                phone_numbers: [
                    ...pc_phones,
                    ...sc
                        .filter((c) => c.parent === pc.uid)
                        .map((c) => c.contactNumber)
                        .filter((c) => c.length !== 0),
                ],
                notes: pc.notes !== '' ? pc.notes : null,
                emails: primaryEmails,
                tags: [],
            },
            custom_sections: details.custom_sections,
            secondary_contacts: sc.map((c) => {
                const emails: {
                    email: string;
                    type: 'CC' | 'SECONDARY';
                }[] = [];

                details.primaryContacts
                    .filter((ssc) => ssc.parent === c.uid)
                    .forEach((ssc) => {
                        if (ssc.isCCEmail)
                            emails.push({
                                email: ssc.email,
                                type: 'CC',
                            });

                        if (ssc.isSecondaryEmail)
                            emails.push({
                                email: ssc.email,
                                type: 'SECONDARY',
                            });
                    });

                return {
                    full_name: c.fullName,
                    phone_numbers: [c.contactNumber].filter(
                        (phone) => phone.trim.length > 0
                    ),
                    primary_email: c.email,
                    tags: [],
                    emails,
                    notes: c.notes !== '' ? c.notes : null,
                };
            }),
            seller_address_information: details.seller_address_information?.[0]
                ? details.seller_address_information
                : ([] as any),
            seller_identifications:
                details.seller_identifications?.filter(
                    (val) => val.identification_name && val.identification_value
                ) || [],
        };
        try {
            let resp = await post<
                IAddCustomVendorPayload,
                {
                    enterprise_vendor_master_id: string;
                    vendor_code: string;
                }
            >(`/organization/vendor_master/admin/invite/create/`, payload);
            resolve(resp.data);
        } catch (error) {
            reject();
        }
    });
};

interface IVendorContactAddPayload {
    seller_entity_id?: string;
    buyer_entity_id?: string;
    full_name: string;
    primary_email: string;
    phone_numbers: string[];
    tags: string[];
    is_primary: boolean;
    emails: {
        email: string;
        type: 'SECONDARY' | 'CC';
    }[];
    department: string;
    designation: string;
}

export const addContactToVendorDirectory = async (
    entityUid: string,
    vendorUid: string,
    details: IVendorContactAdd,
    portal: 'VENDOR_MASTER' | 'BUYER_MASTER'
): Promise<string | null> => {
    const payload: IVendorContactAddPayload = {
        [portal === 'BUYER_MASTER' ? 'buyer_entity_id' : 'seller_entity_id']:
            vendorUid,
        full_name: details.fullName,
        primary_email: details.email,
        phone_numbers: details.buyerContacts
            .map((contact) => contact.number)
            .filter((contact) => !isEmpty(contact)),
        tags: [],
        is_primary: false,
        emails: details.secondaryEmails.map((cce) => ({
            email: cce,
            type: 'SECONDARY',
        })),
        designation: details.designation ?? '',
        department: details.department ?? '',
    };
    try {
        const contactID = await post<any, any>(
            portal === 'VENDOR_MASTER'
                ? `/organization/vendor_master/admin/entity/${entityUid}/contacts/invite/create/`
                : `/organization/buyer_master/admin/entity/${entityUid}/contacts/create/`,
            payload
        );
        return (
            contactID.data?.vendor_contact_id ||
            contactID.data?.buyer_contact_id
        );
    } catch (error) {
        return null;
    }
};

export interface IAdditionalCost {
    additional_cost: {
        additional_cost_id: string;
        cost_name: string;
        cost_type: CostTypeEnum;
        allocation_type: AllocationTypeEnum | null;
        cost_value: number | null;
    };
    cost_source: CostSourceEnum;
    cost_value: number | null;
}

export interface IVendorIdentification {
    identification_id: string;
    entity_id: string;
    identification_name: string;
    identification_category: string;
    identification_value: string;
    entity_name: string;
    is_public: boolean;
    is_default: boolean;
    linked_addresses: any[];
}

export const getEntityIdentifications = async (entity_id: string) => {
    try {
        let resp = await get<IVendorIdentification[]>(
            `/organization/enterprise_entity_identification?entity_id=${entity_id}`
        );

        return resp.data;
    } catch (e) {}
};

export const fetchVendorDetails = async (
    vendorDirUid: string
): Promise<IVendorDirDetails> => {
    const response: any = await get<any>(
        `/organization/vendor_master/${vendorDirUid}/admin/`
    );
    const data: any = response.data;

    if (data) {
        const { primary_vendor_contact: pc, tags } = data;
        const vendor: IVendorDirDetails = {
            vendorCode: data.vendor_code,
            vendorName: data.vendor_name,
            custom_sections: data.custom_sections ?? [],
            secondaryContact: data?.secondary_contacts?.map(
                (vendorSecContact: IVendorLinkSecondaryContactResponse) => ({
                    fullName: vendorSecContact?.full_name,
                    emails: vendorSecContact?.emails,
                    userUid: vendorSecContact?.user,
                    vendorContactUid: vendorSecContact?.vendor_contact_id,
                    invitationStatus: vendorSecContact?.status,
                })
            ),
            primaryContact: {
                fullName: pc ? pc.full_name : '',
                email: pc ? pc.primary_email : '',
                contacts: pc ? pc.phone_numbers : [''],
                userUid: pc ? pc.user : '',
                vendorContactUid: pc ? pc.vendor_contact_id : '',
                invitationStatus: pc ? pc.status : 'INVITED',
                notes: pc.notes || '',
            },
            vendorUid: data.seller_entity || '',
            vendorEnterpriseUid: data.seller_enterprise || '',
            vendorStatus: VendorStatus.ACTIVE,
            vendorVerificationStatus: VendorVerificationStatus.INVITATION_HOLD,
            vendorDirUid: data.enterprise_vendor_master_id,
            additional_costs: data.additional_costs.map(
                (cost: IAdditionalCost) => ({
                    ...cost.additional_cost,
                    cost_value: cost.cost_value,
                })
            ),
            notes: data.notes || '',
            contactsList: data.secondary_vendor_contacts.map((contact: any) => {
                return {
                    fullName: contact.full_name,
                    email: contact.primary_email,
                    phone:
                        contact.phone_numbers.length > 0
                            ? contact.phone_numbers[0]
                            : '',
                    invited: true,
                };
            }),
            tags: tags ?? [],
            seller_address_information: data?.seller_address_information
                ? data?.seller_address_information
                : ([] as any),
            seller_identifications: data.seller_identifications
                ? data.seller_identifications
                : ([] as any),
        };
        return vendor;
    }

    return response;
};

export const { useGetListOfAllVendorForListOfItemsQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            getListOfAllVendorForListOfItems: build.query<
                {
                    [
                        enterprise_item_id: string
                    ]: IBackendVendorMasterMultipleItemResponse[];
                },
                { entity_id: string; enterprise_item_ids: string[] }
            >({
                queryFn: async ({ entity_id, enterprise_item_ids }) => {
                    try {
                        const response = await get<
                            IBackendVendorMasterMultipleItemResponse[]
                        >(
                            `/organization/vendor_master/entity/${entity_id}/items/?${enterprise_item_ids
                                .map(
                                    (enterprise_item_id) =>
                                        `item_ids[]=${enterprise_item_id}`
                                )
                                .join('&')}`
                        );

                        const data: {
                            [
                                enterprise_item_id: string
                            ]: IBackendVendorMasterMultipleItemResponse[];
                        } = {};

                        response.data.forEach((singleVendorItemForItems) => {
                            if (
                                singleVendorItemForItems.buyer_entity ===
                                entity_id
                            )
                                if (
                                    singleVendorItemForItems.enterprise_item
                                        .enterprise_item_id in data
                                ) {
                                    data[
                                        singleVendorItemForItems.enterprise_item
                                            .enterprise_item_id
                                    ].push(singleVendorItemForItems);
                                } else {
                                    data[
                                        singleVendorItemForItems.enterprise_item.enterprise_item_id
                                    ] = [singleVendorItemForItems];
                                }
                        });

                        return { data };
                    } catch (err: any) {
                        return { error: err };
                    }
                },
            }),
        }),
    });

export const fetchPreferredItems = async (
    entityUid: string,
    vendorUid: string
): Promise<IPreferredItem[]> => {
    const response: any = await get<any>(
        `/organization/vendor_master/entity/${entityUid}/items/?seller_entity_id=${vendorUid}`
    );
    const data: any[] = response.data;
    if (data) {
        const vendors: IPreferredItem[] = data
            .filter(
                (item) =>
                    !isNull(item.enterprise_item) &&
                    item.status === PreferredItemStatus.PREFERRED &&
                    item.enterprise_item.status === ItemStatus.ACTIVE
            )
            .map((item, idx) => {
                const { enterprise_item: itm } = item;
                const newItem: IPreferredItem = {
                    itemName: itm.name,
                    itemId: itm.code,
                    itemUid: itm.enterprise_item_id,
                    itemStatus: item.status,
                };
                return newItem;
            });

        return vendors;
    }
    return response;
};

export interface IPayloadItem {
    enterprise_item: string;
    status: PreferredItemStatus;
}

export interface IVendorItemUpdatePayload {
    buyer_entity: string;
    status: 'STANDARD';
    items: IPayloadItem[];
}

export const updateVendorItems = async (
    vendorDirUid: string,
    payload: IVendorItemUpdatePayload
): Promise<boolean> => {
    try {
        await put<any, IVendorItemUpdatePayload>(
            `/organization/vendor_master/${vendorDirUid}/admin/entity/update/`,
            [payload]
        );
        return true;
    } catch (error) {
        return false;
    }
};

export const fetchVendorContactsList = async (
    entityUid: string,
    vendorUid: string,
    portal: 'VENDOR_MASTER' | 'BUYER_MASTER'
): Promise<IVendorContact[]> => {
    const response: any = await get<any>(
        portal === 'VENDOR_MASTER'
            ? `/organization/vendor_master/admin/entity/${entityUid}/contacts/?seller_entity_id=${vendorUid}`
            : `/organization/buyer_master/admin/entity/${entityUid}/contacts/?buyer_entity_id=${vendorUid}`
    );

    const data: any[] = response.data;

    if (data) {
        const contacts: IVendorContact[] = data
            .map((contact, index) => {
                const newContact: IVendorContact = {
                    index,
                    fullName: contact.full_name,
                    sellerEmails: [
                        {
                            email: contact.primary_email,
                            visible: true,
                        },
                    ],
                    role: contact.user.role,
                    department: contact.department,
                    designation: contact.designation,
                    buyerEmails: [],
                    sellerContacts:
                        contact.user &&
                        contact.user.contacts &&
                        contact.user.contacts.phone_numbers
                            ? contact.user.contacts.phone_numbers.map(
                                  ({ phone, is_public }: any) => ({
                                      number: phone,
                                      visible: is_public,
                                  })
                              )
                            : [],
                    buyerContacts:
                        contact.phone_numbers.length > 0
                            ? contact.phone_numbers.map((phone: string) => ({
                                  number: phone,
                                  visible: true,
                              }))
                            : [
                                  {
                                      number: '',
                                      visible: true,
                                  },
                              ],
                    userUid: contact.user ? contact.user.user_id : '',
                    vendorContactUid:
                        portal === 'VENDOR_MASTER'
                            ? contact.vendor_contact_id
                            : contact.buyer_contact_id,
                    isPrimary: contact.is_primary,
                    invitationStatus: contact.user
                        ? 'ACTIVE'
                        : 'INVITATION_PENDING',
                    notes: contact.notes || '',
                    status: contact.status,
                    tags: contact.tags ?? [],
                    CCEmail: contact.emails
                        ?.filter(
                            (email_details: { email: string; type: string }) =>
                                email_details.type === 'CC'
                        )
                        .map(
                            (email_details: { email: string; type: string }) =>
                                email_details.email
                        ),
                    secondaryEmails: contact.emails
                        ?.filter(
                            (email_details: { email: string; type: string }) =>
                                email_details.type === 'SECONDARY'
                        )
                        .map(
                            (email_details: { email: string; type: string }) =>
                                email_details.email
                        ),
                };
                return newContact;
            })
            .sort((a) => (a.isPrimary ? -1 : 1));

        return contacts;
    }
    return response;
};

export const deleteContactFromDirectory = async (
    entityUid: string,
    vendorContactUid: string,
    portal: 'VENDOR_MASTER' | 'BUYER_MASTER'
): Promise<boolean> => {
    try {
        await del<any>(
            portal === 'VENDOR_MASTER'
                ? `/organization/vendor_master/admin/entity/${entityUid}/contacts/${vendorContactUid}/delete/`
                : `/organization/buyer_master/admin/entity/${entityUid}/contacts/${vendorContactUid}/delete/`
        );
        return true;
    } catch (error) {
        return false;
    }
};

export const makeContactPrimary = async (
    entityUid: string,
    vendorContactUid: string,
    portal: 'VENDOR_MASTER' | 'BUYER_MASTER'
): Promise<boolean> => {
    try {
        await patch<any, any>(
            portal === 'VENDOR_MASTER'
                ? `/organization/vendor_master/admin/entity/${entityUid}/contacts/${vendorContactUid}/primary/`
                : `/organization/buyer_master/admin/entity/${entityUid}/contacts/${vendorContactUid}/primary/`,
            {}
        );
        return true;
    } catch (error) {
        return false;
    }
};

interface IUpdateContactPayload {
    seller_entity_id?: string;
    buyer_entity_id?: string;
    full_name: string;
    emails: {
        email: string;
        type: 'SECONDARY' | 'CC';
    }[];
    phone_numbers: string[];
    tags: [];
    is_primary: boolean;
    notes: string;
    designation?: string;
    department?: string;
}

export const updateContactInDirectory = async (
    entityUid: string,
    vendorContactUid: string,
    vendorUid: string,
    details: IVendorContact,
    portal: 'VENDOR_MASTER' | 'BUYER_MASTER'
): Promise<boolean> => {
    const emails: {
        email: string;
        type: 'SECONDARY' | 'CC';
    }[] = details.CCEmail.map((ccEmail) => ({
        email: ccEmail,
        type: 'CC',
    }));
    details.secondaryEmails.forEach((email) => {
        emails.push({
            email,
            type: 'SECONDARY',
        });
    });
    const payload: IUpdateContactPayload = {
        [portal === 'VENDOR_MASTER' ? 'seller_entity_id' : 'buyer_entity_id']:
            vendorUid,
        full_name: details.fullName,
        emails,
        phone_numbers: details.buyerContacts
            .map((e) => e.number)
            .filter((num) => !isEmpty(num)),
        is_primary: details.isPrimary,
        tags: [],
        notes: details.notes,
        designation: details.designation,
        department: details.department,
    };
    try {
        await put(
            portal === 'VENDOR_MASTER'
                ? `/organization/vendor_master/admin/entity/${entityUid}/contacts/${vendorContactUid}/update/`
                : `/organization/buyer_master/admin/entity/${entityUid}/contacts/${vendorContactUid}/update/`,
            payload
        );
        return true;
    } catch (error) {
        return false;
    }
};

export const updateGlobalContactInDirectory = async (
    vendorContactUid: string,
    vendorUid: string,
    details: IVendorContact
): Promise<boolean> => {
    const payload: IUpdateContactPayload = {
        // const payload: any = {
        seller_entity_id: vendorUid,
        full_name: details.fullName,
        // emails:
        // emails: details.buyerEmails.map((e) => e.email),
        emails: [],
        phone_numbers: details.buyerContacts
            .map((e) => e.number)
            .filter((num) => !isEmpty(num)),
        is_primary: details.isPrimary,
        tags: [],
        notes: details.notes,
    };
    try {
        await put(
            `/organization/vendor_master/admin/enterprise/contacts/${vendorContactUid}/update/`,
            payload
        );
        return true;
    } catch (error) {
        return false;
    }
};

export const makeContactGlobalPrimary = async (
    vendorContactUid: string,
    portal: 'VENDOR_MASTER' | 'BUYER_MASTER'
): Promise<boolean> => {
    try {
        await patch<any, any>(
            portal === 'VENDOR_MASTER'
                ? `/organization/vendor_master/admin/enterprise/contacts/${vendorContactUid}/primary/`
                : `/organization/buyer_master/admin/enterprise/contacts/${vendorContactUid}/primary/`,
            {}
        );
        return true;
    } catch (error) {
        return false;
    }
};

export const inviteNewInviteesToVendor = async (
    details: IVendorDirDetails
): Promise<boolean> => {
    try {
        await post(`/organization/vendor_master/admin/invite/contact/add/`, {
            enterprise_vendor_master_id: details.vendorDirUid,
            secondary_contacts: details.contactsList
                .filter((c) => !c.invited)
                .map((c) => ({
                    full_name: c.fullName,
                    email: c.email,
                    phone_numbers: [c.phone],
                    tags: [],
                })),
        });
        return true;
    } catch (error) {
        return false;
    }
};

export const getVendorTempalteList = async () => {
    let res = await get<any>('/module_templates/?template_type=VENDOR');

    return res.data;
};

export const getVendorTemplateFields = async () => {
    let templateDetails: ITemplateResponse[] = await getVendorTempalteList();

    //

    let res = await get<any>(
        `/module_templates/${templateDetails[0].templates[0].entity_id}/${templateDetails[0].templates[0].template_id}/`
    );

    let templateFields: IITemTemplateFieldResponse = res.data;

    //

    return {
        templateFields,
        template_id: templateDetails[0].templates[0].template_id,
    };
};
