import { Box, Grid, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import React, { Fragment } from 'react';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import LoadingBackDrop from '../../Components/Shared/LoadingBackdrop';
import { getBuyerItemName } from '../../Components/Shared/Utils';
import { calculateItemTotal } from '../../Components/Shared/totalCalculator';
import // IEventItemCustomField,
// // IEventItemCustomFieldsBackend,
// custom_field_backend_section_names,
'../../Events/Interfaces/Buyer/RFQ.model';
import { IRFQItemCustomIDs } from '../../Events/Interfaces/Buyer/SharedRFQ.model';
import { fetchItemDetails } from '../../Organizations/Admin/Services/ItemDirectoryService';
import { replaceUrlWithLink } from '../Constants/WebUrlRegex';
import {
    isValueValidForStringArrNull,
    stringValueForStringArrNull,
} from '../Helpers/helpers';
import {
    ICustomField,
    ICustomSection,
    IRfqTemplate,
    ISectionField,
    TemplateStandardFieldsEnum,
} from '../Interfaces/TemplateInterface';
import TagsDisplay from './TagsDisplay';
import { ICustomAttributeNew } from '../../Models/Attributes.model';

interface ItemData {
    item_information: {
        buyer_item_id: string;
        buyer_item_name: string;
        custom_item_name: string;
        buyer_item_description: string;
        item_additional_details: string;
        custom_ids?: IRFQItemCustomIDs[];
        global_item_id?: string;
    };
    attributes?: ICustomAttributeNew[];
    attribute_information: {
        buyer_item_attributes: {
            attribute_name: string;
            attribute_value: string[];
        }[];
        custom_item_attributes: {
            attribute_name: string;
            attribute_value: string[];
        }[];
    };
    custom_sections: ICustomSection[];
    gr_tolerance_percentage?: string;
    enterprise_item?: string;
    quantity_information?: {
        quantity: string;
        measurement_unit_name: string;
    };
    procurement_information?: {
        lead_time?: string;
        payment_terms?: string | null;
        lead_time_period?: string;
        allow_substitutes?: boolean;
        payment_terms_period?: string | null;
        is_price_confidential?: boolean;
        prepayment_percentage?: string;
        payment_terms_applied_from?: string | null;
    };
    pricing_information?: {
        currency_name?: string;
        desired_price?: string;
        currency_symbol?: string;
        currency_code_id?: string;
        currency_code_abbreviation?: string;
        additional_charges?: { charge_name: string; charge_value: string }[];
        shipping_per_unit?: string;
    };
    incoterm_abbreviation?: string | null;
}

interface IDatagridItemPopup {
    open: boolean;
    setOpen: (State: boolean) => void;
    itemDetails: ItemData | null;
    showWarnings?: boolean;
    enterprise_type: 'BUYER' | 'SELLER';
    template_info?: IRfqTemplate;
}

export const DatagridItemPopup = ({
    open,
    setOpen,
    itemDetails,
    showWarnings = false,
    enterprise_type,
    template_info,
}: IDatagridItemPopup) => {
    const [nonNegotiationTemplate, setNonNegotiationTemplate] = React.useState<
        ISectionField[]
    >([]);
    const [itemTags, setItemTags] = React.useState<string[]>([]);
    const [isTagsLoading, setIsTagsLoading] = React.useState<boolean>(
        enterprise_type === 'BUYER' ? true : false
    );
    React.useEffect(() => {
        if (template_info) {
            const filterNonNegotiationTerms = (field: ISectionField) => {
                if (
                    field.field === 'CUSTOM_SPECIFICATIONS' ||
                    field.field === 'BOM'
                ) {
                    return false;
                }
                if (
                    field.field !== 'GR_TOLERANCE' &&
                    field.fieldType === 'STANDARD' &&
                    template_info.fieldSettings.standardFields[field.field]
                        .sellerSettings.negotiable === false
                ) {
                    return true;
                }
                return false;
            };
            let nonNegotiationTerms = [
                ...template_info?.sections?.ESSENTIAL_TERMS?.fieldList.filter(
                    filterNonNegotiationTerms
                ),
                ...template_info?.sections?.PAYMENT_AND_DELIVERY_TERMS?.fieldList.filter(
                    filterNonNegotiationTerms
                ),
            ];

            setNonNegotiationTemplate(nonNegotiationTerms);
        }
    }, [template_info]);
    React.useEffect(() => {
        if (open) {
            if (enterprise_type === 'BUYER') {
                if (itemDetails?.enterprise_item) {
                    fetchItemDetails(itemDetails?.enterprise_item).then(
                        (res) => {
                            setItemTags(res.tags ?? []);
                            setIsTagsLoading(false);
                        }
                    );
                } else if (itemDetails?.item_information?.global_item_id) {
                    fetchItemDetails(
                        itemDetails?.item_information?.global_item_id
                    ).then((res) => {
                        setItemTags(res.tags ?? []);
                        setIsTagsLoading(false);
                    });
                } else {
                    setIsTagsLoading(false);
                }
                return () => {
                    setItemTags([]);
                    setIsTagsLoading(true);
                };
            }
        }
    }, [
        itemDetails?.enterprise_item,
        enterprise_type,
        itemDetails?.item_information,
        open,
    ]);
    const handleClose = () => setOpen(false);

    let customFieldSectionEssentialTerms = null;
    let customFieldSectionPaymentAndDelivery = null;
    let customFieldSectionAdditionalInformation = null;
    if (itemDetails && itemDetails.custom_sections) {
        customFieldSectionEssentialTerms = itemDetails.custom_sections.find(
            (section) =>
                section.name ===
                    template_info?.sections.ESSENTIAL_TERMS.label ||
                section.name === 'Item Terms'
        );

        customFieldSectionPaymentAndDelivery = itemDetails.custom_sections.find(
            (section) =>
                section.name ===
                    template_info?.sections.PAYMENT_AND_DELIVERY_TERMS.label ||
                section.name === 'Payment and Delivery Terms'
        );

        customFieldSectionAdditionalInformation =
            itemDetails.custom_sections.find(
                (section) =>
                    section.name ===
                        template_info?.sections.ADDITIONAL_DETAILS.label ||
                    section.name === 'Additional Terms'
            );
    }

    let customFieldsEssentialTerms: ICustomField[] = [];
    let customFieldsPaymentAndDeliveryTerms: ICustomField[] = [];
    let customFieldsAdditional: ICustomField[] = [];

    if (customFieldSectionEssentialTerms) {
        customFieldsEssentialTerms =
            customFieldSectionEssentialTerms.custom_fields;
        customFieldsEssentialTerms = customFieldsEssentialTerms.filter(
            (field) => field.name.trim() !== ''
        );
        if (enterprise_type === 'SELLER') {
            customFieldsEssentialTerms = customFieldsEssentialTerms.filter(
                (field) => field.is_visible
            );
        }
    }

    //Removed the value part to show empty custom fields with a - sign
    if (customFieldSectionPaymentAndDelivery) {
        customFieldsPaymentAndDeliveryTerms =
            customFieldSectionPaymentAndDelivery.custom_fields;
        customFieldsPaymentAndDeliveryTerms =
            customFieldsPaymentAndDeliveryTerms.filter(
                (field) => field.name.trim() !== ''
            );
        if (enterprise_type === 'SELLER') {
            customFieldsPaymentAndDeliveryTerms =
                customFieldsPaymentAndDeliveryTerms.filter(
                    (field) => field.is_visible
                );
        }
    }

    if (customFieldSectionAdditionalInformation) {
        customFieldsAdditional =
            customFieldSectionAdditionalInformation.custom_fields;
        customFieldsAdditional = customFieldsAdditional.filter(
            (field) => field.name.trim() !== ''
        );
        if (enterprise_type === 'SELLER') {
            customFieldsAdditional = customFieldsAdditional.filter(
                (field) => field.is_visible
            );
        }
    }
    // custom fields negotiate
    // creatinng customerfieldsectionpayment and customfieldadditionInformation for negotiable fields
    // let customFieldNegotiateSectionEssentialTerms = null;
    // let customFieldNegotiateSectionPaymentAndDelivery = null;
    // let customFieldNegotiateSectionAdditionalInformation = null;
    // if (
    //     itemDetails &&
    //     itemDetails.custom_sections
    // ) {
    //     customFieldNegotiateSectionEssentialTerms =
    //         itemDetails.custom_sections.find(
    //             (section) =>
    //                 section.name ===
    //                     template_info?.sections.ESSENTIAL_TERMS.label ||
    //                 section.name === 'Item Terms'
    //         );
    //     customFieldNegotiateSectionPaymentAndDelivery =
    //         itemDetails.custom_sections.find(
    //             (section) =>
    //                 section.name ===
    //                template_info?.sections.PAYMENT_AND_DELIVERY_TERMS.label ||
    //                 section.name === 'Payment and Delivery Terms'
    //         );

    //     customFieldNegotiateSectionAdditionalInformation =
    //         itemDetails.custom_fields_negotiate?.section_list.find(
    //             (section: { name: string; fields: IEventItemCustomField[] }) =>
    //                 section.name ===
    //                 custom_field_backend_section_names.additional_information_template_name
    //         );
    // }

    // let customFieldsNegotiateEssentialTerms: IEventItemCustomField[] = [];
    // let customFieldsNegotiatePaymentAndDeliveryTerms: IEventItemCustomField[] =
    //     [];
    // let customFieldsNegotiateAdditional: IEventItemCustomField[] = [];

    // //Removed the value part to show empty custom fields with a - sign
    // if (customFieldNegotiateSectionEssentialTerms) {
    //     customFieldsNegotiateEssentialTerms =
    //         customFieldNegotiateSectionEssentialTerms.fields;
    //     customFieldsNegotiateEssentialTerms =
    //         customFieldsNegotiateEssentialTerms.filter(
    //             (field) => field.name.trim() !== ''
    //         );
    // }

    // if (customFieldNegotiateSectionPaymentAndDelivery) {
    //     customFieldsNegotiatePaymentAndDeliveryTerms =
    //         customFieldNegotiateSectionPaymentAndDelivery.fields;
    //     customFieldsNegotiatePaymentAndDeliveryTerms =
    //         customFieldsNegotiatePaymentAndDeliveryTerms.filter(
    //             (field) => field.name.trim() !== ''
    //         );
    // }

    // if (customFieldNegotiateSectionAdditionalInformation) {
    //     customFieldsNegotiateAdditional =
    //         customFieldNegotiateSectionAdditionalInformation.fields;
    //     customFieldsNegotiateAdditional =
    //         customFieldsNegotiateAdditional.filter(
    //             (field) => field.name.trim() !== ''
    //         );
    // }

    const subtotal = calculateItemTotal(
        itemDetails?.quantity_information?.quantity ?? 0,
        itemDetails?.pricing_information?.desired_price ?? 0,
        0,
        []
    );
    const formatCurrency = (
        value: number,
        currency_code_abbreviation: string
    ) => FWNumberFormatter(value, currency_code_abbreviation);

    const getTotalTaxWithCurrency = React.useMemo(() => {
        let total_tax = 0;
        let tax = 0;
        itemDetails?.pricing_information?.additional_charges?.forEach((tax) => {
            total_tax += parseFloat(tax.charge_value);
        });

        if (subtotal) {
            tax = (total_tax / 100) * subtotal;
        } else {
            return `${total_tax}%`;
        }
        if (tax === 0) {
            return '-';
        } else if (
            itemDetails?.pricing_information?.currency_code_abbreviation
        ) {
            return formatCurrency(
                tax,
                itemDetails?.pricing_information?.currency_code_abbreviation
            );
        } else {
            return tax.toFixed(2);
        }
    }, [
        subtotal,
        itemDetails?.pricing_information?.additional_charges,
        itemDetails?.pricing_information?.currency_code_abbreviation,
    ]);

    const getStandardFieldValue = (field: TemplateStandardFieldsEnum) => {
        if (!itemDetails) return <></>;
        switch (field) {
            case TemplateStandardFieldsEnum.QUANTITY:
                return (
                    <>
                        {FWNumberFormatter(
                            parseFloat(
                                itemDetails?.quantity_information?.quantity ??
                                    ''
                            )
                        )}{' '}
                        {
                            itemDetails?.quantity_information
                                ?.measurement_unit_name
                        }
                        (s)
                    </>
                );
            case TemplateStandardFieldsEnum.BASE_RATE:
                return (
                    <>
                        {itemDetails?.pricing_information?.desired_price ===
                        null
                            ? 'Not shared'
                            : `${FWNumberFormatter(
                                  parseFloat(
                                      itemDetails.pricing_information
                                          ?.desired_price ?? ''
                                  ),
                                  itemDetails.pricing_information
                                      ?.currency_code_abbreviation ?? ''
                              )}/${
                                  itemDetails.quantity_information
                                      ?.measurement_unit_name
                              }`}
                    </>
                );
            case TemplateStandardFieldsEnum.PAYMENT_TERMS:
                return (
                    <>
                        {itemDetails.procurement_information?.payment_terms}{' '}
                        {itemDetails.procurement_information?.payment_terms_period?.toLowerCase()}{' '}
                        from{' '}
                        {itemDetails.procurement_information?.payment_terms_applied_from
                            ?.toLowerCase()
                            .split('_')
                            .join(' ')}
                    </>
                );

            case TemplateStandardFieldsEnum.INCOTERMS:
                return <>{itemDetails.incoterm_abbreviation}</>;
            case TemplateStandardFieldsEnum.LEAD_TIME:
                return (
                    <>
                        {itemDetails.procurement_information?.lead_time}{' '}
                        {itemDetails.procurement_information?.lead_time_period?.toLowerCase()}
                    </>
                );
            case TemplateStandardFieldsEnum.SHIPPING_RATE:
                return (
                    <>
                        {+itemDetails.pricing_information?.shipping_per_unit! >
                        0
                            ? `${formatCurrency(
                                  parseFloat(
                                      itemDetails.pricing_information
                                          ?.shipping_per_unit!
                                  ),
                                  itemDetails.pricing_information
                                      ?.currency_code_abbreviation!
                              )}/${
                                  itemDetails.quantity_information
                                      ?.measurement_unit_name
                              }`
                            : ''}
                    </>
                );
            case TemplateStandardFieldsEnum.TAX:
                return <>{getTotalTaxWithCurrency}</>;
        }
    };

    const buyerAttributes = itemDetails?.attributes?.filter(
        (attr) => attr.attribute_id !== null
    );
    const customAttributes = itemDetails?.attributes?.filter(
        (attr) => attr.attribute_id === null
    );

    return (
        <>
            {open && isTagsLoading && <LoadingBackDrop />}
            <FWPopupWithChildren
                title={
                    itemDetails
                        ? getBuyerItemName(itemDetails.item_information)
                        : ''
                }
                open={open && !isTagsLoading}
                handleClose={handleClose}
                size={'medium'}
            >
                {itemDetails ? (
                    <DialogContent sx={{ m: '0px' }}>
                        {/* <ViewTerms item_details={itemDetails} /> */}

                        <Grid container columnSpacing={2} rowSpacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        color: 'text.secondary',
                                        wordWrap: 'break-word',
                                    }}
                                    width="100%"
                                >
                                    {/*TODO: HIDDEN FOR DEMO */}
                                    {/* <>
                                        Item ID:
                                        {
                                            itemDetails.item_information
                                                .buyer_item_id
                                        }
                                    </>
                                    {(
                                        itemDetails.item_information
                                            .custom_ids ?? []
                                    ).map((id: any, idx: number) => (
                                        <>
                                            &nbsp; | &nbsp; {id.name}:{id.value}
                                        </>
                                    ))} */}
                                    {buyerAttributes?.map((attr, idx) => (
                                        <Fragment key={idx}>
                                            {attr.attribute_name}:{' '}
                                            {attr.attribute_values
                                                .map((attrVal) => attrVal.value)
                                                .join('/')}
                                            {buyerAttributes?.length - 1 !==
                                                idx && <>&nbsp; | &nbsp;</>}
                                        </Fragment>
                                    ))}
                                    {customAttributes &&
                                        customAttributes?.length > 0 && (
                                            <>&nbsp; | &nbsp;</>
                                        )}
                                    {customAttributes?.map((attr, idx) => (
                                        <span style={{ color: 'orange' }}>
                                            <Fragment key={idx}>
                                                {attr.attribute_name}:
                                                {attr.attribute_values
                                                    .map(
                                                        (attrVal) =>
                                                            attrVal.value
                                                    )
                                                    .join('/')}
                                                {customAttributes?.length -
                                                    1 !==
                                                    idx && <>&nbsp; | &nbsp;</>}
                                            </Fragment>
                                        </span>
                                    ))}
                                </Typography>
                            </Grid>
                            {enterprise_type === 'BUYER' &&
                                (itemDetails?.enterprise_item ||
                                    itemDetails?.item_information
                                        ?.global_item_id) && (
                                    <Grid item xs={12}>
                                        <Typography
                                            sx={{ color: 'text.secondary' }}
                                        >
                                            Tags
                                        </Typography>
                                        <Typography>
                                            {/* Adding Loader to show tags loading */}

                                            {itemTags.length > 0 ? (
                                                //Made a fragment to make sure multiple components are rendered
                                                <TagsDisplay tags={itemTags} />
                                            ) : (
                                                '-'
                                            )}
                                        </Typography>
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <FWTypography sx={{ color: 'text.secondary' }}>
                                    Description
                                </FWTypography>
                                <FWTypography>
                                    {itemDetails.item_information
                                        .buyer_item_description === ''
                                        ? '-'
                                        : itemDetails.item_information
                                              .buyer_item_description}
                                </FWTypography>
                            </Grid>

                            {nonNegotiationTemplate.length > 0 &&
                                nonNegotiationTemplate.map((field, index) => {
                                    return (
                                        <Grid item xs={'auto'}>
                                            <FWTypography color="text.secondary">
                                                {
                                                    template_info?.fieldSettings
                                                        .standardFields[
                                                        field.field as TemplateStandardFieldsEnum
                                                    ].label
                                                }
                                                <FWTooltip
                                                    title={
                                                        template_info
                                                            ?.fieldSettings
                                                            .standardFields
                                                            .QUANTITY
                                                            .sellerSettings
                                                            .negotiable
                                                            ? 'Visbile to Seller but non-negotiable'
                                                            : 'Hidden from Seller'
                                                    }
                                                >
                                                    <i
                                                        className={
                                                            template_info
                                                                ?.fieldSettings
                                                                .standardFields
                                                                .QUANTITY
                                                                .sellerSettings
                                                                .showField
                                                                ? 'bi bi-eye'
                                                                : 'bi bi-eye-slash'
                                                        }
                                                    ></i>
                                                </FWTooltip>
                                                <FWTypography>
                                                    {getStandardFieldValue(
                                                        field.field as TemplateStandardFieldsEnum
                                                    )}
                                                </FWTypography>
                                            </FWTypography>
                                        </Grid>
                                    );
                                })}
                            {/* {customFieldsNegotiateEssentialTerms.map(
                                (field, index) => {
                                    return (
                                        <Grid item xs={'auto'} key={index}>
                                            <FWTypography color="text.secondary">
                                                {field.name}{' '}
                                                <FWTooltip
                                                    title={
                                                        'Negotiable with seller'
                                                    }
                                                >
                                                    <i
                                                        className={
                                                            'bi bi-currency-exchange'
                                                        }
                                                    />
                                                </FWTooltip>
                                            </FWTypography>
                                            <FWTypography>
                                                {isValueValidForStringArrNull(
                                                    field.value
                                                ) ? (
                                                    <Box
                                                        component="span"
                                                        dangerouslySetInnerHTML={{
                                                            __html: replaceUrlWithLink(
                                                                stringValueForStringArrNull(
                                                                    field.value
                                                                )
                                                            ),
                                                        }}
                                                    ></Box>
                                                ) : (
                                                    'NA(None)'
                                                )}
                                            </FWTypography>
                                        </Grid>
                                    );
                                }
                            )} */}
                            {customFieldsEssentialTerms.map((field, index) => {
                                return (
                                    <Grid item xs={'auto'} key={index}>
                                        <FWTypography color="text.secondary">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '0.5rem',
                                                }}
                                            >
                                                {field.name}{' '}
                                                {field.description && (
                                                    <FWTooltip
                                                        title={
                                                            field.description
                                                        }
                                                    >
                                                        <Box color="primary.main">
                                                            <i className="bi bi-info-circle"></i>
                                                        </Box>
                                                    </FWTooltip>
                                                )}
                                                <FWTooltip
                                                    title={
                                                        field.is_visible
                                                            ? 'Visbile to Seller but non-negotiable'
                                                            : 'Hidden from Seller'
                                                    }
                                                >
                                                    <i
                                                        className={
                                                            field.is_visible
                                                                ? 'bi bi-eye'
                                                                : 'bi bi-eye-slash'
                                                        }
                                                    ></i>
                                                </FWTooltip>
                                            </div>
                                        </FWTypography>
                                        <FWTypography>
                                            {isValueValidForStringArrNull(
                                                field.value
                                            ) ? (
                                                <Box
                                                    component="span"
                                                    dangerouslySetInnerHTML={{
                                                        __html: replaceUrlWithLink(
                                                            stringValueForStringArrNull(
                                                                field.value
                                                            )
                                                        ),
                                                    }}
                                                ></Box>
                                            ) : (
                                                'NA(None)'
                                            )}
                                        </FWTypography>
                                    </Grid>
                                );
                            })}
                            {/* {customFieldsNegotiatePaymentAndDeliveryTerms.map(
                                (field, index) => {
                                    return (
                                        <Grid item xs={'auto'} key={index}>
                                            <FWTypography color="text.secondary">
                                                {field.name}{' '}
                                                <FWTooltip
                                                    title={
                                                        'Negotiable with seller'
                                                    }
                                                >
                                                    <i
                                                        className={
                                                            'bi bi-currency-exchange'
                                                        }
                                                    />
                                                </FWTooltip>
                                            </FWTypography>
                                            <FWTypography>
                                                {isValueValidForStringArrNull(
                                                    field.value
                                                ) ? (
                                                    <Box
                                                        component="span"
                                                        dangerouslySetInnerHTML={{
                                                            __html: replaceUrlWithLink(
                                                                stringValueForStringArrNull(
                                                                    field.value
                                                                )
                                                            ),
                                                        }}
                                                    ></Box>
                                                ) : (
                                                    'NA(None)'
                                                )}
                                            </FWTypography>
                                        </Grid>
                                    );
                                }
                            )} */}
                            {customFieldsPaymentAndDeliveryTerms.map(
                                (field, index) => {
                                    return (
                                        <Grid item xs={'auto'} key={index}>
                                            <FWTypography color="text.secondary">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: '0.5rem',
                                                    }}
                                                >
                                                    {field.name}{' '}
                                                    {field.description && (
                                                        <FWTooltip
                                                            title={
                                                                field.description
                                                            }
                                                        >
                                                            <Box color="primary.main">
                                                                <i className="bi bi-info-circle"></i>
                                                            </Box>
                                                        </FWTooltip>
                                                    )}
                                                    <FWTooltip
                                                        title={
                                                            field.is_visible
                                                                ? 'Visbile to Seller but non-negotiable'
                                                                : 'Hidden from Seller'
                                                        }
                                                    >
                                                        <i
                                                            className={
                                                                field.is_visible
                                                                    ? 'bi bi-eye'
                                                                    : 'bi bi-eye-slash'
                                                            }
                                                        ></i>
                                                    </FWTooltip>
                                                </div>
                                            </FWTypography>
                                            <FWTypography>
                                                {isValueValidForStringArrNull(
                                                    field.value
                                                ) ? (
                                                    <Box
                                                        component="span"
                                                        dangerouslySetInnerHTML={{
                                                            __html: replaceUrlWithLink(
                                                                stringValueForStringArrNull(
                                                                    field.value
                                                                )
                                                            ),
                                                        }}
                                                    ></Box>
                                                ) : (
                                                    '-'
                                                )}
                                            </FWTypography>
                                        </Grid>
                                    );
                                }
                            )}
                            {/* {customFieldsNegotiateAdditional.map(
                                (field, index) => {
                                    return (
                                        <Grid item xs={'auto'} key={index}>
                                            <FWTypography color="text.secondary">
                                                {field.name}{' '}
                                                <FWTooltip
                                                    title={
                                                        'Negotiable with seller'
                                                    }
                                                >
                                                    <i
                                                        className={
                                                            'bi bi-currency-exchange'
                                                        }
                                                    />
                                                </FWTooltip>
                                            </FWTypography>
                                            <FWTypography>
                                                {isValueValidForStringArrNull(
                                                    field.value
                                                ) ? (
                                                    <Box
                                                        component="span"
                                                        dangerouslySetInnerHTML={{
                                                            __html: replaceUrlWithLink(
                                                                stringValueForStringArrNull(
                                                                    field.value
                                                                )
                                                            ),
                                                        }}
                                                    ></Box>
                                                ) : (
                                                    '-'
                                                )}
                                            </FWTypography>
                                        </Grid>
                                    );
                                }
                            )} */}
                            {customFieldsAdditional.map((field, index) => {
                                return (
                                    <Grid item xs={'auto'} key={index}>
                                        <FWTypography color="text.secondary">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '0.5rem',
                                                }}
                                            >
                                                {field.name}{' '}
                                                {field.description && (
                                                    <FWTooltip
                                                        title={
                                                            field.description
                                                        }
                                                    >
                                                        <Box color="primary.main">
                                                            <i className="bi bi-info-circle"></i>
                                                        </Box>
                                                    </FWTooltip>
                                                )}
                                                <FWTooltip
                                                    title={
                                                        field.is_visible
                                                            ? 'Visbile to Seller but non-negotiable'
                                                            : 'Hidden from Seller'
                                                    }
                                                >
                                                    <i
                                                        className={
                                                            field.is_visible
                                                                ? 'bi bi-eye'
                                                                : 'bi bi-eye-slash'
                                                        }
                                                    ></i>
                                                </FWTooltip>
                                            </div>
                                        </FWTypography>
                                        <FWTypography>
                                            {isValueValidForStringArrNull(
                                                field.value
                                            ) ? (
                                                <Box
                                                    component="span"
                                                    dangerouslySetInnerHTML={{
                                                        __html: replaceUrlWithLink(
                                                            stringValueForStringArrNull(
                                                                field.value
                                                            )
                                                        ),
                                                    }}
                                                ></Box>
                                            ) : (
                                                'NA(None)'
                                            )}
                                        </FWTypography>
                                    </Grid>
                                );
                            })}
                            {+(itemDetails.gr_tolerance_percentage ?? '') >
                                0 && (
                                <>
                                    <Grid item xs={'auto'} key={'gr_tolerance'}>
                                        <FWTypography color="text.secondary">
                                            {'GR Tolerance'}
                                        </FWTypography>
                                        <FWTypography>{`${FWNumberFormatter(
                                            +(
                                                itemDetails.gr_tolerance_percentage ??
                                                ''
                                            )
                                        )}%`}</FWTypography>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </DialogContent>
                ) : (
                    <DialogContent sx={{ m: '0px' }}>
                        <Typography textAlign="center" color="text.secondary">
                            No data
                        </Typography>
                    </DialogContent>
                )}
            </FWPopupWithChildren>
        </>
    );
};
