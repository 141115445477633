import { cloneDeep, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { leadTimeSurpassed } from '../../../Components/Shared/Utils';
import {
    DELIVERABLE_LENGTH_LIMIT,
    validatePaymentTerms,
} from '../../../Global/Helpers/paymentTermsHelpers';
import {
    IRfqTemplate,
    TemplateStandardFieldsEnum,
} from '../../../Global/Interfaces/TemplateInterface';
import {
    CreateEventErrorDesc,
    CreateEventErrorFieldEnum,
    ICustomEventDetails,
    ICustomEventDetailsErrors,
} from '../../Interfaces/Buyer/RFQ.model';

export const getListOfErrorsKeys = (
    eventDetailsError: ICustomEventDetailsErrors
) => {
    eventDetailsError = cloneDeep(eventDetailsError);
    for (let key of Object.keys(eventDetailsError)) {
        if (eventDetailsError[key] && eventDetailsError[key]) {
            const temp: any = eventDetailsError[key];
            if (temp?.type === 'WARNING') {
                delete eventDetailsError[key];
            }
        }
    }
    return Object.keys(eventDetailsError).length;
};

export const validateEventDetails = ({
    type,
    field,
    data,
    errors,
    allExistingEvents,
    templateDetails,
}: {
    type: 'DIRECT' | 'FROM_EVENT';
    field: CreateEventErrorFieldEnum | 'ALL';
    data: ICustomEventDetails;
    errors: ICustomEventDetailsErrors;
    allExistingEvents: Set<string>;
    templateDetails: IRfqTemplate;
}): ICustomEventDetailsErrors => {
    const isDeliveryDateInvalid = (
        lead_time: string,
        lead_time_period: string | null,
        delivery_date: string | null
    ) => {
        // returns list of delivery dates
        if (delivery_date === null || isEmpty(delivery_date)) {
            return false;
        }
        return moment(delivery_date).isSameOrBefore(
            moment(
                moment().add(
                    lead_time,
                    lead_time_period?.toLocaleLowerCase() as any
                )
            )
        );
    };
    let newErrors = cloneDeep({ ...errors });
    if (!data) {
        return newErrors;
    }
    if (
        // type === 'FROM_EVENT' &&
        field === CreateEventErrorFieldEnum.custom_fields ||
        field === 'ALL'
    ) {
        delete newErrors.custom_fields;
        let section = data.custom_sections.find(
            (section) =>
                section.name === templateDetails.sections.EVENT_DETAILS.label
        );

        if (section) {
            section.custom_fields.forEach((field) => {
                if (
                    templateDetails?.fieldSettings?.customFields[field.name]
                        ?.buyerSettings?.required
                ) {
                    if (
                        newErrors.custom_fields &&
                        newErrors.custom_fields[field.name]
                    )
                        delete newErrors.custom_fields[field.name];

                    if (
                        field.value === '' ||
                        isEqual(field?.value, []) ||
                        field.value === null ||
                        field.value === 'undefined'
                    ) {
                        if (newErrors.custom_fields)
                            newErrors.custom_fields[field.name] = {
                                type: 'ERROR',
                                message: 'Field is required',
                            };
                        else {
                            newErrors.custom_fields = {
                                [field.name]: {
                                    type: 'ERROR',
                                    message: 'Field is required',
                                },
                            };
                        }
                    } else {
                        if (newErrors.custom_fields)
                            delete newErrors.custom_fields[field.name];
                    }
                }
            });
        } else {
        }

        // else if (data.default_item_terms === 'CUSTOM') {
        //     delete newErrors.custom_fields;
        //     data.custom_fields.section_list.forEach((section) => {
        //         section.fields.forEach((field) => {
        //
        //             if (
        //                 templateDetails?.fieldSettings?.customFields[field.name]
        //                     ?.buyerSettings?.required
        //             ) {
        //                 if (
        //                     newErrors.custom_fields &&
        //                     newErrors.custom_fields[field.name]
        //                 )
        //                     delete newErrors.custom_fields[field.name];

        //                 if (
        //                     field.value === '' ||
        //                     isEqual(field?.value, []) ||
        //                     field.value === null ||
        //                     field.value === 'undefined'
        //                 ) {
        //                     if (newErrors.custom_fields)
        //                         newErrors.custom_fields[field.name] = {
        //                             type: 'ERROR',
        //                             message: 'Field is required',
        //                         };
        //                     else {
        //                         newErrors.custom_fields = {
        //                             [field.name]: {
        //                                 type: 'ERROR',
        //                                 message: 'Field is required',
        //                             },
        //                         };
        //                     }
        //                 } else {
        //                     if (newErrors.custom_fields)
        //                         delete newErrors.custom_fields[field.name];
        //                 }
        //             }
        //         });
        //     });
        // }
        // else {
        //     delete newErrors.custom_fields;
        // }
    }
    // if (
    //     // type === 'FROM_EVENT' &&
    //     field === CreateEventErrorFieldEnum.custom_fields_negotiate ||
    //     field === 'ALL'
    // ) {
    //     delete newErrors.custom_fields_negotiate;
    //     let section = data.custom_fields_negotiate?.section_list.find(
    //         (section) =>
    //             section.name === templateDetails.sections.EVENT_DETAILS.label
    //     );

    //     if (section) {
    //         section.fields.forEach((field) => {
    //             if (
    //                 templateDetails?.fieldSettings?.customFields[field.name]
    //                     ?.buyerSettings?.required
    //             ) {
    //                 if (
    //                     newErrors.custom_fields_negotiate &&
    //                     newErrors.custom_fields_negotiate[field.name]
    //                 )
    //                     delete newErrors.custom_fields_negotiate[field.name];

    //                 if (
    //                     field.value === '' ||
    //                     isEqual(field?.value, []) ||
    //                     field.value === null ||
    //                     field.value === 'undefined'
    //                 ) {
    //                     if (newErrors.custom_fields_negotiate)
    //                         newErrors.custom_fields_negotiate[field.name] = {
    //                             type: 'ERROR',
    //                             message: 'Field is required',
    //                         };
    //                     else {
    //                         newErrors.custom_fields_negotiate = {
    //                             [field.name]: {
    //                                 type: 'ERROR',
    //                                 message: 'Field is required',
    //                             },
    //                         };
    //                     }
    //                 } else {
    //                     if (newErrors.custom_fields_negotiate)
    //                         delete newErrors.custom_fields_negotiate[
    //                             field.name
    //                         ];
    //                 }
    //             }
    //         });
    //     } else {
    //     }

    //     // else if (data.default_item_terms === 'CUSTOM') {
    //     //     delete newErrors.custom_fields_negotiate;
    //     //     data.custom_fields_negotiate.section_list.forEach((section) => {
    //     //         section.fields.forEach((field) => {
    //     //             if (
    //     //                 templateDetails.fieldSettings?.customFields[field.name]
    //     //                     ?.buyerSettings?.required
    //     //             ) {
    //     //                 // required - true

    //     //                 if (
    //     //                     newErrors.custom_fields_negotiate &&
    //     //                     newErrors.custom_fields_negotiate[field.name]
    //     //                 )
    //     //                     delete newErrors.custom_fields_negotiate[
    //     //                         field.name
    //     //                     ];
    //     //                 if (
    //     //                     field.value === '' ||
    //     //                     field.value === null ||
    //     //                     field.value === 'undefined'
    //     //                 ) {
    //     //                     if (newErrors.custom_fields_negotiate)
    //     //                         newErrors.custom_fields_negotiate[field.name] =
    //     //                             {
    //     //                                 type: 'ERROR',
    //     //                                 message: 'Field is required',
    //     //                             };
    //     //                     else {
    //     //                         newErrors.custom_fields_negotiate = {
    //     //                             [field.name]: {
    //     //                                 type: 'ERROR',
    //     //                                 message: 'Field is required',
    //     //                             },
    //     //                         };
    //     //                     }
    //     //                 } else {
    //     //                     if (newErrors.custom_fields_negotiate)
    //     //                         delete newErrors.custom_fields_negotiate[
    //     //                             field.name
    //     //                         ];
    //     //                 }
    //     //             }
    //     //         });
    //     //     });
    //     // }
    //     // else {
    //     //     delete newErrors.custom_fields_negotiate;
    //     // }
    // }
    if (field === CreateEventErrorFieldEnum.event_name || field === 'ALL') {
        delete newErrors.event_name;
        if (isEmpty(data.event_name.trim())) {
            newErrors.event_name = {
                type: 'ERROR',
                message: 'Please enter an event name',
            };
        } else if (data.event_name.trim().length > 50) {
            newErrors.event_name = {
                type: 'ERROR',
                message: 'Maximum 50 characters',
            };
        } else if (
            allExistingEvents.has(data.event_name.trim().toUpperCase())
        ) {
            newErrors.event_name = {
                type: 'ERROR',
                message: 'Duplicate event name',
            };
        }
    }

    if (
        type === 'FROM_EVENT' &&
        (field === CreateEventErrorFieldEnum.event_deadline || field === 'ALL')
    ) {
        delete newErrors.event_deadline;
        let minDeadline = moment().add(1, 'hours').startOf('hours');
        if (
            isEmpty(data.event_deadline) ||
            !moment(data.event_deadline, moment.ISO_8601, true).isValid()
        ) {
            newErrors.event_deadline = {
                type: 'ERROR',
                message: 'Please enter a valid date',
            };
        } else if (moment(data.event_deadline).isBefore(minDeadline)) {
            newErrors.event_deadline = {
                type: 'ERROR',
                message: `Cannot be earlier than ${minDeadline.format(
                    'DD MMM YYYY h A'
                )}`,
            };
        }
    }
    if (field === CreateEventErrorFieldEnum.entity_id || field === 'ALL') {
        delete newErrors['entity_id'];
        if (data.entity_id === '') {
            newErrors.entity_id = {
                type: 'ERROR',
                message: 'Please select a valid entity',
            };
        }
    }

    if (
        type === 'FROM_EVENT' &&
        templateDetails.sections.EVENT_DETAILS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.CUSTOMER_NAME
        ) &&
        templateDetails.fieldSettings.standardFields[
            TemplateStandardFieldsEnum.CUSTOMER_NAME
        ].buyerSettings.showField &&
        templateDetails.fieldSettings.standardFields[
            TemplateStandardFieldsEnum.CUSTOMER_NAME
        ].buyerSettings.required &&
        (field === CreateEventErrorFieldEnum.customer_entity_id ||
            field === 'ALL')
    ) {
        delete newErrors['customer_entity_id'];
        if (!Boolean(data.customer_entity_id)) {
            newErrors.customer_entity_id = {
                type: 'ERROR',
                message: 'Please select a valid entity',
            };
        }
    } else if (
        !templateDetails.sections.EVENT_DETAILS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.CUSTOMER_NAME
        ) ||
        templateDetails.fieldSettings.standardFields[
            TemplateStandardFieldsEnum.CUSTOMER_NAME
        ].buyerSettings.showField === false
    ) {
        delete newErrors['customer_entity_id'];
    }

    if (
        (field === CreateEventErrorFieldEnum.default_customer_entity_id ||
            field === 'ALL') &&
        type === 'FROM_EVENT'
    ) {
        delete newErrors['default_customer_entity_id'];
        if (
            data.default_item_terms === 'CUSTOM' &&
            !Boolean(data.default_customer_entity_id) &&
            !templateDetails.sections.EVENT_DETAILS.fieldList.some(
                (field) =>
                    field.field === TemplateStandardFieldsEnum.CUSTOMER_NAME
            ) &&
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.CUSTOMER_NAME
            ].buyerSettings.showField &&
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.CUSTOMER_NAME
            ].buyerSettings.required
        ) {
            newErrors.default_customer_entity_id = {
                type: 'ERROR',
                message: 'Please select a valid entity',
            };
        }
    }
    if (
        type === 'FROM_EVENT' &&
        (field === 'template_id' || field === 'entity_id' || field === 'ALL')
    ) {
        if (data.template_id === '') {
            newErrors.template_id = {
                type: 'ERROR',
                message: 'Please select a template',
            };
        } else {
            delete newErrors['template_id'];
        }
    }
    if (field === CreateEventErrorFieldEnum.currency_id || field === 'ALL') {
        delete newErrors.currency_id;
        if (data.currency_id === '') {
            newErrors.currency_id = {
                type: 'ERROR',
                message: 'Please select a valid currency',
            };
        }
    }
    if (field === CreateEventErrorFieldEnum.requisition_no || field === 'ALL') {
        delete newErrors.requisition_no;

        if (
            (!Boolean(data.requisition_no) &&
                templateDetails.sections.EVENT_DETAILS.fieldList.some(
                    (field) =>
                        field.field === TemplateStandardFieldsEnum.REQUISITION
                ) &&
                templateDetails.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.REQUISITION
                ].buyerSettings.required) ||
            (false &&
                !Boolean(data.requisition_no) &&
                data.default_item_terms === 'CUSTOM' &&
                templateDetails.sections.ADDITIONAL_DETAILS.fieldList.some(
                    (field) =>
                        field.field === TemplateStandardFieldsEnum.REQUISITION
                ) &&
                templateDetails.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.REQUISITION
                ].buyerSettings.required)
        ) {
            newErrors.requisition_no = {
                type: 'ERROR',
                message: 'Field is required',
            };
        }
    }

    if (field === CreateEventErrorFieldEnum.lead_time || field === 'ALL') {
        if (
            !Boolean(data.lead_time) &&
            templateDetails.sections.EVENT_DETAILS.fieldList.some(
                (field) => field.field === TemplateStandardFieldsEnum.LEAD_TIME
            ) &&
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.LEAD_TIME
            ].buyerSettings.required
        ) {
            newErrors.lead_time = {
                type: 'ERROR',
                message: 'Field is required',
            };
        } else {
            delete newErrors.lead_time;
        }
    }

    if (
        (field === CreateEventErrorFieldEnum.gr_tolerance || field === 'ALL') &&
        type === 'FROM_EVENT'
    ) {
        delete newErrors.gr_tolerance;
        if (
            !Boolean(data.gr_tolerance) &&
            templateDetails.sections.EVENT_DETAILS.fieldList.some(
                (field) =>
                    field.field === TemplateStandardFieldsEnum.GR_TOLERANCE
            ) &&
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.GR_TOLERANCE
            ].buyerSettings.required
        ) {
            newErrors.gr_tolerance = {
                type: 'ERROR',
                message: 'Field is required',
            };
        }
        if (parseInt(data.gr_tolerance) > 10) {
            newErrors.gr_tolerance = {
                type: 'ERROR',
                message: 'Cannot exceed 10%',
            };
        }
    }

    if (
        (field === CreateEventErrorFieldEnum.gr_tolerance || field === 'ALL') &&
        type === 'DIRECT'
    ) {
        delete newErrors.gr_tolerance;
        if (
            !Boolean(data.gr_tolerance) &&
            templateDetails.sections.EVENT_DETAILS.fieldList.some(
                (field) =>
                    field.field === TemplateStandardFieldsEnum.GR_TOLERANCE
            ) &&
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.GR_TOLERANCE
            ].buyerSettings.required
        ) {
            newErrors.gr_tolerance = {
                type: 'ERROR',
                message: 'Field is required',
            };
        }
    }

    if (
        (field === CreateEventErrorFieldEnum.custom_additional_information ||
            field === 'ALL') &&
        type === 'DIRECT'
    ) {
        delete newErrors.custom_additional_information;
        if (
            (!Boolean(data.custom_additional_information) &&
                templateDetails.sections.EVENT_DETAILS.fieldList.some(
                    (field) =>
                        field.field ===
                        TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
                ) &&
                templateDetails.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
                ].buyerSettings.required) ||
            (false &&
                !Boolean(data.custom_additional_information) &&
                data.default_item_terms === 'CUSTOM' &&
                templateDetails.sections.ADDITIONAL_DETAILS.fieldList.some(
                    (field) =>
                        field.field ===
                        TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
                ) &&
                templateDetails.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
                ].buyerSettings.required)
        ) {
            newErrors.custom_additional_information = {
                type: 'ERROR',
                message: 'Field is required',
            };
        }
    }
    if (
        (field ===
            CreateEventErrorFieldEnum.default_event_item_additional_information ||
            field === 'ALL') &&
        type === 'FROM_EVENT'
    ) {
        delete newErrors.default_event_item_additional_information;
        if (
            (!Boolean(data.default_event_item_additional_information) &&
                templateDetails.sections.EVENT_DETAILS.fieldList.some(
                    (field) =>
                        field.field ===
                        TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
                ) &&
                templateDetails.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
                ].buyerSettings.required) ||
            (false &&
                !Boolean(data.default_event_item_additional_information) &&
                data.default_item_terms === 'CUSTOM' &&
                templateDetails.sections.ADDITIONAL_DETAILS.fieldList.some(
                    (field) =>
                        field.field ===
                        TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
                ) &&
                templateDetails.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
                ].buyerSettings.required)
        ) {
            newErrors.default_event_item_additional_information = {
                type: 'ERROR',
                message: 'Field is required',
            };
        }
    }

    if (true) {
        if (field === CreateEventErrorFieldEnum.lead_time || field === 'ALL') {
            // delete newErrors.lead_time;

            if(data.lead_time_period!=='NA'){
                if (
                    isEmpty(data.lead_time) ||
                    +data.lead_time <= 0 ||
                    isEmpty(data.lead_time_period)
                ) {
                    newErrors.lead_time = {
                        type: 'ERROR',
                        message: 'Please enter a valid lead time',
                    };
                } else if (
                    data.lead_time_period !== '' &&
                    leadTimeSurpassed(data.lead_time, data.lead_time_period)
                ) {
                    newErrors.lead_time = {
                        type: 'WARNING',
                        message: 'Lead time exceeds 1 year',
                    };
                }
            }

        
            if (
                isDeliveryDateInvalid(
                    data.lead_time,
                    data.lead_time_period,
                    data.delivery_date
                )
            ) {
                newErrors.lead_time = {
                    type: 'WARNING',
                    message: 'Insufficient lead time',
                };
            }
        }

        // if (
        //     field ===
        //         CreateEventErrorFieldEnum.default_event_quantity_tolerance_percentage ||
        //     field === 'ALL'
        // ) {
        //
        //     delete newErrors['default_event_quantity_tolerance_percentage'];
        //     if (data.default_event_quantity_tolerance_percentage) {
        //         if (
        //             templateDetails.fieldSettings.standardFields[
        //                 TemplateStandardFieldsEnum.GR_TOLERANCE
        //             ].buyerSettings.required &&
        //             !Boolean(data.default_event_quantity_tolerance_percentage)
        //         ) {
        //             newErrors['default_event_quantity_tolerance_percentage'] = {
        //                 type: 'ERROR',
        //                 message: 'Field is required',
        //             };
        //         }
        //     }
        // }

        if (
            field === CreateEventErrorFieldEnum.lead_time_period ||
            field === 'ALL'
        ) {
            if (
                isDeliveryDateInvalid(
                    data.lead_time,
                    data.lead_time_period,
                    data.delivery_date
                )
            ) {
                newErrors.lead_time = {
                    type: 'WARNING',
                    message: 'Insufficient lead time',
                };
            } else {
                if (
                    newErrors?.lead_time?.type === 'WARNING' &&
                    newErrors?.lead_time?.message === 'Insufficient Lead TIme'
                ) {
                    delete newErrors['lead_time'];
                }
            }
        }

        // if (
        //     (field === CreateEventErrorFieldEnum.delivery_date ||
        //         field === 'ALL') &&
        //     data.delivery_date !== ''
        // ) {
        //     delete newErrors.delivery_date;
        //     let today = moment();
        //     if (!moment(data.delivery_date, 'YYYY-MM-DD', true).isValid()) {
        //         newErrors.delivery_date = {
        //             type: 'ERROR',
        //             message: 'Invalid date',
        //         };
        //     } else if (
        //         !moment(data.delivery_date).isSameOrAfter(today, 'day')
        //     ) {
        //         newErrors.delivery_date = {
        //             type: 'WARNING',
        //             message: 'Must be on/after today',
        //         };
        //     }
        //     if (
        //         isDeliveryDateInvalid(
        //             data.lead_time,
        //             data.lead_time_period,
        //             data.delivery_date
        //         )
        //     ) {
        //         newErrors.lead_time = {
        //             type: 'WARNING',
        //             message: 'Insufficient lead time',
        //         };
        //     } else {
        //         if (
        //             newErrors?.lead_time?.type === 'WARNING' &&
        //             newErrors?.lead_time?.message === 'Insufficient lead time'
        //         ) {
        //             delete newErrors['lead_time'];
        //         }
        //     }
        // }

        const templatePaymentTerms =
            templateDetails.fieldSettings.standardFields.PAYMENT_TERMS;
        const prepaymentAllowed =
            templatePaymentTerms.paymentTermOptions.prepaymentAllowed;

        const ptValidation = validatePaymentTerms({
            paymentTerms: data.paymentTerms,
            prepaymentAllowed,
        });

        if (
            field === CreateEventErrorFieldEnum.paymentTerms_prepayment ||
            field === 'ALL'
        ) {
            delete newErrors.paymentTerms_prepayment;
            if (ptValidation.overallErrors.prepaymentBlank) {
                newErrors.paymentTerms_prepayment = {
                    type: 'ERROR',
                    message: 'Input Required',
                };
            } else if (ptValidation.overallErrors.prepaymentAbove100) {
                newErrors.paymentTerms_prepayment = {
                    type: 'ERROR',
                    message: 'Cannot exceed 100%',
                };
            }
        }

        if (
            field ===
                CreateEventErrorFieldEnum.paymentTerms_invoiceItem_terms ||
            field === 'ALL'
        ) {
            delete newErrors.paymentTerms_invoiceItem_terms;
            if (ptValidation.overallErrors.termsBlank) {
                newErrors.paymentTerms_invoiceItem_terms = {
                    type: 'ERROR',
                    message: 'Input Required',
                };
            }
        }

        if (
            field ===
                CreateEventErrorFieldEnum.paymentTerms_invoiceItem_period ||
            field === 'ALL'
        ) {
            delete newErrors.paymentTerms_invoiceItem_period;
            if (ptValidation.overallErrors.periodBlank) {
                newErrors.paymentTerms_invoiceItem_period = {
                    type: 'ERROR',
                    message: 'Input Required',
                };
            }
        }

        if (
            field ===
                CreateEventErrorFieldEnum.paymentTerms_invoiceItem_appliedFrom ||
            field === 'ALL'
        ) {
            delete newErrors.paymentTerms_invoiceItem_appliedFrom;
            if (ptValidation.overallErrors.appliedFromBlank) {
                newErrors.paymentTerms_invoiceItem_appliedFrom = {
                    type: 'ERROR',
                    message: 'Input Required',
                };
            } else if (ptValidation.overallErrors.appliedFromInvalid) {
                newErrors.paymentTerms_invoiceItem_appliedFrom = {
                    type: 'ERROR',
                    message: 'Invalid input',
                };
            } else if (ptValidation.overallErrors.appliedFromTooLong) {
                newErrors.paymentTerms_invoiceItem_appliedFrom = {
                    type: 'ERROR',
                    message: `Cannot exceed ${DELIVERABLE_LENGTH_LIMIT} characters`,
                };
            }
        }

        if (
            // field ===
            //     CreateEventErrorFieldEnum.paymentTerms_poItem_overallPercentage ||
            field === 'ALL' ||
            field === CreateEventErrorFieldEnum.paymentTerms_prepayment ||
            field ===
                CreateEventErrorFieldEnum.paymentTerms_poItem_allocationRows
        ) {
            if (ptValidation.overallErrors.allocationNot100) {
                const msg =
                    +data.paymentTerms.prepaymentPercentage > 0
                        ? 'Allocations and prepayment must add up to 100%'
                        : 'Allocations must add up to 100%';
                newErrors.paymentTerms_poItem_overallPercentage = {
                    type: 'ERROR',
                    message: msg,
                };
            } else {
                delete newErrors.paymentTerms_poItem_overallPercentage;
            }
            newErrors.paymentTerms_poItem_allocationRows = [];
            ptValidation.rowErrors.forEach((rowValidations, index) => {
                const rowErrors: {
                    rowAllocationPercentage?: CreateEventErrorDesc;
                    rowTerms?: CreateEventErrorDesc;
                    rowPeriod?: CreateEventErrorDesc;
                    rowDeliverableName?: CreateEventErrorDesc;
                    rowDate?: CreateEventErrorDesc;
                } = {};
                if (rowValidations.allocationBlank) {
                    rowErrors.rowAllocationPercentage = {
                        type: 'ERROR',
                        message: 'Input Required',
                    };
                } else if (rowValidations.allocationOver100) {
                    rowErrors.rowAllocationPercentage = {
                        type: 'ERROR',
                        message: 'Cannot exceed 100%',
                    };
                }
                if (rowValidations.termsBlank) {
                    rowErrors.rowTerms = {
                        type: 'ERROR',
                        message: 'Input Required',
                    };
                }
                if (rowValidations.periodBlank) {
                    rowErrors.rowPeriod = {
                        type: 'ERROR',
                        message: 'Input Required',
                    };
                }
                if (rowValidations.deliverableBlank) {
                    rowErrors.rowDeliverableName = {
                        type: 'ERROR',
                        message: 'Input Required',
                    };
                } else if (rowValidations.deliverableTooLong) {
                    rowErrors.rowDeliverableName = {
                        type: 'ERROR',
                        message: `Cannot exceed ${DELIVERABLE_LENGTH_LIMIT} characters`,
                    };
                } else if (rowValidations.deliverableInvalid) {
                    rowErrors.rowDeliverableName = {
                        type: 'ERROR',
                        message: 'Invalid input',
                    };
                }
                if (rowValidations.dateBlank) {
                    rowErrors.rowDate = {
                        type: 'ERROR',
                        message: 'Input Required',
                    };
                } else if (rowValidations.datePast) {
                    rowErrors.rowDate = {
                        type: 'ERROR',
                        message: 'Cannot be in the past',
                    };
                }
                newErrors.paymentTerms_poItem_allocationRows.push(rowErrors);
            });
        }
    } else {
        delete newErrors.delivery_date;
        delete newErrors.lead_time;
        delete newErrors.lead_time_period;
        delete newErrors.paymentTerms_prepayment;
        delete newErrors.paymentTerms_invoiceItem_terms;
        delete newErrors.paymentTerms_invoiceItem_period;
        delete newErrors.paymentTerms_invoiceItem_appliedFrom;
        delete newErrors.paymentTerms_poItem_overallPercentage;
        newErrors.paymentTerms_poItem_allocationRows = [];
    }

    if (
        templateDetails.sections.EVENT_DETAILS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.PROJECT
        ) &&
        templateDetails.fieldSettings.standardFields.PROJECT.buyerSettings
            .required &&
        (field === 'default_event_project_id' || field === 'ALL') &&
        data.default_event_project_id.trim() === '' &&
        data.project_id.trim() === '' &&
        type === 'FROM_EVENT'
    ) {
        newErrors.default_event_project_id = {
            type: 'ERROR',
            message: 'Please select a project',
        };
    } else {
        delete newErrors['default_event_project_id'];
    }
    if (
        templateDetails.sections.EVENT_DETAILS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.PROJECT
        ) &&
        templateDetails.fieldSettings.standardFields.PROJECT.buyerSettings
            .required &&
        (field === 'project_id' || field === 'ALL') &&
        data.project_id.trim() === '' &&
        type === 'DIRECT'
    ) {
        newErrors.project_id = {
            type: 'ERROR',
            message: 'Please select a project',
        };
    } else {
        delete newErrors['project_id'];
    }

    if (
        templateDetails.sections.EVENT_DETAILS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.COST_CENTER
        ) &&
        (templateDetails.fieldSettings.standardFields.COST_CENTER.buyerSettings
            .required) &&
        (field === 'default_event_cost_centre_id' || field === 'ALL') &&
      ((  data?.default_event_cost_centre_id?.trim() === '' &&
        type === 'FROM_EVENT')||(data.default_event_cost_centre_id===null))
    ) {
        newErrors.default_event_cost_centre_id = {
            type: 'ERROR',
            message: 'Please select a cost center',
        };
    } else {
        delete newErrors['default_event_cost_centre_id'];
    }
    if (
        templateDetails.sections.EVENT_DETAILS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.COST_CENTER
        ) &&
        templateDetails.fieldSettings.standardFields.COST_CENTER.buyerSettings
            .required &&
        (field === 'cost_centre_id' || field === 'ALL') &&
        data.cost_centre_id.trim() === '' &&
        type === 'DIRECT'
    ) {
        newErrors.cost_centre_id = {
            type: 'ERROR',
            message: 'Please select a cost center',
        };
    } else {
        delete newErrors['cost_centre_id'];
    }
    if (
        templateDetails.sections.EVENT_DETAILS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.GL
        ) &&
        templateDetails.fieldSettings.standardFields.GL.buyerSettings
            .required &&
        (field === 'default_event_gl_id' || field === 'ALL') &&
       ((data.default_event_gl_id?.trim() === '' &&
        type === 'FROM_EVENT')||data.default_event_gl_id===null)
    ) {
        newErrors.default_event_gl_id = {
            type: 'ERROR',
            message: 'Please select a GL',
        };
    } else {
        delete newErrors['default_event_gl_id'];
    }
    if (
        templateDetails.sections.EVENT_DETAILS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.GL
        ) &&
        templateDetails.fieldSettings.standardFields.GL.buyerSettings
            .required &&
        (field === 'gl_id' || field === 'ALL') &&
        data.gl_id.trim() === '' &&
        type === 'DIRECT'
    ) {
        newErrors.gl_id = {
            type: 'ERROR',
            message: 'Please select a GL',
        };
    } else {
        delete newErrors['gl_id'];
    }

    return newErrors;
};

export const getBuyerEventDisplayStatus = (backend_status: string) => {
    switch (backend_status.trim().toUpperCase()) {
        case 'DRAFT':
            return 'Draft';
        case 'APPROVAL_PENDING':
            return 'Approval Pending';
        case 'REWORK':
            return 'Changes requested';
        case 'REJECTED':
            return 'Rejected';
        case 'ONGOING':
            return 'Negotiations';
        case 'AWARD_STAGE':
            return 'Award stage';
        case 'PAUSED':
            return 'Paused';
        case 'PURCHASE_ORDER_ISSUED':
            return 'PO(s) issued';
        case 'PURCHASE_ORDER_ACCEPTED':
            return 'PO(s) accepted';
        default:
            return (
                backend_status.trim().charAt(0).toUpperCase() +
                backend_status.toLowerCase().trim().slice(1)
            );
    }
};

export const getSellerEventDisplayStatus = (backend_status: string) => {
    switch (backend_status.trim().toUpperCase()) {
        case 'INVITATION_PENDING':
            return 'Invitation received';
        case 'INVITATION_REJECTED':
            return 'Invitation declined';
        case 'INVITATION_LAPSED':
            return 'Invitation lapsed';
        case 'DEADLINE_PASSED':
            return 'Deadline passed';
        case 'BID_PENDING':
            return 'Bid pending';
        case 'BID_RECEIVED':
            return 'Bid received';
        case 'BID_SUBMITTED':
            return 'Bid submitted';
        case 'PAUSED':
            return 'Paused';
        case 'PO_PENDING':
            return 'PO pending';
        case 'PO_RECEIVED':
            return 'PO received';
        case 'PO_REVIEWED':
            return 'PO reviewed';
        case 'DEAL_WON':
            return 'Deal won';
        case 'DEAL_LOST':
            return 'Deal lost';

        default:
            return (
                backend_status.trim().charAt(0).toUpperCase() +
                backend_status.toLowerCase().trim().slice(1)
            );
    }
};
