import {
    Backdrop,
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    InputLabel,
    Pagination,
} from '@mui/material';
import {
    CellEditingStartedEvent,
    CellKeyDownEvent,
    ColDef,
    Column,
    ColumnApi,
    ColumnMovedEvent,
    ColumnPinnedEvent,
    ColumnResizedEvent,
    ColumnVisibleEvent,
    DragStoppedEvent,
    FilterChangedEvent,
    FirstDataRenderedEvent,
    FullWidthCellKeyDownEvent,
    GetRowIdFunc,
    GridApi,
    GridReadyEvent,
    PostSortRowsParams,
    RowClassParams,
    RowClassRules,
    RowClickedEvent,
    RowDataChangedEvent,
    RowHeightParams,
    RowNode,
    RowSelectedEvent,
    SortChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    // useCallback,
    useState,
} from 'react';

import 'ag-grid-community/dist/styles/ag-grid.scss';
import 'ag-grid-community/dist/styles/ag-theme-alpine/sass/ag-theme-alpine.scss';
import { cloneDeep, isArray, isEqual } from 'lodash';
import React from 'react';
import { CustomFieldType } from '../Global/Interfaces/TemplateInterface';
import { IVendorBuyerEntity } from '../Organizations/Admin/Interfaces/VendorsInterface';
import ProjectDashboardFilterPopup from '../ProjectGlCostCenter/components/Dashboard/ProjectDashboardFilterPopup';
import ProjectDashboardSortPopup from '../ProjectGlCostCenter/components/Dashboard/ProjectDashboardSortPopup';
import { IFilterColumnMenuList } from '../ProjectGlCostCenter/constants/projectDashboardConstants';
import {
    IFieldTypeLabel,
    PROJECT_DASHBOARD_FIELD_DELIMITER,
} from '../ProjectGlCostCenter/helpers/projectDashboardHelper';
import {
    DASHBOARD_FIELD,
    DASHBOARD_FIELD_COLUMN,
    DASHBOARD_FIELD_NAME,
    DASHBOARD_FIELD_TYPE,
    DASHBOARD_SECTION_NAME,
    DashBoardFilter,
    DashboardSort,
    TEMPLATE_FIELD_TYPE,
} from '../ProjectGlCostCenter/interface/project.model';
import { StatusFilterEnum } from '../UOM&Currency/Hooks/UOM/useMeasurementView';
import { CustomFormControl } from './FWCustomInputs';
import { FWMenu, FWMenuItem } from './FWCustomMenu';
import { FWTooltip } from './FWCustomTooltip';
import FWIcon from './FWIcon';
import { FWInput } from './FWInput';
import FWSelect from './FWSelect';
import { FWTypography } from './FWTypograhy';
import './FWDashboardDataGrid.scss';
import SavedViewsDropdown from './SavedViewsDropDown';
import { AuthContext } from '../Contexts/AuthContext';
import FWDataGridFilterColumnPopover from './FWDataGridFilterColumnPopover';
import FWItemsPerPage from './FWItemsPerPage';

const spinner = `<div style="
border: 6px solid #f3f3f3;
border-radius: 50%;
border-top: 6px solid grey;
width: 40px;
height: 40px;
-webkit-animation: spin 2s linear infinite; 
animation: spin 2s linear infinite;"></div>`;

// this will be shown as the default value for setFilterByCustomStatus and represented as Default option.
export const DEFAULT_OPTION_FOR_CUSTOM_FILTERS = 'All';
export const DEFAULT_OPTION_FOR_ALL_BUYER_ENTITIES = 'All';

interface FWDataGridProps {
    columns: ColDef[]; // columns name and
    rows: Array<any> | null; // data to populate rows
    rowHeight?: number; // Height of the row
    headerHeight?: number; // Height of the header
    pagination?: boolean; // enable/disable pages
    paginationPageSize?: number; // number of rows to show per page
    search?: string; // search filter text
    rowSelection?: string; // multiple select or single select
    suppressRowClickSelection?: boolean;
    context?: any; // context is used to pass parent methods to the cells
    fixedHeight?: number; // if the height needs to be the same always
    selectedRows?: any[]; // index of selected rows [0, 3, 4, ...]
    defaultColDef?: ColDef; // default column definitions for columns
    className?: string; // classes to be added on the grid
    domLayout?: 'autoHeight'; // to handle the auto height for example QC completed dashboard row heights
    rowClassRules?: RowClassRules; // handle dynamic class name changes for rows
    pinnedTopRowData?: any[];
    addNewAdditionalCost?: { showButton: boolean; toggleState: () => void };
    allowFilterByStatus?: boolean;
    allowFilterByCustomStatus?: boolean;
    buyerEntities?: IVendorBuyerEntity[];
    allowFilterByBuyerEntity?: boolean;
    disableDownloadButton?: boolean;
    createNewMeasurementUnit?: { showButton: boolean; toggleState: () => void };
    linkNewItemToTag?: { showButton: boolean; toggleState: () => void };
    customStatusFilterOptions?: string[]; // append All is the default option in list
    maximumColumnsOnDashboard?: number; // used to limit the max number of columns on dashboard
    onBuyerEntityChange?: (newBuyerEntity: IVendorBuyerEntity | null) => void;
    onReady?: (event: GridReadyEvent) => void;
    getRowClass?: (params: RowClassParams) => string | string[] | undefined;
    getRowId?: GetRowIdFunc;
    onRowSelect?: (data: any[]) => void;
    onRowClicked?: (event: RowClickedEvent) => void;
    doesExternalFilterPass?: (node: RowNode) => boolean;
    isExternalFilterPresent?: () => boolean;
    onSortChanged?: (event: SortChangedEvent) => void; // triggers when sort on grid has changed
    onStatusChanged?: (status: StatusFilterEnum) => void; // triggers when status filter for the grid has changed
    onCustomStatusChanged?: (status: any) => void; // triggers when custom status filter for the grid has changed
    postSortRows?: (params: PostSortRowsParams) => void;
    onFilterChanged?: (event: FilterChangedEvent) => void; // triggers when filter on grid has changed
    onColumnMoved?: (event: ColumnMovedEvent) => void; // triggers when column has been moved by user
    onColumnPinned?: (event: ColumnPinnedEvent) => void; // triggers when column/columns were pinned/unpinned
    onColumnVisible?: (event: ColumnVisibleEvent) => void; // triggers when column/columns were hidden or made visible
    onColumnResized?: (event: ColumnResizedEvent) => void; // triggers when column is resized
    onFirstDataRendered?: (event: FirstDataRenderedEvent) => void; // fired first time data is rendered on the grid
    onRowDataChanged?: (event: RowDataChangedEvent) => void; // triggered when new data has been set into the grid using `api.setRowData()` or by changing the `rowData` bound property.
    getRowHeight?: (params: RowHeightParams) => number;
    exportBtnOnClick?: () => Promise<void>;
    onCellKeyDown?: (e: CellKeyDownEvent | FullWidthCellKeyDownEvent) => void; // cell edit event
    onCellEditingStarted?: (e: CellEditingStartedEvent) => void; // cell edit start event
    handleFilterApply?: (filter: DashBoardFilter | null) => void; // used to apply the filter.
    handleSortApply?: (filter: DashboardSort[]) => void; // used to apply the filter.
    handleRowsPerPageChange?: (itemsPerPage: number) => void; // used to handle items_per_page change from custom pagination
    allowCreateNewProject?: boolean;
    disableCreateButtonClick?: boolean;
    showSearchBar?: boolean;
    searchPlaceholder?: string;
    showExportButton?: boolean;
    disableExportButton?: boolean;
    exportFileName?: string;
    displayLoader?: boolean;
    suppressClickEdit?: boolean; // disable edit cell
    defaultSearchString?: string;
    disableFESearch?: boolean; //disable FE search to use BE enabled search
    onTextChange?: (e: any) => void;
    suppressPaginationPanel?: boolean;
    useCustomPagination?: number; // use custom pagination (pass total number of pages to enable the component)
    customPaginationOnChange?: (
        event: React.ChangeEvent<unknown>,
        page: number
    ) => void;
    customPaginationPageNumber?: number; // reset custom pagination to page 1
    showRefreshIcon?: boolean;
    showDownloadRowDataIcon?: boolean;
    onRefreshIconClick?: () => void;
    showFilterColumnMenu?: boolean;
    handleBulkImportBtnClick?: () => void;
    allowFilterProjectByStatus?: boolean;
    filterByEntity?: string;
    filterByStatus?: string;
    setFilterByStatus?: Dispatch<SetStateAction<string>>;
    onAddBtnClick?: () => void;
    onCreateNewProject?: () => void;
    cookieName?: string;
    showNoOfItemsComponent?: boolean;
    noOfItemsForThisPage?: number;
    handleDownloadSelectedRows?: () => void;
    handleRowSelectionInRowDataInterface?: (selectedRows: any[]) => void;
    addButtonDisableTooltip?: string;
    filterByTemplateSection?: boolean;
    templateSections?: string[];
    templateSectionDelimiter?: string;
    showEntityFilterTitle?: boolean;
    handleSelectedTemplateSectionChange?: (
        selectedTemplateSection: string[]
    ) => void;
    gridRef?: any;
    downloadCSV?: () => void;
    saveDashboardColumnOrder?: boolean;
    fieldTypeLabels?: IFieldTypeLabel[];
    builtInFieldsMap?: Record<string, string>;
    filterFieldToNameMap?: Record<string, string>;
    // eventsFieldTypeLabel?: FieldTypeLabelEvents[];
    // resetFilters?: () => void;
    filteredColumns?: string[];
    sortField?: DashboardSort[];
    dashboardFilter?: DashBoardFilter | null;
    isLoading?: boolean;
    currentTab: string;
    dashboardType: string;
    totalItems?: number;
    // isAdmin?: boolean;
    CustomeAdmin?: ReactNode;
    CustomeBulkandPlus?: ReactNode;
    CustomeExport?: ReactNode;
    showFilterColumnIcon?: boolean;
    showSortIcon?: boolean;
    showViews?: boolean;
}

interface HeaderComponentProps {
    displayName: string;
    isFiltered: boolean;
    isSorted: boolean;
}

const FWDashboardDataGrid = (props: FWDataGridProps) => {
    const [gridApi, setGridApi] = useState<GridApi | undefined>();
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | undefined>();
    const [, setShowLoader] = useState(true);

    const onGridReady = (params: GridReadyEvent) => {
        setShowLoader(false);
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        loadSavedViewOnPageLoad(params.columnApi);
        if (onReady) onReady(params);
    };

    // const history = useHistory();

    const [searchTerm, setSearchTerm] = React.useState<string>(
        props.defaultSearchString ?? ''
    );

    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const {
        columns,
        rows,
        rowHeight = 49,
        onReady,
        headerHeight,
        maximumColumnsOnDashboard,
        pagination = true,
        paginationPageSize = 10,
        search,
        pinnedTopRowData,
        addNewAdditionalCost,
        linkNewItemToTag,
        customStatusFilterOptions,
        onRowClicked,
        getRowClass,
        getRowId,
        onRowSelect,
        rowSelection = 'multiple',
        suppressRowClickSelection = true,
        currentTab,
        context,
        fixedHeight,
        selectedRows,
        doesExternalFilterPass,
        isExternalFilterPresent,
        defaultColDef,
        className,
        onSortChanged,
        postSortRows,
        onFilterChanged,
        // onColumnMoved,
        handleDownloadSelectedRows,
        handleRowSelectionInRowDataInterface,
        onColumnPinned,
        onColumnVisible,
        // onColumnResized,
        onFirstDataRendered,
        onRowDataChanged,
        getRowHeight,
        domLayout,
        addButtonDisableTooltip,
        displayLoader,
        rowClassRules,
        showSearchBar = false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        searchPlaceholder,
        showExportButton = false,
        disableExportButton = false,
        exportBtnOnClick,
        exportFileName = 'export.csv',
        suppressClickEdit = true,
        onCellKeyDown,
        onCellEditingStarted,
        disableFESearch = false,
        onTextChange,
        suppressPaginationPanel,
        useCustomPagination,
        buyerEntities,
        customPaginationPageNumber,
        showRefreshIcon,
        customPaginationOnChange,
        showDownloadRowDataIcon,
        onRefreshIconClick,
        showFilterColumnMenu,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        handleBulkImportBtnClick,
        onAddBtnClick,
        createNewMeasurementUnit,
        disableCreateButtonClick,
        allowCreateNewProject,
        allowFilterByStatus,
        allowFilterByCustomStatus,
        showNoOfItemsComponent,
        noOfItemsForThisPage,
        filterByStatus,
        setFilterByStatus,
        allowFilterByBuyerEntity,
        // showEntityFilterTitle,
        onBuyerEntityChange,
        onCreateNewProject,
        disableDownloadButton,
        gridRef,
        filterByTemplateSection,
        templateSections,
        templateSectionDelimiter,
        fieldTypeLabels = [],
        builtInFieldsMap = {},
        filterFieldToNameMap = {},
        // eventsFieldTypeLabel = [],
        handleSelectedTemplateSectionChange,
        handleRowsPerPageChange,
        downloadCSV,
        // resetFilters,
        filteredColumns,
        handleSortApply,
        handleFilterApply,
        sortField = [],
        dashboardFilter = null,
        isLoading = false,
        dashboardType,
        totalItems,
        CustomeAdmin = null,
        CustomeBulkandPlus = null,
        showFilterColumnIcon = true,
        showSortIcon = true,
        showViews = true,
    }: FWDataGridProps = props;

    //states
    const [selectedBuyerEntity, setSelectedBuyerEntity] =
        useState<IVendorBuyerEntity | null>(null);

    const [buyerEntityId, setBuyerEntityId] = useState<string>('');

    const [showLoaderOnExportIcon, setShowLoaderOnExportIcon] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [openFilter, setOpenFilter] = useState(false);

    const [openSort, setOpenSort] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');

    const [filterColumnMenuList, setFilterColumnMenuList] = React.useState<
        IFilterColumnMenuList[]
    >([]);

    const [selectedTemplateSections, setSelectedTemplateSections] = useState<
        string[]
    >(templateSections ?? []);

    const [isSortedd, setIsSortedd] = useState(false);
    const handleSortStateChange = (sorted: boolean) => {
        setIsSortedd(sorted);
    };

    const [filterCount, setFilterCount] = useState(0);
    const handleFilterCountChange = (count: number) => {
        setFilterCount(count);
    };

    // constants
    const noOfRows = (rows && rows.length) || 0;
    const openCoulmnFilterMenu = Boolean(anchorEl);
    const buttonRef = useRef<null | HTMLElement>(null);

    const sortButtonRef = useRef<null | HTMLElement>(null);
    const [activeFilter, setActiveFilter] = useState(false);
    const { authData } = useContext(AuthContext);

    const cookieName: string = useMemo(() => {
        return authData.details?.name
            ? `${authData.details?.name}-${dashboardType}-dashboard`
            : `${dashboardType}-dashboard`;
    }, [authData.details?.name, dashboardType]);
    const handleClosePopper = () => {
        setOpenFilter(false);
        setActiveFilter(false);
    };

    const [activeSort, setActiveSort] = useState(false);
    const handleCloseSortPopper = () => {
        setOpenSort(false);
        setActiveSort(false);
    };

    const handleFilterClick = () => {
        setOpenFilter((prev) => !prev);
        setActiveFilter((prev) => !prev);

        if (openSort) {
            setOpenSort(false);
            setActiveSort(false);
        }
        // setIsFilterActive((prev) => !prev);
    };

    const handleSortClick = () => {
        setOpenSort((prev) => !prev);
        setActiveSort((prev) => !prev);

        if (openFilter) {
            setOpenFilter(false);
            setActiveFilter(false);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);

    const isColumnFiltered = React.useCallback(
        (fieldNameForBE: string | undefined): boolean => {
            if (!fieldNameForBE) {
                return false;
            }

            const isFiltered =
                dashboardFilter?.and_?.some((filterGroup) => {
                    if (filterGroup.single) {
                        return filterGroup.single.field === fieldNameForBE;
                    }

                    if (filterGroup.and_) {
                        return filterGroup.and_.some((subFilter) => {
                            return (
                                subFilter.single &&
                                subFilter.single.field === fieldNameForBE
                            );
                        });
                    }

                    return false;
                }) || false;

            return isFiltered;
        },
        [dashboardFilter]
    );

    const isFieldSorted = React.useCallback(
        (field: string | undefined): boolean => {
            if (!field) {
                return false;
            }
            const isSorted =
                sortField?.some((item) => item.field === field) ?? false;
            return isSorted;
        },
        [sortField]
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedView, setSelectedView] = useState(
        localStorage.getItem('selectedView') || ''
    );

    // Manage saved views state in parent
    const [savedViews, setSavedViews] = useState<{
        [key: string]: IFilterColumnMenuList[];
    }>({});

    const handleColumnMenuList = useCallback(
        (updatedFilteredColumnMenuList: IFilterColumnMenuList[]) => {
            setFilterColumnMenuList(updatedFilteredColumnMenuList);
        },
        []
    );

    const loadSavedView = useCallback(
        (viewName: string) => {
            const view = savedViews[viewName];
            if (view) {
                // setSelectedColumns(view);
                handleColumnMenuList(view);

                if (gridColumnApi) {
                    let columnState = view.map(({ id, checked, width }) => ({
                        colId: id,
                        hide: !checked,
                        width: width,
                    }));

                    gridColumnApi.applyColumnState({
                        state: columnState,
                        applyOrder: true,
                    });
                }
            }
        },
        [gridColumnApi, handleColumnMenuList, savedViews]
    );

    const handleViewChange = useCallback(
        (viewName: string) => {
            setSelectedView(viewName);
            // localStorage.setItem(cookieName, JSON.stringify(savedViews));
            localStorage.setItem(`${cookieName}-selectedView`, viewName);
            loadSavedView(viewName);
        },
        [cookieName, loadSavedView]
    );

    const loadSavedViewOnPageLoad = useCallback(
        (gridColumnApi: ColumnApi | undefined) => {
            const viewList = localStorage.getItem(cookieName);
            let savedViews: {
                [key: string]: IFilterColumnMenuList[];
            } = {};
            if (viewList) {
                savedViews = JSON.parse(viewList);
            }

            const storedSelectedView =
                localStorage.getItem('selectedView') || 'Factwise default view';
            // loadSavedView(storedSelectedView);

            const view = savedViews[storedSelectedView];
            if (view) {
                // setSelectedColumns(view);
                // handleColumnMenuList(view);

                setFilterColumnMenuList(view);
                if (gridColumnApi) {
                    let columnState = view.map(({ id, checked, width }) => ({
                        colId: id,
                        hide: !checked,
                        width: width,
                    }));
                    let sortState = view
                        .filter(
                            (col) =>
                                ![
                                    'options',
                                    'submittedBy',
                                    'submittedOn',
                                ].includes(col.id)
                        )
                        .map((col) => col.id);
                    if (
                        dashboardType === 'project' &&
                        currentTab !== 'in_progress'
                    ) {
                        sortState.push('submittedOn');
                        sortState.push('submittedBy');
                    }
                    sortState.push('0');

                    gridColumnApi.applyColumnState({
                        state: columnState,
                        applyOrder: true,
                    });

                    let currentState = gridColumnApi.getColumnState();
                    currentState.sort(
                        (a: any, b: any) =>
                            sortState.indexOf(a.colId) -
                            sortState.indexOf(b.colId)
                    );

                    gridColumnApi.applyColumnState({
                        state: currentState,
                        applyOrder: true,
                    });
                }
            }
        },
        [cookieName, currentTab, dashboardType]
    );

    const handleCloseDropdown = () => {
        setAnchorEl1(null);
        setDropdownOpen(false);
    };

    const handleViewSelect = (viewName: string) => {
        handleViewChange(viewName);
        handleCloseDropdown();
    };

    // Function to update saved views
    const updateSavedViews = useCallback(
        (
            updatedViews: { [key: string]: IFilterColumnMenuList[] },
            isDeleted?: boolean
        ) => {
            if (!Boolean(isDeleted)) {
                setSavedViews((prevViews) => {
                    let newViews = {
                        ...prevViews,
                        ...updatedViews,
                    };

                    localStorage.setItem(cookieName, JSON.stringify(newViews));
                    return newViews;
                });
            } else {
                setSavedViews(updatedViews);
            }
        },
        [cookieName]
    );

    const HeaderComponent: React.FC<HeaderComponentProps> = ({
        displayName,
        isFiltered,
        isSorted,
    }) => (
        <>
            <FWTypography fontSize={16} color="text.primary">
                {displayName}
            </FWTypography>

            {isFiltered && (
                <Box color="primary.main" sx={{ marginLeft: '0.5rem' }}>
                    <i className="bi bi-funnel-fill" />
                </Box>
            )}
            {isSorted && (
                <Box
                    color={'primary.main'}
                    sx={{ marginLeft: '0.5rem', fontWeight: '900' }}
                >
                    <FWIcon name="bi bi-arrow-down-up" />
                </Box>
            )}
        </>
    );

    const columnsWithCustomHeader = useMemo(() => {
        return columns.map((column) => {
            const isFiltered = isColumnFiltered(column.refData?.fieldNameForBE);
            const isSorted = isFieldSorted(column.refData?.fieldNameForBE);
            return {
                ...column,
                headerComponent: HeaderComponent,
                headerComponentParams: {
                    displayName: column.headerName as string,
                    isFiltered: isFiltered,
                    isSorted: isSorted,
                },
            };
        });
    }, [columns, isColumnFiltered, isFieldSorted]);

    let columnsWithComparator = React.useMemo(() => {
        let colsWithComparator = columnsWithCustomHeader;
        for (let col of colsWithComparator) {
            if (!col.comparator) {
                col.comparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
                    let compValA = valueA ?? '';
                    let compValB = valueB ?? '';
                    try {
                        return compValA
                            .toString()
                            .toUpperCase()
                            .localeCompare(compValB.toString().toUpperCase());
                    } catch (e) {
                        console.error('Comparator error:', e);
                        return 0;
                    }
                    //     headerComponent: (props: any) => {
                    //     return (
                    //         <FWTypography style={{ color:'black', fontWeight: 300}}>
                    //             {props.displayName}
                    //         </FWTypography>
                    //     );
                    // },
                };
            }
        }
        return colsWithComparator;
    }, [columnsWithCustomHeader]);

    useEffect(() => {
        loadSavedViewOnPageLoad(gridColumnApi);
    }, [
        currentTab,
        gridColumnApi,
        loadSavedViewOnPageLoad,
        columnsWithComparator,
    ]);

    const filteredColumnMenuList = useMemo(() => {
        return filterColumnMenuList.filter((column) =>
            column.label.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [filterColumnMenuList, searchQuery]);

    // callbacks
    const handleOnCheck = useCallback(
        (event: RowSelectedEvent) => {
            if (onRowSelect && gridApi) {
                // this will return the selected rows in row data format
                if (handleRowSelectionInRowDataInterface) {
                    handleRowSelectionInRowDataInterface(
                        gridApi.getSelectedRows()
                    );
                }
            }

            if (onRowSelect && gridApi) {
                const data = gridApi.getSelectedRows().map((row) => row.id);
                onRowSelect(data);
            }
        },
        [gridApi, handleRowSelectionInRowDataInterface, onRowSelect]
    );

    const exportAsCSV = React.useCallback(async () => {
        try {
            setShowLoaderOnExportIcon(true);
            if (exportBtnOnClick) {
                await exportBtnOnClick();
                setShowLoaderOnExportIcon(false);
            } else {
                let excludedHeaderNames = ['OPTIONS'];
                if (gridApi) {
                    gridApi.exportDataAsCsv({
                        fileName: exportFileName,
                        columnKeys: gridApi.getColumnDefs()
                            ? gridApi!
                                  .getColumnDefs()!
                                  .filter(
                                      (cd) =>
                                          cd.headerName &&
                                          !excludedHeaderNames.includes(
                                              cd.headerName.toUpperCase()
                                          )
                                  )
                                  .map((cd: ColDef) =>
                                      cd.colId ? cd.colId : ''
                                  )
                            : [],
                    });
                }
            }
        } catch (err) {
            setShowLoaderOnExportIcon(false);
        }
    }, [exportBtnOnClick, exportFileName, gridApi]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        if (!props.cookieName) return;
        setSearchQuery('');
        // let data = gridColumnApi?.getAllColumns();

        const dashboardColumns = gridColumnApi?.getColumnState();

        let columnVisibilityJson: { [key: string]: boolean } = {};
        dashboardColumns?.forEach((column) => {
            columnVisibilityJson[column.colId as string] = !column.hide ?? true;
        });

        if (props.cookieName) {
            localStorage.setItem(
                props.cookieName,
                JSON.stringify(dashboardColumns)
            );
        }
    }, [gridColumnApi, props.cookieName]);

    //  const handleDone = () => {
    //     showFilterColumnMenu(false); // Close the popover
    // };

    // useEffects
    // handle selected rows by default
    useEffect(() => {
        if (selectedRows && gridApi && selectedRows.length >= 0) {
            gridApi.forEachNode((node: RowNode, index: number) => {
                if (typeof node.data.id === 'string') {
                    node.setSelected(selectedRows.includes(node.data.id));
                } else node.setSelected(selectedRows.includes(index));
            });
        }
    }, [selectedRows, noOfRows, gridApi]);

    useEffect(() => {
        if (
            buyerEntities &&
            buyerEntities.length > 0 &&
            selectedBuyerEntity === null
        ) {
            setSelectedBuyerEntity(buyerEntities[0]);
            setBuyerEntityId(buyerEntities[0].entityUid);
        }
    }, [buyerEntities, selectedBuyerEntity]);

    // useEffect(() => {
    //     if (gridApi) {
    //         if (rows === null) {
    //             gridApi.showLoadingOverlay();
    //         } else if (rows != null) {
    //             if (rows.length === 0) {
    //                 gridApi.showNoRowsOverlay();
    //             }
    //         }
    //     }
    // }, [rows, gridApi]);

    function getCookie(name: string | undefined) {
        const cookies = localStorage.getItem(name ?? '');

        if (!cookies) return null;

        return cookies;
    }

    const numberOfOptionsSelected = useMemo(() => {
        return filterColumnMenuList.filter((option) => option.checked)?.length;
    }, [filterColumnMenuList]);

    const maximumColumnsSelected = useMemo(() => {
        if (maximumColumnsOnDashboard !== undefined) {
            return numberOfOptionsSelected > maximumColumnsOnDashboard;
        }
        return false;
    }, [maximumColumnsOnDashboard, numberOfOptionsSelected]);

    // const [filterByStatus, setFilterByStatus] = useState<StatusFilterEnum>(
    //     StatusFilterEnum.ALL
    // )

    // append All is the default option in list
    const [filterByCustomStatus, setFilterByCustomStatus] = useState<any>(
        DEFAULT_OPTION_FOR_CUSTOM_FILTERS
    );

    const toggleFilterColumnMenu = (event: DragStoppedEvent) => {
        const columnsMap: {
            [key: string]: boolean | null | undefined;
        } = {};

        event?.columnApi.getColumnState()?.forEach((column) => {
            columnsMap[column.colId] = column.hide;
        });

        const newFilterColumnMenuList = filterColumnMenuList.map((column) => {
            return {
                ...column,
                checked: !columnsMap[column.id] ?? true,
            };
        });
        setFilterColumnMenuList(newFilterColumnMenuList);
    };

    const setFilterColumnMenuListFunc = useCallback(() => {
        // if (gridApi) gridApi.showLoadingOverlay();
        if (props.cookieName) {
            // const filterModel = getCookie(props.cookieName);

            const dashboardColumnDataString = getCookie(props.cookieName);

            if (
                dashboardColumnDataString !== null &&
                dashboardColumnDataString !== 'undefined' &&
                gridColumnApi
            ) {
                const dashboardColumnData: {
                    colId: string;
                    width: number;
                    hide: boolean;
                    pinned: any;
                    sort: any;
                    sortIndex: any;
                    aggFunc: any;
                    rowGroup: boolean;
                    rowGroupIndex: any;
                    pivot: boolean;
                    pivotIndex: any;
                    flex: number;
                }[] = JSON.parse(dashboardColumnDataString);

                if (isArray(dashboardColumnData)) {
                    dashboardColumnData
                        ?.filter(
                            (val) =>
                                val.colId !== 'options' && val.colId !== '0'
                        )
                        .forEach((column, idx) => {
                            // const colId = column.colId;

                            const isVisible = !column.hide;
                            //
                            if (isVisible !== undefined) {
                                // gridColumnApi.setColumnVisible(
                                //     colId,
                                //     isVisible
                                // );
                            }
                        });
                } else {
                    const filterModel = getCookie(props.cookieName);

                    if (filterModel && gridColumnApi) {
                        // the following code snippet gave an oh-snap - JSON.parse(decodeURIComponent(filterModel));
                        const filterModelJSON = JSON.parse(filterModel);

                        gridColumnApi
                            .getAllColumns()
                            ?.filter(
                                (val) =>
                                    val.getColId() !== 'options' &&
                                    val.getColId() !== '0'
                            )
                            .forEach((column, idx) => {
                                const colId = column.getColId();
                                const isVisible = filterModelJSON
                                    ? JSON.parse(
                                          JSON.stringify(filterModelJSON)
                                      )[colId]
                                    : true;

                                if (isVisible !== undefined) {
                                    // gridColumnApi.setColumnVisible(
                                    //     colId,
                                    //     isVisible
                                    // );
                                }
                            });
                    }
                }
            }
        }

        if (showFilterColumnMenu && gridColumnApi) {
            let filterColumnMenuList: IFilterColumnMenuList[] = [];
            gridColumnApi
                .getAllColumns()
                ?.filter(
                    (val) =>
                        val.getColId() !== 'selection' && val.getColId() !== '0'
                    //  &&
                    // val.getColDef().hide !== true
                )
                .forEach((column: Column) => {
                    const columnData = column.getColDef();
                    let isFWField = false;
                    let sectionName: string | null = null;
                    let columnName: string | null = null;
                    let isBuiltIn: boolean = true;
                    let fieldtype: CustomFieldType = CustomFieldType.SHORTTEXT;
                    if (columnData.refData) {
                        isFWField =
                            columnData.refData[TEMPLATE_FIELD_TYPE] === 'FW';
                        sectionName =
                            columnData.refData[DASHBOARD_SECTION_NAME] ?? null;

                        columnName =
                            columnData.refData[DASHBOARD_FIELD_COLUMN] ?? null;

                        isBuiltIn =
                            columnData.refData[DASHBOARD_FIELD] === 'BUILTIN';

                        fieldtype = columnData.refData[
                            DASHBOARD_FIELD_TYPE
                        ] as CustomFieldType;
                    }

                    filterColumnMenuList.push({
                        id: column.getColId(),
                        isFWField: isFWField,
                        column: columnName,
                        isBuiltIn,
                        fieldType: fieldtype,
                        sectionName: sectionName,
                        label: column.getColDef()?.headerName ?? '',
                        checked: column.isVisible() ?? true,
                        fields:
                            columnData?.refData?.[DASHBOARD_FIELD_NAME] ?? null,
                        width: column.getActualWidth(),
                    });
                });

            setFilterColumnMenuList(filterColumnMenuList ?? []);
        }
        // if (gridApi) gridApi.hideOverlay();
    }, [gridColumnApi, props.cookieName, showFilterColumnMenu]);

    useEffect(() => {
        setFilterColumnMenuListFunc();
    }, [setFilterColumnMenuListFunc]);

    const handleColumnMoved = useCallback(() => {
        const columnState =
            gridColumnApi?.getColumnState().map((col) => col.colId) ?? [];
        const savedViewsFromStorage: {
            [key: string]: IFilterColumnMenuList[];
        } = JSON.parse(localStorage.getItem(cookieName) ?? '');

        if (!savedViewsFromStorage) {
            return;
        }
        let currentView = cloneDeep(savedViewsFromStorage[selectedView]);
        console.log('selectedView', selectedView);
        if (!currentView) {
            return;
        } else if (selectedView === 'Factwise default view') {
            return;
        }
        currentView.sort(
            (a, b) => columnState.indexOf(a.id) - columnState.indexOf(b.id)
        );

        let viewToSave = cloneDeep(savedViewsFromStorage);
        viewToSave[selectedView] = currentView;

        // localStorage.setItem(cookieName, JSON.stringify(viewToSave));
        updateSavedViews(viewToSave, true);
        setFilterColumnMenuList(currentView);
    }, [cookieName, gridColumnApi, selectedView, updateSavedViews]);

    const handleColumnResize = useCallback(() => {
        // console.log('handleColumnResize');
        const columnState = gridColumnApi?.getColumnState();

        const savedViewsFromStorage: {
            [key: string]: IFilterColumnMenuList[];
        } = JSON.parse(localStorage.getItem(cookieName) ?? '');

        if (!savedViewsFromStorage) {
            return;
        }

        let currentView = cloneDeep(savedViewsFromStorage[selectedView]);
        if (!currentView) {
            return;
        }
        currentView.forEach((col) => {
            if (columnState) {
                const colState = columnState.find((c) => c.colId === col.id);
                if (colState) {
                    col.width = colState.width;
                }
            }
        });
        let viewToSave = cloneDeep(savedViewsFromStorage);
        viewToSave[selectedView] = currentView;
        // localStorage.setItem(cookieName, JSON.stringify(viewToSave));
        updateSavedViews(viewToSave, true);
        setFilterColumnMenuList(currentView);
        // console.log('handleColumnResize');
    }, [cookieName, gridColumnApi, selectedView, updateSavedViews]);

    if (displayLoader) {
        return (
            <Box
                style={{
                    width: '100%',
                    height: '82vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // backgroundColor: 'white',
                }}
            >
                <Backdrop
                    style={{
                        zIndex: 1000000,
                        color: '#fff',
                    }}
                    open={true}
                >
                    <CircularProgress style={{ color: 'black' }} />
                </Backdrop>
            </Box>
        );
    }

    return (
        <>
            {/* {(showLoader || gridApi === undefined) && <LoadingBackDrop />} */}
            {(showSearchBar || showExportButton) && (
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: '2px',
                        gap: '0.75rem',
                    }}
                    marginBottom={'12px'}
                >
                    <Grid
                        item
                        xs={4}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: '2px',
                            gap: '0.75rem',
                        }}
                    >
                        {showDownloadRowDataIcon &&
                            gridApi &&
                            handleDownloadSelectedRows && (
                                <FWTooltip title="Download selected rows">
                                    <div>
                                        <IconButton
                                            color="primary"
                                            onClick={handleDownloadSelectedRows}
                                            disabled={disableDownloadButton}
                                            sx={{
                                                fontSize: '1.5rem',
                                                marginLeft: '6px',
                                            }}
                                        >
                                            <i className="bi bi-download"></i>
                                        </IconButton>
                                    </div>
                                </FWTooltip>
                            )}
                        {allowFilterByStatus && (
                            <CustomFormControl fullWidth>
                                <InputLabel id="Filter-By">
                                    Filter By
                                </InputLabel>

                                <FWSelect
                                    value={filterByStatus}
                                    onChange={(e: any) => {
                                        if (setFilterByStatus)
                                            setFilterByStatus(e.target.value);
                                        props.onStatusChanged &&
                                            props.onStatusChanged(
                                                e.target.value
                                            );
                                    }}
                                    labelId="Filter-By"
                                    label="Filter By"
                                    fullWidth
                                >
                                    <FWMenuItem value={StatusFilterEnum.ALL}>
                                        All
                                    </FWMenuItem>
                                    <FWMenuItem value={StatusFilterEnum.ACTIVE}>
                                        Active
                                    </FWMenuItem>
                                    <FWMenuItem
                                        value={StatusFilterEnum.DISABLED}
                                    >
                                        Disabled
                                    </FWMenuItem>
                                </FWSelect>
                            </CustomFormControl>
                        )}

                        <Box>{CustomeAdmin}</Box>

                        {customStatusFilterOptions &&
                            allowFilterByCustomStatus && (
                                <CustomFormControl fullWidth>
                                    <InputLabel id="Filter-custom-status">
                                        Filter By
                                    </InputLabel>

                                    <FWSelect
                                        value={filterByCustomStatus}
                                        onChange={(e: any) => {
                                            setFilterByCustomStatus(
                                                e.target.value
                                            );
                                            props.onCustomStatusChanged &&
                                                props.onCustomStatusChanged(
                                                    e.target.value
                                                );
                                        }}
                                        labelId="Filter-custom-status"
                                        label="Filter By"
                                        fullWidth
                                    >
                                        {customStatusFilterOptions?.map(
                                            (filter) => (
                                                <FWMenuItem value={filter}>
                                                    {filter}
                                                </FWMenuItem>
                                            )
                                        )}
                                    </FWSelect>
                                </CustomFormControl>
                            )}

                        {allowFilterByBuyerEntity && buyerEntities && (
                            <CustomFormControl fullWidth>
                                <InputLabel id="Filter-buyer-entity-status">
                                    Buyer Entity
                                </InputLabel>

                                <FWSelect
                                    value={buyerEntityId}
                                    onChange={(e: any) => {
                                        // setFilterByCustomStatus(e.target.value);
                                        // props.onCustomStatusChanged &&
                                        //     props.onCustomStatusChanged(
                                        //         e.target.value
                                        //     );
                                    }}
                                    labelId="Filter-buyer-entity-status"
                                    label="Buyer Entity"
                                    fullWidth
                                >
                                    {buyerEntities?.map((entity) => (
                                        <FWMenuItem
                                            value={entity.entityUid}
                                            onClick={() => {
                                                setSelectedBuyerEntity(entity);
                                                setBuyerEntityId(
                                                    entity.entityUid
                                                );
                                                if (onBuyerEntityChange) {
                                                    onBuyerEntityChange(entity);
                                                }
                                            }}
                                        >
                                            {entity.entityName}
                                        </FWMenuItem>
                                    ))}
                                </FWSelect>
                            </CustomFormControl>
                        )}

                        {filterByTemplateSection &&
                            templateSections &&
                            templateSectionDelimiter &&
                            handleSelectedTemplateSectionChange && (
                                <CustomFormControl fullWidth>
                                    <InputLabel id="Filter-project-section">
                                        Project section
                                    </InputLabel>

                                    <FWSelect
                                        value={
                                            selectedTemplateSections?.length ===
                                            0
                                                ? ['All templates selected']
                                                : selectedTemplateSections
                                        }
                                        onChange={(e: any) => {
                                            // setFilterByCustomStatus(e.target.value);
                                            // props.onCustomStatusChanged &&
                                            //     props.onCustomStatusChanged(
                                            //         e.target.value
                                            //     );
                                        }}
                                        labelId="Filter-project-section"
                                        label="Project section"
                                        renderValue={(value) => {
                                            if (
                                                isEqual(value, [
                                                    'All templates selected',
                                                ]) ||
                                                (value as string[])?.length ===
                                                    templateSections?.length
                                            ) {
                                                return (
                                                    <FWTypography>
                                                        All sections
                                                    </FWTypography>
                                                );
                                            }
                                            const filterBySections = (
                                                value as string[]
                                            ).map(
                                                (selection) =>
                                                    selection.split(
                                                        PROJECT_DASHBOARD_FIELD_DELIMITER
                                                    )[1]
                                            );

                                            return (
                                                <>
                                                    {filterBySections.map(
                                                        (section) => (
                                                            <Chip
                                                                size="small"
                                                                label={section}
                                                                sx={{
                                                                    marginLeft:
                                                                        '5px',
                                                                }}
                                                            />
                                                        )
                                                    )}
                                                </>
                                            );
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    bgcolor: 'white',
                                                    maxHeight: '538px',
                                                    boxShadow:
                                                        '0px 5px 16px #00000024',
                                                },
                                            },
                                        }}
                                        fullWidth
                                        multiple
                                    >
                                        {templateSections?.length > 1 && (
                                            <FWMenuItem
                                                value={''}
                                                sx={{
                                                    display: 'flex',
                                                    paddingX: 0,
                                                    gap: '4px',
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    let updateList: string[] =
                                                        [];

                                                    const areAllSectionsSelected =
                                                        selectedTemplateSections?.length ===
                                                        templateSections?.length;

                                                    if (
                                                        areAllSectionsSelected
                                                    ) {
                                                        updateList = [];
                                                    } else {
                                                        updateList =
                                                            templateSections;
                                                    }
                                                    setSelectedTemplateSections(
                                                        updateList
                                                    );
                                                    if (
                                                        handleSelectedTemplateSectionChange
                                                    ) {
                                                        handleSelectedTemplateSectionChange(
                                                            updateList
                                                        );
                                                    }
                                                }}
                                            >
                                                <Checkbox
                                                    checked={
                                                        selectedTemplateSections?.length ===
                                                        templateSections?.length
                                                    }
                                                    sx={{ paddingY: 0 }}
                                                />
                                                <FWTypography>
                                                    Select all
                                                </FWTypography>
                                            </FWMenuItem>
                                        )}
                                        {templateSections?.map(
                                            (templateSection) => {
                                                const [
                                                    templateName,
                                                    SectionName,
                                                ] = templateSection.split(
                                                    templateSectionDelimiter
                                                );

                                                const isTemplateAndSectionSelected =
                                                    selectedTemplateSections.findIndex(
                                                        (
                                                            selectedtemplateSection
                                                        ) =>
                                                            selectedtemplateSection ===
                                                            templateSection
                                                    );
                                                return (
                                                    <FWMenuItem
                                                        value={templateSection}
                                                        sx={{
                                                            display: 'flex',
                                                            paddingX: 0,
                                                            gap: '4px',
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setSelectedTemplateSections(
                                                                (prev) => {
                                                                    let newPrev =
                                                                        cloneDeep(
                                                                            prev
                                                                        );

                                                                    if (
                                                                        isTemplateAndSectionSelected ===
                                                                        -1
                                                                    ) {
                                                                        newPrev =
                                                                            [
                                                                                ...newPrev,
                                                                                templateSection,
                                                                            ];
                                                                    } else {
                                                                        newPrev.splice(
                                                                            isTemplateAndSectionSelected,
                                                                            1
                                                                        );
                                                                    }
                                                                    handleSelectedTemplateSectionChange(
                                                                        newPrev
                                                                    );
                                                                    return newPrev;
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                isTemplateAndSectionSelected !==
                                                                -1
                                                            }
                                                            sx={{
                                                                paddingY: 0,
                                                            }}
                                                        />
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                gap: '4px',
                                                            }}
                                                        >
                                                            <FWTypography
                                                                marginRight={
                                                                    '5px'
                                                                }
                                                            >
                                                                {SectionName}
                                                            </FWTypography>
                                                            <FWTooltip
                                                                title={
                                                                    templateName
                                                                }
                                                            >
                                                                <Box color="primary.main">
                                                                    <FWIcon
                                                                        name="bi bi-info-circle"
                                                                        size={
                                                                            16
                                                                        }
                                                                    />
                                                                </Box>
                                                            </FWTooltip>
                                                        </Box>
                                                    </FWMenuItem>
                                                );
                                            }
                                        )}
                                    </FWSelect>
                                </CustomFormControl>
                            )}

                        {/* {allowFilterByBuyerEntity && buyerEntities && (
                            <CustomFormControl fullWidth>
                                <InputLabel id="buyer-entity">
                                    Buyer entity
                                </InputLabel>

                                <FWSelect
                                    value={selectedBuyerEntity?.entityName}
                                    onChange={(e: any) => {
                                        
                                        // if (onBuyerEntityChange) {
                                        //     onBuyerEntityChange(newValue);
                                        // }
                                        // setSelectedBuyerEntity(newValue);
                                    }}
                                    labelId="buyer-entity"
                                    label="Buyer entity"
                                    fullWidth
                                >
                                    {buyerEntities.map((entity) => {
                                        if (
                                            entity.entityUid === 'GLOBAL_ENTITY'
                                        ) {
                                            return (
                                                <FWMenuItem
                                                    value={entity.entityUid}
                                                >
                                                    Global
                                                </FWMenuItem>
                                            );
                                        } else {
                                            return (
                                                <FWMenuItem
                                                    value={entity.entityUid}
                                                >
                                                    {entity.entityName}
                                                </FWMenuItem>
                                            );
                                        }
                                    })}
                                </FWSelect>
                            </CustomFormControl> 
                        )}*/}
                    </Grid>

                    <Grid
                        item
                        xs={'auto'}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            mt: '2px',
                            gap: '0.75rem',
                        }}
                    >
                        {' '}
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'flex-start',
                                gap: '0.50rem',
                                cursor: 'pointer',
                                paddingX: '10px',
                                paddingY: '3px',
                                borderRadius: '8px',
                                backgroundColor: 'white',
                                // '&:hover': {
                                //     backgroundColor: '#EEEDEB',
                                // },
                            }}
                        >
                            {showViews && (
                                <Box sx={{ minWidth: '250px' }}>
                                    <SavedViewsDropdown
                                        savedViews={savedViews}
                                        selectedView={selectedView}
                                        setSelectedView={setSelectedView}
                                        setSavedViews={setSavedViews}
                                        onViewSelect={handleViewSelect}
                                        dashboardType={dashboardType}
                                        handleViewChange={handleViewChange}
                                        // anchorEl={anchorEl1}
                                        // open={dropdownOpen}
                                        // onClose={handleCloseDropdown}
                                    />
                                </Box>
                            )}
                        </Box>
                        {showFilterColumnMenu && (
                            <Box>
                                {gridApi && (
                                    <FWTooltip title="Columns and Views">
                                        <div>
                                            <IconButton
                                                color="primary"
                                                onClick={handleClick}
                                                sx={{
                                                    fontSize: '1.5rem',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#EEEDEB',
                                                    },
                                                }}
                                            >
                                                <i className="bi bi-sliders2"></i>
                                            </IconButton>
                                        </div>
                                    </FWTooltip>
                                )}
                            </Box>
                        )}
                        <Box>
                            {showFilterColumnIcon && (
                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.50rem',
                                        cursor: 'pointer',
                                        paddingX: '10px',
                                        paddingY: '3px',
                                        borderRadius: '8px',
                                        backgroundColor: activeFilter
                                            ? '#EEEDEB'
                                            : 'white',
                                        '&:hover': {
                                            backgroundColor: '#EEEDEB',
                                        },
                                    }}
                                    onClick={handleFilterClick}
                                    ref={buttonRef}
                                >
                                    <FWTooltip title={'Filter'}>
                                        <Box
                                            color={'primary.main'}
                                            fontSize={'1.5rem'}
                                        >
                                            <FWIcon name="bi bi-filter" />
                                            {filterCount > 0 && (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '-4px',
                                                        left: '-4px',
                                                        backgroundColor:
                                                            '#007AFF',
                                                        color: 'white',
                                                        borderRadius: '50%',
                                                        width: '15px',
                                                        height: '15px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        fontSize: '10px',
                                                    }}
                                                >
                                                    {filterCount}
                                                </Box>
                                            )}
                                        </Box>
                                    </FWTooltip>
                                </Box>
                            )}

                            <ProjectDashboardFilterPopup
                                anchorEl={buttonRef.current}
                                defaultValue={dashboardFilter}
                                fieldTypeLabels={fieldTypeLabels}
                                filterFieldToNameMap={filterFieldToNameMap}
                                builtInFieldsMap={builtInFieldsMap}
                                filterColumnMenuList={filterColumnMenuList}
                                handleColumnMoved={handleColumnMoved}
                                maximumColumnsSelected={maximumColumnsSelected}
                                open={openFilter}
                                handleClose={handleClosePopper}
                                isLoading={isLoading ?? false}
                                currentTab={currentTab}
                                onFilterCountChange={handleFilterCountChange}
                                handleFilterApply={async (
                                    filter: DashBoardFilter | null
                                ) => {
                                    if (handleFilterApply) {
                                        await handleFilterApply(filter);
                                    }
                                }}
                                onAddFilterFieldClick={(
                                    e,
                                    checked,
                                    id,
                                    label
                                ) => {
                                    // if maximum number of columns are selected -> allow user to unselect current option else allow user to select & unSelect
                                    if (
                                        (maximumColumnsSelected && checked) ||
                                        !maximumColumnsSelected
                                    ) {
                                        e.preventDefault();
                                        if (gridColumnApi) {
                                            // find column from list of all grid columns
                                            let colDef: Column | undefined =
                                                gridColumnApi
                                                    .getAllColumns()
                                                    ?.find(
                                                        (column: Column) =>
                                                            column.getColId() ===
                                                            id
                                                    );
                                            if (colDef) {
                                                gridColumnApi.setColumnVisible(
                                                    id,
                                                    !checked
                                                );

                                                if (
                                                    props.saveDashboardColumnOrder
                                                ) {
                                                    handleColumnMoved();
                                                }

                                                setFilterColumnMenuList(
                                                    (prev) => {
                                                        let newPrev =
                                                            cloneDeep(prev);

                                                        newPrev = newPrev.map(
                                                            (fcm) =>
                                                                fcm.id === id
                                                                    ? {
                                                                          ...fcm,
                                                                          checked:
                                                                              !checked ??
                                                                              true,
                                                                      }
                                                                    : fcm
                                                        );

                                                        return newPrev;
                                                    }
                                                );
                                            }
                                        }
                                    }
                                }}
                            />
                        </Box>
                        {showSortIcon && (
                            <Box>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.50rem',
                                        cursor: 'pointer',
                                        paddingX: '10px',
                                        paddingY: '3px',
                                        borderRadius: '8px',
                                        backgroundColor: activeSort
                                            ? '#EEEDEB'
                                            : 'white',
                                        '&:hover': {
                                            backgroundColor: '#EEEDEB',
                                        },
                                    }}
                                    onClick={handleSortClick}
                                    ref={sortButtonRef}
                                >
                                    <FWTooltip title={'Sort'}>
                                        <Box
                                            color={'primary.main'}
                                            fontSize={'1.15rem'}
                                        >
                                            <FWIcon name="bi bi-arrow-down-up" />
                                            {isSortedd && (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '-4px',
                                                        left: '-4px',
                                                        backgroundColor:
                                                            '#007AFF',
                                                        color: 'white',
                                                        borderRadius: '50%',
                                                        width: '13px',
                                                        height: '13px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        fontSize: '10px',
                                                    }}
                                                ></Box>
                                            )}
                                        </Box>
                                    </FWTooltip>
                                </Box>

                                <ProjectDashboardSortPopup
                                    open={openSort}
                                    currentTab={currentTab}
                                    defaultValue={sortField}
                                    handleClose={handleCloseSortPopper}
                                    fieldTypeLabels={fieldTypeLabels}
                                    builtInFieldsMap={builtInFieldsMap}
                                    filterFieldToNameMap={filterFieldToNameMap}
                                    anchorEl={sortButtonRef.current}
                                    isLoading={isLoading ?? false}
                                    handleSortApply={(
                                        sort: DashboardSort[]
                                    ) => {
                                        if (handleSortApply) {
                                            handleSortApply(sort);
                                        }
                                    }}
                                    onSortStateChange={handleSortStateChange}
                                />
                            </Box>
                        )}
                        {/* {showFilterColumnMenu && gridApi && (
                            <FWTooltip title="Columns and Views">
                                <div>
                                    <IconButton
                                        color="primary"
                                        onClick={handleClick}
                                        sx={{
                                            fontSize: '1.5rem',
                                            '&:hover': {
                                                backgroundColor: '#EEEDEB',
                                            },
                                        }}
                                    >
                                        <i className="bi bi-sliders2"></i>
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        )} */}
                        {showExportButton && (
                            <FWTooltip
                                title={
                                    downloadCSV
                                        ? 'Export Item Tags Linkages'
                                        : 'Export as Excel'
                                }
                            >
                                <div>
                                    <IconButton
                                        disabled={
                                            disableExportButton ||
                                            showLoaderOnExportIcon
                                        }
                                        color={
                                            disableExportButton
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                        onClick={
                                            downloadCSV
                                                ? downloadCSV
                                                : exportAsCSV
                                        }
                                        sx={{
                                            fontSize: '1.5rem',
                                            '&:hover': {
                                                backgroundColor: '#EEEDEB',
                                            },
                                        }}
                                    >
                                        {showLoaderOnExportIcon ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <i className="bi bi-box-arrow-up"></i>
                                        )}
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        )}
                        {showRefreshIcon && (
                            <FWTooltip title="Refresh dashboard">
                                <div>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={onRefreshIconClick}
                                        sx={{ fontSize: '1.5rem' }}
                                    >
                                        <i className="bi bi-arrow-clockwise"></i>
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        )}
                        {showSearchBar && (
                            <>
                                {searchTerm.length > 0 && (
                                    <>
                                        <FWTooltip title="Results are filtered">
                                            <Box color="primary.main">
                                                <i className="bi bi-funnel-fill fs--24" />
                                            </Box>
                                        </FWTooltip>
                                    </>
                                )}

                                <FWInput
                                    value={searchTerm}
                                    onChange={(e: any) => {
                                        setSearchTerm(e.target.value);
                                        onTextChange &&
                                            onTextChange(e.target.value);
                                    }}
                                    placeholder={'Search'}
                                    iconStart={<i className="bi bi-search" />}
                                    style={{ width: 400 }}
                                />
                            </>
                        )}
                        <Box>{CustomeBulkandPlus}</Box>
                        {allowCreateNewProject && (
                            <FWTooltip
                                title={
                                    disableCreateButtonClick
                                        ? addButtonDisableTooltip ?? ''
                                        : 'Create Project'
                                }
                            >
                                <div>
                                    <IconButton
                                        disabled={
                                            disableCreateButtonClick ?? false
                                        }
                                        color="primary"
                                        onClick={onCreateNewProject}
                                        // sx={{
                                        //     marginRight: showRefreshIcon
                                        //         ? '12px'
                                        //         : '',
                                        // }}
                                    >
                                        <i className="bi bi-plus-circle"></i>
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        )}
                        {onAddBtnClick && (
                            <FWTooltip title="Refresh dashboard">
                                <div>
                                    <IconButton
                                        disabled={
                                            disableCreateButtonClick ?? false
                                        }
                                        color="primary"
                                        onClick={onAddBtnClick}
                                        // sx={{
                                        //     marginRight: showRefreshIcon
                                        //         ? '12px'
                                        //         : '',
                                        // }}
                                    >
                                        <i className="bi bi-plus-circle"></i>
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        )}
                        {addNewAdditionalCost && (
                            <>
                                <FWTooltip title="Add new additional costs">
                                    <div>
                                        <IconButton
                                            sx={{ fontSize: '1.5rem' }}
                                            color="primary"
                                            disabled={
                                                !addNewAdditionalCost?.showButton
                                            }
                                            onClick={() =>
                                                addNewAdditionalCost.toggleState()
                                            }
                                        >
                                            <i className="bi bi-plus-circle"></i>
                                        </IconButton>
                                    </div>
                                </FWTooltip>
                            </>
                        )}
                        {createNewMeasurementUnit && (
                            <>
                                <FWTooltip title="Create new measurement unit">
                                    <div>
                                        <IconButton
                                            sx={{ fontSize: '1.5rem' }}
                                            color="primary"
                                            disabled={
                                                !createNewMeasurementUnit?.showButton
                                            }
                                            onClick={() =>
                                                createNewMeasurementUnit.toggleState()
                                            }
                                        >
                                            <i className="bi bi-plus-circle"></i>
                                        </IconButton>
                                    </div>
                                </FWTooltip>
                            </>
                        )}
                        {linkNewItemToTag && (
                            <>
                                <FWTooltip title="Link item tag to vendor">
                                    <div>
                                        <IconButton
                                            sx={{ fontSize: '1.5rem' }}
                                            color="primary"
                                            disabled={
                                                !linkNewItemToTag?.showButton
                                            }
                                            onClick={() =>
                                                linkNewItemToTag.toggleState()
                                            }
                                        >
                                            <i className="bi bi-plus-circle"></i>
                                        </IconButton>
                                    </div>
                                </FWTooltip>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}

            {/* column filter sidebar */}
            {gridApi && showFilterColumnMenu && (
                <FWMenu
                    anchorEl={anchorEl}
                    open={openCoulmnFilterMenu}
                    onClose={handleClose}
                    PaperProps={{
                        sx: {
                            bgcolor: 'white',
                            minHeight: '338px',
                            maxHeight: '638px',
                            width: '415px',
                        },
                    }}
                >
                    <Box minWidth={100}>
                        <FWDataGridFilterColumnPopover
                            filteredColumnMenuList={filteredColumnMenuList}
                            filteredColumns={filteredColumns ?? []}
                            gridColumnApi={gridColumnApi}
                            maximumColumnsSelected={maximumColumnsSelected}
                            handleColumnMenuList={handleColumnMenuList}
                            handleColumnMoved={handleColumnMoved}
                            saveDashboardColumnOrder={
                                props.saveDashboardColumnOrder
                            }
                            dashboardFilters={dashboardFilter}
                            dashboardSort={sortField}
                            fieldTypeLabels={fieldTypeLabels}
                            onClose={handleClose}
                            dashboardType={dashboardType}
                            savedViews={savedViews} // Pass savedViews
                            updateSavedViews={updateSavedViews}
                            handleViewChange={handleViewChange}
                            currentTab={currentTab}
                        />
                    </Box>
                </FWMenu>
            )}

            <div
                className={`ag-theme-alpine-project ${
                    onRowClicked ? 'pointer' : ''
                }`}
                style={{
                    height: !fixedHeight
                        ? noOfRows < paginationPageSize
                            ? ((noOfRows === 0 ? 2 : noOfRows) + 2) *
                                  rowHeight +
                              15
                            : ''
                        : fixedHeight + 'px',
                }}
            >
                <AgGridReact
                    api={gridApi}
                    setGridApi={setGridApi}
                    rowData={rows}
                    columnDefs={columnsWithComparator}
                    pagination={pagination}
                    paginationPageSize={rowsPerPage}
                    onGridReady={onGridReady}
                    onRowClicked={onRowClicked}
                    quickFilterText={
                        !disableFESearch
                            ? searchTerm === ''
                                ? search
                                : searchTerm
                            : ''
                    }
                    ref={gridRef}
                    cacheQuickFilter={true}
                    getRowClass={getRowClass}
                    loadingCellRenderer={<CircularProgress size={35} />}
                    rowHeight={rowHeight}
                    animateRows={true}
                    rowSelection={rowSelection}
                    onRowSelected={handleOnCheck}
                    context={context}
                    doesExternalFilterPass={doesExternalFilterPass}
                    isExternalFilterPresent={isExternalFilterPresent}
                    rowClassRules={rowClassRules}
                    // suppress events
                    suppressRowClickSelection={suppressRowClickSelection}
                    suppressDragLeaveHidesColumns={true}
                    suppressCellFocus
                    suppressClickEdit={suppressClickEdit}
                    rowMultiSelectWithClick={false}
                    overlayLoadingTemplate={spinner}
                    tooltipShowDelay={0}
                    overlayNoRowsTemplate={`
                        <span style="color: gray">
                        No results for the selected filter
                        </span>
                    `}
                    legacyComponentRendering={true}
                    className={className}
                    getRowHeight={getRowHeight}
                    domLayout={domLayout}
                    getRowId={getRowId}
                    headerHeight={headerHeight}
                    defaultColDef={defaultColDef}
                    pinnedTopRowData={pinnedTopRowData}
                    onSortChanged={onSortChanged}
                    postSortRows={postSortRows}
                    onFilterChanged={onFilterChanged}
                    onColumnMoved={handleColumnMoved}
                    onDragStopped={(e) => {
                        toggleFilterColumnMenu(e);
                    }}
                    onColumnPinned={onColumnPinned}
                    onColumnVisible={onColumnVisible}
                    onColumnResized={handleColumnResize}
                    onFirstDataRendered={onFirstDataRendered}
                    onRowDataChanged={onRowDataChanged}
                    onCellKeyDown={onCellKeyDown}
                    onCellEditingStarted={onCellEditingStarted}
                    suppressPaginationPanel={suppressPaginationPanel}
                    onNewColumnsLoaded={setFilterColumnMenuListFunc}
                ></AgGridReact>

                {useCustomPagination ? (
                    <Box className="mt--10 flex flex--jcsb">
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <FWTypography
                                sx={{ color: '#818590', fontSize: '14px' }}
                            >
                                Rows per page
                            </FWTypography>
                            <FWSelect
                                sx={{
                                    width: '80px',
                                    height: '30px',
                                    backgroundColor: 'white',
                                    fontSize: '14px',
                                }}
                                value={rowsPerPage}
                                onChange={(event) => {
                                    setRowsPerPage(
                                        event.target.value as number
                                    );
                                    if (handleRowsPerPageChange) {
                                        handleRowsPerPageChange(
                                            event.target.value as number
                                        );
                                    }
                                }}
                            >
                                <FWMenuItem value={10}>10</FWMenuItem>
                                <FWMenuItem value={20}>20</FWMenuItem>
                                <FWMenuItem value={30}>30</FWMenuItem>
                                <FWMenuItem value={40}>40</FWMenuItem>
                            </FWSelect>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {totalItems ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mr: 5,
                                        color: '#1A1A1F',
                                    }}
                                >
                                    <FWItemsPerPage
                                        rowsPerPage={rowsPerPage}
                                        currentPage={customPaginationPageNumber}
                                        totalItems={totalItems}
                                    />
                                </Box>
                            ) : (
                                '0 of 0'
                            )}
                            {showNoOfItemsComponent &&
                                noOfItemsForThisPage !== undefined &&
                                customPaginationPageNumber !== undefined && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mr: 5,
                                            color: '#1A1A1F',
                                        }}
                                    >
                                        <FWTypography>
                                            {' '}
                                            {(+customPaginationPageNumber - 1) *
                                                10 +
                                                1 >
                                            noOfItemsForThisPage
                                                ? noOfItemsForThisPage
                                                : (+customPaginationPageNumber -
                                                      1) *
                                                      10 +
                                                  1}{' '}
                                            -{' '}
                                            {+customPaginationPageNumber * 10 >
                                            noOfItemsForThisPage
                                                ? noOfItemsForThisPage
                                                : +customPaginationPageNumber *
                                                  10}{' '}
                                            of {noOfItemsForThisPage}
                                        </FWTypography>
                                    </Box>
                                )}
                            <Pagination
                                count={useCustomPagination}
                                onChange={customPaginationOnChange}
                                page={customPaginationPageNumber}
                            />
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default FWDashboardDataGrid;
